const MyOrderLocalization = {
  percBadge: {
    label: {
      ar: "نسبة الاكتمال",
      en: "Completion rate",
    },
    start: {
      ar: "البداية",
      en: "Start",
    },
    end: {
      ar: "التسليم",
      en: "Delivery",
    },
  },
  videoType: {
    ar: "نوع الفيديو",
    en: "Video type",
  },
  serviceNumber: {
    ar: "رقم الخدمة",
    en: "Service number",
  },
  designerName: {
    ar: "اسم الموظف القائم بالتنفيذ",
    en: "The name of the executing employee",
  },
  clientName: {
    ar:"اسم العميل",
    en:"Client name",
  },
  chatLink: {
    ar: "مراسلة الموظف",
    en: "Chat with the employee",
  },
  chatLinkClient:{
    ar: "مراسلة العميل",
    en: "Chat with the client",

  },
  chatLinkSuper:{
    ar: "مراسلة المشرف",
    en: "Chat with the su[ervisor",

  },
  orderSteps: {
    ar:"مراحل تتبع العمل",
    en:"Order steps",
},
  videoPrice: {
    ar: "تكلفة الفيديو",
    en: "Video cost",
  },
  confirmLever : {
  ar:"تأكيد المرحلة",
  en:"confirm level"
  },
  videoDuration: {
    ar: "مدة الفيديو",
    en: "Video duration",
  },
  totalTime: {
    ar: "المدة الكلية للانتهاء",
    en: "Total duration time",
    helperSpan: {
      ar: "يوم عمل",
      en: "work day",
    },
  },
  detailsLink: {
    ar: "عرض التفاصيل",
    en: "View details",
  },
  completePaymentLabel: {
    ar: "اكمل الدفع",
    en: "Complete payment",
  },
  cancelOrder: {
    confirmation: {
      ar: "تأكيد",
      en: "Confirmation",
    },
    msg: {
      ar: "هل تريد الغاء الطلب؟",
      en: "Do you want to cancel order ?",
    },
    confirm: {
      ar: "تأكيد",
      en: "Confirm",
    },
    cancel: {
      ar: "إلغاء",
      en: "Cancel",
    },
  },
};

export default MyOrderLocalization;
