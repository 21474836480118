/* eslint-disable eqeqeq */
import axios from 'axios';
import userTypes from '../constants/userTypes';

const changeLanguage = async (code, user) => {
  const formData = new FormData();
  formData.append('lang', code);
  try {
    const response = await axios.post(
      `/api/v1/${
        user?.accountType == userTypes?.supervisor
          ? userTypes?.employee
          : user.accountType
      }/set-lang?api_token=${user?.api_token}`,
      formData,
      {
        headers: {
          'X-Portal':
            user?.accountType == userTypes?.supervisor
              ? userTypes?.employee
              : user.accountType,
          'X-Language': code,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS
        }
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export default changeLanguage;
