import React, { createContext, useState } from "react";
import Cookies from "js-cookie";

const INITIAL_VALUES = {
	temporaryUser: Cookies.get("tempUser")
		? JSON.parse(Cookies.get("tempUser"))
		: null,
	setTemporaryUser: user => {},
	removeTemporaryUser: () => {}
};
const TemporaryUserContext = createContext(INITIAL_VALUES);

export const TemporaryUserProvider = ({ children }) => {
	const [tempUser, setTempUser] = useState(
		Cookies.get("tempUser") ? JSON.parse(Cookies.get("tempUser")) : null
	);

	const setUser = user => {
		Cookies.set("tempUser", JSON.stringify(user), {
			sameSite: "strict",
			expires: 90
			// expires: 5 / (24 * 60 * 60) // 5 secondas
		});
		setTempUser(user);
	};

	const removeUser = () => {
		Cookies.remove("tempUser");
		setTempUser(null);
	};

	return (
		<TemporaryUserContext.Provider
			value={{
				temporaryUser: tempUser,
				setTemporaryUser: tUser => setUser(tUser),
				removeTemporaryUser: removeUser
			}}
		>
			{children}
		</TemporaryUserContext.Provider>
	);
};

export default TemporaryUserContext;
