/* eslint-disable react-hooks/exhaustive-deps */
import "./SupervisorOrders.scss";
import React, { useContext, useEffect, useState } from "react";
import LangContext from "../../../contexts/lang-context/LangProvider";
import UesrContext from "../../../contexts/user-context/UserProvider";
import DocTitleScrollTop from "../../../utils/DocTitleScrollTop";
import {
  confirmLevel,
  supervisorOrdersByIdApi,
} from "../../../apis/supervisor-apis/supervisorOrdersApi";
import SupervisorOrderCard from "./SupervisorOrderCard";
import ContentLoader from "react-content-loader";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import MyOrderLocalization from "../../../localization/myOrderCard.localization";
import { Button } from "@material-ui/core";
import OrderDetailsSteps from "../orderDetailsSteps/OrderDetailsSteps";
import Loading from "../../../common/loading/Loading";
const SupervisorOrdersDetails = () => {
  DocTitleScrollTop("My requests");
  const { user } = useContext(UesrContext);
  const {
    language: { code, dir },
  } = useContext(LangContext);

  const [myOrder, setMyOrder] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [completedLevel, setCompletedLevel] = useState(null);

  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const { id } = useParams();

  const getOrderById = async () => {
    setIsLoadingOrders(true);
    setShowConfirmButton(false)
    const data = await supervisorOrdersByIdApi(user, id, code);
    setMyOrder(data.data.data);
    const completedLevel = data.data.data.levels.findIndex(
      (item) => item.status === "complete"
    );
    data.data.data.levels.forEach((item) => {
      if(item.status === "complete") {
        setCompletedLevel(item.level_id);
      } else  if(item.status === "in_work") {
        setCurrentLevel(item.level_id)
        setShowConfirmButton(true)
      }
    })
    

    setIsLoadingOrders(false);
  };
  useEffect(() => {
    getOrderById();
  }, [code, id]);

  const confirmLevelById = async () => {
    setIsLoadingOrders(true);
    const data = await confirmLevel(user, id, code, currentLevel);
    console.log(data);
    getOrderById();
  };
  if (isLoadingOrders) {
    return (
      <>
        <div className="service-card-loader-box">
         <Loading/>
        </div>
      </>
    );
  } else if (myOrder) {
    return (
      <>
        <div className="my-orders-tab">
          <SupervisorOrderCard
            key={myOrder.level_id}
            card={myOrder}
            withBtn={false}
          />
          <OrderDetailsSteps myOrder={myOrder} showConfirmButton={showConfirmButton} confirmLevelById={confirmLevelById} />
        </div>
      </>
    );
  }
  return null;
};

export default SupervisorOrdersDetails;
