/* eslint-disable array-callback-return */
import axios from "axios";

export const employeeAcceptOrderApi = async (user, values, langCode) => {
  try {
    const response = await axios.post(
      "/api/v1/employee/orders/accept",
      values,
      {
        headers: {
          "X-Portal": user?.accountType,
          password: process.env.REACT_APP_AUTH_PASS,
          "Content-Type": "multipart/form-data",
          "X-Language": langCode,
        },
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};
