import axios from 'axios';

export const confirmAuthActivationCodeApi = async (data, langCode) => {
  try {
    const response = await axios.post('/api/v1/confirm-code', data, {
      headers: {
        password: process.env.REACT_APP_AUTH_PASS,
        'content-type': 'multipart/form-data',
        'X-Language': langCode
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const resendAuthActivationCodeApi = async (data, langCode) => {
  try {
    const response = await axios.post('/api/v1/resend-code', data, {
      headers: {
        password: process.env.REACT_APP_AUTH_PASS,
        'content-type': 'multipart/form-data',
        'X-Language': langCode
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};
