/* eslint-disable eqeqeq */
import "./DashboardPage.scss";

import React, { useContext } from "react";
import DashBoardSideMenu from "../../components/dashboard-side-menu/DashBoardSideMenu";
import LangContext from "../../contexts/lang-context/LangProvider";
import UserRoutes from "../../components/app/UserRoutes";
import userLinks from "./userLinks";
import designerLinks from "./designerLinks";
import DesignerRoutes from "../../components/app/DesignerRoutes";
import UesrContext from "../../contexts/user-context/UserProvider";
import userTypes from "../../constants/userTypes";
import SupervisorRoutes from "../../components/app/SupervisorRoutes";
import supervisroLinks from "./supervisorLinks";

const DashboardPage = () => {
  // const { pathname } = useLocation();
  const { language } = useContext(LangContext);
  const { user } = useContext(UesrContext);

  // useEffect(() => {
  // 	if (pathname === "/dashboard") {
  // 		if (false) {
  // 			customHistory.push("/dashboard/user/services");
  // 		} else {
  // 			customHistory.push("/dashboard/designer/home");
  // 		}
  // 	}
  // }, [pathname]);
  return (
    <div className="dashboard-page">
      <div className="mfa-container">
      <div className="dashboard-wrapper">
          {(user?.accountType == userTypes?.client || !user) && (
            <>
              <DashBoardSideMenu links={userLinks(language)} />
              <UserRoutes />
            </>
          )}
          {user?.activated && user?.accountType == userTypes.employee && (
            <>
              <DashBoardSideMenu links={designerLinks(language)} />
              <DesignerRoutes />
            </>
          )}
          {user?.activated && user?.accountType == userTypes.supervisor && (
            <>
              <DashBoardSideMenu links={supervisroLinks(language)} />
              <SupervisorRoutes />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
