const homeAppBarTranslations = {
	signin: {
		ar: "تسجيل الدخول",
		en: "signin"
	},
	or: {
		ar: "أو",
		en: "or"
	},
	signup: {
		ar: "سجل الأن",
		en: "signup"
	}
};

export default homeAppBarTranslations;
