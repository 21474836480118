/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState } from "react";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as Yup from "yup";
import FlashMessageContext from "../../contexts/flash-messages-context/FlashMessageProvider";
import forgetPasswordLocalization from "../../localization/forgetPassword.localization";
import LangContext from "../../contexts/lang-context/LangProvider";
import { changePassword } from "../../apis/auth/forgetPassword";
import ForgetPasswordContext from "../../contexts/forget-password-context/ForgetPasswordProvider";
import ButtonWithLoading from "../../common/button-with-loading/ButtonWithLoading";
import { useHistory, useParams } from "react-router-dom";
import routerLinks from "../app/routerLinks";
import useCustomApiRequest from "../../custom-hooks/useCustomApiRequest";
import checkRes from "../../utils/checkRes";

const ForgetPasswordNewPassword = () => {
  const history = useHistory();
  const params = useParams();
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);
  const {
    language: { code },
  } = useContext(LangContext);
  const { setTempEmail, correctCode } = useContext(ForgetPasswordContext);

  const { password, confirmPassword, submitBtn, successPassChanged } =
    forgetPasswordLocalization;

  const FORGET_PASSWORD_SCHEMA = Yup.object().shape({
    password: Yup.string()
      .required(password.errors.required[code])
      .min(6, password.errors.minChar(6)[code])
      .max(22, password.errors.maxChar(22)[code])
      .trim(password.errors.trim[code])
      // .trim("No leading or trailing spaces")
      .strict(),
    password_confirm: Yup.string()
      .required(confirmPassword.errors.required[code])
      .oneOf([Yup.ref("password")], confirmPassword.errors.validate[code]),
  });

  const [showPass, setShowPass] = useState(false);
  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const customApiRequest = useCustomApiRequest();

  return (
    <Formik
      initialValues={{
        password: "",
        password_confirm: "",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("type", params?.accountType);
        formData.append("pin_code", correctCode);
        formData.append("password", values?.password);
        formData.append("password_confirmation", values?.password_confirm);
        customApiRequest(
          changePassword(formData),
          (res) => {
            setSubmitting(false);
            if (checkRes(res)) {
              resetForm();
              setTempEmail("");
              setFlashMsgIsOpen(true);
              setFlashMsg(successPassChanged[code]);
              setFlashMsgDuration(4000);
              setNotificationSeverity("success");
              history.push(routerLinks.signinPage);
            } else if (res?.data?.status === 0) {
              if (res?.data?.message) {
                setFlashMsgIsOpen(true);
                setFlashMsg(res?.data?.message);
                setFlashMsgDuration(4000);
                setNotificationSeverity("error");
              }
            }
          },
          (error) => {
            setSubmitting(false);
            setFlashMsgIsOpen(true);
            setFlashMsg(error?.response?.data?.message || "حاول فى وقت لاحق");
            setFlashMsgDuration(4000);
            setNotificationSeverity("error");
          }
        );
      }}
      validationSchema={FORGET_PASSWORD_SCHEMA}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="forget-password-form"
            autoComplete="off"
            id="forget-password-form"
          >
            <Field
              component={TextField}
              name="password"
              type={showPass ? "text" : "password"}
              label={password.label[code]}
              variant="outlined"
              color="primary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              component={TextField}
              name="password_confirm"
              type={showPass ? "text" : "password"}
              label={confirmPassword.label[code]}
              variant="outlined"
              color="primary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
									<pre>{JSON.stringify(errors, null, 2)}</pre>
									<pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </form>
          <div className="send-btn">
            <ButtonWithLoading
              isSubmitting={isSubmitting}
              errors={errors}
              btnText={submitBtn.label[code]}
              loadingMsg={submitBtn.sending[code]}
              className="submit-btn"
              form="forget-password-form"
            />
          </div>
        </>
      )}
    </Formik>
  );
};

export default ForgetPasswordNewPassword;
