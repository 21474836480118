/* eslint-disable eqeqeq */
import "./ServicesCard.scss";

import React, { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import serviceBkg from "../../assets/imgs/services/service-bkg.svg";
import serviceBkgLight from "../../assets/imgs/services/service-bkg-light.svg";
import LangContext from "../../contexts/lang-context/LangProvider";
import homePageTranslations from "../../localization/homepage";
import routerLinks from "../../components/app/routerLinks";
import { Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import UesrContext from "../../contexts/user-context/UserProvider";
const selectBkg = (index) => {
  if (index === 1 || index === 7) {
    return serviceBkgLight;
  }
  return serviceBkg;
};

// id, uni_code, title, video
const ServicesCard = ({ index, title, to, video, image, id }) => {
  const {
    language: { code },
  } = useContext(LangContext);
  const { user } = useContext(UesrContext);

  const {
    servicesSection: {
      serviceCard: { btnText },
    },
  } = homePageTranslations;

  // ///////////////
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const handleVideoModalOpen = () => {
    setVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "video-wrap": {
      border: 0,
      outline: 0,
      position: "fixed",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      display: "grid",
      placeContent: "center",
    },
  }));
  const classes = useStyles();

  const arr = [
    {
      id: 1,
      ar: "الفت انتباه جمهورك أكثر بفيديوهات الموشن جرافيك",
      en: "Capture your audience's attention with engaging motion graphics",
    },
    {
      id: 2,
      ar: "تغلب على ملل البيانات بقصة مرسومة في فيديو رسوم متحركة ثنائية الأبعاد",
      en: "Beat data boredom with a story presented in a 2D animated video",
    },
    {
      id: 3,
      ar: "اوصل إلى ذروة الإبداع بتكنولوجيا الرسوم المتحركة ثلاثية الأبعاد في فيديوهاتك",
      en: "Get creative with 3D animation technology in your videos",
    },
    {
      id: 4,
      ar: "امنح قصة مشروعك لمسات حية تبيع لك أكثر",
      en: "Give your project's story lively touches that sell you more",
    },
    {
      id: 5,
      ar: "محتاج تغيّر طريقة ظهورك وحضورك للجمهور؟ قدم شركتك بشكل مختلف عبر الفيديو المتكامل",
      en: "Need to change the way you appear to the audience? Present your company differently with integrated video",
    },
    {
      id: 6,
      ar: "عندك لقطات كثيرة ولا تعرف كيف تصنع منها قصة؟ سجلها ونحن نعدلها",
      en: "Have multiple clips but unsure how to weave them into a story? Record them, and we'll edit it for you",
    },
    {
      id: 7,
      ar: "عميلك يهرب بسرعة؟ طوّل وجوده في موقعك وحساباتك بالفيديوهات المصورة",
      en: "Is your audience quickly losing interest? Extend their engagement on your website and accounts with captivating videos",
    },
    {
      id: 8,
      ar: "قلقان بشأن تصوير منتجاتك، مناسباتك،، إعلاناتك، أو جلسات الموضة؟ صوّرها باحتراف معنا.",
      en: "Worried about shooting your products, events, advertisements or fashion sessions? Photograph them professionally with us.",
    },
  ];

  const renderP = (id) => {
    const found = arr.find((item) => item?.id == id);
    if (found) return found[code];
  };

  return (
    <>
      <div className='service-container'>
        <div
          className='service-card'
          // style={{ backgroundImage: `url(${selectBkg(index)})` }}
          style={{ backgroundImage: `url(/VideoPanel${image})` }}
        >
          <div className='service-btns'>
            <RouterLink
              className='service-link'
              // to={user?.accountType === 'client' ? to : routerLinks.userSignin}
              to={user ? to : "/signin/user"}
            >
              {btnText[code]}
            </RouterLink>

            <div className='play-btn' onClick={handleVideoModalOpen}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22.501'
                height='25.717'
                viewBox='0 0 22.501 25.717'
              >
                <path
                  id='Icon_awesome-play'
                  data-name='Icon awesome-play'
                  d='M21.316,10.784,3.636.332A2.4,2.4,0,0,0,0,2.406v20.9A2.409,2.409,0,0,0,3.636,25.38l17.68-10.447A2.408,2.408,0,0,0,21.316,10.784Z'
                  transform='translate(0 -0.002)'
                />
              </svg>
            </div>
          </div>
          <div
            className='overlay-bg'
            style={{
              backgroundImage: `url(${process.env.REACT_APP_BACK_END_URL}${image})`,
            }}
          ></div>
        </div>
        <div className='service-description'>
          <p className='service-name'>{title}</p>
          <p className='service-p'>{renderP(id)}</p>
        </div>
      </div>
      {video && (
        <Modal
          className='video-modal'
          open={videoModalOpen}
          onClose={handleVideoModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={videoModalOpen}>
            <div className={classes["video-wrap"]}>
              <button
                onClick={() => {
                  setVideoModalOpen(false);
                }}
                className='close-btn'
              >
                x
              </button>
              <video
                controls
                style={{ borderRadius: 5, width: "auto", maxWidth: "90vw" }}
                src={process.env.REACT_APP_BACK_END_URL + video}
              ></video>
            </div>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default ServicesCard;
