import React, { createContext, useState } from "react";

const INITIAL_STATE = {
  tempEmail: "",
  setTempEmail: (token) => {},
  correctCode: false,
  setCorrectCode: (code) => {},
};

const ForgetPasswordContext = createContext(INITIAL_STATE);

export const ForgetPasswordProvider = ({ children }) => {
  const [tempEmail, setTempEmail] = useState(INITIAL_STATE.tempToken);
  const [correctCode, setCorrectCode] = useState(INITIAL_STATE.correctCode);
  return (
    <ForgetPasswordContext.Provider
      value={{
        tempEmail,
        setTempEmail,
        correctCode,
        setCorrectCode,
      }}
    >
      {children}
    </ForgetPasswordContext.Provider>
  );
};

export default ForgetPasswordContext;
