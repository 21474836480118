/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import parse from 'html-react-parser';

import './ServiceStepZero.scss';
import LangContext from '../../../contexts/lang-context/LangProvider';

const ServiceStepZero = ({ foundService }) => {
  const {
    language: { code }
  } = useContext(LangContext);
  const trans = {
    about: {
      ar: 'نبذة عن الخدمة',
      en: 'About service'
    },
    recog: {
      ar: 'ما يميز الخدمة',
      en: 'What distinguishes it'
    },
    desc: {
      ar: 'مواصفات الخدمة',
      en: 'Service Specifications'
    }
  };
  return (
    <div className="service-step-zero">
      <div className="about-wrapper">
        <div className="wrapper-label">{trans.about[code]}</div>
        <div>{foundService?.about && parse(foundService.about)}</div>
      </div>
      <hr />
      <div className="recognition-wrapper">
        <div className="wrapper-label">{trans.recog[code]}</div>
        <div>
          {foundService?.recognition && parse(foundService?.recognition)}
        </div>
      </div>
      <hr />
      <div className="desc-wrapper">
        <div className="wrapper-label">{trans.desc[code]}</div>
        <div>
          {foundService?.description && parse(foundService.description)}
        </div>
      </div>
    </div>
  );
};

export default ServiceStepZero;
