import { useContext } from 'react';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';
import ServiceStepEight from '../service-step-eight/ServiceStepEight';
import * as Yup from 'yup';

const ServiceSixStepNine = ({ setStep }) => {
  const {
    stepOne: {
      scriptIsReady: { yes, no }
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection }
  } = serviceDetailsLocalization;
  const {
    language: { code }
  } = useContext(LangContext);
  return (
    <>
      <ServiceStepEight
        setStep={setStep}
        validationSchema={Yup.object().shape({
          targetAudience: Yup.array()
            .of(Yup.string().required())
            .min(1, targetAudience.errors.required[code]),
          otherTargetAudience: Yup.string().when(
            'targetAudience_other_checkbox',
            {
              is: true,
              then: Yup.string().required(
                targetAudience.otherTextarea.errors.required[code]
              )
            }
          )
        })}
      />
    </>
  );
};

export default ServiceSixStepNine;
