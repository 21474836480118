import "./SharedBtn.styles.scss";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import LangContext from "../../contexts/lang-context/LangProvider";
const SharedBtn = ({ buttonComp, to, children, btnClass, ...props }) => {
	const { language } = useContext(LangContext);

	if (buttonComp) {
		return (
			<div
				className={`shared-btn ${btnClass ? btnClass : ""} ${language.dir}`}
				{...props}
			>
				{children}
			</div>
		);
	}
	return (
		<RouterLink
			to={to}
			className={`shared-btn ${btnClass ? btnClass : ""} ${language.dir}`}
		>
			{children}
		</RouterLink>
	);
};

SharedBtn.propTypes = {
	to: PropTypes.string,
	btnClass: PropTypes.string,
	children: PropTypes.any
};

export default SharedBtn;
