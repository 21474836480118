import React, { useContext, useState } from "react";
import ServiceStepOne from "./service-step-one/ServiceStepOne";
import ServiceStepTwo from "./service-step-two/ServiceStepTwo";
import ServiceStepThree from "./service-step-three/ServiceStepThree";
import ServiceStepFour from "./service-step-four/ServiceStepFour";
import ServiceStepFive from "./service-step-five/ServiceStepFive";
import ServiceStepSix from "./service-step-six/ServiceStepSix";
import ServiceStepSeven from "./service-step-seven/ServiceStepSeven";
import ServiceStepEight from "./service-step-eight/ServiceStepEight";
import ServiceStepNine from "./service-setp-nine/ServiceStepNine";
import ServiceStepTen from "./service-step-ten/ServiceStepTen";
import serviceDetailsLocalization from "../../localization/serviceDetailsLocalization";
import * as Yup from "yup";
import LangContext from "../../contexts/lang-context/LangProvider";
import FormikStepper from "../../common/formik-stepper/FormikStepper";
import FlashMessageContext from "../../contexts/flash-messages-context/FlashMessageProvider";
import { service1234InitialValues } from "./serviceDetailsInitialValues";
import UesrContext from "../../contexts/user-context/UserProvider";
import useCustomApiRequest from "../../custom-hooks/useCustomApiRequest";
import checkRes from "../../utils/checkRes";
import { useHistory } from "react-router-dom";
import makeOrder1234Api from "../../apis/user/makeOrder1234Api";
import routerLinks from "../app/routerLinks";
import ServiceStepZero from "./service-step-zero/ServiceStepZero";
const Service1234Steps = ({
  serviceId,
  foundService,
  setIsSubmittingOrder,
  setSuccessOrder,
  setSingleOrder,
}) => {
  const history = useHistory();
  const SUPPORTED_FORMATS = {
    pdf: ["application/pdf"],
    images: ["image/jpg", "image/jpeg", "image/png"],
  };

  const FILE_SIZE = 1024 * 1024; // 1024 * 1024 bytes = 1024 kbytes = 1 megabytes
  // const FILE_SIZE = 100 * 1024; // 100 kbytes
  const { user } = useContext(UesrContext);
  const {
    language: { code },
  } = useContext(LangContext);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, musicSelection, colorSelection },
  } = serviceDetailsLocalization;

  const customApiRequest = useCustomApiRequest();
  const [step, setStep] = useState(0);

  return (
    <FormikStepper
      step={step}
      setStep={setStep}
      stepperType="user"
      className="formik-stepper"
      initialValues={service1234InitialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        setIsSubmittingOrder(true);
        customApiRequest(
          makeOrder1234Api(user, foundService, values),
          (res) => {
            setIsSubmittingOrder(false);
            setSubmitting(false);
            if (checkRes(res)) {
              setFlashMsgIsOpen(true);
              setFlashMsg(res?.data?.message);
              setFlashMsgDuration(4000);
              setNotificationSeverity("success");
              history.push(routerLinks?.userCart);
            } else {
              setFlashMsgIsOpen(true);
              setFlashMsg(res?.data?.message || "Something went wrong");
              setFlashMsgDuration(4000);
              setNotificationSeverity("error");
            }
          },
          (error) => {
            setIsSubmittingOrder(false);
            setSubmitting(false);
            setFlashMsgIsOpen(true);
            setFlashMsg(
              error?.response?.data?.message || "Something went wrong"
            );
            setFlashMsgDuration(4000);
            setNotificationSeverity("error");
          }
        );
        // try {
        //   setSubmitting(true);
        //   setIsSubmittingOrder(true);
        //   const res = await makeOrder(user?.token, serviceId, values);
        //   // console.log(res);
        //   if (res.status === 200 && res.data.data && res.data.status === 1) {
        //     // resetForm();
        //     setSingleOrder(res.data.data);
        //     setSubmitting(false);
        //     setIsSubmittingOrder(false);
        //     setSuccessOrder(true);
        //   } else {
        //     setSubmitting(false);
        //     setIsSubmittingOrder(false);
        //     //
        //     setFlashMsgIsOpen(true);
        //     setFlashMsg(res?.data?.message);
        //     setFlashMsgDuration(4000);
        //     setNotificationSeverity("error");
        //   }
        // } catch (err) {
        //   console.log(err);
        //   setSubmitting(false);
        //   setIsSubmittingOrder(false);
        //   //
        //   setFlashMsgIsOpen(true);
        //   setFlashMsg("Some thing went wrong. please try again later");
        //   setFlashMsgDuration(4000);
        //   setNotificationSeverity("error");
        // }
      }}
    >
      <ServiceStepZero foundService={foundService} />
      <ServiceStepOne
        validationSchema={Yup.object().shape({
          scriptIsReady: Yup.string().required(),
          script: Yup.string().when("scriptIsReady", {
            is: "ready",
            then: Yup.string().required(yes.textarea.errors.required[code]),
          }),
          //////////////////////////////
          numberOfWords: Yup.string().when("scriptIsReady", {
            is: "ready",
            then: Yup.string()
              .matches(/^[0-9]+$/, yes.wordsCount.errors.isNumber[code])
              .required(yes.wordsCount.errors.required[code]),
          }),
          acceptScript: Yup.string().when("scriptIsReady", {
            is: "ready",
            then: Yup.string().required(yes.acceptScript.errors.required[code]),
          }),
          reviewedVideoDuration: Yup.string()
            .test(
              "reviewedVideoDuration",
              code === "ar" ? "أدخل مدة الفيديو" : "Enter video duration",

              (v, context) => {
                let result = true;
                if (
                  (!v || !parseFloat(v) > 0) &&
                  (context?.parent?.acceptScript === "review" ||
                    context?.parent?.scriptIsReady === "not_ready")
                ) {
                  result = false;
                }
                return result;
              }
            )
            .test(
              "reviewedVideoDuration",
              "لا يسمح الا بكتابة الارقام",
              (v, context) => {
                let result = true;
                if (
                  v &&
                  !v.match(/^(\d+)?(\.\d+)?$/) &&
                  (context?.parent?.acceptScript === "review" ||
                    context?.parent?.scriptIsReady === "not_ready")
                ) {
                  result = false;
                }
                return result;
              }
            ),
          //////////////////////////////
          briefScript: Yup.string().when("scriptIsReady", {
            is: "not_ready",
            then: Yup.string().required(no.textarea.errors.required[code]),
          }),
        })}
        // label="معلومات التسجيل الاساسية"
      />
      {/*  */}
      <ServiceStepTwo
        validationSchema={Yup.object().shape({
          videoLang: Yup.string().required(
            stepTwo.videoLang.errors.required[code]
          ),
          // displayMethod: Yup.string().required(
          //   stepTwo.displayMethod.errors.required[code]
          // ),
          voiceOver: Yup.string().required(
            stepTwo.voiceOver.errors.required[code]
          ),
        })}
      />
      {/*  */}
      <ServiceStepThree
        validationSchema={Yup.object().shape({
          // arabicVoiceOver: Yup.string().required(
          // 	stepThree.errors.requiredVoiceOver[code]
          // )
          // ,
          arabicVoiceOverModel: Yup.string().when("arabicVoiceOver", {
            is: (val) => val,
            then: Yup.string().required(stepThree.errors.requiredModel[code]),
          }),
          // englishVoiceOver: Yup.string().required(
          // 	stepThree.errors.requiredVoiceOver[code]
          // )
          // ,
          englishVoiceOverModel: Yup.string().when("englishVoiceOver", {
            is: (val) => val,
            then: Yup.string().required(stepThree.errors.requiredModel[code]),
          }),
          count_voice_comment: Yup.string()
            .matches(/^[1-9]+$/, "Please enter a valid number")
            .required("Please enter the number of interlocutors"),
        })}
      />
      {/*  */}
      <ServiceStepFour
      // validationSchema={Yup.object().shape({
      // 	twitterScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	instagramScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	facebookScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	snapChatScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	youtubeScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	)
      // })}
      />
      {/*  */}
      <ServiceStepFive
        validationSchema={Yup.object().shape({
          // companySpeciality: Yup.string().required(
          //   companySpeciality.errors.required[code]
          // ),
          otherCompSpeciality: Yup.string().when("companySpeciality", {
            is: "other",
            then: Yup.string().required(
              companySpeciality.otherTextarea.errors.required[code]
            ),
          }),
          brandName: Yup.string().required(brandName.errors.required[code]),
        })}
      />
      {/*  */}
      <ServiceStepSix
        setStep={setStep}
        validationSchema={Yup.object().shape({
          // videoPurpose: Yup.string().required(
          //   videoPurpose.errors.required[code]
          // ),
          otherVideoPurpose: Yup.string().when("videoPurpose", {
            is: "other",
            then: Yup.string().required(
              videoPurpose.otherTextarea.errors.required[code]
            ),
          }),
          // targetCategory: Yup.string().required(
          //   targetCategory.errors.required[code]
          // )
        })}
      />
      {/*  */}
      <ServiceStepSeven
        setStep={setStep}
        validationSchema={Yup.object().shape({
          // targetSector: Yup.string().required(
          //   targetSector.errors.required[code]
          // ),
          otherTargetSector: Yup.string().when("targetSector", {
            is: "other",
            then: Yup.string().required(
              targetSector.otherTextarea.errors.required[code]
            ),
          }),
        })}
      />
      {/*  */}
      <ServiceStepEight
        setStep={setStep}
        validationSchema={Yup.object().shape({
          // targetAudience: Yup.array()
          //   .of(Yup.string().required())
          //   .min(1, targetAudience.errors.required[code]),
          otherTargetAudience: Yup.string().when(
            "targetAudience_other_checkbox",
            {
              is: true,
              then: Yup.string().required(
                targetAudience.otherTextarea.errors.required[code]
              ),
            }
          ),
        })}
      />
      {/*  */}
      <ServiceStepNine
        setStep={setStep}
        // validationSchema={Yup.object().shape({
        //   offerPackages: Yup.string().required(offerPackages.errors[code]),
        //   highlightPoint: Yup.string().required(highlightPoint.errors[code])
        // })}
      />
      {/*  */}
      <ServiceStepTen
        validationSchema={Yup.object().shape({
          socialMediaAddresses: Yup.object().shape({
            socialCheckBoxs: Yup.array(),
            // .of(Yup.string().required())
            // .min(1, socialMedia.errors.required[code]),

            inputs: Yup.object().shape({
              twitter: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.twitter",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "twitter"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                  // error shows if the return of the test function is false
                }
              ),
              facebook: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.facebook",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "facebook"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
              instagram: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.instagram",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "instagram"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),

              snapchat: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.snapchat",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "snapchat"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),

              youtube: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.youtube",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "youtube"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
              linkedin: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.linkedin",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "linkedin"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
              tiktok: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.tiktok",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "tiktok"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
            }),
          }),
          font: Yup.string().required(fontSelection.errors.required[code]),
          uploadedFont: Yup.mixed().when("font", {
            is: "yes",
            then: Yup.mixed().required(fontSelection.yes.errors.required[code]),
            // .test(
            //   "fontFormat",
            //   fontSelection.yes.errors.unSuppFormat[code],
            //   (value) => {
            //     let result = true;
            //     if (value && !SUPPORTED_FORMATS.pdf.includes(value.type)) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // )
            // .test(
            //   "fontSize",
            //   fontSelection.yes.errors.tooLarge[code],
            //   (value) => {
            //     let result = true;
            //     if (value && value.size > FILE_SIZE) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
          }),
          music: Yup.string().required(musicSelection.errors.required[code]),
          uploadedMusic: Yup.mixed().when("font", {
            is: "yes",
            then: Yup.mixed().required(
              musicSelection.yes.errors.required[code]
            ),
            // .test(
            //   "fontFormat",
            //   musicSelection.yes.errors.unSuppFormat[code],
            //   (value) => {
            //     let result = true;
            //     if (value && !SUPPORTED_FORMATS.pdf.includes(value.type)) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // )
            // .test(
            //   "fontSize",
            //   musicSelection.yes.errors.tooLarge[code],
            //   (value) => {
            //     let result = true;
            //     if (value && value.size > FILE_SIZE) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
          }),
          color: Yup.string().required(colorSelection.errors.required[code]),
          uploadedColor: Yup.mixed().when("color", {
            is: "yes",
            then: Yup.mixed().required(
              colorSelection.yes.errors.required[code]
            ),
            // .test(
            //   "fileFormat1",
            //   colorSelection.yes.errors.unSuppFormat[code],
            //   (value) => {
            //     let result = true;
            //     if (value && !SUPPORTED_FORMATS.pdf.includes(value.type)) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
            // .test(
            //   "fileSize1",
            //   colorSelection.yes.errors.tooLarge[code],
            //   (value) => {
            //     let result = true;
            //     if (value && value.size > FILE_SIZE) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
          }),
        })}
      />
    </FormikStepper>
  );
};

export default Service1234Steps;
