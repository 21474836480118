/* eslint-disable react-hooks/exhaustive-deps */
import './ServiceStepNine.scss';

import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';

const ServiceStepNine = ({ setStep }) => {
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    stepNine: { offerPackages, highlightPoint }
  } = serviceDetailsLocalization;
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    if (values.videoPurpose !== 'other' && values.otherTargetSector) {
      setFieldValue('otherTargetSector', '');
    }
  }, [values.videoPurpose]);
  useEffect(() => {
    if (
      values?.scriptIsReady === 'ready' &&
      values?.acceptScript === 'accept'
    ) {
      setStep((prev) => prev + 1);
    }
  }, [values]);

  return (
    <div className="formik-step service-step-nine">
      {((values?.scriptIsReady === 'ready' &&
        values?.acceptScript === 'review') ||
        values?.scriptIsReady === 'not_ready') && (
        <>
          <div className="textarea-section">
            <p className="textarea-title">{offerPackages.title[code]}</p>
            <Field
              component={TextField}
              label={offerPackages.label[code]}
              name="offerPackages"
              variant="outlined"
              multiline
              rows="4"
              className="textarea-field"
            />
          </div>
          {/*  */}
          <div className="textarea-section">
            <p className="textarea-title">{highlightPoint.title[code]}</p>
            <Field
              component={TextField}
              label={highlightPoint.label[code]}
              name="highlightPoint"
              variant="outlined"
              multiline
              rows="4"
              className="textarea-field"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceStepNine;
