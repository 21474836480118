/* eslint-disable eqeqeq */
import axios from 'axios';
import userTypes from '../../constants/userTypes';

// 'pending','accepted','completed'
const profileApi = async (user, langCode) => {
  try {
    const res = await axios.get(
      `/api/v1/${
        user?.accountType == userTypes?.supervisor
          ? userTypes?.employee
          : user.accountType
      }/profile?api_token=${user?.api_token}`,
      {
        headers: {
          'X-Portal':
            user?.accountType == userTypes?.supervisor
              ? userTypes?.employee
              : user.accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        }
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};

export default profileApi;
