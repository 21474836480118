/* eslint-disable react-hooks/exhaustive-deps */
import './SigninPage.scss';
import React, { useContext, useEffect, useState } from 'react';
import {
  Link as RouterLink,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import LangContext from '../../contexts/lang-context/LangProvider';
import Layout from '../../components/layout/Layout';
import signinPage from '../../localization/signinPage';
import DesignerSignin from '../../components/designer-signin/DesignerSignin';
import UserSignin from '../../components/user-signin/UserSignin';
import LangSelect from '../../common/lang-select/LangSelect';
import Logo from '../../common/logo/Logo';
import slugify from 'slugify';
import routerLinks from '../../components/app/routerLinks';
import SuperVisorSignin from '../../components/supervisor-signin/SupervisorSignin';

const SigninPage = () => {
  const {
    signinLinks: { userLink, developerLink, supervisorLink }
  } = signinPage;
  const {
    language: { code }
  } = useContext(LangContext);

  const { pathname } = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    setActiveTab(slugify(pathname));
    if (slugify(pathname) === slugify(routerLinks.signinPage))
      history.push(routerLinks.userSignin);
  }, [pathname]);

  const tabs = [
    {
      id: 1,
      name: userLink[code],
      route: routerLinks.userSignin,
      className: 'user-signup-link'
    },
    {
      id: 2,
      name: developerLink[code],
      route: routerLinks.designerSignin,
      className: 'developer-signup-link'
    },
    {
      id: 3,
      name: supervisorLink[code],
      route: routerLinks.supervisorSignin,
      className: 'supervisor-signup-link'
    }
  ];

  const renderSignupLinks = () => {
    return tabs.map(({ id, name, route, className }) => (
      <RouterLink
        key={id}
        className={`${className} ${
          activeTab === slugify(route) ? 'active' : ''
        }`}
        to={route}
      >
        {name}
      </RouterLink>
    ));
  };

  return (
    <Layout>
      <Route exact path={routerLinks.userSignin}>
        <div className="signup-page">
          <div className="mfa-container">
            <div className="page-wrapper">
              <div className="logo-lang-btn">
                <div className="logo-signup-links">
                  <Logo />
                  <div className="signup-links">{renderSignupLinks()}</div>
                </div>
                <LangSelect />
              </div>
              {/* signin user component */}
              <UserSignin />
            </div>
          </div>
        </div>
      </Route>
      {/*  */}
      <Route exact path={routerLinks.designerSignin}>
        <div className="signup-page">
          <div className="mfa-container">
            <div className="page-wrapper">
              <div className="logo-lang-btn">
                <div className="logo-signup-links">
                  <Logo />
                  <div className="signup-links">{renderSignupLinks()}</div>
                </div>
                <LangSelect />
              </div>
              {/* signin developer component */}
              <DesignerSignin />
            </div>
          </div>
        </div>
      </Route>
      {/*  */}
      <Route exact path={routerLinks.supervisorSignin}>
        <div className="signup-page">
          <div className="mfa-container">
            <div className="page-wrapper">
              <div className="logo-lang-btn">
                <div className="logo-signup-links">
                  <Logo />
                  <div className="signup-links">{renderSignupLinks()}</div>
                </div>
                <LangSelect />
              </div>
              {/* signin developer component */}
              <SuperVisorSignin />
            </div>
          </div>
        </div>
      </Route>
    </Layout>
  );
};

export default SigninPage;
