import axios from 'axios';
import moment from 'moment';

const makeOrder7Api = async (user, foundService, values) => {
  const {
    film_material_address,
    film_material_lat,
    film_material_lng,
    film_material_from_time,
    film_material_to_time,
    film_material_date,
    film_material_contact_num,
    film_material_photo_area,
    film_material_photo_type,
    film_material_count_day,
    film_material_photo_count_people,
    country,
    film_material_city_id,
    //
    briefScript,
    highlightPoint,
  } = values;

  try {
    const formData = new FormData();
    formData.append('api_token', user?.api_token);
    foundService && formData.append('service_id', foundService.id);

    formData.append('film_material_type', 'out_film');

    formData.append('film_material_address', film_material_address);
    formData.append('film_material_lat', film_material_lat);
    formData.append('film_material_lng', film_material_lng);
    formData.append('film_material_from_time', moment(film_material_from_time, "h a").format("HH:mm:ss"));
    formData.append('film_material_to_time', moment(film_material_to_time, "h a").format("HH:mm:ss"));
    formData.append('film_material_date', film_material_date?.format('YYYY-MM-DD'));
    formData.append('film_material_contact_num', film_material_contact_num);
    formData.append('film_material_photo_area', film_material_photo_area);
    formData.append('film_material_photo_type', film_material_photo_type);
    formData.append('country', country);
    formData.append('film_material_city_id', film_material_city_id);

    if (film_material_photo_type === 'press_coverage') {
      formData.append('film_material_count_day', film_material_count_day);
    }

    if (film_material_photo_type === 'meetings') {
      formData.append(
        'film_material_photo_count_people',
        film_material_photo_count_people
      );
    }

    formData.append('video_text', briefScript);

    const response = await axios.post('/api/v1/client/add-to-card', formData, {
      headers: {
        'X-Portal': user?.accountType,
        password: process.env.REACT_APP_AUTH_PASS,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (e) {
    console.log('errors : ', e)
    throw e;
  }
};

export default makeOrder7Api;
