import "./EmployeeOrdersCard.scss";

import React, { useContext,useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import MyOrderLocalization from "../../../localization/myOrderCard.localization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import ChatIcon from "../../../common/icons/ChatIcon";
import routerLinks from "../../../components/app/routerLinks";
import EmployeeOrdersContext from "../../../contexts/employee-orders-context/EmployeeOrdersContext";
import { orderStatusValues } from "../../../orderStatus";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UesrContext from "../../../contexts/user-context/UserProvider";
import { getSuperVisorMessagesApi } from "../../../apis/conversations/messages";

const EmployeeOrdersCard = ({ withBtn, card, ...props }) => {
  const {
    language: { code, dir },
  } = useContext(LangContext);
  const { user } = useContext(UesrContext);

  const {
    setAcceptOrderModalOpened,
    setRejectOrderModalOpened,
    setSelectedOrder,
  } = useContext(EmployeeOrdersContext);
  const {
    percBadge,
    videoType,
    serviceNumber,
    designerName,
    videoPrice,
    videoDuration,
    totalTime,
    detailsLink,
    chatLinkSuper
  } = MyOrderLocalization;

  const history = useHistory()

	const getMessage = useCallback(async (orderId ) => {
		const data = await getSuperVisorMessagesApi(user, orderId,"","");
    history.push(`/dashboard/designer/messages/${orderId}`)
	}, [user,history])


  return (
    <div className="employee-orders-card">
      {/*  */}
      {/*  */}
      <div className={`mini-card ${dir}`}>
        <div className="mini-title">{card?.service?.en_title}</div>
        <div className="mini-badge">
          <div className="badge-label">{percBadge.label[code]}</div>
          <div className="badge-bar-wrap">
            <div className="badge-bar">
              <div className="empty-bar">
                <div
                  className="filled-bar"
                  style={{
                    width: `${
                      card?.completed_ratio ? card.completed_ratio : 0
                    }%`,
                  }}
                >
                  <span className="perc-span">
                    {card?.completed_ratio ? card.completed_ratio : 0}%
                  </span>
                  <span className="ball-span"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="start-end-wrap">
            {card?.start_date && (
              <div className="start-warp">
                <div className="start-label">{percBadge.start[code]}</div>
                <div className="start-date">{card.start_date}</div>
              </div>
            )}
            {card?.end_date && (
              <div className="end-wrap">
                <div className="end-label">{percBadge.end[code]}</div>
                <div className="end-date">{card.end_date}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*  */}
      {/*  */}

      <div className="card-details">
        <div className="type-number-wrap">
          <div className="video-type">
            <span className="label-span">{videoType[code]} : </span>
            <span className="value-span">
              {card?.service?.en_title || card?.service?.ar_title || ""}
            </span>
          </div>
          <div className="service-number">
            <span className="label-span">{serviceNumber[code]} : </span>
            <span className="value-span">{card?.id}</span>
          </div>
        </div>

        {/* <div className="designer-name">
          <span className="label-span">{designerName[code]} : </span>
          <span className="value-span">
            <span className="name">----</span>
            <span className="job"></span>
          </span>
        </div> */}
        <div className="duration-total-wrap">
          <div className="videoDuration">
            <span className="label-span">{videoDuration[code]} : </span>
            <span className="value-span">{card?.duration} دقيقة</span>
          </div>
          <div className="total-time">
            <span className="label-span">{totalTime[code]} : </span>
            <span className="value-span">{card?.total_days || "---"} </span>
            <span className="helper-span"> {totalTime.helperSpan[code]}</span>
          </div>
        </div>
        <div className="video-price">
          {/* <span className="label-span">{videoPrice[code]} : </span> */}
          {/* <span className="value-span">{card?.total_price}$</span> */}
        </div>
        { card.status === "accepted" && <Button
          // to="/dashboard/user/my-orders/order-details/chat-with-designer"
          className="chat-link"
          onClick={() => {getMessage(card.id)}}

        >
          <div className="value-span">
            <ChatIcon />
          </div>
          <span>{chatLinkSuper[code]}</span>
        </Button>}
        {/* <div className="tags-wrapper">
          <div className="tag-wrap">
            <LocalOfferRounded />
            <span>ترجمة</span>
          </div>
          <div className="tag-wrap">
            <LocalOfferRounded />
            <span>كتابة الاسكربت</span>
          </div>
          <div className="tag-wrap">
            <LocalOfferRounded />
            <span>لهجة عربية فصحى</span>
          </div>
          <div className="tag-wrap">
            <LocalOfferRounded />
            <span>لهجة اماراتية</span>
          </div>
        </div> */}
      </div>

      {/*  */}
      {/*  */}
      <div className="card-side-wrapper">
        {card?.orderStatus === orderStatusValues?.pending && (
          <>
            <button
              className="accept-btn"
              onClick={() => {
                setSelectedOrder(card);
                setAcceptOrderModalOpened(true);
              }}
            >
              الموافقة
            </button>
            <button
              onClick={() => {
                setSelectedOrder(card);
                setRejectOrderModalOpened(true);
              }}
              className="reject-btn"
            >
              رفض الطلب
            </button>
          </>
        )}
        {withBtn && (
          <RouterLink
            to={routerLinks?.designerSingleProject(card?.id)}
            className="details-link"
          >
            {detailsLink[code]}
          </RouterLink>
        )}
      </div>
    </div>
  );
};

export default EmployeeOrdersCard;
