import "./UserSignup.scss";

import React, { useContext } from "react";
import personOnChairImg from "../../assets/imgs/signup-signin/person-on-chair.png";
import signupPage from "../../localization/signupPage";
import LangContext from "../../contexts/lang-context/LangProvider";
import UserSignupForm from "./UserSignupForm";

const UserSignup = ({type}) => {
	const {
		userSignup: { title: sectionTitle }
	} = signupPage;
	const {
		language: { code, dir }
	} = useContext(LangContext);

	return (
		<div className="user-signup-section">
			<div className="title-form-section">
				<div className="section-title">{sectionTitle[code]}</div>

				<UserSignupForm type={type} />
			</div>
			<div className={`section-img ${dir}`}>
				<img src={personOnChairImg} alt="person on chair" />
			</div>
		</div>
	);
};

export default UserSignup;
