import "./ApplicationStepOne.scss";

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { PersonRounded, PublishRounded } from "@material-ui/icons";
import { Field, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Select, TextField } from "formik-material-ui";
import designerApplicationLocalization from "../../../localization/designerApplication.localization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import useCountries from "../../../custom-hooks/useCountries";
import UesrContext from "../../../contexts/user-context/UserProvider";
import citiesApi from "../../../apis/general/citiesApi";
import Loading from "../../../common/loading/Loading";
const ApplicationStepOne = () => {
  const { stepOne } = designerApplicationLocalization;
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const { allCountries } = useCountries();
  useEffect(() => {
    if (selectedCountryId) {
    }
  }, [selectedCountryId]);
  const {
    language: { code },
  } = useContext(LangContext);
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext();

  // const sleep = ms => new Promise(r => setTimeout(r, ms));
  const handleFileUpload = async (name, event) => {
    // await sleep(500);
    let file = await event.target.files[0];
    setFieldValue(name, file);
    setFieldTouched(name, true);
  };
  const { user } = useContext(UesrContext);
  const [allCities, setAllCities] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const fetchCities = async (id) => {
    setIsLoading(true)
    const data = await citiesApi({ countryId: id }, code, user)
    if(data?.data?.data) {
      setAllCities(data?.data?.data)
    }
    setIsLoading(false)
  }
    
  return (
    <>   
    {
    isLoading ?
      <Loading centerLoader />
     : <div className="formik-step application-step-one">
      <div className="step-title">{stepOne.mainTitle[code]}</div>
      <Field
        component={TextField}
        name="firstname"
        type="text"
        label={stepOne.firstname.label[code]}
        variant="outlined"
        color="primary"
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <PersonRounded />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Field
        component={TextField}
        name="lastname"
        type="text"
        label={stepOne.nickName.label[code]}
        variant="outlined"
        color="primary"
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <PersonRounded />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl
        variant="outlined"
        error={touched?.country && errors?.country ? true : false}
      >
        <InputLabel htmlFor="country-id" className="country-label">
          {stepOne.country.label[code]}
        </InputLabel>
        <Field
          onChange={(e) => {
            setFieldValue("country", e.target.value);
            setSelectedCountryId(e.target.value);
            fetchCities(e.target.value)
          }}
          component={Select}
          name="country"
          // variant="outlined"
          inputProps={{
            id: "country-id",
          }}
        >
          {allCountries?.length > 0
            ? allCountries.map((c) => (
                <MenuItem key={c?.id} value={c?.id}>
                  {c?.ar_name || c?.en_name || c?.name}
                </MenuItem>
              ))
            : null}
        </Field>
        {touched?.country && errors?.country && (
          <FormHelperText error>{errors.country}</FormHelperText>
        )}
      </FormControl>


  {/* city */}
      {selectedCountryId &&  <FormControl
        variant="outlined"
        error={touched?.city && errors?.city ? true : false}
      >
        <InputLabel htmlFor="city-id" className="city-label country-label">
          {stepOne.city.label[code]}
        </InputLabel>
        <Field
          component={Select}
          name="cityId"
          // variant="outlined"
          inputProps={{
            id: "city-id",
          }}
        >
          {allCities?.length > 0
            ? allCities.map((c) => (
                <MenuItem key={c?.id} value={c?.id}>
                  {c?.ar_name || c?.en_name || c?.name}
                </MenuItem>
              ))
            : null}
        </Field>
        {touched?.city && errors?.city && (
          <FormHelperText error>{errors.city}</FormHelperText>
        )}
      </FormControl>}

      <div className="upload-wrap cv-wrap">
        <div className="title-input-wrap">
          <div className="input-title">{stepOne.cv.label[code]}</div>
          <label className="upload-file-label">
            {/* <Field name="cv" type="file" /> */}
            <input
              id="file"
              name="cv"
              type="file"
              onChange={(e) => handleFileUpload("cv", e)}
            />
            {values?.cv ? (
              values.cv?.name && values.cv.name.split(/(\\|\/)/g).pop()
            ) : (
              <>
                <span className="icon-text">
                  <PublishRounded />
                  {stepOne.uploadBtn.label[code]}
                </span>
              </>
            )}
          </label>
        </div>

        {errors?.cv && touched?.cv && (
          <FormHelperText error>{errors.cv}</FormHelperText>
        )}
      </div>
      {/*  */}
      <div className="upload-wrap identity-wrap">
        <div className="title-input-wrap">
          <div className="input-title">{stepOne.identity.label[code]}</div>
          <label className="upload-file-label">
            {/* <Field name="identity" type="file" /> */}
            <input
              id="file"
              name="identity"
              type="file"
              onChange={(e) => handleFileUpload("identity", e)}
            />
            {values?.identity ? (
              values.identity.name.split(/(\\|\/)/g).pop()
            ) : (
              <>
                <span className="icon-text">
                  <PublishRounded />
                  {stepOne.uploadBtn.label[code]}
                </span>
              </>
            )}
          </label>
        </div>
        {errors?.identity && touched?.identity && (
          <FormHelperText error>{errors.identity}</FormHelperText>
        )}
      </div>
    </div>
    }
    </>

  );
};

export default ApplicationStepOne;
