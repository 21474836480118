import axios from 'axios';

// 'pending','accepted','completed'
const supervisorOrdersApi = async (status, user, langCode) => {
  try {
    const res = await axios.get(
      `/api/v1/supervisor/orders?api_token=${user?.api_token}&status=${status}`,
      {
        headers: {
          'X-Portal': user?.accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        }
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};

export default supervisorOrdersApi;



export const supervisorOrdersByIdApi = async ( user,id, langCode) => {
  try {
    const res = await axios.get(
      `/api/v1/supervisor/order/id?api_token=${user?.api_token}&order_id=${id}`,
      {
        headers: {
          'X-Portal': user?.accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        }
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};

export const confirmLevel = async ( user,id, langCode,level) => {
  try {
    const res = await axios.post(
      `/api/v1/supervisor/confirm-level`,
      {

        api_token:user?.api_token,
        order_id:id,
        level_id:+level
      },
      {
        headers: {
          'X-Portal': user?.accountType,
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        },
        
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};

export const getSuperNotifications = async ( user,id, langCode,level) => {
  try {
    const res = await axios.get(
      `/api/v1/supervisor/notifications?api_token=${user?.api_token}`,
      {
        headers: {
          'X-Portal': user?.accountType,
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        },
        
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};

