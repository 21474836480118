import "./SocialApiHandler.scss";

import React, { useContext, useEffect, useState } from "react";
import userSigninLocalization from "../../localization/userSignin.localization";
import facebookImg from "../../assets/imgs/icons/social/facebook.svg";
import googleImg from "../../assets/imgs/icons/social/google.svg";
// import appleImg from "../../assets/imgs/icons/social/apple.svg";
import LangContext from "../../contexts/lang-context/LangProvider";
// import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import {
  userSigninWithGoogle,
  designerSigninWithGoogle,
  userSigninWithFacebook,
  designerSigninWithFacebook,
} from "../../apis/auth/signin";
import FlashMessageContext from "../../contexts/flash-messages-context/FlashMessageProvider";
import UesrContext from "../../contexts/user-context/UserProvider";
import customHistory from "../../history";
import incorrectUserTypeLocalization from "../../localization/incorrectUserTypeSignin";
import LoadingModal from "../loading-modal/LoadingModal";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import routerLinks from "../../components/app/routerLinks";

const SocialApiHandler = ({ comp }) => {
  const { moreSigninOptions } = userSigninLocalization;

  const {
    language: { code },
  } = useContext(LangContext);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);
  const { setCurrentUser } = useContext(UesrContext);

  // handle signin with google
  const [socialModalOpened, setSocialModalOpened] = useState(false);

  useEffect(() => {
    if (socialModalOpened) {
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("body").style.padding = "0 10px";
    } else {
      document.querySelector("body").style.overflow = "auto";
      document.querySelector("body").style.padding = "0";
    }
  }, [socialModalOpened]);

  const history = useHistory()
  //////////////////////////////////////////////
  const handleSigninWithGoogle = async (googleResponse) => {
      // const googleResponse = await gResponse;
    const correctUserType = (signinResponse) => {
      // here the correct user type means that "userType" is correct type ( 1 <= user , 2 <= employee)
      setCurrentUser(signinResponse);
      customHistory.push("/");
      if (signinResponse?.data?.message) {
        setFlashMsgIsOpen(true);
        setFlashMsg(signinResponse?.data?.message);
        setFlashMsgDuration(4000);
        setNotificationSeverity("success");
      }
    };
    const inCorrectUserType = () => {
      setFlashMsgIsOpen(true);
      comp === "user" &&
        setFlashMsg(incorrectUserTypeLocalization.incorrectTypeOfUser[code]);
      comp === "designer" &&
        setFlashMsg(
          incorrectUserTypeLocalization.incorrectTypeOfDesigner[code]
        );
      comp === "supervisor" &&
        setFlashMsg(
          incorrectUserTypeLocalization.incorrectTypeOfSupervisor[code]
        );

      setFlashMsgDuration(4000);
      setNotificationSeverity("error");
    };
    //
    const successSignup = (signupResponse) => {
      // customHistory.push("/");
      // if (signupResponse?.data?.message) {
      //   setFlashMsgIsOpen(true);
      //   setFlashMsg(signupResponse.data.message);
      //   setFlashMsgDuration(4000);
      //   setNotificationSeverity("success");
      // }
      setCurrentUser(signupResponse.data.data);
      history.push('/activate-account')
    };
    const failureSignup = (signupResponse) => {
      if (signupResponse?.data?.message) {
        setFlashMsgIsOpen(true);
        setFlashMsg(signupResponse?.data?.message);
        setFlashMsgDuration(4000);
        setNotificationSeverity("error");
      }
    };
    comp === "user" &&
      (await userSigninWithGoogle(
        googleResponse,
        code,
        correctUserType,
        inCorrectUserType,
        successSignup,
        failureSignup,
        //
        setSocialModalOpened,
        "client"
              ));
    comp === "designer" &&
      (await userSigninWithGoogle(
        googleResponse,
        code,
        correctUserType,
        inCorrectUserType,
        successSignup,
        failureSignup,
        //
        setSocialModalOpened,
        "employee"
      ));
      comp === "supervisor" &&
      (await userSigninWithGoogle(
        googleResponse,
        code,
        correctUserType,
        inCorrectUserType,
        successSignup,
        failureSignup,
        //
        setSocialModalOpened,
        "supervisor"
      ));
  };
  ////////////////////////////////////////////
  ////////////////////////////////////////////
  ////////////////////////////////////////////
  const handleSigninWithFacebook = async (fbResponse) => {
    const facebookResponse = await fbResponse;
    console.log("facebook response: ", facebookResponse);
    const correctUserType = (signinResponse) => {
      // here the correct user type means that "userType" is correct type ( 1 <= user , 2 <= employee)
      setCurrentUser(signinResponse.data.data);
      customHistory.push("/");
      if (signinResponse?.data?.message) {
        setFlashMsgIsOpen(true);
        setFlashMsg(signinResponse?.data?.message);
        setFlashMsgDuration(4000);
        setNotificationSeverity("success");
      }
    };
    const inCorrectUserType = () => {
      setFlashMsgIsOpen(true);
      comp === "user" &&
        setFlashMsg(incorrectUserTypeLocalization.incorrectTypeOfUser[code]);
      comp === "designer" &&
        setFlashMsg(
          incorrectUserTypeLocalization.incorrectTypeOfDesigner[code]
        );
      setFlashMsgDuration(4000);
      setNotificationSeverity("error");
    };
    //
    const successSignup = (signupResponse) => {
      setCurrentUser(signupResponse.data.data);
      customHistory.push("/");
      if (signupResponse?.data?.message) {
        setFlashMsgIsOpen(true);
        setFlashMsg(signupResponse.data.message);
        setFlashMsgDuration(4000);
        setNotificationSeverity("success");
      }
    };
    const failureSignup = (signupResponse) => {
      if (signupResponse?.data?.message) {
        setFlashMsgIsOpen(true);
        setFlashMsg(signupResponse?.data?.message);
        setFlashMsgDuration(4000);
        setNotificationSeverity("error");
      }
    };
    comp === "user" &&
      (await userSigninWithFacebook(
        facebookResponse,
        code,
        correctUserType,
        inCorrectUserType,
        successSignup,
        failureSignup,
        //
        setSocialModalOpened
      ));
    comp === "designer" &&
      (await designerSigninWithFacebook(
        facebookResponse,
        code,
        correctUserType,
        inCorrectUserType,
        successSignup,
        failureSignup,
        //
        setSocialModalOpened
      ));
  };

  return (
    <div className="more-social-options">
      <p className="more-p">{moreSigninOptions[code]}</p>
      <ul className="options-ul">
        <FacebookLogin
          appId="1930101080461748"
          autoLoad={false}
          fields="name,first_name,last_name,email,picture"
          render={(renderProps) => (
            <li onClick={renderProps.onClick}>
              <img src={facebookImg} alt="facebook signin" />
            </li>
          )}
          callback={handleSigninWithFacebook}
        />

        {/* <GoogleLogin
          clientId="540774759398-u8ea4ilqd6frakj9ve344l57bioagiqb.apps.googleusercontent.com"
          onSuccess={handleSigninWithGoogle}
          onFailure={handleSigninWithGoogle}
          cookiePolicy={"single_host_origin"}
          autoLoad={false}
          icon={false}
          render={(renderProps) => (
            <li onClick={renderProps.onClick}>
              <img className="google-img" src={googleImg} alt="google signin" />
            </li>
          )}
        /> */}

        <GoogleLogin
          onSuccess={credentialResponse => {
            const credentials = jwtDecode(credentialResponse.credential)
            handleSigninWithGoogle(credentials)
          }}
          onError={handleSigninWithGoogle}
        />

        {/* <li>
					<img src={appleImg} alt="apple signup" />
				</li> */}
      </ul>

      {/*  Social modal */}
      {socialModalOpened && <LoadingModal />}
    </div>
  );
};

export default SocialApiHandler;
