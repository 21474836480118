import React, { useContext } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MyOrderLocalization from "../../../localization/myOrderCard.localization";
import { Button } from "@material-ui/core";
import LangContext from "../../../contexts/lang-context/LangProvider";
import './oderDetailsSteps.scss'
const OrderDetailsSteps = ({
  myOrder,
  showConfirmButton,
  confirmLevelById,
}) => {
  const {
    language: { code, dir },
  } = useContext(LangContext);

  return (
    <div className="order-status">
      <div className="order-status-header">
        {MyOrderLocalization.orderSteps[code]}
      </div>
      <div className="order-steps-container">
        {myOrder.levels.map((order, i) => (
          <div
            className={`order-steps 
            ${order.status === "in_work" ? "active-step" : "notActive-step"}
            ${order.status === "complete" ? "completed-step" : ""}
            ${(order.status === undefined && order?.pivot?.status === "in_work") ? "active-step" : "notActive-step"}
            ${(order.status === undefined && order?.pivot?.status === "complete") ? "completed-step" : ""}

            `}
            key={order.status}
          >
            <div className="order-steps-icon">
              <CheckCircleIcon
                className={`
                ${order.status === "in_work" ? "active-step" : "notActive-step"}
                ${order.status === "complete" ? "completed-step" : ""}
                ${(order.status === undefined && order?.pivot?.status === "in_work") ? "active-step" : "notActive-step"}
                ${(order.status === undefined && order?.pivot?.status === "complete") ? "completed-step" : ""}

                `}
              />
              {/* <div
                      className={`${
                        i + 1 === myOrder.levels.length ? "" : "steps-line"
                      } ${
                        order.status === "complete"
                          ? "completed-step-line"
                          : ""
                      }`}
                    ></div> */}
            </div>
            <div className="order-steps-details">
              <div>{order.level_id || order?.pivot?.level_id}</div>
              <div>{order.level_name || (code === "en" ? order.level_en : order.level_ar)}</div>
              <div>{(order.count_days || order.count_days === 0) ? order.count_days : order?.pivot?.count_days}</div>
              <div>{order.end_date !== "" || order?.pivot?.end_date}</div>
              <div>{order.status_name || order?.pivot?.status_name}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-20">
        {showConfirmButton && (
          <Button
            variant="contained"
            className="details-link "
            color="primary"
            onClick={confirmLevelById}
          >
            {MyOrderLocalization.confirmLever[code]}
          </Button>
        )}
      </div>
    </div>
  );
};

export default OrderDetailsSteps;
