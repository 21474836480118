/* eslint-disable react-hooks/exhaustive-deps */
import './ApplicationStepThree.scss';

import React, { useContext, useEffect } from 'react';
import designerApplicationLocalization from '../../../localization/designerApplication.localization';
import LangContext from '../../../contexts/lang-context/LangProvider';
import { Field, useFormikContext } from 'formik';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import { PublishRounded } from '@material-ui/icons';
import UesrContext from '../../../contexts/user-context/UserProvider';

const ApplicationStepThree = () => {
  const { stepThree } = designerApplicationLocalization;
  const {
    language: { code }
  } = useContext(LangContext);

  const {
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched
  } = useFormikContext();
  useEffect(() => {
    setTouched({});
  }, []);
  const { setFetchUserCount, user } = useContext(UesrContext);

  const handleFilesUpload = async (name, event) => {
    let files = await event.target.files;
    setFieldValue(name, files);
    setFieldTouched(name, true);
  };

  const renderFileName = (fileList) => {
    let mappedArray = [];
    for (let i = 0; i < fileList.length; i++) {
      // get item
      // console.log(fileList.item(i));
      const listItem = (
        <div key={i} className="file-item">
          {fileList[i].name.split(/(\\|\/)/g).pop()}
        </div>
      );
      mappedArray.push(listItem);
    }
    return mappedArray;
  };

  const practicalExpArr = [
    {
      id: 1,
      label: {
        ar: '3 سنوات',
        en: '3 Years'
      },
      value: '3'
    },
    {
      id: 2,
      label: {
        ar: '4 سنوات',
        en: '4 Years'
      },
      value: '4'
    },
    {
      id: 3,
      label: {
        ar: '5 سنوات او اكثر',
        en: '5 Years or more'
      },
      value: '5'
    }
  ];
  const languagesTypesArr = [
    {
      id: 1,
      label: {
        ar: 'اللغة العربية',
        en: 'Arabic Language'
      },
      value: 'ar'
    },
    {
      id: 2,
      label: {
        ar: 'اللغة الانجليزية',
        en: 'English Language'
      },
      value: 'en'
    },
    {
      id: 3,
      label: {
        ar: 'كليهما معا',
        en: 'Both'
      },
      value: 'both'
    }
  ];

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.label[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === item.value)
                setFieldValue(fieldName, '');
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  return (
    <div className="formik-step application-step-three">
      <div className="step-title">{stepThree.mainTitle[code]}</div>

      <div className="radio-group-wrapper">
        <h3 className="group-title">{stepThree.practicalExp.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="experience_year"
        >
          {renderRadiosGroup(practicalExpArr, 'experience_year')}
        </Field>
      </div>

      {/*  */}
      {/*  */}

      <div className="upload-wrap works-wrap">
        <div className="title-input-wrap">
          <div className="label-input">
            <div className="input-title">
              {stepThree.uploadWork.label[code]}
            </div>
            <label className="upload-file-label">
              <input
                id="file"
                name="ex_works"
                multiple
                type="file"
                onChange={(e) => handleFilesUpload('ex_works', e)}
              />
              <>
                <span className="icon-text">
                  <PublishRounded />
                  {stepThree.uploadWork.uploadInput.label[code]}
                </span>
              </>
            </label>
          </div>

          {values?.ex_works && (
            <div className="file-list">{renderFileName(values.ex_works)}</div>
          )}
        </div>

        {errors?.ex_works && touched?.ex_works && (
          <FormHelperText error>{errors.ex_works}</FormHelperText>
        )}
      </div>

      {/*  */}
      {/*  */}

     {user?.accountType !== "supervisor" && <div className="radio-group-wrapper">
        <h3 className="group-title">{stepThree.spokenLang.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="language"
        >
          {renderRadiosGroup(languagesTypesArr, 'language')}
        </Field>
      </div>}
    </div>
  );
};

export default ApplicationStepThree;
