import "./DesignerSignup.scss";

import React, { useContext } from "react";
import signupPage from "../../localization/signupPage";
import LangContext from "../../contexts/lang-context/LangProvider";
import DesignerSignupForm from "./DesignerSignupForm";
import employeeImg from "../../assets/imgs/signup-signin/teacher.png";

const DesignerSignup = ({type}) => {
	const {
		developerSignup: { title: sectionTitle }
	} = signupPage;
	const {
		language: { code, dir }
	} = useContext(LangContext);

	return (
		<div className="developer-signup-section">
			<div className="title-form-section">
				<div className="section-title">{sectionTitle[code]}</div>

				<DesignerSignupForm  type={type} />
			</div>
			<div className={`section-img ${dir}`}>
				<img src={employeeImg} alt="employee" />
			</div>
		</div>
	);
};

export default DesignerSignup;
