import React, { useState, useContext } from 'react';
import ActivationCodeForm from '../../common/activation-code-form/ActivationCodeForm';
import FlashMessageContext from '../../contexts/flash-messages-context/FlashMessageProvider';
import useDigitInput from 'react-digit-input';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import {
  confirmAuthActivationCodeApi,
  resendAuthActivationCodeApi
} from '../../apis/auth/authActivationCodeApis';
import LangContext from '../../contexts/lang-context/LangProvider';
import checkRes from '../../utils/checkRes';
import UesrContext from '../../contexts/user-context/UserProvider';
import './ActivateUserPage.scss';
import routerLinks from '../../components/app/routerLinks';
import { useHistory } from 'react-router-dom';

const btnTypes = {
  confirmCode: 1,
  resendCode: 2
};

const ActivateAccountPage = () => {
  const { language } = useContext(LangContext);
  const { user, setCurrentUser } = useContext(UesrContext);
  const customApiRequest = useCustomApiRequest();
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity
  } = useContext(FlashMessageContext);
  const history = useHistory();
  const [submitCodeCount, setSubmitCodeCount] = React.useState(0);
  const [value, onChange] = React.useState('');
  const [err, setErr] = useState({});
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange
  });

  const [isLoadingState, setIsLoadingState] = React.useState({
    type: '',
    isLoading: false
  });

  const [invalidOtp, setInvalidOtp] = useState(false)
  const submitForm = () => {
    setInvalidOtp(false)
    if (value?.trim().length === 4) {
      setErr({});
      setIsLoadingState({
        type: btnTypes.confirmCode,
        isLoading: true
      });

      const formData = new FormData();
      formData.append('pin_code', value);
      formData.append('type', user?.accountType);

      customApiRequest(
        confirmAuthActivationCodeApi(formData, language?.code),
        (res) => {
          setIsLoadingState({
            type: btnTypes.confirmCode,
            isLoading: false
          });
          if (checkRes(res) && res?.data?.data) {
            setFlashMsgIsOpen(true);
            setFlashMsg(res?.data?.message || 'Correct Code');
            setFlashMsgDuration(4000);
            setNotificationSeverity('success');
            //

            setCurrentUser({
              ...res.data.data,
              accountType: user?.accountType ? user.accountType : null,
              activated: true
            });
            //
            history.push(routerLinks?.homePage);
          } else {
            setSubmitCodeCount((prev) => prev + 1);
            setFlashMsgIsOpen(true);
            setFlashMsg(res?.data?.message || 'Incorrect code');
            setFlashMsgDuration(4000);
            setNotificationSeverity('error');
            setInvalidOtp(true)

          }
        },
        (error) => {
          setIsLoadingState({
            type: btnTypes.confirmCode,
            isLoading: false
          });
          setSubmitCodeCount((prev) => prev + 1);
          setFlashMsgIsOpen(true);
          setFlashMsg(error?.response?.data?.message || 'Incorrect code');
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        }
      );
    } else {
      setErr({
        message: 'الكود غير صحيح'
      });
    }
  };

  const resendCode = () => {
    setIsLoadingState({
      type: btnTypes.resendCode,
      isLoading: true
    });
    const formData = new FormData();
    formData.append('type', user?.accountType);
    formData.append('email', user?.email);
    customApiRequest(
      resendAuthActivationCodeApi({
        // check: 1, // 1 => in active user registeration resend code, 2 => in foreget password resend code
        formData
      }),
      (res) => {
        setIsLoadingState({
          type: btnTypes.resendCode,
          isLoading: false
        });
        if (checkRes(res)) {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'Check your email for code');
          setFlashMsgDuration(4000);
          setNotificationSeverity('success');
        } else {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'Incorrect code');
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        }
      },
      (error) => {
        setIsLoadingState({
          type: btnTypes.resendCode,
          isLoading: false
        });
        setFlashMsgIsOpen(true);
        setFlashMsg(error?.response?.data?.message || 'Try again later');
        setFlashMsgDuration(4000);
        setNotificationSeverity('error');
      }
    );
  };

  return (
    <div className="activate-user-page ">
      <div className="mfa-container">
        <div className="title-form-wrap">
          <div className="form-title">
            {language?.code === 'ar'
              ? 'أدخل الكود المرسل على الايميل الخاص بك'
              : ''}
            {language?.code === 'en'
              ? 'Check your email for the activation code'
              : ''}
          </div>
          <ActivationCodeForm
            value={value}
            digits={digits}
            err={err}
            setErr={setErr}
            submitCodeCount={submitCodeCount}
            onChange={onChange}
            isLoadingState={isLoadingState}
            submitForm={submitForm}
            resendCode={resendCode}
            btnTypes={btnTypes}
            invalid={invalidOtp}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivateAccountPage;
