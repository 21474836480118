import React, { useContext } from "react";
import "./payment.scss";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Typography } from "@material-ui/core";
import paymentProcessLocalization from "../../localization/paymentProcess.localization";
import LangContext from "../../contexts/lang-context/LangProvider";
const PaymentError = () => {
  const {
    fields: { failed },
  } = paymentProcessLocalization;
  const { language } = useContext(LangContext);
  return (
    <div className='payment-container'>
      <HighlightOffIcon fontSize='large' color='error' />
      <Typography color='error'>{failed.label[language.code]}</Typography>
    </div>
  );
};

export default PaymentError;
