import React, { useState, createContext } from "react";

const INITIAL_STATE = {
  userServices: null,
  setUserServices: (services) => {},
  servicePrices: null,
  setServicePrices: (v) => {},
  subServices: [],
  setSubServices: (v) => {},
};
const UserServicesContext = createContext(INITIAL_STATE);

export const UserServicesProvider = ({ children }) => {
  const [userServices, setUserServices] = useState(INITIAL_STATE.userServices);
  const [servicePrices, setServicePrices] = useState(
    INITIAL_STATE.servicePrices
  );
  const [subServices, setSubServices] = useState(INITIAL_STATE.servicePrices);
  return (
    <UserServicesContext.Provider
      value={{
        userServices,
        setUserServices,
        servicePrices,
        setServicePrices,
        subServices,
        setSubServices,
      }}
    >
      {children}
    </UserServicesContext.Provider>
  );
};

export default UserServicesContext;
