import './FormikStepper.scss';

import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import ButtonWithLoading from '../../common/button-with-loading/ButtonWithLoading';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import LangContext from '../../contexts/lang-context/LangProvider';
import stepperBtnsLocalization from '../../localization/stepperBtns.localization';
import UesrContext from '../../contexts/user-context/UserProvider';
import { useHistory } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';

const FormikStepper = ({ children, step, setStep, ...props }) => {
  const { user } = useContext(UesrContext);
  const history = useHistory();
  const { nextBtn, prevBtn, submitServiceBtn, submitDesignerBtn, apply } =
    stepperBtnsLocalization;
  const {
    language: { dir, code }
  } = useContext(LangContext);
  const childrenArray = React.Children.toArray(children);

  // const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  const renderBtnText = () => {
    if (isLastStep()) {
      if (props.stepperType === 'user') {
        return submitServiceBtn[code];
      } else if (props?.stepperType === 'designer') {
        return submitDesignerBtn[code];
      }
    } else {
      if (step === 0) return apply[code];
      else return nextBtn[code];
    }
  };

  return (
    <Formik
      {...props}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
        } else {
          // if (step === 0 && user) {
          //   setStep((currState) => currState + 1);
          // } else history.push(routerLinks?.signinPage);
          if (user) {
            setStep((currState) => currState + 1);
          } else history.push(routerLinks?.signinPage);
        }
      }}
      validationSchema={currentChild?.props?.validationSchema}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        isSubmitting,
        errors,
        setErrors,
        touched
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="stepper-form"
            autoComplete="off"
          >
            {currentChild}

            <div className={`stepper-btns ${dir}`}>
              {step > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setErrors({})
                    setStep((currState) => currState - 1)
                  }}
                >
                  <ArrowRightIcon color="#fff" />
                  <span>{prevBtn[code]}</span>
                </Button>
              )}
              {step < childrenArray.length && (
                <ButtonWithLoading
                  type="submit"
                  variant="contained"
                  color="primary"
                  isSubmitting={isSubmitting}
                  errors={errors}
                  btnText={renderBtnText()}
                  // btnText={`${
                  //   isLastStep()
                  //     ? props.stepperType === 'user'
                  //       ? submitServiceBtn[code]
                  //       : submitDesignerBtn[code]
                  //     : nextBtn[code]
                  // }`}
                  loadingMsg="جارى المعالجة..."
                >
                  <ArrowLeftIcon color="#fff" />
                </ButtonWithLoading>
              )}
            </div>

            {/* <div style={{ direction: "ltr", overflow: "scroll" }}>
              <pre />
              <h1>Values</h1>
              <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre />
              <h1>Errors</h1>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
              <pre />
              <h1>Touched</h1>
              <pre>{JSON.stringify(touched, null, 2)}</pre>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
};

export default FormikStepper;
