/* eslint-disable react-hooks/exhaustive-deps */
import "react-image-crop/dist/ReactCrop.css";
import "./AvatarUpload.scss";
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from "react";
import { useFormikContext } from "formik";
import { Avatar, Button, Modal } from "@material-ui/core";
import MyDropZone from "../dorp-zone/MyDropZone";
import { AddAPhoto } from "@material-ui/icons";
import LangContext from "../../contexts/lang-context/LangProvider";
import avatarUploadLocalization from "../../localization/avatarUploadLocalization";
import ReactCrop from "react-image-crop";
import CropperContext from "../../contexts/cropper-context/CropperProvider";
import CustomCanvas from "../CustomCanvas";
// import convertPathToFile from "../../utils/convertPathTofile";

const AvatarUpload = ({ formName, inputName, initialPhoto }) => {
	const { setFieldValue, errors } = useFormikContext();

	const { croppedPersonalImgFile, setCroppedPersonalImgFile } = useContext(
		CropperContext
	);
	// upload personal image
	const [personalImgModalOpen, setPersonalImgModalOpen] = useState(false);
	// personal img crop
	const [personalImgFilesToUpload, setPersonalImgFilesToUpload] = useState([]);
	const originalPersonalImgRef = useRef(null);
	const [personalImgCompletedCrop, setPersonalImgCompletedCrop] = useState(
		null
	);
	const previewCanvasRef = useRef(null);
	const [cropPersonalImg, setCropPersonalImg] = useState({
		unit: "%",
		aspect: 1 / 1,
		width: 50,
		// height: 50,
		x: 25,
		y: 25
	});
	const onPersonalImgLoad = useCallback(img => {
		originalPersonalImgRef.current = img;
	}, []);
	useEffect(() => {
		if (croppedPersonalImgFile) {
			setFieldValue(inputName, croppedPersonalImgFile);
		} else {
			// convertPathToFile(initialPhoto)
			// 	.then(file => {
			// 		setFieldValue(inputName, file);
			// 	})
			// 	.catch(e => console.log(e));
			setFieldValue(inputName, null);
		}
	}, [croppedPersonalImgFile]);

	const handlePersonalImgFilesDrop = acceptedFiles => {
		const duplicates = [];
		for (let i of acceptedFiles) {
			for (let f of personalImgFilesToUpload) {
				if (i.name === f.name) {
					duplicates.push(i);
				}
			}
		}
		for (let i = 0; i < acceptedFiles.length; i++) {
			for (let f of duplicates) {
				if (acceptedFiles[i].name === f.name) {
					acceptedFiles.splice(i, 1);
				}
			}
		}

		const files = acceptedFiles.map(file =>
			Object.assign(file, {
				preview: URL.createObjectURL(file)
			})
		);
		setPersonalImgFilesToUpload(currState => [...currState, ...files]);
		// setFieldValue(inputName, files[0]);
	};

	const {
		language: { dir, code }
	} = useContext(LangContext);
	return (
		<>
			<div className={`custom-avatar-img ${dir}`}>
				{personalImgFilesToUpload.length === 0 ? (
					<>
						<div
							className={`dashed-personal-img ${
								errors &&
								errors[inputName] &&
								personalImgFilesToUpload.length === 0
									? "err-border"
									: ""
							}`}
							onClick={() => setPersonalImgModalOpen(true)}
						>
							{/* check if i have the initial photo */}
							{initialPhoto ? (
								<img
									className="initial-photo"
									// src={initialPhoto}
									src={initialPhoto}
									alt="avatar"
								/>
							) : (
								<Avatar className="avatar-placeholder" />
							)}
							<AddAPhoto className="add-icon" />
						</div>
						{errors &&
						errors[inputName] &&
						personalImgFilesToUpload.length === 0 ? (
							<p className="err-p">{errors[inputName]}</p>
						) : (
							<p>الصورة الشخصية</p>
						)}
					</>
				) : (
					<div
						className="after-crop-wrap"
						onClick={() => setPersonalImgModalOpen(true)}
					>
						{personalImgCompletedCrop && (
							<CustomCanvas
								croppedImgObj={personalImgCompletedCrop}
								previewCanvasRef={previewCanvasRef}
								originalImgRef={originalPersonalImgRef}
								fileName="abc.jpeg"
								setCroppedFile={setCroppedPersonalImgFile}
							/>
						)}

						{/* {(!personalImgCompletedCrop ||
								personalImgCompletedCrop.width === 0) && (
								<DropZonePreview
									filesToUpload={personalImgFilesToUpload}
									clearFileFromUpload={clearPersonalImgFileFromUpload}
									imgRef={originalPersonalImgRef}
								/>
							)} */}
					</div>
				)}
			</div>

			{/* image upload modal */}

			{/* personal image modal */}
			<Modal
				className="personal-img-modal"
				open={personalImgModalOpen}
				onClose={() => setPersonalImgModalOpen(false)}
			>
				<div className="modal-body">
					{personalImgFilesToUpload.length === 0 && (
						<MyDropZone
							multipleFiles={false}
							handleFilesDrop={handlePersonalImgFilesDrop}
							filesToUpload={personalImgFilesToUpload}
							formName={formName}
							id="avatar-input"
							dropzoneText={avatarUploadLocalization.title[code]}
							inputName={inputName}
						/>
					)}

					{personalImgFilesToUpload.length > 0 && (
						<ReactCrop
							// locked={true}
							src={personalImgFilesToUpload[0].preview}
							onImageLoaded={onPersonalImgLoad}
							crop={cropPersonalImg}
							onChange={c => setCropPersonalImg(c)}
							onComplete={c => setPersonalImgCompletedCrop(c)}
						/>
					)}

					{personalImgFilesToUpload.length > 0 && (
						<Button
							className="done-btn"
							variant="contained"
							color="primary"
							onClick={() => {
								setPersonalImgModalOpen(false);
								setPersonalImgCompletedCrop(cropPersonalImg);
							}}
						>
							<span>{avatarUploadLocalization.saveBtn[code]}</span>
						</Button>
					)}
				</div>
			</Modal>
		</>
	);
};

export default AvatarUpload;
