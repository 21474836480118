import axios from 'axios';

export const sendForgetPassCode = async (values) => {
  try {
    const response = await axios.post('/api/v1/forget-password', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
        password: process.env.REACT_APP_AUTH_PASS
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const checkForgetPassCode = async (values) => {
  try {
    const response = await axios.post('/api/v1/check-code', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
        password: process.env.REACT_APP_AUTH_PASS
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const changePassword = async (values, token) => {
  try {
    const response = await axios.post('/api/v1/new-password', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
        password: process.env.REACT_APP_AUTH_PASS
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};
