import React from "react";

const BellIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25.553"
			height="28.423"
			viewBox="0 0 25.553 28.423"
		>
			<path
				id="Path_162"
				data-name="Path 162"
				d="M27.893,20.771,25.335,18.2V11.861a9.748,9.748,0,0,0-8.271-9.777A9.578,9.578,0,0,0,6.236,11.563V18.2L3.678,20.771a2.331,2.331,0,0,0,1.648,3.965H10.1v.483a5.457,5.457,0,0,0,5.684,5.2,5.457,5.457,0,0,0,5.684-5.2v-.483h4.775a2.331,2.331,0,0,0,1.648-3.965Zm-9.265,4.448a2.672,2.672,0,0,1-2.842,2.359,2.672,2.672,0,0,1-2.842-2.359v-.483h5.684ZM6.563,21.894,8.24,20.217A2.842,2.842,0,0,0,9.078,18.2V11.563A6.722,6.722,0,0,1,11.38,6.5,6.636,6.636,0,0,1,16.7,4.841a6.906,6.906,0,0,1,5.8,6.963V18.2a2.842,2.842,0,0,0,.824,2.018l1.691,1.677Z"
				transform="translate(-3.009 -2.002)"
				// fill="#8d8d8d"
			/>
		</svg>
	);
};

export default BellIcon;
