import React from "react";

const FacebookSocial = ({ width = 31.5, height = 31.5 }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 31.5 31.5"
		>
			<path
				id="Icon_awesome-facebook-square"
				data-name="Icon awesome-facebook-square"
				d="M28.125,2.25H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h9.65V23.041H8.6V18h4.43V14.158c0-4.37,2.6-6.784,6.586-6.784a26.836,26.836,0,0,1,3.9.34V12h-2.2a2.521,2.521,0,0,0-2.842,2.723V18h4.836l-.773,5.041H18.475V33.75h9.65A3.375,3.375,0,0,0,31.5,30.375V5.625A3.375,3.375,0,0,0,28.125,2.25Z"
				transform="translate(0 -2.25)"
			/>
		</svg>
	);
};

export default FacebookSocial;
