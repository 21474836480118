import ServiceStepOne from "../service-step-one/ServiceStepOne";
import * as Yup from "yup";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import { useContext } from "react";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Create } from "@material-ui/icons";

const ServiceSevenStepTwo = () => {
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection },
  } = serviceDetailsLocalization;
  const {
    language: { code },
  } = useContext(LangContext);
  return (
    <div className="formik-step service-seven-step-two">
      <div
        className="textarea-section"
        style={{
          marginBottom: 18,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p className="breif-title">{no.briefTitle[code]}</p>
        <Field
          component={TextField}
          // label=" يرجى كتابة الفكرة مختصرة عن العمل الطلوب أو تحميل النص"
          name="briefScript"
          variant="outlined"
          multiline
          rows="4"
          className="script-textarea"
        />
      </div>

      {/* <Field
        component={TextField}
        name="highlightPoint"
        type="text"
        label="نقطة التميز"
        variant="outlined"
        color="primary"
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <Create />
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}
    </div>
  );
};

export default ServiceSevenStepTwo;
