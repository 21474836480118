import "./UserSignin.scss";
import React, { useContext } from "react";
import personOnChairImg from "../../assets/imgs/signup-signin/person-on-chair.png";
import signinPage from "../../localization/signinPage";
import LangContext from "../../contexts/lang-context/LangProvider";
import UserSigninForm from "./UserSigninForm";

const UserSignin = () => {
	const {
		userSignin: { title: sectionTitle }
	} = signinPage;
	const {
		language: { code, dir }
	} = useContext(LangContext);

	return (
		<div className="user-signin-section">
			<div className="title-form-section">
				<div className="section-title">{sectionTitle[code]}</div>

				<UserSigninForm />
			</div>
			<div className={`section-img ${dir}`}>
				<img src={personOnChairImg} alt="person on chair" />
			</div>
		</div>
	);
};

export default UserSignin;
