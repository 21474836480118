/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormControlLabel, Radio } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import debounce from "lodash.debounce";
import useCustomApiRequest from "../../../custom-hooks/useCustomApiRequest";
import calculateNumberOfMinutesApi from "../../../apis/user/calculateNumberOfMinutesApi";
import UesrContext from "../../../contexts/user-context/UserProvider";
import countWords from "../../../utils/countWords";
import checkRes from "../../../utils/checkRes";
import loadingIcon from "../../../assets/imgs/icons/loading1.gif";
import UserServicesContext from "../../../contexts/services-context/UserServicesProvider";

import "./ServiceStepOne.scss";

const ServiceStepOne = () => {
  const params = useParams();
  const { user } = useContext(UesrContext);
  const {
    language: { code },
  } = useContext(LangContext);
  const { servicePrices } = useContext(UserServicesContext);
  const {
    stepOne: {
      videoDuration,
      scriptIsReady,
      scriptIsReady: { yes, no },
    },
  } = serviceDetailsLocalization;

  const { isSubmitting, setFieldValue, values, initialValues, handleChange } =
    useFormikContext();
  const customApiRequest = useCustomApiRequest();
  // handle count up and down for video duration

  const [calculatedVideoDuration, setCalculatedVideoDuration] = useState(
    initialValues.videoDuration
  );

  console.log("calculatedVideoDuration : ", calculatedVideoDuration);

  useEffect(() => {
    setFieldValue("calculatedVideoDuration", calculatedVideoDuration);
  }, [calculatedVideoDuration]);

  useEffect(() => {
    if (values?.script)
      setFieldValue("numberOfWords", countWords(values.script));
  }, [values?.script]);

  ///////////////////////////////////////

  // handle calculate number of words based on input text

  const [isSearching, setIsSearching] = React.useState(false);

  const [query, setQuery] = React.useState("");
  const changeHandler = (event) => {
    setQuery(event.target.value);
  };
  // const debouncedCallback = debounce(callback, waitTime);
  const debouncedChangeHandler = React.useCallback(
    debounce(changeHandler, 400),
    []
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && query) {
      setIsSearching(true);
      customApiRequest(
        calculateNumberOfMinutesApi(
          {
            service_id: params?.serviceId,
            count_words: countWords(query),
          },
          user,
          code
        ),
        (res) => {
          setIsSearching(false);
          if (checkRes(res) && res?.data?.data) {
            setCalculatedVideoDuration(res.data.data);
          }
        },
        (error) => {
          setIsSearching(false);
        }
      );
    }
    return () => (isMounted = false);
  }, [query]);

  // handle script is ready or not
  useEffect(() => {
    if (values.scriptIsReady === "ready") {
      setCalculatedVideoDuration(0);
      setFieldValue("numberOfWords", initialValues.numberOfWords);
      setFieldValue("briefScript", initialValues.briefScript);
    } else if (values.scriptIsReady === "not_ready") {
      setFieldValue("countWords", 0);
      setFieldValue("script", initialValues.script);
      setFieldValue("numberOfWords", initialValues.numberOfWords);
      setFieldValue("acceptScript", initialValues.acceptScript);
      setCalculatedVideoDuration(0);
    }
  }, [values.scriptIsReady]);

  ////////////////////////////////
  const renderTextareaSection = () => (
    <div className="textarea-section">
      <Field
        component={TextField}
        label={yes.textarea.label[code]}
        name="script"
        variant="outlined"
        multiline
        rows="4"
        className="script-textarea"
        onChange={(e) => {
          handleChange(e);
          debouncedChangeHandler(e);
        }}
      />
      <Field
        component={TextField}
        type="number"
        label={yes.wordsCount.label[code]}
        name="numberOfWords"
        variant="outlined"
        className="words-count-input"
        disabled
        inputProps={{ min: initialValues.numberOfWords }}
      />
      {values?.acceptScript === "review" ? (
        <div className="vid-input-wrap">
          <Field
            component={TextField}
            label={yes.videoDuration.label[code]}
            name="reviewedVideoDuration"
            variant="outlined"
            className="vid-duration-input"
          />
        </div>
      ) : (
        <div className="vid-input-wrap">
          <Field
            component={TextField}
            label={yes.videoDuration.label[code]}
            name="calculatedVideoDuration"
            variant="outlined"
            disabled
            className="vid-duration-input"
          />
          {isSearching ? (
            <img style={{ height: 28 }} src={loadingIcon} alt="loading" />
          ) : (
            <span className="helper-span">
              {videoDuration.helperText[code]}
            </span>
          )}
        </div>
      )}

      <Field
        className="accept-review-btns"
        component={RadioGroup}
        name="acceptScript"
      >
        <FormControlLabel
          onClick={(e) => {
            if (String(values.acceptScript) === "accept")
              setFieldValue("acceptScript", "");
          }}
          value="accept"
          control={
            <Radio size="small" color="secondary" disabled={isSubmitting} />
          }
          label={yes.acceptBtn.label[code]}
          disabled={isSubmitting}
        />
        <div className="review-wrap">
          <FormControlLabel
            onClick={(e) => {
              if (String(values.acceptScript) === "review")
                setFieldValue("acceptScript", "");
            }}
            value="review"
            control={
              <Radio size="small" color="secondary" disabled={isSubmitting} />
            }
            label={yes.reviewBtn.label[code]}
            disabled={isSubmitting}
          />
          {values.acceptScript === "review" &&
            servicePrices?.countWordsEqualMinute && (
              <span className="helper-span">
                {
                  yes.reviewBtn.helperText(
                    servicePrices?.countWordsEqualMinute
                  )[code]
                }
              </span>
            )}
        </div>
      </Field>
    </div>
  );

  return (
    <div className="formik-step service-step-one">
      {/* script is ready section */}
      <div className="script-is-ready-section">
        <h3 className="main-title">{scriptIsReady.mainTitle[code]}</h3>

        <Field
          className="yes-no-section"
          name="scriptIsReady"
          component={RadioGroup}
        >
          {/* Yes section  */}
          <div className="yes-section">
            <div className="yes-row">
              <FormControlLabel
                onClick={(e) => {
                  if (String(values.scriptIsReady) === "ready")
                    setFieldValue("scriptIsReady", "");
                }}
                value="ready"
                control={<Radio color="primary" disabled={isSubmitting} />}
                label={yes.checkBox.label[code]}
                disabled={isSubmitting}
              />
              {values.scriptIsReady === "ready" && (
                <span className="helper-text">
                  {yes.checkBox.helperText[code]}
                </span>
              )}
            </div>

            {values.scriptIsReady === "ready" && renderTextareaSection()}
          </div>

          {/* No section  */}
          <div className="no-section">
            <div className="no-row">
              <FormControlLabel
                value="not_ready"
                control={<Radio color="primary" disabled={isSubmitting} />}
                label={no.checkBox.label[code]}
                disabled={isSubmitting}
                onClick={(e) => {
                  if (String(values.scriptIsReady) === "not_ready")
                    setFieldValue("scriptIsReady", "");
                }}
              />
              {values.scriptIsReady === "not_ready" &&
                servicePrices?.countWordsEqualMinute && (
                  <span className="helper-text">
                    {
                      no.checkBox.helperText(
                        servicePrices?.countWordsEqualMinute
                      )[code]
                    }
                  </span>
                )}
            </div>

            {values.scriptIsReady === "not_ready" && (
              <div className="textarea-section">
                <p className="breif-title">{no.briefTitle[code]}</p>
                <Field
                  component={TextField}
                  label={no.textarea.label[code]}
                  name="briefScript"
                  variant="outlined"
                  multiline
                  rows="4"
                  className="script-textarea"
                />

                <Field
                  component={TextField}
                  type="number"
                  // label={yes.wordsCount.label[code]}
                  label={code === "ar" ? "عدد الدقايق" : "Number of Minutes"}
                  name="reviewedVideoDuration"
                  variant="outlined"
                  className="words-count-input"
                  inputProps={{ min: initialValues.reviewedVideoDuration }}
                />
                {/* <Field
                  component={TextField}
                  type="number"
                  // label={yes.wordsCount.label[code]}
                  label={code === "ar" ? "عدد الدقايق" : "Number of Minutes"}
                  name="numberOfWords"
                  variant="outlined"
                  className="words-count-input"
                  inputProps={{ min: initialValues.numberOfWords }}
                /> */}
              </div>
            )}
          </div>
        </Field>
      </div>
    </div>
  );
};

export default ServiceStepOne;
