export const orderStatusValues = {
  pending: "pending",
  current: "current",
  completed: "completed",
};

export const userOrderStatusValues = {
  pending: "pending",
  accepted: "accepted",
  completed: "completed",
};

export const OrderStatusArr = () => {
  return [
    // {
    //   id: 1,
    //   name: "قيد الإنتظار",
    //   value: orderStatusValues.pending,
    // },
    {
      id: 2,
      name: "الطلبات الجارية",
      value: orderStatusValues.current,
    },
    {
      id: 3,
      name: "الطلبات المكتملة",
      value: orderStatusValues.completed,
    },
  ];
};


export const userOrderStatusArr = () => {
  return [
    {
      id: 1,
      name: {
        ar: "قيد الإنتظار",
        en:"pending"
      },
      value: userOrderStatusValues.pending,
    },
    {
      id: 2,
      name: {
        ar: "الطلبات المقبولة",
        en:"accepted"
      },
      value: userOrderStatusValues.accepted,
    },
    {
      id: 3,
      name: {
        ar:  "الطلبات المكتملة",
        en:"pending"
      },
      value: userOrderStatusValues.completed,
    },
  ];
};
