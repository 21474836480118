/* eslint-disable react-hooks/exhaustive-deps */
import './ServiceStepEight.scss';

import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';
import { Alert } from '@material-ui/lab';
import './ServiceStepEight.scss';

const allTargetAudience = [
  {
    id: 1,
    label: {
      ar: 'رجال',
      en: 'Men'
    },
    value: {
      ar: 'رجال',
      en: 'Men'
    }
  },
  {
    id: 2,
    label: {
      ar: 'نساء',
      en: 'Women'
    },
    value: {
      ar: 'نساء',
      en: 'Women'
    }
  },
  {
    id: 3,
    label: {
      ar: 'أطفال',
      en: 'Children'
    },
    value: {
      ar: 'أطفال',
      en: 'Children'
    }
  },
  {
    id: 4,
    label: {
      ar: 'كبار سن',
      en: 'The elders'
    },
    value: {
      ar: 'كبار سن',
      en: 'The elders'
    }
  },
  {
    id: 5,
    label: {
      ar: 'الشباب',
      en: 'Young'
    },
    value: {
      ar: 'الشباب',
      en: 'Young'
    }
  },
  {
    id: 6,
    label: {
      ar: 'سن المراهقة',
      en: 'Teenagers'
    },
    value: {
      ar: 'سن المراهقة',
      en: 'Teenagers'
    }
  },
  {
    id: 7,
    label: {
      ar: 'أصحاب الأعمال',
      en: 'Bussiness Men'
    },
    value: {
      ar: 'أصحاب الأعمال',
      en: 'Bussiness Men'
    }
  },
  {
    id: 8,
    label: {
      ar: 'أصحاب الهمم',
      en: 'People of determination'
    },
    value: {
      ar: 'أصحاب الهمم',
      en: 'People of determination'
    }
  },
  {
    id: 9,
    label: {
      ar: 'الموظفين',
      en: 'Employees'
    },
    value: {
      ar: 'الموظفين',
      en: 'Employees'
    }
  }
];

const ServiceStepEight = ({ setStep }) => {
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    stepEight: { targetAudience }
  } = serviceDetailsLocalization;
  const { isSubmitting, values, setFieldValue, errors, touched, setTouched } =
    useFormikContext();

  useEffect(() => {
    if (values.targetAudience !== 'other' && values.otherTargetAudience) {
      setFieldValue('otherTargetAudience', '');
    }
  }, [values.targetAudience]);

  useEffect(() => {
    setTouched({});
  }, []);
  useEffect(() => {
    if (values.videoPurpose !== 'other' && values.otherTargetSector) {
      setFieldValue('otherTargetSector', '');
    }
  }, [values.videoPurpose]);
  useEffect(() => {
    if (
      values?.scriptIsReady === 'ready' &&
      values?.acceptScript === 'accept'
    ) {
      setStep((prev) => prev + 1);
    }
  }, [values]);
  return (
    <div className="formik-step service-step-eight">
      {((values?.scriptIsReady === 'ready' &&
        values?.acceptScript === 'review') ||
        values?.scriptIsReady === 'not_ready') && (
        <div className="checkBoxs-title-wrap">
          <div className="checkboxs-title">{targetAudience.label[code]}</div>

          <div className="checkboxs-wrap">
            {allTargetAudience.map((item, index) => (
              <div key={index} className="checkbox-input-wrap">
                <label>
                  <Field
                    type="checkbox"
                    name={`targetAudience`}
                    value={String(item?.value[code]) || String(item.value)}
                    disabled={isSubmitting}
                  />
                  {item?.label[code]}
                </label>
              </div>
            ))}

            <label>
              <Field
                type="checkbox"
                name={`targetAudience_other_checkbox`}
                disabled={isSubmitting}
              />
              {code === 'ar' ? 'أخرى يجب ذكرها' : 'Other, you should mention'}
            </label>
          </div>

          {errors?.targetAudience && (
            <Alert severity="error" className="error-alert">
              {errors?.targetAudience}
            </Alert>
          )}

          {values.targetAudience_other_checkbox && (
            <Field
              component={TextField}
              label={targetAudience.otherTextarea.label[code]}
              name="otherTargetAudience"
              variant="outlined"
              multiline
              rows="2"
              className="other-field"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceStepEight;
