import React from "react";

const SettingsIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Icon_feather-settings"
			data-name="Icon feather-settings"
			width="29.585"
			height="29.585"
			viewBox="0 0 29.585 29.585"
		>
			<path
				id="Path_268"
				data-name="Path 268"
				d="M20.75,17.125A3.625,3.625,0,1,1,17.125,13.5,3.625,3.625,0,0,1,20.75,17.125Z"
				transform="translate(-2.333 -2.333)"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
			/>
			<path
				id="Path_269"
				data-name="Path 269"
				d="M23.734,18.418a1.994,1.994,0,0,0,.4,2.2l.073.073a2.418,2.418,0,1,1-3.42,3.42l-.073-.073a2.01,2.01,0,0,0-3.408,1.426v.205a2.417,2.417,0,1,1-4.834,0v-.109a1.994,1.994,0,0,0-1.305-1.825,1.994,1.994,0,0,0-2.2.4l-.073.073a2.418,2.418,0,1,1-3.42-3.42l.073-.073a2.01,2.01,0,0,0-1.426-3.408H3.917a2.417,2.417,0,1,1,0-4.834h.109A1.994,1.994,0,0,0,5.85,11.167a1.994,1.994,0,0,0-.4-2.2L5.379,8.9A2.418,2.418,0,1,1,8.8,5.476l.073.073a1.994,1.994,0,0,0,2.2.4h.1a1.994,1.994,0,0,0,1.208-1.825V3.917a2.417,2.417,0,1,1,4.834,0v.109a2.01,2.01,0,0,0,3.408,1.426l.073-.073a2.418,2.418,0,1,1,3.42,3.42l-.073.073a1.994,1.994,0,0,0-.4,2.2v.1a1.994,1.994,0,0,0,1.825,1.208h.205a2.417,2.417,0,1,1,0,4.834h-.109a1.994,1.994,0,0,0-1.825,1.208Z"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
			/>
		</svg>
	);
};

export default SettingsIcon;
