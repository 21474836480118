const avatarUploadLocalization = {
	title: {
		ar: "أسحب الصورة وضعها هنا...",
		en: "Drag the image here..."
	},
	saveBtn: {
		ar: "حفظ",
		en: "Save"
	}
};

export default avatarUploadLocalization;
