/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// import "./ServiceStepFive.scss";

import React, { useContext, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import UserServicesContext from "../../../contexts/services-context/UserServicesProvider";
import { FormControlLabel, FormHelperText, Radio } from "@material-ui/core";
import { RadioGroup, TextField } from "formik-material-ui";
import LangContext from "../../../contexts/lang-context/LangProvider";
import { makeStyles } from "@material-ui/core/styles";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import { PublishRounded } from "@material-ui/icons";
import "./ServiceSixStepOne.scss";

const lightGraphicsTypes = {
  yes: "1",
  no: "0",
};

const filmMaterialTypes = {
  uploadFile: "upload_file",
  fromApp: "from_app",
};

const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1,
  },
  field: {
    margin: "10px 0",
  },
}));

const ServiceSixStepOne = () => {
  const classes = useStyles();
  const {
    language: { code },
  } = useContext(LangContext);
  const { servicePrices } = useContext(UserServicesContext);
  const {
    service6StepTen: { motion, isFilm },
  } = serviceDetailsLocalization;
  const {
    isSubmitting,
    setTouched,
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const isMotionArr = [
    {
      id: 1,
      label: {
        ar: `نعم`,
        en: `Yes`,
        // ar: `نعم ${servicePrices?.graphPricePerSeconds}$ لكل ${servicePrices?.graphCountSeconds} ثانية - ملاحظة تتم البرمجة لحساب عدد الكلمات ${servicePrices?.countWordsEqualMinute} كلمة = دقيقة وتحسب كل كلمتين زيادة بثانية وقيمة الثانية ب ${servicePrices?.secondPrice}$`,
        // en: `Yes ${servicePrices?.graphPricePerSeconds}$ for every ${servicePrices?.graphCountSeconds} second - Note words count ${servicePrices?.countWordsEqualMinute} word = one minute and every additional 2 words equal one second and the second equals ${servicePrices?.secondPrice}$`,
      },
      value: lightGraphicsTypes?.yes,
    },
    {
      id: 2,
      label: {
        ar: "لا",
        en: "No",
      },
      value: lightGraphicsTypes?.no,
    },
  ];

  const isFilmArr = [
    {
      id: 1,
      label: {
        ar: "نعم",
        en: "Yes",
      },
      value: filmMaterialTypes?.uploadFile,
    },
    {
      id: 2,
      label: {
        ar: ` أختيار المادة الفلمية والصور حسب السيناريو من قبل فيديو تيوب`,
        en: `Choose the film material and images according to the scenario by Video Tube`,
        // ar: `هل تريد أختيار المادة الفلمية والصور حسب السيناريو من قبل فيديو تيوب؟ ${servicePrices?.filmServicePrice}$`,
        // en: `Do you want to choose the film material and images according to the scenario by Video Tube? ${servicePrices?.filmServicePrice}$`,
      },
      value: filmMaterialTypes?.fromApp,
    },
  ];

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.label[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === String(item.value))
                setFieldValue(fieldName, "");
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  useEffect(() => {
    setTouched({});
  }, []);

  const handleFileUpload = async (name, event) => {
    // await sleep(500);
    let file = await event.target.files[0];
    setFieldValue(name, file);
    setFieldTouched(name, true);
  };
  const renderFileLinkInputs = () => {
    return (
      <div className="file-link-inputs-wrap">
        <div className="upload-wrap">
          <div className="title-input-wrap">
            <div className="input-title">Upload Film Materials</div>
            <label className="upload-file-label">
              {/* <Field name="cv" type="file" /> */}
              <input
                id="file"
                name="film_material_file"
                type="file"
                onChange={(e) => handleFileUpload("film_material_file", e)}
              />
              {values?.film_material_file ? (
                values.film_material_file?.name &&
                values.film_material_file.name.split(/(\\|\/)/g).pop()
              ) : (
                <>
                  <span className="icon-text">
                    <PublishRounded />
                    Upload Film Materials
                  </span>
                </>
              )}
            </label>
          </div>

          {errors?.film_material_file && touched?.film_material_file && (
            <FormHelperText error>{errors.film_material_file}</FormHelperText>
          )}
        </div>

        <Field
          component={TextField}
          label="Film Materials Link"
          name="film_material_link"
          variant="outlined"
        />
      </div>
    );
  };
  //

  return (
    <div className="formik-step service-six-step-one">
      <div className="radio-group-wrapper light-graphics-wrapper">
        <h3 className="group-title">{motion.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="light_graphics"
        >
          {renderRadiosGroup(isMotionArr, "light_graphics")}
        </Field>
      </div>

      {/* {values?.light_graphics == lightGraphicsTypes?.yes && ( */}
        <div className="radio-group-wrapper film-material-type">
          <h3 className="group-title">{isFilm.label[code]}</h3>
          <Field
            className="field-group-wrap"
            component={RadioGroup}
            name="film_material_type"
          >
            {renderRadiosGroup(isFilmArr, "film_material_type")}
          </Field>
        </div>
      {/* )} */}

      {
        values?.film_material_type == 
        filmMaterialTypes?.uploadFile &&
        renderFileLinkInputs()}
    </div>
  );
};

export default ServiceSixStepOne;
