import "./MessageBox.scss";

import React, { useContext } from "react";
import { AccessTime } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";
import UesrContext from "../../contexts/user-context/UserProvider";
import indexLocalization from "../../localization";
import LangContext from "../../contexts/lang-context/LangProvider";
const MessageBox = ({ message }) => {
	// const { clsName, msgSenderName, msgSenderImg, msgText, msgCreatedAt } = props;
	const { user } = useContext(UesrContext);

	const { downloadFile } = indexLocalization
	const {
		language: { code, dir },
	} = useContext(LangContext);

	return (
		<div className={`${user.accountType == message.sender_type ? '' : 'flexEnd'}`}>

			<div className={`message-box-wrap ${user.accountType == message.sender_type ? 'bg-red' : 'bg-gray'}`}>
				{/* <div className="img-sender-wrap">
				{msgSenderImg ? (
					<img src={msgSenderImg} alt="sender" />
				) : (
					<Avatar>{msgSenderName?.charAt(0)}</Avatar>
				)}
			</div> */}

				<div className="msg-data">
					{message.message && <div className="msg-text">{message.message}</div>}
					{message.file && <a href={`${process.env.REACT_APP_BACK_END_URL}${message.file}`} className="msg-text">{downloadFile[code]}</a>}
					{message.image && <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACK_END_URL}${message.image}`} className="msg-text">
						{/* {message.image} */}
						<img width="180px" height="180px" style={{ objectFit: "cover" }} src={`${process.env.REACT_APP_BACK_END_URL}${message.image}`} alt="" />
					</a>}

					{message.created_at && (
						<div className="msg-created-at">
							<AccessTime />
							{message.created_at}
						</div>
					)}
				</div>

			</div>
		</div>

	);
};

export default MessageBox;
