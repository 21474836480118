import userSideMenuLocalization from '../../localization/userSideMenu.localization';
import ArrowLeftIcon from '../../common/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../common/icons/ArrowRightIcon';
import BellIcon from '../../common/icons/BellIcon';
import ChatIcon from '../../common/icons/ChatIcon';
import SettingsIcon from '../../common/icons/SettingsIcon';
import VideoIcon from '../../common/icons/VideoIcon';
import routerLinks from '../../components/app/routerLinks';

const { services, myOrders, notifications, messages, settings } =
  userSideMenuLocalization;

const {
  userServices,
  userOrders,
  userNotifications,
  userMessages,
  userSettings
} = routerLinks;

const userLinks = ({ dir, code }) => [
  {
    id: 1,
    name: services[code],
    route: userServices,
    icon: dir === 'rtl' ? <ArrowLeftIcon /> : <ArrowRightIcon />,
    className: 'services-link'
  },
  {
    id: 2,
    name: myOrders[code],
    route: "/dashboard/user/my-orders/pending",
    icon: <VideoIcon />,
    className: 'orders-link'
  },
  {
    id: 3,
    name: notifications[code],
    route: userNotifications,
    icon: <BellIcon />,
    className: 'notifications-link'
  },
  {
    id: 4,
    name: messages[code],
    route: userMessages,
    icon: <ChatIcon />,
    className: 'messages-link'
  },
  {
    id: 5,
    name: settings[code],
    route: userSettings,
    icon: <SettingsIcon />,
    className: 'settings-link'
  }
];

export default userLinks;
