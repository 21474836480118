import React from "react";

const ChatIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="27.895"
			height="26.5"
			viewBox="0 0 27.895 26.5"
		>
			<g
				id="Group_4022"
				data-name="Group 4022"
				transform="translate(-12.553 -13.947)"
			>
				<circle
					id="Ellipse_102"
					data-name="Ellipse 102"
					cx="1.5"
					cy="1.5"
					r="1.5"
					transform="translate(25 24)"
					// fill="#8d8d8d"
				/>
				<ellipse
					id="Ellipse_103"
					data-name="Ellipse 103"
					cx="1"
					cy="1.5"
					rx="1"
					ry="1.5"
					transform="translate(31 24)"
					// fill="#8d8d8d"
				/>
				<ellipse
					id="Ellipse_104"
					data-name="Ellipse 104"
					cx="1"
					cy="1.5"
					rx="1"
					ry="1.5"
					transform="translate(20 24)"
					// fill="#8d8d8d"
				/>
				<path
					id="Path_161"
					data-name="Path 161"
					d="M25.711,3H6.184A4.184,4.184,0,0,0,2,7.184V28.105a1.394,1.394,0,0,0,2.106,1.2l6.262-3.794a1.4,1.4,0,0,1,.767-.2H25.711a4.184,4.184,0,0,0,4.184-4.184V7.184A4.184,4.184,0,0,0,25.711,3Zm1.395,18.132a1.4,1.4,0,0,1-1.395,1.395H11.136a4.184,4.184,0,0,0-2.162.6L4.789,25.637V7.184A1.4,1.4,0,0,1,6.184,5.789H25.711a1.4,1.4,0,0,1,1.395,1.395Z"
					transform="translate(10.553 10.947)"
					// fill="#8d8d8d"
				/>
			</g>
		</svg>
	);
};

export default ChatIcon;
