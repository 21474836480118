import axios from "axios";

const getMessagesApi = async (user, id) => {
    try {
        const func = await axios.get(
            `/api/v1/${user.accountType}/messages?api_token=${user?.api_token}&order_id=${id}`,
            {
                headers: {
                    password: process.env.REACT_APP_AUTH_PASS,
                    "X-Portal": user?.accountType,

                }
            }
        );

        return func;
    } catch (e) {
        throw e;
    }
};

export const getSuperVisorMessagesApi = async (user, orderId,type,typeID) => {
    let url ="";
    if(type ==="employee") {
        url =`/api/v1/${user.accountType}/messages?api_token=${user?.api_token}&order_id=${orderId}&employee_id=${typeID}`
    } else if(type === "client") {
        url =`/api/v1/${user.accountType}/messages?api_token=${user?.api_token}&order_id=${orderId}&client_id=${typeID}`

    } else {
        url =`/api/v1/${user.accountType}/messages?api_token=${user?.api_token}&order_id=${orderId}`

    }
    try {
        const func = await axios.get(
            url,
            {
                headers: {
                    password: process.env.REACT_APP_AUTH_PASS,
                    "X-Portal": user?.accountType,

                }
            }
        );

        return func;
    } catch (e) {
        throw e;
    }
};
export default getMessagesApi