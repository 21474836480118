import { createMuiTheme } from "@material-ui/core/styles";

const theme = dir =>
	createMuiTheme({
		direction: dir,
		palette: {
			primary: {
				main: "#d72a33"
				// main: '#ffc400'
				// main: '#2F4456'
				// main: "#d72a33"
			},
			secondary: {
				main: "#0984e3"
				// main: "#f44336"
				// main: "#fff"
			}
		},
		typography: {
			fontFamily: null
		}
	});

export default theme;
