import { createContext, useState } from 'react';
const INITIAL_VALUES = {
  userCart: null,
  setUserCart: (v) => {},
  fetchCartCount: 0,
  setFetchCartCount: (v) => {},
  isLoadingCart: false,
  setIsLoadingCart: (v) => {}
};

const UserCartContext = createContext(INITIAL_VALUES);

export const UserCartProvider = ({ children }) => {
  const [userCart, setUserCart] = useState(INITIAL_VALUES?.userCart);
  const [fetchCartCount, setFetchCartCount] = useState(
    INITIAL_VALUES?.fetchCartCount
  );
  const [isLoadingCart, setIsLoadingCart] = useState(
    INITIAL_VALUES?.isLoadingCart
  );
  return (
    <UserCartContext.Provider
      value={{
        userCart,
        setUserCart,
        fetchCartCount,
        setFetchCartCount,
        isLoadingCart,
        setIsLoadingCart
      }}
    >
      {children}
    </UserCartContext.Provider>
  );
};

export default UserCartContext;
