import axios from "axios";

export const signup = async (data, langCode) => {
  try {
    const response = await axios.post("/api/v1/register", data, {
      headers: {
        password: process.env.REACT_APP_AUTH_PASS,
        // password: "#?Pith]Soft&_!VideoTube@7$21%",
        // "Content-Type": "application/json",
        // Accept: "application/json",
        "content-type": "multipart/form-data",
        lang: langCode,
      },
    });
    return response;
  } catch (e) {
    console.log(e.response);
    throw e;
  }
};
