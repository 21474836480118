import { useContext } from "react";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import ServiceStepTen from "../service-step-ten/ServiceStepTen";
import * as Yup from "yup";

const ServiceSixStepEleven = () => {
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection, musicSelection },
  } = serviceDetailsLocalization;
  const {
    language: { code },
  } = useContext(LangContext);
  return (
    <>
      <ServiceStepTen
        validationSchema={Yup.object().shape({
          socialMediaAddresses: Yup.object().shape({
            socialCheckBoxs: Yup.array()
              .of(Yup.string().required())
              .min(1, socialMedia.errors.required[code]),

            inputs: Yup.object().shape({
              twitter: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.twitter",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "twitter"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                  // error shows if the return of the test function is false
                }
              ),
              facebook: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.facebook",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "facebook"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
              instagram: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.instagram",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "instagram"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),

              snapchat: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.snapchat",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "snapchat"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),

              youtube: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.youtube",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "youtube"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
              linkedin: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.linkedin",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "linkedin"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
              tiktok: Yup.string().test(
                "socialMediaAddresses.socialCheckBoxs.inputs.tiktok",
                socialMedia.textField.errors[code],
                (value, context) => {
                  let result = true;
                  let found = context.from[1].value.socialCheckBoxs.find(
                    (i) => i === "tiktok"
                  );
                  if (found && !value?.trim()) {
                    result = false;
                  }
                  return result;
                }
              ),
            }),
          }),
          font: Yup.string().required(fontSelection.errors.required[code]),
          uploadedFont: Yup.mixed().when("font", {
            is: "yes",
            then: Yup.mixed().required(fontSelection.yes.errors.required[code]),
            // .test(
            //   "fontFormat",
            //   fontSelection.yes.errors.unSuppFormat[code],
            //   (value) => {
            //     let result = true;
            //     if (value && !SUPPORTED_FORMATS.pdf.includes(value.type)) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // )
            // .test(
            //   "fontSize",
            //   fontSelection.yes.errors.tooLarge[code],
            //   (value) => {
            //     let result = true;
            //     if (value && value.size > FILE_SIZE) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
          }),
          music: Yup.string().required(musicSelection.errors.required[code]),
          uploadedMusic: Yup.mixed().when("font", {
            is: "yes",
            then: Yup.mixed().required(
              musicSelection.yes.errors.required[code]
            ),
            // .test(
            //   "fontFormat",
            //   musicSelection.yes.errors.unSuppFormat[code],
            //   (value) => {
            //     let result = true;
            //     if (value && !SUPPORTED_FORMATS.pdf.includes(value.type)) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // )
            // .test(
            //   "fontSize",
            //   musicSelection.yes.errors.tooLarge[code],
            //   (value) => {
            //     let result = true;
            //     if (value && value.size > FILE_SIZE) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
          }),
          color: Yup.string().required(colorSelection.errors.required[code]),
          uploadedColor: Yup.mixed().when("color", {
            is: "yes",
            then: Yup.mixed().required(
              colorSelection.yes.errors.required[code]
            ),
            // .test(
            //   "fileFormat1",
            //   colorSelection.yes.errors.unSuppFormat[code],
            //   (value) => {
            //     let result = true;
            //     if (value && !SUPPORTED_FORMATS.pdf.includes(value.type)) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
            // .test(
            //   "fileSize1",
            //   colorSelection.yes.errors.tooLarge[code],
            //   (value) => {
            //     let result = true;
            //     if (value && value.size > FILE_SIZE) {
            //       result = false;
            //     }
            //     return result;
            //   }
            // ),
          }),
        })}
      />
    </>
  );
};

export default ServiceSixStepEleven;
