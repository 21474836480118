import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingModal from "../../common/loading-modal/LoadingModal";
import footerPageApi from "../../apis/general/footerPageApi";
import LangContext from "../../contexts/lang-context/LangProvider";
import UesrContext from "../../contexts/user-context/UserProvider";

const FooterPage = () => {
  const { id } = useParams();
  const {
    language: { code },
  } = useContext(LangContext);
  const { user } = useContext(UesrContext);

  const [loading, setLoading] = useState(true);
  const [innerHtml, setInnerHtml] = useState("");
  useEffect(() => {
    async function fetchFooterPage(key, lang) {
      const {
        data: { data },
      } = await footerPageApi(key, lang, user);

      setInnerHtml(data);
      setLoading(false);
    }
    fetchFooterPage(id, code);
  }, [id, code, user]);
  return (
    <div>
      {loading ? (
        <LoadingModal />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: innerHtml }} />
      )}
    </div>
  );
};

export default FooterPage;
