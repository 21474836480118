const signinPage = {
  signinLinks: {
    userLink: {
      ar: 'تسجيل الدخول كمستخدم',
      en: 'Signin as a user'
    },
    developerLink: {
      ar: 'تسجيل الدخول كموظف',
      en: 'Signin as an employee'
    },

    supervisorLink: {
      ar: 'تسجيل الدخول كمشرف',
      en: 'Signin as an supervisor'
    }
  },
  userSignin: {
    title: {
      ar: 'تسجيل الدخول كمستخدم',
      en: 'Signin a a user'
    }
  },
  developerSignin: {
    title: {
      ar: 'تسجيل الدخول كموظف',
      en: 'Signin as an employee'
    }
  },
  supervisorSignin: {
    title: {
      ar: 'تسجيل الدخول كمشرف',
      en: 'Signin as an supervisor'
    }
  }
};

export default signinPage;
