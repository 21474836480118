import React from "react";

const MaleIcon = ({ color }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11.187"
			height="29.831"
			viewBox="0 0 11.187 29.831"
		>
			<path
				id="Icon_awesome-male"
				data-name="Icon awesome-male"
				d="M5.593,0A3.729,3.729,0,1,1,1.864,3.729,3.729,3.729,0,0,1,5.593,0m2.8,8.39H7.728a5.12,5.12,0,0,1-4.269,0H2.8a2.8,2.8,0,0,0-2.8,2.8V19.11a1.4,1.4,0,0,0,1.4,1.4h.932v7.924a1.4,1.4,0,0,0,1.4,1.4H7.458a1.4,1.4,0,0,0,1.4-1.4V20.509h.932a1.4,1.4,0,0,0,1.4-1.4V11.186A2.8,2.8,0,0,0,8.39,8.39Z"
				fill={color}
			/>
		</svg>
	);
};

export default MaleIcon;
