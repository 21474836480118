import { useContext } from "react";
import LangContext from "../../../contexts/lang-context/LangProvider";
import ServiceStepThree from "../service-step-three/ServiceStepThree";
import * as Yup from "yup";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";

const ServiceFiveStepFour = () => {
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection },
  } = serviceDetailsLocalization;
  const {
    language: { code },
  } = useContext(LangContext);

  return (
    <>
      <ServiceStepThree
        validationSchema={Yup.object().shape({
          // arabicVoiceOver: Yup.string().required(
          // 	stepThree.errors.requiredVoiceOver[code]
          // )
          // ,
          arabicVoiceOverModel: Yup.string().when("arabicVoiceOver", {
            is: (val) => val,
            then: Yup.string().required(stepThree.errors.requiredModel[code]),
          }),
          // englishVoiceOver: Yup.string().required(
          // 	stepThree.errors.requiredVoiceOver[code]
          // )
          // ,
          englishVoiceOverModel: Yup.string().when("englishVoiceOver", {
            is: (val) => val,
            then: Yup.string().required(stepThree.errors.requiredModel[code]),
          }),
          count_voice_comment: Yup.string()
            .matches(/^[1-9]+$/, "Please enter a valid number")
            .required("Please enter the number of interlocutors"),
        })}
      />
    </>
  );
};

export default ServiceFiveStepFour;
