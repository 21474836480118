import React from "react";

const CartIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Icon_feather-shopping-cart"
			data-name="Icon feather-shopping-cart"
			width="27.648"
			height="26.528"
			viewBox="0 0 27.648 26.528"
		>
			<path
				id="Path_1335"
				data-name="Path 1335"
				d="M14.241,31.12A1.12,1.12,0,1,1,13.12,30,1.12,1.12,0,0,1,14.241,31.12Z"
				transform="translate(-2.657 -7.213)"
				fill="none"
				stroke="#8d8d8d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
			/>
			<path
				id="Path_1336"
				data-name="Path 1336"
				d="M30.741,31.12A1.12,1.12,0,1,1,29.62,30,1.12,1.12,0,0,1,30.741,31.12Z"
				transform="translate(-6.833 -7.213)"
				fill="none"
				stroke="#8d8d8d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
			/>
			<path
				id="Path_1337"
				data-name="Path 1337"
				d="M1.5,1.5H5.982l3,15a2.241,2.241,0,0,0,2.241,1.8h10.89a2.241,2.241,0,0,0,2.241-1.8l1.793-9.4H7.1"
				fill="none"
				stroke="#8d8d8d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
			/>
		</svg>
	);
};

export default CartIcon;
