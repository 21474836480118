
export function socialReducer(state, action) {
    switch (action.type) {
        case "addSocial": {
            return {
                ...state,
                facebook: action.payload?.facebook_link,
                twitter: action.payload?.twitter_link,
                instagram: action.payload?.insta_link,
                youtube: action.payload?.videotube_link,
                linkedin: action.payload?.linked_link,
                tiktok: action.payload?.tiktok_link,
                whatsapp: action.payload?.phone_num,
                snap: action.payload?.snap_link,
                email: action.payload?.email,
                address: action.payload?.address,
                mobile: action.payload?.phone_num,
                isLoading:false
            }
        }

        default: {
            throw Error('Unknown action: ' + action.type);

        }

    }
}