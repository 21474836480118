/* eslint-disable array-callback-return */
import axios from "axios";

export const employeeApply = async (user, values, langCode) => {
  const {
    firstname,
    lastname,
    country,
    city,
    cv,
    identity,
    job_apply_reason,
    your_skill,
    weak_points,
    strengths_points,
    team_work,
    has_experience,
    about,
    company_feedback,
    best_contact,
    best_work_time,
    services,
    gender,
    experience_year,
    ex_works,
    language,
    transfer_money_way,
    bank_name,
    bank_country,
    bank_account_num,
    iban_num,
    western_full_name,
    western_country,
    paypal_mail,
    email,
    mobile,
    is_accept,
    role
  } = values;

  const getAttributesArr = (arr) => {
    let attArr = [];
    if (arr?.length > 0) {
      const filteredArray = arr.filter((obj) => obj?.attr_id?.length > 0)
      attArr = filteredArray.map((obj) => {
        if (obj?.attr_id?.length > 0) {
          // if (obj?.voiceNote)
          //   return {
          //     attr_id: obj.attr_id[0],
          //     voice: obj?.voiceNote,
          //   };
          if(obj?.attr_id[0]) {
            return {
              attr_id: obj?.attr_id[0],
            };
          }
         
        }
      });
    }

    return attArr;
  };

  try {
    const formData = new FormData();
    formData.append("api_token", user?.api_token);
    formData.append("name", firstname);
    formData.append("nickname", lastname);
    formData.append("country_id", country);
    formData.append("city_id", city || "1");
    formData.append("cv", cv);
    formData.append("personalID", identity);
    formData.append("job_apply_reason", job_apply_reason);
    formData.append("your_skill", your_skill);
    formData.append("weak_points", weak_points);
    formData.append("strengths_points", strengths_points);
    formData.append("team_work", team_work);
    formData.append("has_experience", has_experience);
    formData.append("about", about);
    formData.append("company_feedback", company_feedback);
    formData.append("best_contact", best_contact);
    formData.append("best_work_time", best_work_time);

    if (services?.length > 0) {
      let arr = services.filter((item) => item);
      if (arr?.length > 0) {
        let newArr = [];
        for (let obj of arr) {
          if(obj?.service_id) {
            newArr.push({
              service_id: obj?.service_id?.length > 0 ? obj.service_id[0] : "",
              attributes:
                obj?.attributes?.length > 0
                  ? getAttributesArr(obj.attributes)
                  : [],
            });
          }
    
        }
        formData.append("services", JSON.stringify(newArr));
        // for (let obj of arr) {
        //   formData.append(
        //     'services[]',
        //     JSON.stringify({
        //       service_id: obj?.service_id?.length > 0 ? obj.service_id[0] : '',
        //       attributes:
        //         obj?.attributes?.length > 0
        //           ? JSON.stringify(getAttributesArr(obj.attributes))
        //           : []
        //     })
        //   );
        // }
      }
    }
    formData.append("gender", gender);
    formData.append("experience_year", experience_year);
    for (let file of ex_works) {
      formData.append("ex_works[]", file);
    }
    formData.append("language", language);
    formData.append("transfer_money_way", transfer_money_way);
    formData.append("bank_name", bank_name);
    formData.append("bank_country", bank_country);
    formData.append("bank_account_num", bank_account_num);
    formData.append("iban_num", iban_num);
    formData.append("western_full_name", western_full_name);
    formData.append("western_country", western_country);
    formData.append("paypal_mail", paypal_mail);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("is_accept", is_accept ? "1" : "0");
    formData.append("role", user?.accountType);

    // for (const pair of formData.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`);
    // }
    const response = await axios.post(
      "/api/v1/application/submit",
      formData,
      {
        headers: {
          "X-Portal": user?.accountType,
          password: process.env.REACT_APP_AUTH_PASS,
          "Content-Type": "multipart/form-data",
          "X-Language": langCode,
        },
      }
    );
    return {res:response,err:null};
  } catch (e) {
    console.log(e);
    return {res:null ,err:e}
  }
};
