import axios from 'axios';

const calculateNumberOfMinutesApi = async (values, user, langCode) => {
  try {
    const func = await axios.get(
      `/api/v1/client/word-duration?api_token=${user?.api_token}&count_words=${values?.count_words}&service_id=${values?.service_id}`,
      {
        headers: {
          'X-Portal': user?.accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          lang: langCode
        }
      }
    );

    return func;
  } catch (e) {
    throw e;
  }
};

export default calculateNumberOfMinutesApi;
