import axios from "axios";

export const getUserNotifications = async ( user,id, langCode,level) => {
    try {
      const res = await axios.get(
        `/api/v1/client/notifications?api_token=${user?.api_token}`,
        {
          headers: {
            'X-Portal': user?.accountType,
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          },
          
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };
  