/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import ButtonWithLoading from '../../common/button-with-loading/ButtonWithLoading';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import {
  TextField as MuiTextField,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  PersonRounded,
  EmailRounded
} from '@material-ui/icons';
import LangContext from '../../contexts/lang-context/LangProvider';
import developerSignup from '../../localization/developerSignup.localization';
import SocialApiHandler from '../../common/social-api-handler/SocialApiHandler';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import useSignupEmailPassword from '../../custom-hooks/useSignupEmailPassword';
import userTypes from '../../constants/userTypes';
import routerLinks from '../app/routerLinks';
import UesrContext from '../../contexts/user-context/UserProvider';
import footerPageApi from '../../apis/general/footerPageApi';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1
  },
  field: {
    margin: '10px 0'
  }
}));
const SupervisorSignupForm = ({type}) => {
  const { signMeUpWithEmailPassword, isLoadingSignup } =
    useSignupEmailPassword();
  const classes = useStyles();

  const {
    language: { code }
  } = useContext(LangContext);
  const {
    fields: {
      firstname,
      lastname,
      email,
      phoneNumber,
      password,
      confirmPassword,
      agreeTerms
    },
    signupBtn,
    alreadySignedup
  } = developerSignup;

  const SIGNUP_SCHEMA = Yup.object().shape({
    firstname: Yup.string()
      // .trim('No leading and trailing spaces')
      // .strict(true)
      .required(firstname.errors.required[code])
      .min(2, firstname.errors.minChar(2)[code])
      .max(12, firstname.errors.maxChar(12)[code])
      .trim()
      .test('firstname', firstname.errors.noSpaces[code], (value) => {
        return value && !value.includes(' ');
      })
      ,
    lastname: Yup.string()
      // .trim('No leading and trailing spaces')
      // .strict(true)
      .required(lastname.errors.required[code])
      .min(2, lastname.errors.minChar(2)[code])
      .max(12, lastname.errors.maxChar(12)[code])
      .trim()
      .test('lastname', lastname.errors.noSpaces[code], (value) => {
        return value && !value.includes(' ');
      }),
    email: Yup.string()
      .email(email.errors.typeError[code])
      // .typeError()
      .trim()
      .required(email.errors.required[code]),
    mobile: Yup.string()
      .required(phoneNumber.errors.required[code])
      .matches(/^[0-9]+$/, phoneNumber.errors.validNumber[code])
    .min(11, phoneNumber.errors.minChar(11)[code])
    .max(15, phoneNumber.errors.maxChar(15)[code]),
    password: Yup.string()
      .required(password.errors.required[code])
      .min(6, password.errors.minChar(6)[code])
      .max(22, password.errors.maxChar(22)[code])
      .trim(password.errors.trim[code])
      // .trim("No leading or trailing spaces")
      .strict(),
    password_confirmation: Yup.string()
      .required(confirmPassword.errors.required[code])
      .oneOf([Yup.ref('password')], confirmPassword.errors.validate[code]),
    accept_term: Yup.boolean()
      .required(agreeTerms.errors[code])
      .oneOf([true], agreeTerms.errors[code])
  });

  const [showPass, setShowPass] = useState(false);
  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [termsModalOpened, setTermsModalOpened] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const handlePhoneValue = (v, setFieldValue,zipCode) => {
    setPhoneValue(v);
    setFieldValue('mobile', v);
    setFieldValue('zip_code', zipCode);

  };
  const { user } = useContext(UesrContext);

  const  [termsHtml,setTermsHtml] = useState(null)
  const handleTermsModal =async () => {

    const {data :{data}}= await footerPageApi(type,code,user)
    setTermsHtml(data)
    setTermsModalOpened(true)
  }


  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        password: '',
        password_confirmation: '',
        accept_term: true,
        zip_code:''

      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await sleep(500);
        setSubmitting(true);
        const castedValues = {
          type: userTypes?.supervisor,
          f_name: values.firstname.trim(),
          l_name: values.lastname.trim(),
          email: values?.email,
          mobile: values?.mobile?.replace(values?.zip_code,''),
          password: values?.password,
          password_confirmation: values?.password_confirmation,
          accept_term: values?.accept_term ? 1 : 0,
          social_id: null,
          social_type: null,
          zip_code:values.zip_code

        };
        signMeUpWithEmailPassword(
          userTypes?.supervisor,
          castedValues,
          resetForm
        );
      }}
      validationSchema={SIGNUP_SCHEMA}
    >
      {({
        values,
        isSubmitting,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setFieldTouched

      }) => {
        console.log('errors : ', errors);

        return (
          <>
            <form
              onSubmit={handleSubmit}
              className="developer-signup-form"
              autoComplete="off"
              id="developer-signup-form"
            >
              <Field
                component={TextField}
                name="firstname"
                type="text"
                label={firstname.label[code]}
                variant="standard"
                color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <PersonRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Field
                component={TextField}
                name="lastname"
                type="text"
                label={lastname.label[code]}
                variant="standard"
                color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <PersonRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Field
                component={TextField}
                name="email"
                type="email"
                label={email.label[code]}
                variant="standard"
                color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <EmailRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <div className="phone-number-wrap">
                <ReactPhoneInput
                  country={'ae'}
                  value={phoneValue}
                  onChange={(v,result) => handlePhoneValue(v, setFieldValue,result?.dialCode)}
                  component={MuiTextField}
                  dropdownClass={classes.countryList}
                  specialLabel={false}
                  name="mobile"
                  onBlur={() => {
                    setFieldTouched('mobile', true);
                  }}
                  label={phoneNumber.label[code]}

                />
                {touched?.mobile && errors?.mobile && (
                  <FormHelperText error>{errors.mobile}</FormHelperText>
                )}
              </div>

              <Field
                component={TextField}
                name="password"
                type={showPass ? 'text' : 'password'}
                label={password.label[code]}
                variant="standard"
                color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Field
                component={TextField}
                name="password_confirmation"
                type={showPass ? 'text' : 'password'}
                label={confirmPassword.label[code]}
                variant="standard"
                color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <div className="terms-conditions-wrap">
                <Field
                  component={CheckboxWithLabel}
                  name="accept_term"
                  type="checkbox"
                  Label={{
                    // label: agreeTerms.label[code],
                    className: 'terms-input'
                  }}
                  // label={agreeTerms.label[code]}
                  color="primary"
                />

                <div
                  className={`terms-btn ${
                    touched?.accept_term && errors?.accept_term ? 'error' : ''
                  }`}
                  onClick={() => handleTermsModal()}
                >
                  {agreeTerms.label[code]}
                </div>
              </div>

              <Modal
                className="terms-modal"
                open={termsModalOpened}
                onClose={() => setTermsModalOpened(false)}
              >
                <div className="modal-content">
                  <h2 className="modal-h">سياسة الشروط والأحكام</h2>
                  <div className="terms-data" dangerouslySetInnerHTML={{__html:termsHtml}}>
     
                  </div>
                </div>
              </Modal>

              {/* <ButtonWithLoading
						isSubmitting={isSubmitting}
						errors={errors}
						btnText={signupBtn[code]}
						loadingMsg="Please Wait.."
						className="submit-btn"
					/> */}

              {/* <pre>{JSON.stringify(values, null, 2)}</pre>
					<pre>{JSON.stringify(errors, null, 2)}</pre>
					<pre>{JSON.stringify(touched, null, 2)}</pre> */}
            </form>
            <div className="btn-more-options">
              <ButtonWithLoading
                isSubmitting={isLoadingSignup}
                errors={errors}
                btnText={signupBtn[code]}
                loadingMsg="Please Wait.."
                className="submit-btn"
                form="developer-signup-form"
              />

              <SocialApiHandler comp={userTypes?.supervisor} />
            </div>

            <div className="have-account">
              <p className="pp">{alreadySignedup.p[code]}</p>
              <span>..</span>
              <RouterLink
                className="signin-link"
                to={routerLinks?.supervisorSignin}
              >
                {alreadySignedup.link[code]}
              </RouterLink>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default SupervisorSignupForm;
