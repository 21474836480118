const desingerBalanceLocalization = {
	mainTitle: {
		ar: "رصيدى",
		en: "My balance"
	},
	totalBalanceTitle: {
		ar: "اجمالي الرصيد المتاح للسحب",
		en: "The total balance available for withdrawal"
	},
	suspendedBalance: {
		ar: "رصيد معلق",
		en: "Suspended balance"
	},
	withdrawOperation: {
		ar: "اجراء عمليه سحب",
		en: "Make a withdrawal operation"
	},
	balanceInput: {
		ar: "اكتب المبلغ",
		en: "Write down the amount"
	},
	withdrawMethod: {
		ar: "طريقة السحب",
		en: "withdrawal method"
	},
	withdraw: {
		ar:"سحب",
		en:"withdraw"
	},
	more: {
		ar:"جميع التحويلات",
		en:"All transactions"
	},
	pending : {
		ar:"معلقة",
		en:"pending"
	},
	completed : {
		ar:"مكتملة",
		en:"completed"
	},
	progress : {
		ar:"in progress",
		en:"قيد التنفي"
	}

};

export default desingerBalanceLocalization;
