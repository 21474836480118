import React, { lazy, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ForgetPasswordProvider } from '../../contexts/forget-password-context/ForgetPasswordProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import AboutPage from '../../pages/about-page/AboutPage';
import ActivateAccountPage from '../../pages/activate-user-page/ActivateUserPage';
// import CommingSoonPage from "../../pages/comming-soon-page/CommingSoonPage";
import DashboardPage from '../../pages/dashboard/DashboardPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
// import HomePage from "../../pages/home-page/HomePage";
import SigninPage from '../../pages/signin-page/SigninPage';
import SignupPage from '../../pages/signup-page/SignupPage';
import protectMe from '../../utils/protectMe';
import ForgetPassword from '../forget-password/ForgetPassword';
import routerLinks from './routerLinks';
// import ServiceDetails from "../service-details/ServiceDetails";
const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  const { loggedIn, user } = useContext(UesrContext);

  const renderSigninRoute = () => {
    if (!loggedIn) {
      return <SigninPage />;
    } else if (loggedIn && user && !user?.activated) {
      return <Redirect to={routerLinks?.activateAccountRoute} />;
    } else {
      return <Redirect to={routerLinks?.homePage} />;
    }
  };
  const renderSignupRoute = () => {
    if (!loggedIn) {
      return <SignupPage />;
    } else if (loggedIn && user && !user?.activated) {
      return <Redirect to={routerLinks?.activateAccountRoute} />;
    } else {
      return <Redirect to={routerLinks?.homePage} />;
    }
  };

  return (
    <Switch>
      {/* <Route exact path={routerLinks.homePage} component={CommingSoonPage} /> */}
      <Route exact path={routerLinks.homePage} component={HomePage} />
      <Route exact path={routerLinks.aboutPage} component={AboutPage} />
      {/* <Route
				exact
				path="/dashboard/user/:userId/services/service-details"
				component={ServiceDetails}
			/> */}

      <Route path={routerLinks.signupPage}>{renderSignupRoute()}</Route>
      <Route path={routerLinks.signinPage}>{renderSigninRoute()}</Route>
      <Route path={routerLinks.activateAccountRoute}>
        {loggedIn && user && !user?.activated ? (
          <ActivateAccountPage />
        ) : (
          <Redirect to={routerLinks?.signinPage} />
        )}
      </Route>

      <Route path={routerLinks.forgetPassword()}>
        {!loggedIn ? (
          <ForgetPasswordProvider>
            <ForgetPassword />
          </ForgetPasswordProvider>
        ) : (
          <Redirect to={routerLinks?.homePage} />
        )}
      </Route>

      <Route key="dashboard-key" path={routerLinks?.dashboardPage}>
        <DashboardPage />
      </Route>

      {/* {protectMe(
        routerLinks.dashboardPage,
        <DashboardPage />,
        651212346,
        routerLinks.signinPage,
        loggedIn,
        user,
        false
      )} */}

      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
