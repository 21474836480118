/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import designerApplicationLocalization from '../../../localization/designerApplication.localization';
import LangContext from '../../../contexts/lang-context/LangProvider';
import { Field, useFormikContext } from 'formik';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import { RadioGroup, TextField } from 'formik-material-ui';
import './ApplicationStepTwo.scss';

const ApplicationStepTwo = () => {
  const {
    language: { code }
  } = useContext(LangContext);
  const { stepTwo0 } = designerApplicationLocalization;

  const { isSubmitting, values, setFieldValue, errors, touched } =
    useFormikContext();

  const sharedFieldLabel = (key) => stepTwo0[key]?.label[code];
  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.label[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) == String(item.value))
                setFieldValue(fieldName, '');
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  return (
    <div className="formik-step application-step-two">
      <div className="step-title">{stepTwo0.mainTitle[code]}</div>
      <div className="fields-wrapper">
        <Field
          component={TextField}
          label={sharedFieldLabel('applyReason')}
          name="job_apply_reason"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />
        <Field
          component={TextField}
          label={sharedFieldLabel('skills')}
          name="your_skill"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />
        <Field
          component={TextField}
          label={sharedFieldLabel('weaknessPoints')}
          name="weak_points"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />
        <Field
          component={TextField}
          label={sharedFieldLabel('strengthPoints')}
          name="strengths_points"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />

        <div className="radio-group-wrapper">
          <h3>{sharedFieldLabel('teamWork')}</h3>

          <Field
            className="field-group-wrap"
            component={RadioGroup}
            name="team_work"
          >
            {renderRadiosGroup(
              [
                {
                  id: '0',
                  label: {
                    ar: 'نعم',
                    en: 'Yes'
                  },
                  value: '0'
                },
                {
                  id: '1',
                  label: {
                    ar: 'لا',
                    en: 'No'
                  },
                  value: '1'
                }
              ],
              'team_work'
            )}
          </Field>
        </div>

        <div className="radio-group-wrapper">
          <h3>{sharedFieldLabel('prevExp')}</h3>

          <Field
            className="field-group-wrap"
            component={RadioGroup}
            name="has_experience"
          >
            {renderRadiosGroup(
              [
                {
                  id: '0',
                  label: {
                    ar: 'نعم',
                    en: 'Yes'
                  },
                  value: '0'
                },
                {
                  id: '1',
                  label: {
                    ar: 'لا',
                    en: 'No'
                  },
                  value: '1'
                }
              ],
              'has_experience'
            )}
          </Field>
        </div>

        <Field
          component={TextField}
          label={sharedFieldLabel('breifAboutYou')}
          name="about"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />
        <Field
          component={TextField}
          label={sharedFieldLabel('briefAboutUs')}
          name="company_feedback"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />
        <Field
          component={TextField}
          label={sharedFieldLabel('contactOption')}
          name="best_contact"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />
        <Field
          component={TextField}
          label={sharedFieldLabel('workTimes')}
          name="best_work_time"
          variant="outlined"
          multiline
          rows="2"
          className="text-area-field"
        />
      </div>
    </div>
  );
};

export default ApplicationStepTwo;
