import fb6281200 from "../../../assets/imgs/scales/FaceBook/628-1200.png";
import fb6751200 from "../../../assets/imgs/scales/FaceBook/675-1200.png";
import fb10801080 from "../../../assets/imgs/scales/FaceBook/1080-1080.png";
//
import insta5221080 from "../../../assets/imgs/scales/Insta/522-1080.png";
import insta10801080 from "../../../assets/imgs/scales/Insta/1080-1080.png";
import insta13501080 from "../../../assets/imgs/scales/Insta/1350-1080.png";
import insta23401080 from "../../../assets/imgs/scales/Insta/2340-1080.png";
//
import linkedin3961548 from "../../../assets/imgs/scales/LinkedIn/396-1584.png";
import linkedin6281200 from "../../../assets/imgs/scales/LinkedIn/628-1200.png";
//
import snap19201080 from "../../../assets/imgs/scales/SnapChat/1920-1080.png";
//
import tiktok19201080 from "../../../assets/imgs/scales/TikTok/1920-1080.png";
//
import twitter5001500 from "../../../assets/imgs/scales/Twitter/500-1500.png";
import twitter5121024 from "../../../assets/imgs/scales/Twitter/512-1024.png";
import twitter10801080 from "../../../assets/imgs/scales/Twitter/1080-1080.png";
//
import youtube7201200 from "../../../assets/imgs/scales/YouTube/720-1200.png";
import youtube10801920 from "../../../assets/imgs/scales/YouTube/1080-1920.png";
import youtube19201080 from "../../../assets/imgs/scales/YouTube/1920-1080.png";

//
import linkedIn3961584 from "../../../assets/imgs/scales/LinkedIn/396-1584.png";
import linkedIn6281200 from "../../../assets/imgs/scales/LinkedIn/628-1200.png";

const scalesImgs = {
  fb6281200,
  fb6751200,
  fb10801080,
  //
  insta5221080,
  insta10801080,
  insta13501080,
  insta23401080,
  //
  linkedin3961548,
  linkedin6281200,
  //
  snap19201080,
  //
  tiktok19201080,
  twitter5001500,
  twitter5121024,
  twitter10801080,
  //
  youtube7201200,
  youtube10801920,
  youtube19201080,
  //
  linkedIn3961584,
  linkedIn6281200,
};

export default scalesImgs;
