import "./MyOrdersCard.scss";

import React, { useContext, useState ,useCallback} from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import MyOrderLocalization from "../../../localization/myOrderCard.localization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import ChatIcon from "../../../common/icons/ChatIcon";
import { LocalOfferRounded } from "@material-ui/icons";
import routerLinks from "../../../components/app/routerLinks";
import { Button } from "@material-ui/core";
import {
  cancelOrderApi,
  completePaymentApi,
} from "../../../apis/user/newOrderApi";
import LoadingModal from "../../../common/loading-modal/LoadingModal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import UesrContext from "../../../contexts/user-context/UserProvider";
import getMessagesApi from "../../../apis/conversations/messages";

const MyOrdersCard = ({ withBtn, card ,hideCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const { user } = useContext(UesrContext);

  const {
    language: { code, dir },
  } = useContext(LangContext);
  const {
    percBadge,
    videoType,
    serviceNumber,
    designerName,
    chatLinkSuper,
    videoPrice,
    videoDuration,
    totalTime,
    detailsLink,
    completePaymentLabel,
  } = MyOrderLocalization;

  const completePayment = async (id) => {
    setIsLoading(true);
    await completePaymentApi(user,id);
    setIsLoading(false);
  };

  const handleCancelOrder = (id) => {
    setOpenCancelModal(true);
    setOrderId(card.id);
  };

  const history = useHistory()

	const getMessage = useCallback(async (orderId ) => {
		const data = await getMessagesApi(user, orderId);
    history.push(`/dashboard/user/messages/${orderId}`)
	}, [user,history])


  return (
    <div className='my-orders-card'>
      {/*  */}
      {/*  */}
      {isLoading && <LoadingModal />}
      <div className={`mini-card ${dir}`}>
        <div className='mini-title'>{card?.service?.en_title}</div>
        <div className='mini-badge'>
          <div className='badge-label'>{percBadge.label[code]}</div>
          <div className='badge-bar-wrap'>
            <div className='badge-bar'>
              <div className='empty-bar'>
                <div
                  className='filled-bar'
                  style={{
                    width: `${
                      card?.completed_ratio ? card.completed_ratio : 0
                    }%`,
                  }}
                >
                  <span className='perc-span'>
                    {card?.completed_ratio ? card.completed_ratio : 0}%
                  </span>
                  <span className='ball-span'></span>
                </div>
              </div>
            </div>
          </div>
          <div className='start-end-wrap'>
            {card?.start_date && (
              <div className='start-warp'>
                <div className='start-label'>{percBadge.start[code]}</div>
                <div className='start-date'>{card.start_date}</div>
              </div>
            )}
            {card?.end_date && (
              <div className='end-wrap'>
                <div className='end-label'>{percBadge.end[code]}</div>
                <div className='end-date'>{card.end_date}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*  */}
      {/*  */}

      <div className='card-details'>
        {!hideCancel && <button
          className={`cancelOrder ${dir}`}
          onClick={() => handleCancelOrder(card.id)}
        >
          X
        </button>
        }
        <div className='type-number-wrap'>
          <div className='video-type'>
            <span className='label-span'>{videoType[code]} : </span>
            <span className='value-span'>
              {card?.service?.title || card?.service?.ar_title || ""}
            </span>
          </div>
          <div className='service-number'>
            <span className='label-span'>{serviceNumber[code]} : </span>
            <span className='value-span'>{card?.id}</span>
          </div>
        </div>

        {/* <div className='designer-name'>
          <span className='label-span'>{designerName[code]} : </span>
          <span className='value-span'>
            <span className='name'>----</span>
            <span className='job'></span>
          </span>
        </div> */}
        <div className='duration-total-wrap'>
          <div className='videoDuration'>
            <span className='label-span'>{videoDuration[code]} : </span>
            <span className='value-span'>{card?.duration} دقيقة</span>
          </div>
          <div className='total-time'>
            <span className='label-span'>{totalTime[code]} : </span>
            <span className='value-span'>{card?.total_days || "---"} </span>
            <span className='helper-span'> {totalTime.helperSpan[code]}</span>
          </div>
        </div>
        <div className='video-price'>
          <span className='label-span'>{videoPrice[code]} : </span>
          <span className='value-span'>{card?.total_price}$</span>
        </div>
      
        { card.status === "accepted" && <Button
          // to="/dashboard/user/my-orders/order-details/chat-with-designer"
          className="chat-link"
          onClick={() => {getMessage(card.id)}}

        >
          <div className="value-span">
            <ChatIcon />
          </div>
          <span>{chatLinkSuper[code]}</span>
        </Button>}

        {/* <div className='tags-wrapper'>
          <div className='tag-wrap'>
            <LocalOfferRounded />
            <span>ترجمة</span>
          </div>
          <div className='tag-wrap'>
            <LocalOfferRounded />
            <span>كتابة الاسكربت</span>
          </div>
          <div className='tag-wrap'>
            <LocalOfferRounded />
            <span>لهجة عربية فصحى</span>
          </div>
          <div className='tag-wrap'>
            <LocalOfferRounded />
            <span>لهجة اماراتية</span>
          </div>
        </div> */}
      </div>

      {/*  */}
      {/*  */}
      
      <div className='card-actions'>
        {withBtn && (
          <RouterLink
            to={routerLinks?.orderDetails(card?.id)}
            className='details-link'
          >
            {detailsLink[code]}
          </RouterLink>
        )}
        {card.payment_status === "0" && (
          <Button
            variant='contained'
            className='details-link'
            color='primary'
            onClick={() => completePayment(card.id)}
          >
            {completePaymentLabel[code]}
          </Button>
        )}
      </div>
      <CancelOrderModal
        open={openCancelModal}
        handleClose={() => setOpenCancelModal(false)}
        id={orderId}
      />
    </div>
  );
};

const CancelOrderModal = ({ open, handleClose, id }) => {
  const history = useHistory();
  const { user } = useContext(UesrContext);
  const {
    language: { code, dir },
  } = useContext(LangContext);

  const handleConfirm = async () => {
    try {
      const res = await cancelOrderApi(id, user);
      history.go(0);
    } catch (error) {}
  };
  const { cancelOrder } = MyOrderLocalization;

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          {cancelOrder.confirmation[code]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{cancelOrder.msg[code]}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            {cancelOrder.cancel[code]}
          </Button>
          <Button onClick={handleConfirm} color='primary' autoFocus>
            {cancelOrder.confirm[code]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyOrdersCard;
