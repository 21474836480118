import React from "react";

const SnapChatSocial = ({ width = 31.5, height = 31.5 }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 31.5 31.5"
		>
			<path
				id="Icon_awesome-snapchat-square"
				data-name="Icon awesome-snapchat-square"
				d="M28.125,2.25H3.375A3.376,3.376,0,0,0,0,5.625v24.75A3.376,3.376,0,0,0,3.375,33.75h24.75A3.376,3.376,0,0,0,31.5,30.375V5.625A3.376,3.376,0,0,0,28.125,2.25Zm-.457,22.141c-.246.57-1.273.984-3.15,1.28a8.64,8.64,0,0,0-.3,1.118.542.542,0,0,1-.57.415h-.014c-.436,0-.9-.2-1.814-.2a3.7,3.7,0,0,0-2.63.963,5.246,5.246,0,0,1-3.459,1.28,5.364,5.364,0,0,1-3.41-1.28A3.707,3.707,0,0,0,9.689,27a17.924,17.924,0,0,0-1.814.218.558.558,0,0,1-.584-.422,8.827,8.827,0,0,0-.3-1.125c-.97-.148-3.15-.527-3.2-1.5a.5.5,0,0,1,.415-.52c3.255-.534,4.718-3.874,4.781-4.015,0-.007.007-.014.014-.021a1.052,1.052,0,0,0,.113-.879c-.239-.555-1.259-.752-1.687-.928-1.111-.436-1.266-.942-1.2-1.287a1.2,1.2,0,0,1,1.54-.724,2.649,2.649,0,0,0,1.1.3.986.986,0,0,0,.464-.1c-.1-1.68-.33-4.078.267-5.421A6.385,6.385,0,0,1,15.518,6.75l.471-.007a6.328,6.328,0,0,1,5.927,3.818c.6,1.343.366,3.734.267,5.421a.884.884,0,0,0,.4.1,2.774,2.774,0,0,0,1.034-.3,1.285,1.285,0,0,1,.956,0,.978.978,0,0,1,.731.837c.007.457-.4.851-1.209,1.167-.1.042-.218.077-.345.12-.457.148-1.153.366-1.336.809a1.078,1.078,0,0,0,.112.879c.007.007.007.014.014.021.063.141,1.526,3.48,4.781,4.015a.54.54,0,0,1,.345.759Z"
				transform="translate(0 -2.25)"
			/>
		</svg>
	);
};

export default SnapChatSocial;
