/* eslint-disable react-hooks/exhaustive-deps */
import "./ForgetPassword.scss";

import React, { useContext, useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router";
import routerLinks from "../app/routerLinks";
import ForgetPasswordCode from "./ForgetPasswordCode";
import ForgetPasswordNewPassword from "./ForgetPasswordNewPassword";
import NotFoundPage from "../../pages/not-found-page/NotFoundPage";
import slugify from "slugify";
import ForgetPasswordEmail from "./ForgetPasswordEmail";
import ForgetPasswordContext from "../../contexts/forget-password-context/ForgetPasswordProvider";

const ForgetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { tempEmail, correctCode } = useContext(ForgetPasswordContext);

  console.log(location.pathname);
  console.log("rr", routerLinks?.forgetPassword(params.accountType));
  useEffect(() => {
    if (
      slugify(location.pathname) ===
      slugify(routerLinks.forgetPassword(params?.accountType))
    )
      history.push(routerLinks.forgetPasswordEmail(params?.accountType));
  }, [location.pathname]);

  return (
    <>
      <div className="forget-password-page">
        <div className="mfa-container">
          <div className="forget-password-wrap">
            <Switch>
              <Route exact path={routerLinks.forgetPasswordEmail()}>
                <ForgetPasswordEmail />
              </Route>
              {tempEmail && !correctCode && (
                <Route exact path={routerLinks.forgetPasswordCode()}>
                  <ForgetPasswordCode />
                </Route>
              )}
              {correctCode && (
                <Route exact path={routerLinks.forgetPasswordNewPass()}>
                  <ForgetPasswordNewPassword />
                </Route>
              )}
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
