import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const NotificationBar = ({
	flashMsgIsOpen,
	flashMsg,
	flashMsgDuration,
	notificationSeverity,
	handleFlashMsgClose
}) => {
	return (
		<Snackbar
			// style={{ direction: 'ltr' }}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			open={flashMsgIsOpen}
			autoHideDuration={flashMsgDuration}
			onClose={handleFlashMsgClose}
		>
			<MuiAlert
				onClose={handleFlashMsgClose}
				severity={notificationSeverity}
				variant="filled"
			>
				{flashMsg}
			</MuiAlert>
		</Snackbar>
	);
};

export default NotificationBar;
