const userCartLocalization = {
  cartPage: {
    mainTitle: {
      ar: "سلة الشراء",
      en: "Shopping cart",
    },
    subTitle: {
      ar: "تفاصيل الفاتورة الخاصة بك",
      en: "Your bell details",
    },
    serviceType: {
      ar: "نوع الخدمة",
      en: "Service type",
    },
    serviceNumber: {
      ar: "رقم الخدمة",
      en: "Service number",
    },
    videoDuration: {
      title: {
        ar: "مدة الفيديو",
        en: "Video duration",
      },
      min: {
        ar: "دقيقة",
        en: "minute",
      },
    },
    videoLang: {
      ar: "لغة الفيديو",
      en: "Video language",
    },
    langDisplay: {
      ar: "طريقة عرض اللغة",
      en: "Language display method",
    },
    voiceoverGender: {
      ar: "جنس المعلق الصوتي",
      en: "The gender of the voiceover",
    },
    scalePrice: {
      ar: "تكلفة القياس",
      en: "Scale cost",
    },
    graphPrice: {
      ar: "تكلفة الجرافيك",
      en: "Graphic cost",
    },
    filmMaterPrice: {
      ar: "تكلفة صناعة الفيلم",
      en: "Film material cost",
    },
    superVisorPrice: {
      en: "Administrative expenses",
      ar: "مصاريف ادارية",
    },
    taxPrice: {
      ar: "تكلفة الضريبة",
      en: "Tax price",
    },

    voiceOver: {
      ar: "التعليق الصوتى",
      en: "Vocieover",
    },
    arabicVoiceover: {
      ar: "تعليق صوتى عربى",
      en: "Arabic voiceover",
    },
    englishVoiceOver: {
      ar: "تعليق صوتى انجليزى",
      en: "English voiceover",
    },
    videoScale: {
      ar: "مقاس الفيديو",
      en: "Video scale",
    },
    companySpeciality: {
      ar: "تخصص الشركة",
      en: "Company speciality",
    },
    nickName: {
      ar: "هل مطلوب اسم دعائى للشركة ؟",
      en: "Need a company nickname ?",
    },
    videoPurpose: {
      ar: "الهدف من الفيديو",
      en: "Video Purpose",
    },
    targetGroup: {
      ar: "الفئة المستهدفة",
      en: "Target Group",
    },
    targetSector: {
      ar: "القطاع المستهدف",
      en: "Target Sector",
    },
    targetAudience: {
      ar: "الجمهور المستهدف",
      en: "Target Audience",
    },
    offerPackages: {
      ar: "باقات العرض",
      en: "Offer packages",
    },
    distinctionPoint: {
      ar: "نقطة التميز",
      en: "Distinction Point",
    },
    socialLinks: {
      ar: "التواصل الاجتماعى",
      en: "Social Links",
    },
    total: {
      ar: "الاجمالى",
      en: "Total",
    },
    chooseBtn: {
      ar: "متابعة الدفع",
      en: "Choose payment",
    },
 
  },
  choosePaymentPage: {
    mainTitle: {
      ar: "تحصيل الفاتورة",
      en: "Collect the bill",
    },
    subTitle: {
      ar: "اختار وسيلة الدفع",
      en: "Choose payment method",
    },
  },
  facebook_scale: {
    ar: "مقاس فيس بوك",
    en: "Facebook scale",
  },
  twitter_scale: {
    ar: "مقاس اكس",
    en: "X scale",
  },

  insta_scale: {
    ar: "مقاس انستغرام ",
    en: "Instagram scale",
  },

  snap_scale: {
    ar: "مقاس سناب شات",
    en: "Snapchat scale",
  },

  youtube_scale: {
    ar: "مقاس يوتيوب",
    en: "youtube scale",
  },
};

export default userCartLocalization;
