const userSideMenuLocalization = {
	services: {
		ar: "خدمات فيديو تيوب",
		en: "Video tube services"
	},
	myOrders: {
		ar: "طلباتى",
		en: "My requests"
	},
	notifications: {
		ar: "الاشعارات",
		en: "Notifications"
	},
	messages: {
		ar: "الرسائل",
		en: "Messages"
	},
	settings: {
		ar: "الاعدادات",
		en: "Settings"
	},
	noOrder:{
		ar:"لا يوجد طلبات متاحة",
		en:"No available orders"
	}

};

export default userSideMenuLocalization;
