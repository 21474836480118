const developerSignup = {
	fields: {
		firstname: {
			label: {
				ar: "الاسـم الاول",
				en: "Firstname"
			},
			errors: {
				required: {
					ar: "ادخل الاسم الاول",
					en: "Firstname is required"
				},
				minChar: min => ({
					ar: `الاسـم الاول لا يقل عن ${min} حرف`,
					en: `Firstname must be at least ${min} chars`
				}),
				maxChar: max => ({
					ar: `الاسـم الاول لا يزيد عن ${max} حرف`,
					en: `Firstname must be at most ${max} chars`
				}),
				noSpaces: {
					ar: "لا يسمح بوجود فراغات فى الاسم الاول",
					en: "No white spaces allowed in firstname"
				}
			}
		},
		lastname: {
			label: {
				ar: "اســم العائلة",
				en: "Lastname"
			},
			errors: {
				required: {
					ar: "ادخل اسـم العائلة",
					en: "Lastname is required"
				},
				minChar: min => ({
					ar: `اسـم العائلة لا يقل عن ${min} حرف`,
					en: `Lastname must be at least ${min} chars`
				}),
				maxChar: max => ({
					ar: `اسـم العائلة لا يزيد عن ${max} حرف`,
					en: `Lastname must be at most ${max} chars`
				}),
				noSpaces: {
					ar: "لا يسمح بوجود فراغات فى اسـم العائلة",
					en: "No white spaces allowed in lastname"
				}
			}
		},
		nickName: {
			label: {
				ar: "الكنيه",
				en: "nickcame"
			},
			errors: {
				required: {
					ar: "ادخل الكنيه",
					en: "nickcame is required"
				},
				minChar: min => ({
					ar: ` الكنيه لا يقل عن ${min} حرف`,
					en: `nickcame must be at least ${min} chars`
				}),
				maxChar: max => ({
					ar: ` الكنيه لا يزيد عن ${max} حرف`,
					en: `nickcame must be at most ${max} chars`
				}),
				noSpaces: {
					ar: "لا يسمح بوجود فراغات فى الكنيه",
					en: "No white spaces allowed in nickcame"
				}
			}
		},
		email: {
			label: {
				ar: "البريد الالكترونى",
				en: "Email address"
			},
			errors: {
				required: {
					ar: "برجاء ادخال البريد الالكترونى",
					en: "Enter your email address"
				},
				typeError: {
					ar: "برجاء ادخال بريد الكترونى صحيح",
					en: "Provide a vaild email address"
				}
			}
		},
		phoneNumber: {
			label: {
				ar: "رقـم الهاتف",
				en: "Phone number"
			},
			errors: {
				required: {
					ar: "برجاء ادخال رقم الهاتف",
					en: "Enter your phone number"
				},
				validNumber: {
					ar: "لا يسمح الا بادخال ارقام ",
					en: "Only numbers accepted"
				},
				minChar: min => ({
					ar: `اقـل حد ${min} أرقـام`,
					en: `At least ${min} digits required`
				}),
				maxChar: max => ({
					ar: `اقصى حد ${max} أرقـام`,
					en: `At Most ${max} digits required`
				})
			}
		},
		password: {
			label: {
				ar: "كلمة المرور",
				en: "Password"
			},
			errors: {
				required: {
					ar: "برجاء ادخال كلمة المرور",
					en: "Please enter your password"
				},
				minChar: min => ({
					ar: `كلمة المرور بحد ادنى ${min} حرف`,
					en: `Password must be at least ${min} chars`
				}),
				maxChar: max => ({
					ar: `كلمة المرور بحد اقصى ${max} حرف`,
					en: `Password must be at Most ${max} chars`
				}),
				trim: {
					ar: "لا يسمح بالفراغات فى بداية او نهاية كلمة المرور",
					en: "No leading or trailing spaces"
				}
			}
		},
		confirmPassword: {
			label: {
				ar: "تأكيد كلمة المرور",
				en: "Confirm Password"
			},
			errors: {
				required: {
					ar: "برجاء ادخال تأكيد كلمة المرور",
					en: "Enter your password confirmation"
				},
				validate: {
					ar: "كلمة المرور غير مطابقة",
					en: "The password did't match"
				}
			}
		},
		agreeTerms: {
			label: {
				ar: "أوافق علي الشروط والاحكام وسياسة الخصوصية الخاصة بفيديو تيوب",
				en: "Accept terms, conditions and privcy policy of Videotube."
			},
			errors: {
				ar: "لا بد من الموافقة على الشروط والاحكام",
				en: "The terms and conditions must be accepted."
			}
		}
	},
	signupBtn: {
		ar: "تسجيل",
		en: "Signup"
	},
	moreSignupOptions: {
		ar: "أو قم بالتسجيل من خلال",
		en: "Or you can signup via"
	},
	alreadySignedup: {
		p: {
			ar: "لديك حساب مسجل بالفعل",
			en: "You have account"
		},
		link: {
			ar: "قم بتسجيل الدخول",
			en: "Signin now"
		}
	}
};

export default developerSignup;
