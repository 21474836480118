import React from "react";

const ArrowLeftIcon = ({ color = "#000" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21.86"
			height="21.306"
			viewBox="0 0 21.86 21.306"
		>
			<path
				id="Icon_awesome-arrow-right"
				data-name="Icon awesome-arrow-right"
				d="M12.565,4.074,11.482,2.991a1.166,1.166,0,0,0-1.654,0L.344,12.471a1.166,1.166,0,0,0,0,1.654l9.484,9.484a1.166,1.166,0,0,0,1.654,0l1.083-1.083a1.172,1.172,0,0,0-.02-1.673l-5.879-5.6H20.689a1.168,1.168,0,0,0,1.171-1.171V12.52a1.168,1.168,0,0,0-1.171-1.171H6.667l5.879-5.6A1.164,1.164,0,0,0,12.565,4.074Z"
				transform="translate(0 -2.647)"
				fill={color}
			/>
		</svg>
	);
};

export default ArrowLeftIcon;
