export const service1234InitialValues = {
  // step one
  scriptIsReady: "", // ready or not_ready
  script: "",
  numberOfWords: 0,
  calculatedVideoDuration: 0,
  reviewedVideoDuration: 0,
  acceptScript: "", // accept or review
  briefScript: "",
  ///////////////////////////////////////
  // step two
  videoLang: "",
  displayMethod: "",
  voiceOver: "",
  /////////////////////////////////////
  // step three
  arabicVoiceOver: "",
  arabicVoiceOverModel: "",
  englishVoiceOver: "",
  englishVoiceOverModel: "",
  count_voice_comment: 1,
  /////////////////////////////////////
  // step four
  twitterScale: "",
  instagramScale: "",
  facebookScale: "",
  snapChatScale: "",
  youtubeScale: "",
  tiktokScale: "",
  /////////////////////////////////////
  // step five
  companySpeciality: "",
  otherCompSpeciality: "",
  brandName: "", // 1 <== yes, 2 <== no
  /////////////////////////////////////
  // step six
  videoPurpose: "",
  otherVideoPurpose: "",
  targetCategory: "",
  /////////////////////////////////////
  // step seven
  targetSector: "",
  otherTargetSector: "",
  /////////////////////////////////////
  // step eight
  targetAudience: [],
  targetAudience_other_checkbox: false,
  otherTargetAudience: "",
  /////////////////////////////////////
  // step nine
  offerPackages: "",
  highlightPoint: "",
  /////////////////////////////////////
  // step ten
  isSocial: "",
  socialMediaAddresses: {
    socialCheckBoxs: [], // checkboxs initial values ['twitter', 'facebook', 'instagram', 'linkedin', 'snapchat']
    inputs: {
      twitter: "",
      facebook: "",
      instagram: "",
      snapchat: "",
      youtube: "",
    },
  },
  font: "", // yes or no
  uploadedFont: null,
  music: "", // yes or no
  uploadedMusic: null,
  color: "", // yes or no
  uploadedColor: null,
  ////////////////////////////////////
};
////////////////////////////////////////
export const service5InitialValues = {
  // step one
  light_graphics: "", // 0, 1 => no, yes
  film_material_type: "", // upload_file, from_app (حسب السيناربو), out_film (from videotube)
  // incase of => upload file
  film_material_file: null,
  film_material_link: "",
  // incase of out_film
  film_material_address: "",
  film_material_lat: "",
  film_material_lng: "",
  film_material_from_time: null,
  film_material_to_time: null,
  film_material_date: null,
  film_material_contact_num: "",
  film_material_photo_area: "", // inside, outside, both
  film_material_photo_type: "", // full_coverage , press_coverage , filming , ads , meetings ,hd_air , 4k_air
  film_material_count_day: "", // incase of full_coverage, press_coverage,
  film_material_photo_count_people: "", // incase of meetings => 1, 2, 3
  // step one old
  videoDuration: 1,
  briefScript: "",
  ///////////////////////////////////////
  // step two
  videoLang: "",
  displayMethod: "",
  /////////////////////////////////////
  // step three
  twitterScale: [],
  instagramScale: [],
  facebookScale: [],
  snapChatScale: [],
  youtubeScale: [],
  tiktokScale: [],
  linkedin_scale: [],

  /////////////////////////////////////
  // step four
  companySpeciality: "",
  otherCompSpeciality: "",
  /////////////////////////////////////
  // step five
  videoPurpose: "",
  otherVideoPurpose: "",
  targetCategory: "",
  /////////////////////////////////////
  // step six
  targetSector: "",
  otherTargetSector: "",
  /////////////////////////////////////
  // step seven
  targetAudience: [],
  otherTargetAudience: "",
  /////////////////////////////////////
  // step eight
  offerPackages: "",
  highlightPoint: "",
  /////////////////////////////////////
  // step nine
  isSocial: "",
  socialMediaAddresses: {
    socialCheckBoxs: [], // checkboxs initial values ['twitter', 'facebook', 'instagram', 'linkedin', 'snapchat']
    inputs: {
      twitter: "",
      facebook: "",
      instagram: "",
      snapchat: "",
      youtube: "",
    },
  },
  font: "", // yes or no
  uploadedFont: null,
  color: "", // yes or no
  uploadedColor: null,
  ////////////////////////////////////
};
///////////////////////////////////////
export const service6InitialValues = {
  // step one
  light_graphics: "", // 0, 1 => no, yes
  film_material_type: "", // upload_file, from_app (حسب السيناربو), out_film (from videotube)
  // incase of => upload file
  film_material_file: null,
  film_material_link: "",
  // step one old
  videoDuration: 1,
  briefScript: "",
  ///////////////////////////////////////
  // step two
  videoLang: "",
  displayMethod: "",
  /////////////////////////////////////
  // step three
  twitterScale: "",
  instagramScale: "",
  facebookScale: "",
  snapChatScale: "",
  youtubeScale: "",
  /////////////////////////////////////
  // step four
  companySpeciality: "",
  otherCompSpeciality: "",
  /////////////////////////////////////
  // step five
  videoPurpose: "",
  otherVideoPurpose: "",
  targetCategory: "",
  /////////////////////////////////////
  // step six
  targetSector: "",
  otherTargetSector: "",
  /////////////////////////////////////
  // step seven
  targetAudience: [],
  otherTargetAudience: "",
  /////////////////////////////////////
  // step eight
  offerPackages: "",
  highlightPoint: "",
  /////////////////////////////////////
  // step nine
  isSocial: "",
  socialMediaAddresses: {
    socialCheckBoxs: [], // checkboxs initial values ['twitter', 'facebook', 'instagram', 'linkedin', 'snapchat']
    inputs: {
      twitter: "",
      facebook: "",
      instagram: "",
      snapchat: "",
      youtube: "",
    },
  },
  font: "", // yes or no
  uploadedFont: null,
  color: "", // yes or no
  uploadedColor: null,
  ////////////////////////////////////
};
///////////////////////////////////////
export const service7InitialValues = {
  // step one
  // incase of out_film
  film_material_address: "",
  film_material_lat: "",
  film_material_lng: "",
  film_material_from_time: null,
  film_material_to_time: null,
  film_material_date: null,
  film_material_contact_num: "",
  film_material_photo_area: "", // inside, outside, both
  film_material_photo_type: "", // full_coverage , press_coverage , filming , ads , meetings ,hd_air , 4k_air
  film_material_count_day: "", // incase of full_coverage, press_coverage,
  film_material_photo_count_people: "", // incase of meetings => 1, 2, 3
  highlightPoint: "",
  // step one old
  briefScript: "",
  distinction_point:""
};
///////////////////////////////////////
export const service8InitialValues = {
  // step one
  // incase of out_film
  film_material_address: "",
  film_material_lat: "",
  film_material_lng: "",
  film_material_from_time: null,
  film_material_to_time: null,
  film_material_date: null,
  film_material_contact_num: "",
  film_material_photo_area: "", // inside, outside, both
  film_material_photo_type: "", // full_coverage , press_coverage , filming , ads , meetings ,hd_air , 4k_air
  film_material_count_day: "", // incase of full_coverage, press_coverage,
  // step one old
  briefScript: "",
  film_material_city_id:"",
  country:""
};
