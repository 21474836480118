import "./MyDropZone.scss";
import React from "react";
import Dropzone from "react-dropzone";

const MyDropZone = ({
	multipleFiles,
	handleFilesDrop,
	filesToUpload,
	formName,
	id,
	dropzoneText,
	inputName
}) => {
	const acceptFiles =
		"image/jpg, image/png, image/jpeg, image/gif, video/mp4, video/mkv, video/avi";
	return (
		<Dropzone
			onDrop={acceptedFiles => handleFilesDrop(acceptedFiles)}
			// accept="image/*, video/*"
			accept={acceptFiles}
			multiple={multipleFiles}
		>
			{({ getRootProps, getInputProps, isDragActive }) => {
				return (
					// filesToUpload.length === 0 && (
					// )
					<div
						className={`dropZoneWrapper ${
							filesToUpload.length > 0 ? "hideMe" : ""
						}`}
					>
						<div
							className={`${filesToUpload.length ? "filled" : ""}`}
							{...getRootProps()}
						>
							<input
								name={inputName}
								id={id}
								{...getInputProps()}
								form={formName}
							/>
							<div className={`dropZone ${isDragActive ? "dragZone" : ""}`}>
								<div className="placeholderText">
									<i className="icon-upload-cloud-4"></i>
									<p>{dropzoneText}</p>
								</div>
							</div>
						</div>
					</div>
				);
			}}
		</Dropzone>
	);
};

export default MyDropZone;
