import "./DesignerMessages.scss";
import { Avatar } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import desinerImg from "../../../assets/imgs/designer-avatar.png";
import DocTitleScrollTop from "../../../utils/DocTitleScrollTop";
import MessageBox from "../../../common/message-box/MessageBox";
import UesrContext from "../../../contexts/user-context/UserProvider";
import getConversationsApi from "../../../apis/conversations/conversations";
import NewMessage from "./NewMessage";
import getMessagesApi, {
  getSuperVisorMessagesApi,
} from "../../../apis/conversations/messages";
import registerTokenNotificationsApi from "../../../apis/conversations/registerToken";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import indexLocalization from "../../../localization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import ContentLoader from "react-content-loader";

const UserMessages = ({notify,setNotify}) => {
  DocTitleScrollTop("Messages");

  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState("");
  const [messages, setMessages] = useState([]);
  const [type, setType] = useState("");
  const [typeId, setTypeId] = useState("");
  const [orderId, setOrderId] = useState("");
  const { user } = useContext(UesrContext);
  const [openLoader, setopenLoader] = useState(true)
  const params = useParams();
  useEffect(() => {
    if (params?.type) {
      setType(params?.type);
    }
    if (params?.typeId) {
      setTypeId(params?.typeId);
    }
    if (params?.orderId) {
      setOrderId(params?.orderId);
    }
  }, [params]);

  const firebaseConfig = useMemo(() =>{
    return {
      apiKey: "AIzaSyAEyHtoZqYQUC25bGfkhvYTBPVMgDZkivM",
      authDomain: "videotube-f6a14.firebaseapp.com",
      projectId: "videotube-f6a14",
      storageBucket: "videotube-f6a14.appspot.com",
      messagingSenderId: "1065299110898",
      appId: "1:1065299110898:web:32dd5c4202995ed673996b",
    }
  },[]) 

  const vapidKey =
    "BEdRyFebE2WqWtHWIoazamSjhdF8Oyld0mFkW7JV7t6t93Eh8m-XWYwbjEmj1je5uahJXQ4_V1Je6ZloSYbYVHg";


  const getMessage = useCallback(
    async (orderId, type, typeID) => {
      const data = await getSuperVisorMessagesApi(user, orderId, type, typeID);
      // setConversationId(conversationId)
      if(data.data.data) {
        setMessages(data.data.data.messages);
        setConversationId(data?.data?.data?.conversation_id);
       
      }
    },
    [user]
  );

  const { noMsg } = indexLocalization;
  const {
    language: { dir, code },
  } = useContext(LangContext);

  useEffect(() => {
    async function getConversations() {
      const data = await getConversationsApi(user);
      if (data?.data?.data.length) {
        setConversations(data.data.data);
      }
      if (orderId && type && typeId) {
        getMessage(orderId, type, typeId);
      } else if (orderId) {
        getMessage(orderId, "", "");
      }
      setopenLoader(false)
    }
    getConversations();


    // if( window.isSecureContext || window.location.protocol === 'https:') {
    //   const app = initializeApp(firebaseConfig);
    //   const messaging = getMessaging(app);    
    //   Notification.requestPermission()
    //   .then((permission) => {
    //     if (permission === "granted") {
    //       // get the FCM token
    //       let deviceID = "";
    //       getToken(messaging, { vapidKey: vapidKey })
    //         .then((token) => {
    //           if (user.accountType === "employee") {
    //             deviceID = `EM_${user.id}`;
    //           } else if (user.accountType === "supervisor") {
    //             deviceID = `SU_${user.id}`;
    //           }

    //           registerTokenNotificationsApi(user, token, deviceID);
    //           onMessage(messaging, (payload) => {
    //             console.log("new message",{payload});
    //             if(orderId) {
    //               getMessage(orderId, type, typeId);

    //             } else {
    //               getConversations()
    //             }
    //           });
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     } else {
    //       console.log("Permission denied");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // }
    if (notify) {
      if (orderId) {
        getMessage(orderId, type, typeId);
      } else {
        getConversations();
      }
      setNotify(false)
    }
  }, [user, getMessage, orderId, type, typeId,notify]);


const returnConversationUserName = useCallback((item) => {
if(item?.client) {
  return `${item?.client?.f_name} ${item?.client?.l_name} `
} else if(item?.employee) {
  return `${item?.employee?.f_name} ${item?.employee?.l_name} `

}  else {
  return `${item?.supervisor?.f_name} ${item?.supervisor?.l_name} `

}


},[])
  const renderUsersList = () => {
    return (

      <>


        {conversations.map((item) => {
          const type = item?.employee ? "employee" : "client";
          const typeId = item?.employee ? item?.employee?.id : item?.client?.id;
          const imageSrc =item?.employee ? item?.employee?.image : item?.client?.image;
          return (
            <div
              className="msg-list-item"
              key={item.id}
              onClick={() => {
                getMessage(item?.order?.id, type, typeId);
                setType(type);
                setTypeId(typeId);
                setOrderId(item?.order?.id);
                setConversationId(item.id);
              }}
            >
              <Avatar src={`${process.env.REACT_APP_BACK_END_URL}${imageSrc}`} />
              <div className="msg-data">
                <div className="msg-title">
{                returnConversationUserName(item)
}                </div>
                <div className="msg-desc">{item?.last_message?.message}</div>
                {item?.last_message?.created_at && (
                  <div className="msg-date">
                    <AccessTime />
                    {item?.last_message?.created_at}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
		{openLoader && <ContentLoader
        width="100%"
        height="100%"
        speed={1}
        rtl={dir === "rtl" ? true : false}
      >
        <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
      </ContentLoader>}

      {!openLoader  && (
        <div className="messages-page">
          <div className="messages-users-wrap">
          {conversations.length > 0  ? <div className="users-wrap">{renderUsersList()}</div> : <div/>}
          <div className="p-4 padding-b-0 messages-wrap-container">
              <div className="messages-warp ">
                {messages.length > 0 ? (
                  messages.map((item) => (
                    <MessageBox key={item.id} message={item} />
                  ))
                ) : (
                  <div />
                )}
              
              </div>
              {conversationId && (
                  <NewMessage conversationId={conversationId} getMessage={() =>{getMessage(orderId, type, typeId);}}/>
                )}
            </div>
          </div>
        </div>
      )}

      {conversations.length === 0 &&  !orderId && !conversationId && !openLoader && (
        <div class="empty-wrapper">
          <h2>{noMsg[code]}</h2>
        </div>
      )}
    </>
  );
};

export default UserMessages;
