import "./ApplePay.scss";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import VisaIcon from "../icons/payment/VisaIcon";
import ApplePayIcon from "../icons/payment/ApplePayIcon";
import PaypalIcon from "../icons/payment/PaypalIcon";

const ApplePay = () => {
	return (
		<div className="apple-pay-warpper">
			<div className="payment-routes-wrap">
				<RouterLink className="visa-link" to="/dashboard/user/visa">
					<VisaIcon />
				</RouterLink>
				<RouterLink className="paypal-link" to="/dashboard/user/paypal">
					<PaypalIcon />
				</RouterLink>
				<RouterLink
					className="apple-pay-link active-link"
					to="/dashboard/user/applepay"
				>
					<ApplePayIcon />
				</RouterLink>
			</div>
		</div>
	);
};

export default ApplePay;
