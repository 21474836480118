import axios from "axios";

// 'pending','accepted','completed'
const getEmployeeOrdersApi = async (filterValues, user, langCode) => {
  try {
    const res = await axios.get(
      `/api/v1/employee/orders?api_token=${user?.api_token}&status=${
        filterValues?.status || ""
      }`,
      {
        headers: {
          "X-Portal": user?.accountType,
          "Content-Type": "multipart/form-data",
          password: process.env.REACT_APP_AUTH_PASS,
          "X-Language": langCode,
        },
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};

export default getEmployeeOrdersApi;
