import "./Visa.scss";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import VisaIcon from "../icons/payment/VisaIcon";
import ApplePayIcon from "../icons/payment/ApplePayIcon";
import PaypalIcon from "../icons/payment/PaypalIcon";
import visaCardImg from "../../assets/imgs/visa-card.png";

const Visa = () => {
	return (
		<div className="visa-warpper">
			<div className="payment-routes-wrap">
				<RouterLink className="visa-link active-link" to="/dashboard/user/visa">
					<VisaIcon />
				</RouterLink>
				<RouterLink className="paypal-link" to="/dashboard/user/paypal">
					<PaypalIcon />
				</RouterLink>
				<RouterLink className="apple-pay-link" to="/dashboard/user/applepay">
					<ApplePayIcon />
				</RouterLink>
			</div>
			<div className="visa-form">
				<img src={visaCardImg} alt="visa" />
			</div>
		</div>
	);
};

export default Visa;
