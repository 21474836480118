const designerApplicationLocalization = {
  stepOne: {
    mainTitle: {
      ar: 'المعلومات الشخصية',
      en: 'Personal information'
    },
    firstname: {
      label: {
        ar: 'الاسـم ',
        en: 'Firstname'
      },
      errors: {
        required: {
          ar: 'ادخل الاسم ',
          en: 'Firstname is required'
        },
        minChar: (min) => ({
          ar: `الاسـم  لا يقل عن ${min} حرف`,
          en: `Firstname must be at least ${min} chars`
        }),
        maxChar: (max) => ({
          ar: `الاسـم  لا يزيد عن ${max} حرف`,
          en: `Firstname must be at most ${max} chars`
        })
      }
    },
    lastname: {
      label: {
        ar: 'اســم العائلة',
        en: 'Lastname'
      },
      errors: {
        required: {
          ar: 'ادخل اسـم العائلة',
          en: 'Surname is required'
        },
        minChar: (min) => ({
          ar: `اسـم العائلة لا يقل عن ${min} حرف`,
          en: `Surname must be at least ${min} chars`
        }),
        maxChar: (max) => ({
          ar: `اسـم العائلة لا يزيد عن ${max} حرف`,
          en: `Surname must be at most ${max} chars`
        })
      }
    },
    nickName: {
			label: {
				ar: "الكنيه",
				en: "Surname"
			},
			errors: {
				required: {
					ar: "ادخل الكنيه",
					en: "nickcame is required"
				},
				minChar: min => ({
					ar: ` الكنيه لا يقل عن ${min} حرف`,
					en: `nickcame must be at least ${min} chars`
				}),
				maxChar: max => ({
					ar: ` الكنيه لا يزيد عن ${max} حرف`,
					en: `nickcame must be at most ${max} chars`
				}),
				noSpaces: {
					ar: "لا يسمح بوجود فراغات فى الكنيه",
					en: "No white spaces allowed in nickcame"
				}
			}
		},
    country: {
      label: {
        ar: 'الدولة',
        en: 'Country'
      },
      errors: {
        required: {
          ar: 'برجاء اختيار الدولة',
          en: 'Please choose the country'
        }
      }
    },
    city: {
      label: {
        ar: 'المدينة',
        en: 'City'
      },
      errors: {
        required: {
          ar: 'برجاء اختيار المدينة',
          en: 'Please choose the country'
        }
      }
    },

    cv: {
      label: {
        ar: 'يرجي تحميل ال cv الخاص بك',
        en: 'Please upload your cv'
      },
      errors: {
        required: {
          ar: 'مطلوب رفع الملف',
          en: 'Upload your cv'
        },
        unSuppFormat: {
          ar: 'يسمح برفع صيغة PDF فقط',
          en: 'Only PDF format allowed'
        },
        tooLarge: {
          ar: 'حجم الملف كبير',
          en: 'The file is too large                                                                                    '
        }
      }
    },
    identity: {
      label: {
        ar: 'يرجي تحميل نسخة من الهوية او جواز السفر',
        en: 'Please upload your ID or passport'
      },
      errors: {
        required: {
          ar: 'مطلوب رفع الملف',
          en: 'Upload your Identity or your passport'
        },
        unSuppFormat: {
          ar: 'يسمح برفع صورة فقط',
          en: 'Only image is allowed to upload.'
        },
        tooLarge: {
          ar: 'حجم الملف كبير',
          en: 'The file is too large                                                                                    '
        }
      }
    },
    uploadBtn: {
      label: {
        ar: 'تحميل',
        en: 'Upload'
      }
    }
  },
  //
  //
  //
  stepTwo0: {
    mainTitle: {
      ar: 'أسئلة عامة',
      en: 'General Info'
    },
    applyReason: {
      label: {
        ar: 'لماذا تقدمت لهذه الوظيفة؟',
        en: 'Why did you applyed for this job?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    skills: {
      label: {
        ar: 'ما هى مهاراتك',
        en: 'What are your skills?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    weaknessPoints: {
      label: {
        ar: 'ما هى نقاط ضعفك',
        en: 'Whar are your weakness points?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    strengthPoints: {
      label: {
        ar: 'ما هى نقاط قوتك',
        en: 'What are your strength poitns?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    teamWork: {
      label: {
        ar: 'هل عملت مع فريق من قبل',
        en: 'Did you worked in a team before?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    prevExp: {
      label: {
        ar: 'هل لديك خبرة سابقة',
        en: 'Did you have a previous work experience?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    breifAboutYou: {
      label: {
        ar: 'نبذة عن نفسك',
        en: 'Brief about your self'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    briefAboutUs: {
      label: {
        ar: 'هل لديك فكرة عن شركتنا/مشروعنا؟',
        en: 'Did you have info about our company?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    contactOption: {
      label: {
        ar: 'ما هى افضل طرق للتواصل؟',
        en: 'What is the best way for contacting?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    },
    workTimes: {
      label: {
        ar: 'ما هى أفضل الاوقات بالنسبة لك للعمل؟',
        en: 'What is your best work times?'
      },
      errors: {
        required: {
          ar: 'من فضلك ادخل الاجابة',
          en: 'Please enter you answer'
        }
      }
    }
  },
  stepTwo: {
    mainTitle: {
      ar: 'بيانات تخص المهنة',
      en: 'Data related to the profession'
    },
    profession: {
      label: {
        ar: 'حدد المهنة',
        en: 'Select a profession'
      },
      errors: {
        required: {
          ar: 'اختار المهنة',
          en: 'Choose a profession'
        }
      }
    },
    gender: {
      label: {
        ar: 'الجنس',
        en: 'Gender'
      },
      fields: {
        male: {
          ar: 'ذكر',
          en: 'Male'
        },
        female: {
          ar: 'أنثى',
          en: 'Female'
        }
      },
      errors: {
        required: {
          ar: 'من فضلك اختار الجنس',
          en: 'Please select the gender'
        }
      }
    },
    arabicVoiceOver: {
      label: {
        ar: 'التعليق الصوتى العربى',
        en: 'Arabic Voiceover'
      }
    },
    englishVoiceOver: {
      label: {
        ar: 'التعليق الصوتى الانجليزى',
        en: 'English Voiceover'
      }
    }
  },
  //
  //
  //
  stepThree: {
    mainTitle: {
      ar: 'بيانات تخص المهنة',
      en: 'Data related to the profession'
    },
    practicalExp: {
      label: {
        ar: 'الخبرة العملية',
        en: 'Practical experience'
      },
      errors: {
        required: {
          ar: 'اختار خبرتك العملية',
          en: 'Choose your practical experience'
        }
      }
    },
    uploadWork: {
      label: {
        ar: 'يرجي تحميل 3 اعمال او اكثر عن كل خدمة تم اختيارها سابقا',
        en: 'Please upload 3 or more works for each previously selected service.'
      },
      uploadInput: {
        label: {
          ar: 'تحميل',
          en: 'Upload'
        },
        errors: {
          required: {
            ar: 'برجاء رفع الاعمال',
            en: 'please upload your works'
          },
          onlyPdf: {
            ar: 'يسمح برفع صيغة PDF فقط',
            en: 'Only PDF format allowed'
          },
          pdfImgs: {
            ar: 'يسمح برفع PDF او صور فقط',
            en: 'You can upload only PDF and images.'
          },
          tooLarge: {
            ar: 'حجم الملف كبير',
            en: 'The file is too large                                                                                    '
          }
        }
      }
    },
    spokenLang: {
      label: {
        ar: 'لغة التحدث',
        en: 'Spoken language'
      },
      errors: {
        required: {
          ar: 'برجاء اختيار لغة التحدث',
          en: 'Please Choose your spoken language'
        }
      }
    }
  },
  //
  //
  //
  stepFour: {
    mainTitle: {
      ar: 'البيانات البنكية لتحويل المبلغ',
      en: 'Bank data to transfer the amount'
    },
    tranferMoneyWay: {
      label: {
        ar: 'طريقة تحويل الاموال',
        en: 'Transfer Money Way'
      }
    },
    bankName: {
      label: {
        ar: 'اســم البنك',
        en: 'Bank name'
      },
      errors: {
        required: {
          ar: 'ادخل اسم البنك',
          en: 'Enter the bank name'
        }
      }
    },
    bankCountry: {
      label: {
        ar: 'الدولة',
        en: 'Country'
      },
      errors: {
        required: {
          ar: 'برجاء اختيار الدولة',
          en: 'Please choose the country'
        }
      }
    },
    accountNumber: {
      label: {
        ar: 'رقم الحساب البنكي',
        en: 'Bank account number'
      },
      errors: {
        required: {
          ar: 'ادخل رقم الحساب البنكى',
          en: 'Enter the bank account number'
        },
        validNumber: {
          ar: 'لا يسمح الا بادخال ارقام ',
          en: 'Only numbers accepted'
        }
      }
    },
    ibanNumber: {
      label: {
        ar: 'رقم الايبان',
        en: 'IBAN number'
      },
      errors: {
        required: {
          ar: 'ادخل رقم ال IBAN',
          en: 'Enter the IBAN number'
        },
        validNumber: {
          ar: 'لا يسمح الا بادخال ارقام ',
          en: 'Only numbers accepted'
        }
      }
    },
    westernFullName: {
      label: {
        ar: 'الاسم بالكامل نفس الوثيقة الشخصية',
        en: 'Fullname as in the id card'
      }
    },
    westernCountry: {
      label: {
        ar: 'الدولة',
        en: 'Country'
      }
    },
    paypalEmail: {
      label: {
        ar: 'البريد الاكترونى الخاص ب باى بال للتحويل',
        en: 'Paypal email'
      }
    },
    email: {
      label: {
        ar: 'البريد الاكترونى',
        en: 'Email Address'
      }
    },
    phone: {
      label: {
        ar: 'رقم الهاتف',
        en: 'Phone number'
      }
    }
  },

  //
  //
  //
  stepFive: {
    mainTitle: {
      ar: 'تأمين الحساب',
      en: 'Account security'
    },
    accountEmail: {
      label: {
        ar: 'البريد الالكترونى',
        en: 'Email address'
      },
      errors: {
        required: {
          ar: 'برجاء ادخال البريد الالكترونى',
          en: 'Enter your email address'
        },
        typeError: {
          ar: 'برجاء ادخال بريد الكترونى صحيح',
          en: 'Provide a vaild email address'
        }
      }
    },
    phoneNumber: {
      label: {
        ar: 'رقـم الهاتف',
        en: 'Phone number'
      },
      errors: {
        required: {
          ar: 'برجاء ادخال رقم الهاتف',
          en: 'Enter your phone number'
        },
        validNumber: {
          ar: 'لا يسمح الا بادخال ارقام ',
          en: 'Only numbers accepted'
        }
      }
    }
  }
};

export default designerApplicationLocalization;
