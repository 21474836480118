export const getYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);
  return new Date(yesterday);
};

export const getTommorow = () => {
  const today = new Date();
  const tommorow = new Date(today);

  tommorow.setDate(tommorow.getDate() + 2);
  return new Date(tommorow);
};
