import designerSideMenuLocalization from "../../localization/designerSideMenu.localization";
import BellIcon from "../../common/icons/BellIcon";
import ChatIcon from "../../common/icons/ChatIcon";
import SettingsIcon from "../../common/icons/SettingsIcon";
import VideoIcon from "../../common/icons/VideoIcon";
import HomeIcon from "../../common/icons/HomeIcon";
import DollarIcon from "../../common/icons/DollarIcon";
import routerLinks from "../../components/app/routerLinks";
import { orderStatusValues } from "../../orderStatus";

const { home, projects, notifications, messages, balance, settings } =
  designerSideMenuLocalization;
const {
  designerHome,
  designerProjects,
  designerNotifications,
  designerMessages,
  designerBalance,
  designerSettings,
} = routerLinks;

const designerLinks = ({ code }) => [
  {
    id: 1,
    name: home[code],
    route: designerHome,
    icon: <HomeIcon />,
    className: "home-link",
  },
  {
    id: 2,
    name: projects[code],
    route: `${designerProjects}?status=${orderStatusValues?.current}`,
    icon: <VideoIcon />,
    className: "projects-link",
  },
  {
    id: 3,
    name: notifications[code],
    route: designerNotifications,
    icon: <BellIcon />,
    className: "notifications-link",
  },
  {
    id: 4,
    name: messages[code],
    route: designerMessages,
    icon: <ChatIcon />,
    className: "messages-link",
  },
  {
    id: 5,
    name: balance[code],
    route: designerBalance,
    icon: <DollarIcon />,
    className: "messages-link",
  },
  {
    id: 6,
    name: settings[code],
    route: designerSettings,
    icon: <SettingsIcon />,
    className: "settings-link",
  },
];

export default designerLinks;
