/* eslint-disable react-hooks/exhaustive-deps */
import './ServiceStepFive.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import { RadioGroup, TextField } from 'formik-material-ui';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';

const allSpec = [
  {
    id: 1,
    name: {
      ar: 'التعليم',
      en: 'Education'
    },
    value: {
      ar: 'التعليم',
      en: 'Education'
    }
  },
  {
    id: 2,
    name: {
      ar: 'الصحة',
      en: 'Health'
    },
    value: {
      ar: 'الصحة',
      en: 'Health'
    }
  },
  {
    id: 3,
    name: {
      ar: 'التكنولوجيا',
      en: 'Technology'
    },
    value: {
      ar: 'التكنولوجيا',
      en: 'Technology'
    }
  },
  {
    id: 4,
    name: {
      ar: 'الصناعة',
      en: 'Industry'
    },
    value: {
      ar: 'الصناعة',
      en: 'Industry'
    }
  },
  {
    id: 5,
    name: {
      ar: 'الطاقة',
      en: 'Energy'
    },
    value: {
      ar: 'الطاقة',
      en: 'Energy'
    }
  },
  {
    id: 6,
    name: {
      ar: 'الأمن',
      en: 'Safty'
    },
    value: {
      ar: 'الأمن',
      en: 'Safty'
    }
  },
  {
    id: 7,
    name: {
      ar: 'الإعلام',
      en: 'Media'
    },
    value: {
      ar: 'الإعلام',
      en: 'Media'
    }
  },
  {
    id: 8,
    name: {
      ar: 'الإتصالات',
      en: 'Communication'
    },
    value: {
      ar: 'الإتصالات',
      en: 'Communication'
    }
  }
];

const ServiceStepFive = () => {
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    stepFive: { companySpeciality, brandName }
  } = serviceDetailsLocalization;
  const { isSubmitting, values, setFieldValue, setTouched, touched, errors } =
    useFormikContext();

  const brandNameArray = [
    {
      id: 1,
      name: {
        ar: 'نعم',
        en: 'Yes'
      },
      value: '1'
    },
    {
      id: 2,
      name: {
        ar: 'لا',
        en: 'No'
      },
      value: '0'
    }
  ];

  useEffect(() => {
    if (values.companySpeciality !== 'other' && values.otherCompSpeciality) {
      setFieldValue('otherCompSpeciality', '');
    }
  }, [values.companySpeciality]);

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={String(item.value[code] || item.value)}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.name[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === String(item.value))
                setFieldValue(fieldName, "");
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  useEffect(() => {
    setTouched({});
  }, []);

  return (
    <div className="formik-step service-step-five">
      {((values?.scriptIsReady === 'ready' &&
        values?.acceptScript === 'review') ||
        values?.scriptIsReady === 'not_ready') && (
        <div className="radio-group-wrapper">
          <h3 className="group-title">{companySpeciality.label[code]}</h3>
          <Field
            className="field-group-wrap"
            component={RadioGroup}
            name="companySpeciality"
          >
            {allSpec?.length > 0 && (
              <>
                {renderRadiosGroup(allSpec, 'companySpeciality')}
                <FormControlLabel
                  value="other"
                  control={<Radio color="primary" disabled={isSubmitting} />}
                  label={companySpeciality.otherTextarea.label[code]}
                  disabled={isSubmitting}
                />
              </>
            )}
            {values.companySpeciality === 'other' && (
              <Field
                component={TextField}
                label={companySpeciality.otherTextarea.placeholder[code]}
                name="otherCompSpeciality"
                variant="outlined"
                multiline
                rows="2"
                className="other-field"
              />
            )}
          </Field>
        </div>
      )}
      <div className="radio-group-wrapper">
        <h3 className="group-title">{brandName.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="brandName"
        >
          {renderRadiosGroup(brandNameArray, 'brandName')}
        </Field>
      </div>
    </div>
  );
};

export default ServiceStepFive;
