/* eslint-disable react-hooks/exhaustive-deps */
import "./EmployeeOrderDetails.scss";

import React, { useContext, useEffect, useState } from "react";
import OrderStages from "./OrderStages";
import useCustomApiRequest from "../../../custom-hooks/useCustomApiRequest";
import UesrContext from "../../../contexts/user-context/UserProvider";
import getSingleOrderApi from "../../../apis/user/getSingleOrderApi";
import { useParams } from "react-router-dom";
import LangContext from "../../../contexts/lang-context/LangProvider";
import checkRes from "../../../utils/checkRes";
import ContentLoader from "react-content-loader";
import EmployeeOrdersCard from "./EmployeeOrdersCard";
import getSingleEmployeeProjectApi from "../../../apis/employee/getSingleEmployeeProjectApi";
import { getEmployeeOrdersByIdApi } from "../../../apis/employee/employeeApi";
import OrderDetailsSteps from "../orderDetailsSteps/OrderDetailsSteps";
const EmployeeOrderDetails = () => {
  const { user } = useContext(UesrContext);
  const params = useParams();
  const {
    language: { code, dir },
  } = useContext(LangContext);
  const [singleOrder, setSingleOrder] = useState(null);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  // const customApiRequest = useCustomApiRequest();
 
  // useEffect(() => {
  //   let isMounted = true;

  //   if (isMounted && user) {
  //     const fetchCart = () => {
  //       setIsLoadingOrder(true);
  //       customApiRequest(
  //         getSingleEmployeeProjectApi({ id: params?.id }, user, code),
  //         (res) => {
  //           setIsLoadingOrder(false);
  //           if (checkRes(res) && res?.data?.data) {
  //             setSingleOrder(res.data.data);
  //           }
  //         },
  //         (error) => {
  //           setIsLoadingOrder(false);
  //         }
  //       );
  //     };
  //     fetchCart();
  //   }

  //   return () => (isMounted = false);
  // }, [code]);


  const [myOrder, setMyOrder] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [completedLevel, setCompletedLevel] = useState(null);

  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const { id } = useParams();

  const getOrderById = async () => {
    setIsLoadingOrders(true);
    const data = await getEmployeeOrdersByIdApi(user, id, code);
    setMyOrder(data.data.data);
    const completedLevel = data.data.data.levels.findIndex(
      (item) => item.status === "complete"
    );
    data.data.data.levels.forEach((item) => {
      if(item.status === "complete") {
        setCompletedLevel(item.level_id);
        console.log(item.level_id)
      }
      if(item.status === "in_work") {
        setCurrentLevel(item.level_id)
        setShowConfirmButton(true)
      }
    })
    

    setIsLoadingOrders(false);
  };
  useEffect(() => {
    getOrderById();
  }, [code, id]);


  if (isLoadingOrder) {
    return (
      <>
        <div className="service-card-loader-box">
          <ContentLoader
            width="100%"
            height="100%"
            speed={1}
            rtl={dir === "rtl" ? true : false}
          >
            <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
          </ContentLoader>
        </div>
      </>
    );
  } else if(myOrder) {
    return (
      <div className="order-details-wrap">
        <EmployeeOrdersCard withBtn={false}
                    key={myOrder.level_id}
                    card={myOrder}
         />
  
  <OrderDetailsSteps myOrder={myOrder} showConfirmButton={showConfirmButton} confirmLevelById={() => {}} />
      </div>
    );
  
  }
  return null
};

export default EmployeeOrderDetails;
