import React, { useContext, useState } from 'react';
import serviceDetailsLocalization from '../../localization/serviceDetailsLocalization';
import * as Yup from 'yup';
import LangContext from '../../contexts/lang-context/LangProvider';
import FormikStepper from '../../common/formik-stepper/FormikStepper';
import FlashMessageContext from '../../contexts/flash-messages-context/FlashMessageProvider';
import { service6InitialValues } from './serviceDetailsInitialValues';
import UesrContext from '../../contexts/user-context/UserProvider';
import ServiceSixStepOne from './service-six-steps/ServiceSixStepOne';
import ServiceSixStepTwo from './service-six-steps/ServiceSixStepTwo';
import ServiceSixStepThree from './service-six-steps/ServicSixStepThree';
import ServiceSixStepFour from './service-six-steps/ServiceSixStepFour';
import ServiceSixStepFive from './service-six-steps/ServiceSixStepFive';
import ServiceSixStepSix from './service-six-steps/ServiceSixStepSix';
import ServiceSixStepSeven from './service-six-steps/ServiceSixStepSeven';
import ServiceSixStepEight from './service-six-steps/ServiceSixStepEight';
import ServiceSixStepNine from './service-six-steps/ServiceSixStepNine';
import ServiceSixStepTen from './service-six-steps/ServiceSixStepTen';
import ServiceSixStepEleven from './service-six-steps/ServiceSixStepEleven';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import makeOrder6Api from '../../apis/user/makeOrder6Api';
import { useHistory } from 'react-router-dom';
import routerLinks from '../app/routerLinks';
import checkRes from '../../utils/checkRes';
import ServiceStepZero from './service-step-zero/ServiceStepZero';

const Service6Steps = ({
  serviceId,
  foundService,
  setIsSubmittingOrder,
  setSuccessOrder,
  setSingleOrder
}) => {
  const history = useHistory();
  const SUPPORTED_FORMATS = {
    pdf: ['application/pdf'],
    images: ['image/jpg', 'image/jpeg', 'image/png']
  };

  const FILE_SIZE = 1024 * 1024; // 1024 * 1024 bytes = 1024 kbytes = 1 megabytes
  // const FILE_SIZE = 100 * 1024; // 100 kbytes
  const { user } = useContext(UesrContext);
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity
  } = useContext(FlashMessageContext);
  const {
    stepOne: {
      scriptIsReady: { no }
    }
  } = serviceDetailsLocalization;
  const [step, setStep] = useState(0);

  const customApiRequest = useCustomApiRequest();
  return (
    <FormikStepper
      step={step}
      setStep={setStep}
      stepperType="user"
      className="formik-stepper"
      initialValues={service6InitialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        setIsSubmittingOrder(true);
        customApiRequest(
          makeOrder6Api(user, foundService, values),
          (res) => {
            setIsSubmittingOrder(false);
            setSubmitting(false);
            if (checkRes(res)) {
              setFlashMsgIsOpen(true);
              setFlashMsg(res?.data?.message);
              setFlashMsgDuration(4000);
              setNotificationSeverity('success');
              history.push(routerLinks?.userCart);
            } else {
              setFlashMsgIsOpen(true);
              setFlashMsg(res?.data?.message || 'Something went wrong');
              setFlashMsgDuration(4000);
              setNotificationSeverity('error');
            }
          },
          (error) => {
            setIsSubmittingOrder(false);
            setSubmitting(false);
            setFlashMsgIsOpen(true);
            setFlashMsg(
              error?.response?.data?.message || 'Something went wrong'
            );
            setFlashMsgDuration(4000);
            setNotificationSeverity('error');
          }
        );
      }}
    >
      <ServiceStepZero foundService={foundService} />
      <ServiceSixStepOne />
      <ServiceSixStepTwo />
      <ServiceSixStepThree />
      <ServiceSixStepFour />
      <ServiceSixStepFive />
      <ServiceSixStepSix />
      <ServiceSixStepSeven setStep={setStep} />
      <ServiceSixStepEight setStep={setStep} />
      <ServiceSixStepNine setStep={setStep} />
      <ServiceSixStepTen setStep={setStep} />
      <ServiceSixStepEleven />
      {/*  */}
    </FormikStepper>
  );
};

export default Service6Steps;
