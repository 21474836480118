import React from "react";

const DollarIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13.777"
			height="26.461"
			viewBox="0 0 13.777 26.461"
		>
			<path
				id="Icon_metro-dollar2"
				data-name="Icon metro-dollar2"
				d="M23.735,19.7a5.618,5.618,0,0,1-1.469,3.891,6.341,6.341,0,0,1-3.817,2.016v2.584a.455.455,0,0,1-.473.473H15.982a.479.479,0,0,1-.473-.473V25.608a9.308,9.308,0,0,1-1.883-.458,9.068,9.068,0,0,1-2.591-1.366,8.416,8.416,0,0,1-.687-.554q-.185-.177-.258-.266a.448.448,0,0,1-.03-.605l1.521-1.993a.46.46,0,0,1,.34-.177.366.366,0,0,1,.354.133l.03.03A7.711,7.711,0,0,0,15.894,22.2a5.158,5.158,0,0,0,1.093.118,3.58,3.58,0,0,0,2.1-.635,2.068,2.068,0,0,0,.908-1.8,1.5,1.5,0,0,0-.221-.783,2.767,2.767,0,0,0-.495-.62,4.053,4.053,0,0,0-.864-.554q-.591-.3-.975-.473t-1.181-.48q-.576-.236-.908-.369t-.908-.391q-.576-.258-.923-.458t-.834-.524a5.2,5.2,0,0,1-.79-.628,9.2,9.2,0,0,1-.642-.724,3.613,3.613,0,0,1-.524-.856,5.922,5.922,0,0,1-.31-.982,5.125,5.125,0,0,1-.126-1.152,5.049,5.049,0,0,1,1.447-3.573A6.757,6.757,0,0,1,15.51,5.334V2.676a.479.479,0,0,1,.473-.473h1.993a.455.455,0,0,1,.473.473v2.6a8.213,8.213,0,0,1,1.632.34,9.46,9.46,0,0,1,1.285.495,7.114,7.114,0,0,1,.938.554q.443.31.576.428T23.1,7.3a.427.427,0,0,1,.074.561l-1.2,2.156a.4.4,0,0,1-.34.236.471.471,0,0,1-.4-.1q-.044-.044-.214-.177t-.576-.391a7.62,7.62,0,0,0-.864-.473,6.6,6.6,0,0,0-1.1-.384,4.926,4.926,0,0,0-1.263-.17,3.838,3.838,0,0,0-2.289.635,1.985,1.985,0,0,0-.76,2.348,1.662,1.662,0,0,0,.436.613,6.507,6.507,0,0,0,.583.487,5.2,5.2,0,0,0,.827.458q.554.258.893.4t1.034.406q.783.3,1.2.465t1.122.517a8.92,8.92,0,0,1,1.115.628,9.428,9.428,0,0,1,.916.738,3.823,3.823,0,0,1,.783.938,5.351,5.351,0,0,1,.465,1.13,4.871,4.871,0,0,1,.192,1.388Z"
				transform="translate(-9.957 -2.203)"
			/>
		</svg>
	);
};

export default DollarIcon;
