import axios from "axios";

export const getEmployeeNotifications = async ( user,id, langCode,level) => {
    try {
      const res = await axios.get(
        `/api/v1/employee/notifications?api_token=${user?.api_token}`,
        {
          headers: {
            'X-Portal': user?.accountType,
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          },
          
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };
  
  

  export const acceptEmployeeJob = async ( user,id,serviceId, langCode) => {
    try {
      const res = await axios.post(
        `/api/v1/employee/orders/accept`,
        {
          api_token:user?.api_token,
          order_id:id,
          service_id:serviceId
          },
        {
          headers: {
            'X-Portal': user?.accountType,
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          },
          
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };
  
  
  export const rejectEmployeeJob = async ( user,id,serviceId, langCode) => {
    try {
      const res = await axios.post(
        `/api/v1/employee/orders/refuse`,
        {
          api_token:user?.api_token,
          order_id:id,
          service_id:serviceId
          },
        {
          headers: {
            'X-Portal': user?.accountType,
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          },
          
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };
  
  


  export const getEmployeeOrdersByIdApi = async ( user,id, langCode) => {
    try {
      const res = await axios.get(
        `/api/v1/employee/order/id?api_token=${user?.api_token}&order_id=${id}`,
        {
          headers: {
            'X-Portal': user?.accountType,
            'Content-Type': 'multipart/form-data',
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          }
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };
  

  export const getEmployeeBalance = async ( user,langCode) => {
    try {
      const res = await axios.get(
        `/api/v1/my-balance?api_token=${user?.api_token}`,
        {
          headers: {
            'X-Portal': user?.accountType,
            'Content-Type': 'multipart/form-data',
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          }
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };
  export const getEmployeeWalletHistory = async ( user,page,langCode) => {
    const skip = (page -1) * 10

    try {
      const res = await axios.get(
        `/api/v1/wallet-history?api_token=${user?.api_token}&skip=${skip}`,
        {
          headers: {
            'X-Portal': user?.accountType,
            'Content-Type': 'multipart/form-data',
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          }
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };
  export const postEmployeeWithdraw = async ( user,amount,langCode) => {
    try {
      const res = await axios.post(
        `/api/v1/withdraw`,
        {
          api_token:user?.api_token,
          amount:amount,
        },
        {
          headers: {
            'X-Portal': user?.accountType,
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode,
          }
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };

  export const getEmployeeWithdraw = async ( user,page,langCode) => {
    const skip = (page -1) * 10
    try {
      const res = await axios.get(
        `/api/v1/withdraw?api_token=${user?.api_token}&skip=${skip}`,
        {
          headers: {
            'X-Portal': user?.accountType,
            'Content-Type': 'multipart/form-data',
            password: process.env.REACT_APP_AUTH_PASS,
            'X-Language': langCode
          }
        }
      );
  
      return res;
    } catch (e) {
      throw e;
    }
  };