import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import slugify from 'slugify';
import UesrContext from '../../contexts/user-context/UserProvider';
import routerLinks from '../app/routerLinks';
// import MainAppFooter from "../main-app-footer/MainAppFooter";
import HomeAppBar from '../my-app-bar/HomeAppBar';
import MainAppBar from '../my-app-bar/MainAppBar';
import UnderMaintain from '../../pages/home-page/UnderMaintain';
import WhatsappSocial from '../../common/icons/social/WhatsappSocial';
import { SocialContext, SocialDispatchContext } from '../../contexts/social-context/socialContext';
import getSocialApi from '../../apis/general/social';
const AppLayout = ({ children }) => {
  // console.log(useHistory());
  const { pathname } = useLocation();
  const { loggedIn } = useContext(UesrContext);
  const dispatch = useContext(SocialDispatchContext)
  const social = useContext(SocialContext)

  useEffect(() => {
    const getSocialLinks = async () => {
      getSocialApi().then((data) => {
           if(data?.data?.data) {
            dispatch({
              type:"addSocial",
              payload:data?.data?.data
            })
           }
      })

    }
    getSocialLinks()
  }, [])



  if (pathname === '/' && !loggedIn) {
    return (
      <>
        <HomeAppBar /> 
        {
          social.mobile && <a href={`https://wa.me/${social.mobile}`} target='_blank' rel='noreferrer' className="fixed-whatsapp">
            <WhatsappSocial />
          </a>
        }
        {children} 
        {/* <UnderMaintain /> */}
      </>
    );
  } else if (pathname === '/' && loggedIn) {
    return (
      <>
        <MainAppBar />
       { social.mobile && <a href={`https://wa.me/${social.mobile}`} target='_blank' rel='noreferrer' className="fixed-whatsapp">
          <WhatsappSocial />
        </a>}
        {children}
      </>
    );
  } else if (
    pathname !== '/' &&
    slugify(pathname) !== slugify(routerLinks.userSignup) && // "/signup/user"
    slugify(pathname) !== slugify(routerLinks.designerSignup) && // "/signup/designer"
    slugify(pathname) !== slugify(routerLinks.userSignin) && // "/signin/user"
    slugify(pathname) !== slugify(routerLinks.designerSignin) && // "/signin/designer"
    slugify(pathname) !== slugify(routerLinks.supervisorSignin) && // "/signin/user"
    slugify(pathname) !== slugify(routerLinks.supervisorSignup) // "/signin/designer"
  ) {
    return (
      <>
        <MainAppBar />
        { social.mobile && <a href={`https://wa.me/${social.mobile}`} target='_blank' rel='noreferrer' className="fixed-whatsapp">
          <WhatsappSocial />
        </a>}
        {children}
      </>
    );
  } else {
    return (
      <>
        {/* <MainAppBar /> */}
        { social.mobile && <a href={`https://wa.me/${social.mobile}`} target='_blank' rel='noreferrer' className="fixed-whatsapp">
          <WhatsappSocial />
        </a>}
        {children}
      </>
    );
  }
  // return children;
};

export default AppLayout;
