const designerHomeLocalization = {
	welcome: {
		ar: "مرحبا بك",
		en: "Welcome"
	},
	applyText: {
		ar:
			"فضلا قم بملئ طلب التوظيف التالي ليتم مراجعته من قبل الإدارة ثم الرد عليك بالقبول او الرفض",
		en:
			"Please fill out the following employment application to be reviewed by the management and then respond to you with acceptance or rejection"
	},
	fillBtn: {
		ar: "ملئ الطلب",
		en: "Fill The application"
	},
	notActivated: {
		ar: " لم يتم تفعيل الاكونت",
		en: "Account is not activated"
	},
	underReview:{
		ar:"الطلب تحت المراجعه",
		en:"Your application is under review"
	},
	noOrder:{
		ar:"لا يوجد طلبات متاحة",
		en:"No available orders"
	}

};

export default designerHomeLocalization;
