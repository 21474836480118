import React, { useContext } from 'react';
import UserContext from '../contexts/user-context/UserProvider';
import { useHistory } from 'react-router-dom';
import routerLinks from '../components/app/routerLinks';
import useCustomApiRequest from './useCustomApiRequest';
import { signinWithEmail } from '../apis/auth/signin';
import FlashMessageContext from '../contexts/flash-messages-context/FlashMessageProvider';
import LangContext from '../contexts/lang-context/LangProvider';

const useSigninEmailPassword = () => {
  const history = useHistory();
  const { language } = useContext(LangContext);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity
  } = useContext(FlashMessageContext);
  const { setUserToState, setCurrentUser } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignin, setIsLoadingSignin] = React.useState(false);

  const signMeInWithEmailPassword = (
    accountType,
    values,
    setSubmitting,
    resetForm,
    code
  ) => {
    setSubmitting(true);
    const formData = new FormData();
    if (values?.email) formData.append('email', values.email);
    if (values?.password) formData.append('password', values.password);
    formData.append('type', accountType);
    formData.append('social_id', '');

    customApiRequest(
      signinWithEmail(formData, language?.code),
      (res) => {
        setSubmitting(false);
        if (res?.data?.status === 0) {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'البيانات المدخلة غير صحيحة');
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        } else if (
          res?.data?.status === 1 &&
          res?.data?.data?.mail_fails?.length > 0
        ) {
          setFlashMsgIsOpen(true);
          setFlashMsg(
            res?.data?.message || 'فشل الارسال الى البريد الالكترونى'
          );
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        } else if (res?.data?.status === 2 && !res?.data?.data) {
          // user not activated yet
          setUserToState({
            accountType,
            email: values?.email,
            activated: false
          });
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'Check your email for code');
          setFlashMsgDuration(4000);
          setNotificationSeverity('success');
          history.push(routerLinks?.activateAccountRoute);
        } else if (res?.data?.status === 1 && res?.data?.data) {
          // user is activated
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'Check your email for code');
          setFlashMsgDuration(4000);
          setNotificationSeverity('success');
          setCurrentUser({
            ...res.data.data,
            accountType,
            activated: true
          });
          history.push(routerLinks?.homePage);
        } else {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'Something went wrong');
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        }
      },
      (error) => {
        setSubmitting(false);
        setFlashMsgIsOpen(true);
        setFlashMsg(error?.response?.data?.message || 'حاول فى وقت لاحق');
        setFlashMsgDuration(4000);
        setNotificationSeverity('error');
      }
    );
  };

  return { signMeInWithEmailPassword, isLoadingSignin, setIsLoadingSignin };
};

export default useSigninEmailPassword;
