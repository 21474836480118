/* eslint-disable react-hooks/exhaustive-deps */
import { AddAPhoto } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

const CustomCanvas = ({
	croppedImgObj,
	previewCanvasRef,
	originalImgRef,
	fileName,
	setCroppedFile
}) => {
	const [url, setUrl] = useState(null);
	useEffect(() => {
		if (
			!croppedImgObj ||
			!previewCanvasRef.current ||
			!originalImgRef.current
		) {
			return;
		}
		if (croppedImgObj && previewCanvasRef && originalImgRef) {
			const image = originalImgRef?.current && originalImgRef.current;
			const canvas = previewCanvasRef?.current && previewCanvasRef.current;
			const crop = croppedImgObj;

			const scaleX = image.naturalWidth / image.width;
			const scaleY = image.naturalHeight / image.height;
			const ctx = canvas.getContext("2d");
			const pixelRatio = window.devicePixelRatio;

			canvas.width = crop.width * pixelRatio;
			canvas.height = crop.height * pixelRatio;

			ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
			ctx.imageSmoothingQuality = "high";

			ctx.drawImage(
				image,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);

			canvas.toBlob(blob => {
				if (!blob) {
					console.error("Canvas is empty");
					return;
				}
				blob.name = fileName;
				// window.URL.revokeObjectURL(originalImgRef);
				// const url = window.URL.createObjectURL(blob);
				// setFile(blob);

				const myFile = new File([blob], `canvas_image${new Date()}.jpeg`, {
					type: "image/jpeg",
					lastModified: new Date(),
					size: 2
				});
				setCroppedFile(myFile);

				// setFile(myFile);
				const reader = new FileReader();
				reader.addEventListener("load", () => {
					setUrl(reader.result);
				});
				reader.readAsDataURL(myFile);
			}, "image/jpeg");
		}
	}, [croppedImgObj]);
	return (
		<>
			<img className="after-crop-img" src={url} alt="img" />
			<AddAPhoto className="add-icon" />
			<canvas
				ref={previewCanvasRef}
				// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
				style={{
					display: "none",
					width: Math.round(croppedImgObj?.width ?? 0),
					height: Math.round(croppedImgObj?.height ?? 0)
				}}
			/>
		</>
	);
};

export default CustomCanvas;
