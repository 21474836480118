/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// import "./ServiceStepFive.scss";

import React, { useContext, useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import { DatePicker, TimePicker } from "formik-material-ui-pickers";
import UserServicesContext from "../../../contexts/services-context/UserServicesProvider";
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { RadioGroup, TextField } from "formik-material-ui";
import LangContext from "../../../contexts/lang-context/LangProvider";
import { makeStyles } from "@material-ui/core/styles";
import ReactPhoneInput from "react-phone-input-material-ui";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import {
  PublishRounded,
  CalendarTodayRounded,
  AccessTimeOutlined,
  Create,
} from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import Map from "../../map/Map";
import "./ServiceEightStepOne.scss";
import moment from "moment";
import { getTommorow } from "../../../utils/getDates";
import citiesApi from "../../../apis/general/citiesApi";
import UesrContext from "../../../contexts/user-context/UserProvider";
import designerApplicationLocalization from "../../../localization/designerApplication.localization";
import useCountries from "../../../custom-hooks/useCountries";
import Loading from "../../../common/loading/Loading";

const filmPhotoAreaTypes = {
  inside: "inside",
  outside: "outside",
  both: "both",
};

const filmPhotoTypes = {
  normal: "normal",
  perfect_per15: "perfect_per15",
  perfect_per25: "perfect_per25",
  perfect_up15: "perfect_up15",
  perfect_up40: "perfect_up40",
};

const filmPeopleCount = {
  onePerson: 1,
  twoPeople: 2,
  threeOrMore: 3,
};

const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1,
  },
  field: {
    margin: "10px 0",
  },
}));

const ServiceEightStepOne = () => {
  const classes = useStyles();
  const {
    language: { code },
  } = useContext(LangContext);
  const { servicePrices } = useContext(UserServicesContext);
  const {
    service6StepTen: { motion, isFilm },
  } = serviceDetailsLocalization;
  const {
    isSubmitting,
    setTouched,
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const filmPhotoAreaArr = [
    {
      id: "inside",
      label: {
        ar: "داخلى",
        en: "Inside",
      },
      value: filmPhotoAreaTypes?.inside,
    },
    {
      id: "outside",
      label: {
        ar: "خارجى",
        en: "Outside",
      },
      value: filmPhotoAreaTypes?.outside,
    },
    {
      id: "both",
      label: {
        ar: "كليهما معا",
        en: "Both",
      },
      value: filmPhotoAreaTypes?.both,
    },
  ];

  const filmPhotoTypesArr = [
    {
      id: 1,

      label: {
        ar: `تصوير عادى لتغطية الاحداث مثل (المؤتمر الصحفى - الاجتماعات - الندوات ) يوم واحد ${servicePrices?.CountHourPerDayForNormalPackage} ساعات`,
        en: `Ordinary filming to cover events such as (press conference - meetings - seminars) (1 day ${servicePrices?.CountHourPerDayForNormalPackage}hours)`,
      },
      value: filmPhotoTypes?.normal,
    },
    {
      id: 2,
      label: {
        ar: `تصوير محترف للمنتجات (غير الاطعة) (15 منتج ليوم واحد ${servicePrices?.CountHourPerDayFor_15_Product} ساعات)`,
        en: `Professional photography of products (other than food) (15 products for one day, ${servicePrices?.CountHourPerDayFor_15_Product} hours)`,
      },
      value: filmPhotoTypes?.perfect_per15,
    },
    {
      id: 3,
      label: {
        ar: `تصوير محترف للمنتجات (غير الاطعة) (25 منتج ليوم واحد ${servicePrices?.CountHourPerDayFor_25_Product} ساعات)`,
        en: `Professional photography of products (other than food) (25 products for one day, ${servicePrices?.CountHourPerDayFor_25_Product} hours)`,
      },
      value: filmPhotoTypes?.perfect_per25,
    },
    {
      id: 4,
      label: {
        ar: `تصوير محترف للمنتجات (الاطعة) (من 15-20 منتج ليوم واحد`,
        en: `Professional photography of products (food) (15-20 products for one day`,
        // ar: `تصوير محترف للمنتجات (الاطعة) (من 15-20 منتج ليوم واحد  ${servicePrices?.CountHourPerDayFor_15_20_Product} ساعات) ${servicePrices?.perfectPackageFor_15_20_Product}$`,
        // en: `Professional photography of products (food) (15-20 products for one day, ${servicePrices?.CountHourPerDayFor_15_20_Product} hours) ${servicePrices?.perfectPackageFor_15_20_Product}$`,
      },
      value: filmPhotoTypes?.perfect_up15,
    },
    {
      id: 5,
      label: {
        ar: `تصوير محترف للمنتجات (الاطعة) (من 40-45 منتج ليوم واحد`,
        en: `Professional photography of products (food) (40-45 products for one day`,
        // ar: `تصوير محترف للمنتجات (الاطعة) (من 40-45 منتج ليوم واحد  ${servicePrices?.CountHourPerDayFor_40_45_Product} ساعات) ${servicePrices?.perfectPackageFor_40_45_Product}$`,
        // en: `Professional photography of products (food) (40-45 products for one day, ${servicePrices?.CountHourPerDayFor_40_45_Product} hours) ${servicePrices?.perfectPackageFor_40_45_Product}$`,
      },
      value: filmPhotoTypes?.perfect_up40,
    },
  ];

  const filmPeopleCountArr = [
    {
      id: 1,
      label: {
        ar: `للشخص الواحد فى اليوم الواحد كاميرا واحدة`,
        en: `One Person for one day - One Camera`,
        // ar: `للشخص الواحد فى اليوم الواحد كاميرا واحدة ${servicePrices?.MeetingsPerDayOnePersonPrice}$`,
        // en: `One Person for one day - One Camera ${servicePrices?.MeetingsPerDayOnePersonPrice}$`,
      },
      value: filmPeopleCount?.onePerson,
    },
    {
      id: 2,
      label: {
        ar: `شخصين فى اليوم الواحد فى اللقاء الحوارى كاميرتين`,
        en: `Two People in one day - Two Cameras`,
        // ar: `شخصين فى اليوم الواحد فى اللقاء الحوارى كاميرتين ${servicePrices?.MeetingsPerDayTwoPersonPrice}$`,
        // en: `Two People in one day - Two Cameras ${servicePrices?.MeetingsPerDayTwoPersonPrice}$`,
      },
      value: filmPeopleCount?.twoPeople,
    },
    {
      id: 3,
      label: {
        ar: `3 اشخاص أو أكثر فى اليوم الواحد 3 كاميرات`,
        en: `Three People or more in one day - 3 Cameras`,
        // ar: `3 اشخاص أو أكثر فى اليوم الواحد 3 كاميرات ${servicePrices?.MeetingsPerDayThreePersonPrice}$`,
        // en: `Three People or more in one day - 3 Cameras ${servicePrices?.MeetingsPerDayThreePersonPrice}$`,
      },
      value: filmPeopleCount?.threeOrMore,
    },
  ];

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.label[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === String(item.value))
                setFieldValue(fieldName, "");
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  useEffect(() => {
    setTouched({});
  }, []);

  //
  // handle out_film value
  const [selectedLocation, setSelecectedLocation] = useState({
    lat: "",
    lng: "",
  });
  const [selectedAddress, setSelectedAddress] = useState("");
  useEffect(() => {
    if (selectedLocation?.lat && selectedLocation?.lng) {
      setFieldValue("film_material_lat", selectedLocation.lat);
      setFieldValue("film_material_lng", selectedLocation.lng);
    }
  }, [selectedLocation.lat, selectedLocation.lng]);


  useEffect(() => {
    setFieldValue("film_material_address", selectedAddress);
  }, [selectedAddress])


  const [phoneValue, setPhoneValue] = React.useState("");
  const handlePhoneValue = (v, setFieldValue) => {
    setPhoneValue(v);
    setFieldValue("film_material_contact_num", v);
  };
  const { user } = useContext(UesrContext);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [allCities, setAllCities] = useState([])
  const [isCityDisabled, setIsCityDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fetchCities = async (id) => {
    setIsCityDisabled(true)
    setIsLoading(true)
    const data = await citiesApi({ countryId: id }, code, user)
    if (data?.data?.data) {
      setAllCities(data?.data?.data)
      setIsCityDisabled(false)
      setIsLoading(false)
    }
  }
  const { stepOne } = designerApplicationLocalization;
  const { allCountries } = useCountries();

  const renderOutFilmSection = () => {
    return (

      <>
        {
          isLoading ?
          <Loading centerLoader />

            : <div className="outfilm-section">

              <div className="form-to-wrap">

                <FormControl
                  className="field-wrapper"
                  variant="outlined"
                  error={touched?.country && errors?.country ? true : false}
                >
                  <InputLabel htmlFor="country-id" className="country-label">
                    {stepOne.country.label[code]}
                  </InputLabel>
                  <Field
                    value={selectedCountryId}
                    onChange={(e) => {
                      setFieldValue("country", e.target.value);
                      setSelectedCountryId(e.target.value);
                      fetchCities(e.target.value)
                    }}
                    component={Select}
                    name="country"
                    // variant="outlined"
                    inputProps={{
                      id: "country-id",
                    }}
                  >
                    {allCountries?.length > 0
                      ? allCountries.map((c) => (
                        <MenuItem key={c?.id} value={c?.id}>
                          {c?.ar_name || c?.en_name || c?.name}
                        </MenuItem>
                      ))
                      : null}
                  </Field>
                  {touched?.country && errors?.country && (
                    <FormHelperText error>{errors.country}</FormHelperText>
                  )}
                </FormControl>


                {selectedCountryId && <FormControl
                  className="field-wrapper"
                  variant="outlined"
                  error={touched?.city && errors?.city ? true : false}
                >
                  <InputLabel htmlFor="city-id" className="city-label country-label">
                    {stepOne.city.label[code]}
                  </InputLabel>
                  <Field
                    defaultValue=""
                    onChange={(e) => {
                      setFieldValue("film_material_city_id", e.target.value);
                    }}
                    component={Select}
                    name="film_material_city_id"
                    // variant="outlined"
                    inputProps={{
                      id: "city-id",
                    }}
                    disabled={isCityDisabled}
                  >
                    {allCities?.length > 0
                      ? allCities.map((c) => (
                        <MenuItem key={c?.id} value={c?.id}>
                          {c?.ar_name || c?.en_name || c?.name}
                        </MenuItem>
                      ))
                      : null}
                  </Field>
                  {touched?.city && errors?.city && (
                    <FormHelperText error>{errors.city}</FormHelperText>
                  )}
                </FormControl>}
              </div>
              <div className="map-wrapper">
                {code === "ar" && (
                  <h3 style={{ color: "#d72a33" }}>
                    عنوان التصوير على جوجل
                  </h3>
                )}
                {code === "en" && (
                  <h3 style={{ color: "#d72a33" }}>Film address</h3>
                )}

                <Map
                  width="100%"
                  height="400px"
                  selectedLocation={selectedLocation}
                  setSelecectedLocation={setSelecectedLocation}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                />
              </div>

              <div className="date-time-wrapper">
                <Field
                  className="field-wrapper"
                  component={DatePicker}
                  label="File Date"
                  name="film_material_date"
                  disablePast
                  // shouldDisableDate={(dateParam) => {
                  //   return moment(dateParam).year() === moment().year() &&
                  //     moment(dateParam).month() === moment().month() &&
                  //     moment(dateParam).dayOfYear() === moment().dayOfYear()
                  //     ? true
                  //     : false;
                  // }}
                  minDate={getTommorow()}
                  // format="MM/dd/yyyy"
                  inputVariant="outlined"
                  disabled={isSubmitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <CalendarTodayRounded fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="form-to-wrap">
                  <Field
                    className="field-wrapper"
                    component={TimePicker}
                    label="Time From : "
                    name="film_material_from_time"
                    inputVariant="outlined"
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AccessTimeOutlined fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    className="field-wrapper"
                    component={TimePicker}
                    label="To From : "
                    name="film_material_to_time"
                    inputVariant="outlined"
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AccessTimeOutlined fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="phone-number-wrap">
                <p>Contact Number </p>
                <ReactPhoneInput
                  country={"ae"}
                  value={phoneValue}
                  onChange={(v) => handlePhoneValue(v, setFieldValue)}
                  component={MuiTextField}
                  dropdownClass={classes.countryList}
                  specialLabel={false}
                  name="film_material_contact_num"
                />
                {touched?.film_material_contact_num &&
                  errors?.film_material_contact_num && (
                    <FormHelperText error>
                      {errors.film_material_contact_num}
                    </FormHelperText>
                  )}
              </div>

              <div className="radio-group-wrapper">
                {code === "ar" && <h3 className="group-title">منطقة التصوير</h3>}
                {code === "en" && <h3 className="group-title">File Area</h3>}

                <Field
                  className="field-group-wrap"
                  component={RadioGroup}
                  name="film_material_photo_area"
                >
                  {renderRadiosGroup(filmPhotoAreaArr, "film_material_photo_area")}
                </Field>
              </div>

              <div className="radio-group-wrapper film-photo-type">
                {code === "ar" && <h3 className="group-title">نوعية التصوير</h3>}
                {code === "en" && <h3 className="group-title">Filming Type</h3>}
                <Field
                  className="field-group-wrap"
                  component={RadioGroup}
                  name="film_material_photo_type"
                >
                  {renderRadiosGroup(filmPhotoTypesArr, "film_material_photo_type")}
                </Field>
              </div>

              {values?.film_material_photo_type == filmPhotoTypes?.normal && (
                <Field
                  component={TextField}
                  name="film_material_count_day"
                  type="text"
                  label={(() => {
                    if (code === "ar") return "عدد أيام التصوير";
                    else if (code === "en") return "Film number of days";
                    return "";
                  })()}
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <Create />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {values?.film_material_photo_type == filmPhotoTypes?.meetings && (
                <div className="radio-group-wrapper">
                  {code === "ar" && <h3 className="group-title">عدد الاشخاص</h3>}
                  {code === "en" && <h3 className="group-title">number of people</h3>}
                  <Field
                    className="field-group-wrap"
                    component={RadioGroup}
                    name="film_material_photo_count_people"
                  >
                    {renderRadiosGroup(
                      filmPeopleCountArr,
                      "film_material_photo_count_people"
                    )}
                  </Field>
                </div>
              )}
            </div>
        }
      </>
    );
  };

  return (
    <div className="formik-step service-eight-step-one">
      {renderOutFilmSection()}
    </div>
  );
};

export default ServiceEightStepOne;
