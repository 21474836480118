import React, { useCallback, useContext, useEffect, useState } from "react";
import "./payment.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Typography } from "@material-ui/core";
import paymentProcessLocalization from "../../localization/paymentProcess.localization";
import LangContext from "../../contexts/lang-context/LangProvider";
import { useLocation } from "react-router-dom";
import Loading from "../../common/loading/Loading";
import { confirmPaymentApi } from "../../apis/user/makeOrder";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Button } from "@material-ui/core";
import UesrContext from "../../contexts/user-context/UserProvider";

const PaymentSuccess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get("paymentId");
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const {
    fields: { failed },
  } = paymentProcessLocalization;
  const { language } = useContext(LangContext);
  const { user } = useContext(UesrContext);
  const confirmPayment = useCallback(async()=>{
    setIsLoading(true)
    const { err, response } = await confirmPaymentApi(paymentId,user)
    if (response) {
      setIsLoading(false)
      setIsError(false)
    }
    if (err) {
      setIsLoading(false)
      setIsError(true)
    }
  },[paymentId,user]) 
  

  useEffect(() => {
    confirmPayment();
  }, [confirmPayment]);

  const {
    fields: { done },
  } = paymentProcessLocalization;
  return (
    <>
      {
        isLoading ?
          <Loading  />
          :
          <div className='payment-container'>
            {isError ?
              <>
                <HighlightOffIcon fontSize='large' color='error' />
                <Typography color='error'>{failed.label[language.code]}</Typography>
                <Button
                      variant="contained"
                      color="primary"
                      onClick={confirmPayment}
                    >
                      {failed.tryAgain[language.code]}
                    </Button>
              </>
              :
              <>

                <CheckCircleIcon fontSize='large' className='payment-success' />
                <Typography className='payment-success'>
                  {done.label[language.code]}
                </Typography>
              </>
            }
          </div>

      }
    </>
  );
};

export default PaymentSuccess;
