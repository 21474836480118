import { Modal } from "@material-ui/core";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { employeeAcceptOrderApi } from "../../../apis/employee/employeeAcceptOrderApi";
import routerLinks from "../../../components/app/routerLinks";
import EmployeeOrdersContext from "../../../contexts/employee-orders-context/EmployeeOrdersContext";
import FlashMessageContext from "../../../contexts/flash-messages-context/FlashMessageProvider";
import LangContext from "../../../contexts/lang-context/LangProvider";
import UesrContext from "../../../contexts/user-context/UserProvider";
import useCustomApiRequest from "../../../custom-hooks/useCustomApiRequest";
import checkRes from "../../../utils/checkRes";

const EmployeeAcceptModal = () => {
  const { user } = useContext(UesrContext);
  const history = useHistory();

  const {
    selectedOrder,
    acceptOrderModalOpened,
    setSelectedOrder,
    setAcceptOrderModalOpened,
    setIsSubmitting,
    setFetchedDataCount,
  } = useContext(EmployeeOrdersContext);
  const {
    language: { code },
  } = useContext(LangContext);
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);
  const customApiRequest = useCustomApiRequest();

  const handleAcceptOrder = () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("api_token", user?.api_token);
    formData.append("order_id", selectedOrder?.id || "");

    customApiRequest(
      employeeAcceptOrderApi(user, formData, code),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          setAcceptOrderModalOpened(false);
          setFetchedDataCount((prev) => prev + 1);
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || "تمت الموافقة على الطب بنجاح");
          setFlashMsgDuration(4000);
          setNotificationSeverity("success");
          history.push(routerLinks?.designerSingleProject(selectedOrder?.id));
        } else {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || "حاول فى وقت لاحق");
          setFlashMsgDuration(4000);
          setNotificationSeverity("error");
        }
      },
      (error) => {
        setIsSubmitting(false);
        setFlashMsgIsOpen(true);
        setFlashMsg(error?.response?.data?.message || "حاول فى وقت لاحق");
        setFlashMsgDuration(4000);
        setNotificationSeverity("error");
      }
    );
  };

  return (
    <Modal
      className="employee-accept-reject-modal accept-modal"
      open={acceptOrderModalOpened}
      onClose={() => setAcceptOrderModalOpened(false)}
    >
      <div className="modal-content">
        <h2 className="modal-h">الموافقة على الطلب</h2>
        <div className="modal-data">
          <p>{`انت على وشك الموافقة على طلب ${selectedOrder?.service?.title}`}</p>
        </div>

        <div className="modal-btns-wrapper">
          <button
            onClick={() => {
              handleAcceptOrder();
            }}
            className="submit-btn"
          >
            نعم
          </button>
          <button
            className="cancel-btn"
            onClick={() => {
              setSelectedOrder(null);
              setAcceptOrderModalOpened(false);
            }}
          >
            إغلاق
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeAcceptModal;
