/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import './ApplicationStepFour.scss';

import React, { useContext, useEffect } from 'react';
import designerApplicationLocalization from '../../../localization/designerApplication.localization';
import LangContext from '../../../contexts/lang-context/LangProvider';
import { Select, TextField } from 'formik-material-ui';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { Field, useFormikContext } from 'formik';
import transfareMoneyWay from '../../../transfareMoneyWay';
import useCountries from '../../../custom-hooks/useCountries';

const ApplicationStepFour = () => {
  const { stepFour } = designerApplicationLocalization;
  const { allCountries } = useCountries();
  const {
    language: { code }
  } = useContext(LangContext);
  const { touched, isSubmitting, setFieldValue, values, setTouched, errors } =
    useFormikContext();

  useEffect(() => {
    setTouched({});
  }, []);

  const transferMoneyWayArr = [
    {
      id: 1,
      label: {
        ar: 'تحويل عن طريق البنك',
        en: 'Bank transfer'
      },
      value: transfareMoneyWay?.bank
    },
    {
      id: 2,
      label: {
        ar: 'تحويل وسترن يوني',
        en: 'Western Union transfer'
      },
      value: transfareMoneyWay?.western
    },
    {
      id: 3,
      label: {
        ar: 'تحويل عن طريق باى بال',
        en: 'Paypal transfer'
      },
      value: transfareMoneyWay?.paypal
    }
  ];

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.label[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === item.value)
                setFieldValue(fieldName, '');
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  const renderBankFields = () => {
    return (
      <div className="fields-wrap">
        <Field
          component={TextField}
          name="bank_name"
          type="text"
          label={stepFour.bankName.label[code]}
          variant="outlined"
          color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Create />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {/*  */}
        <FormControl
          variant="outlined"
          error={touched?.bank_country && errors?.bank_country ? true : false}
        >
          <InputLabel htmlFor="bankCountry-id">
            {stepFour.bankCountry.label[code]}
          </InputLabel>
          <Field
            component={Select}
            name="bank_country"
            // variant="outlined"
            inputProps={{
              id: 'bankCountry-id'
            }}
          >
            {allCountries?.length > 0
              ? allCountries.map((c) => (
                  <MenuItem key={c?.id} value={c?.id}>
                    {c?.ar_name || c?.en_name || c?.name}
                  </MenuItem>
                ))
              : null}
            {/* <MenuItem value="0">المملكة العربية السعودية</MenuItem>
              <MenuItem value="1">مصر</MenuItem>
              <MenuItem value="2">الامارات</MenuItem> */}
          </Field>
          {touched?.bank_country && errors?.bank_country && (
            <FormHelperText error>{errors.bank_country}</FormHelperText>
          )}
        </FormControl>
        {/*  */}
        <Field
          component={TextField}
          name="bank_account_num"
          type="text"
          label={stepFour.accountNumber.label[code]}
          variant="outlined"
          color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Create />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {/*  */}
        <Field
          component={TextField}
          name="iban_num"
          type="text"
          label={stepFour.ibanNumber.label[code]}
          variant="outlined"
          color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Create />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    );
  };
  const renderWesternFields = () => {
    return (
      <div className="fields-wrap">
        <Field
          component={TextField}
          name="western_full_name"
          type="text"
          label={stepFour.westernFullName.label[code]}
          variant="outlined"
          color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Create />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {/*  */}
        <FormControl
          variant="outlined"
          error={
            touched?.western_country && errors?.western_country ? true : false
          }
        >
          <InputLabel htmlFor="bankCountry-id-western">
            {stepFour.bankCountry.label[code]}
          </InputLabel>
          <Field
            component={Select}
            name="western_country"
            // variant="outlined"
            inputProps={{
              id: 'bankCountry-id-western'
            }}
          >
            {allCountries?.length > 0
              ? allCountries.map((c) => (
                  <MenuItem key={c?.id} value={c?.id}>
                    {c?.ar_name || c?.en_name || c?.name}
                  </MenuItem>
                ))
              : null}
          </Field>
          {touched?.western_country && errors?.western_country && (
            <FormHelperText error>{errors.western_country}</FormHelperText>
          )}
        </FormControl>
        {/*  */}
      </div>
    );
  };
  const renderPaypalFields = () => {
    return (
      <div className="fields-wrap">
        <Field
          component={TextField}
          name="paypal_mail"
          type="text"
          label={stepFour.paypalEmail.label[code]}
          variant="outlined"
          color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Create />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    );
  };
  const renderEmailFields = () => {
    return (
      <div className="fields-wrap">
        <Field
          component={TextField}
          name="email"
          type="text"
          label={stepFour.email.label[code]}
          variant="outlined"
          color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Create />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Field
          component={TextField}
          name="phone"
          type="text"
          label={stepFour.phone.label[code]}
          variant="outlined"
          color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Create />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    );
  };

  return (
    <div className="formik-step application-step-four">
      <div className="step-title">{stepFour.mainTitle[code]}</div>

      <div className="radio-group-wrapper">
        <h3 className="group-title">{stepFour.tranferMoneyWay.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="transfer_money_way"
        >
          {renderRadiosGroup(transferMoneyWayArr, 'transfer_money_way')}
        </Field>
      </div>

      {values?.transfer_money_way == transfareMoneyWay?.bank &&
        renderBankFields()}
      {values?.transfer_money_way == transfareMoneyWay?.western &&
        renderWesternFields()}
      {values?.transfer_money_way == transfareMoneyWay?.paypal &&
        renderPaypalFields()}
      {values?.transfer_money_way == transfareMoneyWay?.email &&
        renderEmailFields()}
    </div>
  );
};

export default ApplicationStepFour;
