/* eslint-disable react-hooks/exhaustive-deps */
import './ServiceStepSix.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import { RadioGroup, TextField } from 'formik-material-ui';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';

const allGoals = [
  {
    id: 1,
    name: {
      ar: 'عرض خاص',
      en: 'Special Offer'
    },
    value: {
      ar: 'عرض خاص',
      en: 'Special Offer'
    }
  },
  {
    id: 2,
    name: {
      ar: 'خدمة جديدة',
      en: 'New Service'
    },
    value: {
      ar: 'خدمة جديدة',
      en: 'New Service'
    }
  },
  {
    id: 3,
    name: {
      ar: 'رد على منافس',
      en: 'Reply to competitor'
    },
    value: {
      ar: 'رد على منافس',
      en: 'Reply to competitor'
    }
  },
  {
    id: 4,
    name: {
      ar: 'معلومات جديدة',
      en: 'New Information'
    },
    value: {
      ar: 'معلومات جديدة',
      en: 'New Information'
    }
  }
];

const ServiceStepSix = ({ setStep }) => {
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    stepSix: { videoPurpose, targetCategory }
  } = serviceDetailsLocalization;
  const { isSubmitting, values, setFieldValue, touched, errors, setTouched } =
    useFormikContext();

  const targetCategoryArray = [
    {
      id: '1',
      name: {
        ar: 'جديدة',
        en: 'New'
      },
      value: {
        ar: 'جديدة',
        en: 'New'
      }
    },
    {
      id: '2',
      name: {
        ar: 'موجودة من قبل',
        en: 'Already Exist'
      },
      value: {
        ar: 'موجودة من قبل',
        en: 'Already Exist'
      }
    }
  ];

  useEffect(() => {
    if (values.videoPurpose !== 'other' && values.otherVideoPurpose) {
      setFieldValue('otherVideoPurpose', '');
    }
  }, [values.videoPurpose]);

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={String(item.value[code]) || String(item.value)}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.name[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === String(item.value))
                setFieldValue(fieldName, "");
            }}
          />
        ))}

        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  useEffect(() => {
    setTouched({});
  }, []);

  useEffect(() => {
    if (
      values?.scriptIsReady === 'ready' &&
      values?.acceptScript === 'accept'
    ) {
      setStep((prev) => prev + 1);
    }
  }, [values]);

  return (
    <div className="formik-step service-step-six">
      {((values?.scriptIsReady === 'ready' &&
        values?.acceptScript === 'review') ||
        values?.scriptIsReady === 'not_ready') && (
        <>
          <div className="radio-group-wrapper">
            <h3 className="group-title">{videoPurpose.label[code]}</h3>
            <Field
              className="field-group-wrap"
              component={RadioGroup}
              name="videoPurpose"
            >
              {allGoals?.length > 0 && (
                <>
                  {renderRadiosGroup(allGoals, 'videoPurpose')}
                  <FormControlLabel
                    value="other"
                    control={<Radio color="primary" disabled={isSubmitting} />}
                    label={
                      code === 'ar'
                        ? 'أخرى يجب ذكرها'
                        : 'Other you should mention'
                    }
                    disabled={isSubmitting}
                  />
                </>
              )}
              {values.videoPurpose === 'other' && (
                <Field
                  component={TextField}
                  label={videoPurpose.otherTextarea.label[code]}
                  name="otherVideoPurpose"
                  variant="outlined"
                  multiline
                  rows="2"
                  className="other-field"
                />
              )}
            </Field>
          </div>
          <div className="radio-group-wrapper">
            <h3 className="group-title">{targetCategory.label[code]}</h3>
            <Field
              className="field-group-wrap"
              component={RadioGroup}
              name="targetCategory"
            >
              {renderRadiosGroup(targetCategoryArray, 'targetCategory')}
            </Field>
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceStepSix;
