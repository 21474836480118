import React from "react";
import Layout from "../../components/layout/Layout";

const AboutPage = () => {
	return (
		<Layout>
			<div>about page</div>
		</Layout>
	);
};

export default AboutPage;
