/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import countriesApi from '../apis/general/countriesApi';
import LangContext from '../contexts/lang-context/LangProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';
import UesrContext from '../contexts/user-context/UserProvider';

const useCountries = () => {
  const {
    language: { code }
  } = useContext(LangContext);
  const { user } = useContext(UesrContext);

  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [allCountries, setAllCountries] = useState([]);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const fetchServices = () => {
        setIsLoadingCountries(true);
        customApiRequest(
          countriesApi(user,code),
          (res) => {
            setIsLoadingCountries(false);
            if (checkRes(res) && res?.data?.data) {
              setAllCountries(res.data.data);
            }
          },
          (error) => {
            setIsLoadingCountries(false);
          }
        );
      };
      fetchServices();
    }

    return () => (isMounted = false);
  }, [code]);

  return {
    isLoadingCountries,
    setIsLoadingCountries,
    allCountries,
    setAllCountries
  };
};

export default useCountries;
