import axios from 'axios';
import userTypes from '../../constants/userTypes';

const signoutApi = async (accountType = 'client', values, langCode) => {
  try {
    const signoutRes = await axios.post(
      `/api/v1/${
        accountType === userTypes?.supervisor ? userTypes.employee : accountType
      }/logout`,
      values,
      {
        headers: {
          'X-Portal': accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        }
      }
    );
    // if (user?.socialKey) {
    // 	await firebase.auth().signOut();
    // }

    return signoutRes;
  } catch (e) {
    console.log('e : ', e);
    throw e;
  }
};

export default signoutApi;
