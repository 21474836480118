import { useContext } from 'react';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';
import * as Yup from 'yup';
import ServiceStepSeven from '../service-step-seven/ServiceStepSeven';

const ServiceFiveStepEight = ({ setStep }) => {
  const {
    stepOne: {
      scriptIsReady: { yes, no }
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection }
  } = serviceDetailsLocalization;
  const {
    language: { code }
  } = useContext(LangContext);

  return (
    <>
      <ServiceStepSeven
        setStep={setStep}
        validationSchema={Yup.object().shape({
          targetSector: Yup.string().required(
            targetSector.errors.required[code]
          ),
          otherTargetSector: Yup.string().when('targetSector', {
            is: 'other',
            then: Yup.string().required(
              targetSector.otherTextarea.errors.required[code]
            )
          })
        })}
      />
    </>
  );
};

export default ServiceFiveStepEight;
