/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import ButtonWithLoading from '../../common/button-with-loading/ButtonWithLoading';
import { TextField } from 'formik-material-ui';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff, PersonRounded } from '@material-ui/icons';
import LangContext from '../../contexts/lang-context/LangProvider';
import developerSigninLocalization from '../../localization/userSignin.localization';
import SocialApiHandler from '../../common/social-api-handler/SocialApiHandler';
import useSigninEmailPassword from '../../custom-hooks/useSigninEmailPassword';
import routerLinks from '../app/routerLinks';
import userTypes from '../../constants/userTypes';

const DesignerSigninForm = () => {
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    fields: { email, password },
    signinBtn,
    forgetPass,
    notSignedup
  } = developerSigninLocalization;

  const SIGNIN_SCHEMA = Yup.object().shape({
    email: Yup.string()
      // .trim('No leading and trailing spaces')
      // .strict(true)
      .required(email.errors.required[code])
      .trim(),
    password: Yup.string().required(password.errors.required[code])
  });

  const [showPass, setShowPass] = useState(false);
  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { signMeInWithEmailPassword } = useSigninEmailPassword();
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        remember: true
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        signMeInWithEmailPassword(
          userTypes?.employee,
          values,
          setSubmitting,
          resetForm,
          code
        );
      }}
      validationSchema={SIGNIN_SCHEMA}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="developer-signin-form"
            autoComplete="off"
            id="developer-signin-form"
          >
            <Field
              component={TextField}
              name="email"
              type="text"
              label={email.label[code]}
              variant="standard"
              color="primary"
              className="form-field"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <PersonRounded />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Field
              component={TextField}
              name="password"
              type={showPass ? 'text' : 'password'}
              label={password.label[code]}
              variant="standard"
              color="primary"
              className="form-field"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <RouterLink
              className="forget-link"
              to={routerLinks?.forgetPassword('employee')}
            >
              {forgetPass[code]}
            </RouterLink>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
					<pre>{JSON.stringify(errors, null, 2)}</pre>
					<pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </form>
          <div className="btn-more-options">
            <ButtonWithLoading
              isSubmitting={isSubmitting}
              errors={errors}
              btnText={signinBtn[code]}
              loadingMsg="Please Wait.."
              className="submit-btn"
              form="developer-signin-form"
            />

            <SocialApiHandler comp="designer" />
          </div>

          <div className="dont-have-account">
            <p className="pp">{notSignedup.p[code]}</p>
            <span>..</span>
            <RouterLink className="signup-link" to="/signup/designer">
              {notSignedup.link[code]}
            </RouterLink>
          </div>
        </>
      )}
    </Formik>
  );
};

export default DesignerSigninForm;
