const designerSideMenuLocalization = {
	home: {
		ar: "الرئيسية",
		en: "Home"
	},
	projects: {
		ar: "مشاريعى",
		en: "My projects"
	},
	notifications: {
		ar: "الاشعارات",
		en: "Notifications"
	},
	messages: {
		ar: "الرسائل",
		en: "Messages"
	},
	balance: {
		ar: "رصيدى",
		en: "Balance"
	},
	settings: {
		ar: "الاعدادات",
		en: "Settings"
	}
};

export default designerSideMenuLocalization;
