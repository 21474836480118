const homePageTranslations = {
  welcomeSection: {
    h1: {
      p: {
        row1: {
          ar: "مرحبا بكم",
          en: "Welcome to",
        },
        row2: {
          ar: "بفيديو تيوب",
          en: "VideoTube",
        },
      },
    },
    h2: {
      ar: "برؤيتك نصنع الابداع",
      en: "With your vision, we create creativity",
    },
    desc: {
      P: {
        row1: {
          ar: "فيديو تيوب متخصصون بصناعه المحتوي بجودة ودقة عالية",
          en: "We specialize in manufacturing content with high quality and accuracy. ",
        },
        row2: {
          ar: "فريق عمل محترف في صناعه وكتابة المحتوي ,, كل ما عليك سوي",
          en: "A professional team works in content creation and writing. All you have to do ",
        },
        row3: {
          ar: "تصفح خدماتنا ثم التسجيل واترك الباقي لنا",
          en: "is browse our services, register, and leave the rest to us.",
        },
      },
    },
    serviceBtn: {
      ar: "خدماتنا",
      en: "services",
    },
  },
  servicesSection: {
    sectionTitle: {
      h2: {
        ar: "خدماتنـا",
        en: "Services",
      },
      p: {
        ar: "متخصصون بصناعة المحتوى",
        en: "We specialize in creating content",
      },
    },
    serviceCard: {
      btnText: {
        ar: "أطلب الخدمة",
        en: "Request for service",
      },
    },
  },
  howItWorks: {
    sectionTitle: {
      h2: {
        ar: "كيف يتم انتاج الفيديو الخاص بك",
        en: "How is your video produced",
      },
      p: {
        ar: "ما لا تعرفة عن طريقة انتاج الفيديو الخاص بك",
        en: "What you don't know about the process of producing your video",
      },
    },
    steps: {
      stepOne: {
        title: {
          ar: "المرحلة الاولى",
          en: "Step one",
        },
        desc: {
          ar: "تجميع المعلومات الخاصة بالفيديو والمحتوي",
          en: "Collect video and content information",
        },
      },
      stepTwo: {
        title: {
          ar: "المرحلة الثانية",
          en: "Step two",
        },
        desc: {
          ar: "انشاء الأفكار وفلترتها للخروج بمحتوي ابداعي",
          en: "Create ideas and filter them to create creative content",
        },
      },
      stepThree: {
        title: {
          ar: "المرحلة الثالثة",
          en: "Step three",
        },
        desc: {
          ar: "البدء بإنتاج الفيديو بأفضل البرامج والادوات",
          en: "Start producing the video with the best programs and tools",
        },
      },
      stepFour: {
        title: {
          ar: "المرحلة الرابعة",
          en: "Step four",
        },
        desc: {
          ar: "انتاج فيديو مناسب لجميع المنصات ووسائل التواصل الاجتماعي",
          en: "Producing a video suitable for all platforms and social media",
        },
      },
      stepFive: {
        title: {
          ar: "المرحلة الخامسة",
          en: "Step five",
        },
        desc: {
          ar: "مراجعة الفيديو الخاص بك والاستلام بنجاح",
          en: "Review your video and receive successfully",
        },
      },
      stepSix: {
        title: {
          ar: "المرحلة الأخيرة",
          en: "Last step",
        },
        desc: {
          ar: "تجميع المعلومات الخاصة بالفيديو والمحتوي",
          en: "Collect video and content information",
        },
      },
    },
  },
  testimonialsSection: {
    sectionTitle: {
      h2: {
        ar: "أراء العملاء",
        en: "Testimonials",
      },
      p: {
        ar: "نعتز بثقة عملائنا بنا ,, بثقتكم نتقدم",
        en: "We value the trust of our clients in us. With your trust, we progress",
      },
    },
    moreTitle: {
      p1: {
        ar: "برؤيتك نصنع",
        en: "With your vision we create",
      },
      p2: {
        ar: "الابداع",
        en: "Creativity",
      },
    },
    firstReview: {
      name :{
        ar:"جمال الشحي",
        en:"Jamal Al Shehhi"
      },
      position :{
        ar:"المؤسس ومدير عام دار كتاب للنشر و التوزيع",
        en:"Founder and General Manager of Dar Kuttab for Publishing and Distribution"
      },
      text : {
        ar:"حبيت أكثر سرعة تواصلهم، أفكارهم والجودة العالية في كل التفاصيل",
        en:"I liked the speed of their communication, their ideas, and the high quality in every detail"
      }
    },
    secondReview: {
      name :{
        ar:"فهيم الزبيدي",
        en:"Fahim Al-Zubaidi"
      },
      position :{
        ar:"شركة أكشاك للتعامل الإلكتروني",
        en:"Akshak Company for Electronic Dealing"
      },
      text : {
        ar:"فريق العمل تعامل معانا باحترافية عالية مع فهم عميق لاحتياجاتنا. بالاضافة الى ذلك تميز الفريق بالالتزام بالمواعيد وحسن التنفيذ",
        en:"The staff dealt with us with great professionalism and a deep understanding of our needs. In addition, the team was distinguished by its punctuality and good implementation"
      }
    },
    thirdReview: {
      name :{
        ar:"منى خليفه حماد",
        en:"Mona Khalifa Hammad"
      },
      position :{
        ar:"مدير مركز علمني للاستشارات الادارية والتدريب",
        en:"Director of the Alamni Center for Management Consultation and Training"
      },
      text : {
        ar:"كنت أدري إن الفيديوهات مهمة، لكن ما كنت أسويها صح! لكن الحين ماشالله عليهم الفرق بعدهم كبير",
        en:"I knew videos were important, but I wasn't doing them right! But now, God willing, the difference is huge"
      }
    }
  },
  giftSection: {
    p1: {
      ar: "سجل الان واحصل",
      en: "Register now and get",
    },
    p2: {
      ar: "على خصم 20%",
      en: "20% discout",
    },
    btnText: {
      ar: "سجل الان",
      en: "Signup now",
    },
  },
  footer: {
    services: {
      ar: "خدماتنا",
      en: "Our services",
    },
    importantLink: {
      ar: "روابط مهمة",
      en: "Important links",
    },
    userConditions: {
      ar: "شروط العمل للمستخدم",
      en: "Conditions of usage",
    },
    employeeConditions: {
      ar: "شروط العمل للموظف",
      en: "Conditions of employment",
    },
    aboutCompany: {
      ar: "عن الشركة",
      en: "About company",
    },
    privacyPolicy: {
      ar: "سياسة الخصوصية",
      en: "Privacy policy",
    },
    signIn: {
      ar: "تسجيل الدخول",
      en: "Sign in",
    },
    p: {
      ar: "فيديو تيوب متخصصون بصناعه المحتوي بجودة وسرعة ودقة عالية ,, فريق عمل محترف في صناعة وكتابة المحتوي ,, كل ما عليك سوي تصفح خدماتنا ثم التسجيل واترك الباقي لنا",
      en: "VideoTube specializes in creating content with high quality, speed, and accuracy. A professional team in creating and writing content. All you have to do is browse our services, then register, and leave the rest to us.  ",
    },
    contactUs: {
      ar: "تواصل معنا",
      en: "Contact with us",
    },
    socialMedia: {
      ar: "وسائل التواصل الاجتماعي",
      en: "Social media",
    },
    supervisorConditions: {
      ar: "شروط العمل للمشرف",
      en: "Conditions of supervision ",
    },
    address: {
      ar: "دبي - الإمارات العربية المتحدة",
      en: "Dubai - United Arab Emirates",

    }
  },
  common : {
    warning: {
      ar:"تنبيه",
      en:"Warning"
    },
    allowNotifications : {
      ar:"من فضلك قم بتفعيل الاشعارات من   اعدادات المتصفح لتصلك الطلبات الجديده ",
      en:"Please allow notifications from browser to receieve new orders"
    },
    allowNotificationsClient : {
      ar:"من فضلك قم بتفعيل الاشعارات من المتصفح لتصلك الاشعارات الجديده ",
      en:"Please allow notifications from browser settings  to receieve new orders"
    },

    done :{
      ar:" تم التفعيل",
      en: "Allowed notifications"
    },
    close: {
      ar:"اغلق",
      en:"Close"
    }
  }
};

export default homePageTranslations;
