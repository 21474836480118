/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import designerApplicationLocalization from '../../../localization/designerApplication.localization';
import LangContext from '../../../contexts/lang-context/LangProvider';
import { Field, FieldArray, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import MaleIcon from '../../../common/icons/MaleIcon';
import FemaleIcon from '../../../common/icons/FemaleIcon';
import './ApplicationStepTwoTwo.scss';
import useDesignerServices from '../../../custom-hooks/useDesignerServices';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const ApplicationStepTwoTwo = () => {
  const {
    language: { code }
  } = useContext(LangContext);
  const { stepTwo } = designerApplicationLocalization;

  const { isSubmitting, values, setFieldValue, errors } = useFormikContext();

  const { allServices, isLoadingServices } = useDesignerServices();
  // voice over models

  const selectedAttrIds = [];

  values.services.forEach(service => {
    if(service && service.attributes) {
      service.attributes.forEach(attr => {
        if(attr && attr.attr_id && attr.attr_id.length > 0) {
          selectedAttrIds.push(attr.attr_id[0]); 
        }
      });
    }
  });
    const renderModelsGroup = (arr, name,id) => {
    // const parentObj = values.services.filter(item => item && item.attributes);
    // const result = parentObj.filter(item => item.attributes && item.attributes[0].attr_id && item.attributes[0].attr_id[0] == id);

    // find(item => item && item.attributes && item.attributes.includes("12"))
    return (
      arr?.length > 0 &&
      arr.map((item) => (
        <div className="voice-note-input-wrapper" key={item?.id + 'voice_note'}>
          <FormControlLabel
            key={`${item?.id}_voice_note`}
            value={String(item.id)}
            control={<Radio color="primary" disabled={isSubmitting} />}
            // label={item?.attribute_id}
            disabled={!selectedAttrIds.includes(`${id}`)}
          />
          <audio
            controls
            src={process.env.REACT_APP_BACK_END_URL + item?.voice}
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      ))
    );
  };

  const renderProfessionErr = () => {
    if (errors?.services) {
      if (typeof errors.services === 'string')
        return <FormHelperText error>{errors.services}</FormHelperText>;
      else if (
        errors.services.length > 0 &&
        typeof errors.services === 'function'
      )
        return <FormHelperText error>{errors.services[0]}</FormHelperText>;
      return null;
    }
  };
  const renderProfession = () => {
    return (
      <div className="profession-wrap">
        <div className="checkboxs-title">{stepTwo.profession.label[code]}</div>
        {renderProfessionErr()}
        <FieldArray name="services">
          {() => {
            return (
              <div className="checkboxs-wrap">
                {allServices?.length > 0 &&
                  allServices.map((item, index) => (
                    <div
                      key={index + item?.id + '_key'}
                      className="checkbox-attributes-wrapper"
                    >
                      <div className="checkbox-input-wrap">
                        <label>
                          <Field
                            type="checkbox"
                            name={`services.${index}.service_id`}
                            value={String(item?.id)}
                            disabled={isSubmitting}
                          />
                          {item?.title}
                        </label>
                      </div>
                      {values?.services?.find(
                        (o) =>
                          o?.service_id?.length > 0 &&
                          o?.service_id[0] == item?.id
                      ) &&
                        item?.attributes?.length > 0 && (
                          <div
                            className="attributes-wrapper"
                            style={{
                              margin: '6px 18px',
                              padding: '8px 18px',
                              backgroundColor: '#fff',
                              borderRadius: '10px',
                              border: '1px solid rgba(0, 0, 0, .1)'
                            }}
                          >
                            {item.attributes.map((attr, i) => {
                              return (
                                <div
                                  key={index + i + attr?.id + '_attr'}
                                  className="attributes-voices-wrapper"
                                >
                                  <div className="checkbox-input-wrap">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name={`services.${index}.attributes.${i}.attr_id`}
                                        value={String(attr?.id)}
                                        disabled={isSubmitting}
                                      />
                                      {attr?.value}
                                    </label>
                                  </div>

                                  {attr?.voice_notes?.length > 0 && (
                                    <Field
                                      className="field-group-wrap"
                                      component={RadioGroup}
                                      name={`services.${index}.attributes.${i}.voiceNote`}
                                      
                                    >
                                      {/* {renderModelsGroup(
                                        attr.voice_notes,
                                        `services.${index}.attributes.${i}.voiceNote`,
                                        attr?.id
                                      )} */}
                                    </Field>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            );
          }}
        </FieldArray>
      </div>
    );
  };

  const renderRadiosGroup = (arr, name) => {
    return arr.map((item) => (
      <FormControlLabel
        key={item.id}
        value={String(item.id)}
        control={
          <Radio
            color="primary"
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[name]) === String(item.id))
                setFieldValue(name, '');
            }}
          />
        }
        label={item?.name}
        disabled={isSubmitting}
      />
    ));
  };

  const renderStepContent = () => {
    if (isLoadingServices) {
      return <LoadingModal />;
    } else if (allServices?.length > 0) {
      return (
        <div className="step-content">
          <div className="step-title">{stepTwo.mainTitle[code]}</div>

          {renderProfession()}

          <div className="gender-wrap">
            <div className="wrap-title">{stepTwo.gender.label[code]}</div>
            <div className="gender-labels">
              <label
                className={`gender-label ${
                  values.gender === '1' ? 'checked' : ''
                }`}
              >
                <Field type="radio" name="gender" value="1" />
                {stepTwo.gender.fields.male[code]}
                <MaleIcon color="#ababab" />
              </label>
              <label
                className={`gender-label ${
                  values.gender === '2' ? 'checked' : ''
                }`}
              >
                <Field type="radio" name="gender" value="2" />
                {stepTwo.gender.fields.female[code]}
                <FemaleIcon color="#ababab" />
              </label>
            </div>

            {errors?.gender && (
              <FormHelperText error>{errors.gender}</FormHelperText>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="formik-step application-step-two-two">
      {renderStepContent()}
      {/*  */}
      {/*  */}
      {/*  */}
    </div>
  );
};

export default ApplicationStepTwoTwo;
