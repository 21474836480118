/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import './DesignerApplication.scss';

import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Modal } from '@material-ui/core';
import FormikStepper from '../../common/formik-stepper/FormikStepper';
import successImg from '../../assets/imgs/icons/success.gif';
import LangContext from '../../contexts/lang-context/LangProvider';
import designerApplicationLocalization from '../../localization/designerApplication.localization';
import ApplicationStepOne from './application-step-one/ApplicationStepOne';
import ApplicationStepTwo from './application-step-two/ApplicationStepTwo';
import ApplicationStepThree from './application-step-three/ApplicationStepThree';
import ApplicationStepFour from './application-step-four/ApplicationStepFour';
import ApplicationStepFive from './application-step-filve/ApplicationStepFive';
import { employeeApply } from '../../apis/employee/apply';
import UesrContext from '../../contexts/user-context/UserProvider';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import { useHistory } from 'react-router-dom';
import routerLinks from '../app/routerLinks';
import ApplicationStepTwoTwo from './application-step-two/ApplicationStepTwoTwo';
import transfareMoneyWay from '../../transfareMoneyWay';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import FlashMessageContext from '../../contexts/flash-messages-context/FlashMessageProvider';

const DesignerApplication = () => {
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);

  DocTitleScrollTop('Apply');
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const {
    language: { code }
  } = useContext(LangContext);
  const { setFetchUserCount, user } = useContext(UesrContext);
  const { stepOne, stepTwo, stepThree, stepFour } =
    designerApplicationLocalization;
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const history = useHistory();

  const [step, setStep] = useState(0);
  return (
    <div className="application-page">
      <div className="steps-wrapper">
        <FormikStepper
          step={step}
          setStep={setStep}
          stepperType="designer"
          className="formik-stepper"
          initialValues={{
            // step one
            firstname: '',
            lastname: '',
            country: '',
            cityId:'',
            cv: null,
            identity: null,
            role:user?.accountType,
            //////////////////////////
            // step two
            job_apply_reason: '',
            your_skill: '',
            weak_points: '',
            strengths_points: '',
            team_work: '',
            has_experience: '',
            about: '',
            company_feedback: '',
            best_contact: '',
            best_work_time: '',
            //////////////////////////
            // step two two
            services: [],
            arabicVoiceOver: '',
            englishVoiceOver: '',
            gender: '',
            ////////////////////////
            // step three
            experience_year: '',
            ex_works: null,
            language: '',
            ////////////////////////
            // step four
            transfer_money_way: '',
            bank_name: '',
            bank_country: '',
            bank_account_num: '',
            iban_num: '',
            western_full_name: '',
            western_country: '',
            paypal_mail: '',
            email: '',
            phone: '',
            is_accept: ''
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const {res,err} = await employeeApply(user, values, code);
            if (res?.status === 200) {
              setFetchUserCount((prev) => prev + 1);
              if(user?.accountType === "supervisor") {
                history.push(routerLinks.supervisorHome);

              } else {
                history.push(routerLinks.designerHome);

              }
            }
            if(err) {
              setFlashMsgIsOpen(true);
              setFlashMsg(
                err?.response?.data?.message || "حدث خطأ حاول فى وقت لاحق"
              );
              setFlashMsgDuration(4000);
              setNotificationSeverity("error");
      
            }
            setIsSubmittingForm(false);
          }}
        >
          <ApplicationStepOne
            validationSchema={Yup.object().shape({
              firstname: Yup.string()
                .required(stepOne.firstname.errors.required[code])
                .min(2, stepOne.firstname.errors.minChar(2)[code])
                .max(12, stepOne.firstname.errors.maxChar(12)[code])
                // .trim()
                ,
              lastname: Yup.string()
                .required(stepOne.lastname.errors.required[code])
                .min(2, stepOne.lastname.errors.minChar(2)[code])
                .max(12, stepOne.lastname.errors.maxChar(12)[code])
                .trim(),
              country: Yup.string().required(
                stepOne.country.errors.required[code]
              ),
              cityId: Yup.string().required(
                stepOne.city.errors.required[code]
              ),
              cv: Yup.mixed().required(stepOne.cv.errors.required[code]),
              identity: Yup.mixed().required(
                stepOne.identity.errors.required[code]
              )
            })}
          />

          <ApplicationStepTwo />

         {user?.accountType !== "supervisor" && <ApplicationStepTwoTwo
            validationSchema={Yup.object().shape({
              services: Yup.array()
                .of(
                  Yup.mixed().test(
                    'services',
                    'Please Select your profession',
                    (v, context) => {
                      let result = true;
                      let arr =
                        context.parent?.length > 0 ? context.parent : [];
                      if (arr?.length > 0) {
                        arr.filter((item) => item);
                      }
                      if (!arr || arr?.length == 0) {
                        result = false;
                        return;
                      } else if (arr?.length > 0) {
                        for (let obj of arr) {
                          if (obj?.service_id?.length > 0) {
                            result = true;
                            break;
                          } else result = false;
                        }
                      }

                      return result;
                    }
                  )
                )
                .min(1, 'Select your profession'),
              gender: Yup.string().required(
                stepTwo.gender.errors.required[code]
              )
            })}
          />}
          {/*  */}
          {/*  */}
          {/*  */}
          <ApplicationStepThree
            validationSchema={Yup.object().shape({
              // practicalExp: Yup.string().required(
              //   stepThree.practicalExp.errors.required[code]
              // ),
              //
              //
              ex_works: Yup.mixed().required(
                stepThree.uploadWork.uploadInput.errors.required[code]
              )
              // .test(
              // 	"fileFormat",
              // 	stepThree.uploadWork.uploadInput.errors.pdfImgs[code],
              // 	value => {
              // 		let result = true;
              // 		if (value && value.length) {
              // 			for (let fileObj of value) {
              // 				if (!SUPPORTED_FORMATS.pdfImgs.includes(fileObj.type)) {
              // 					result = false;
              // 					break;
              // 				}
              // 			}
              // 		}
              // 		return result;
              // 	}
              // )
              // .test(
              // 	"fileSize",
              // 	stepThree.uploadWork.uploadInput.errors.tooLarge[code],
              // 	value => {
              // 		let result = true;
              // 		if (value && value.length) {
              // 			for (let fileObj of value) {
              // 				if (fileObj.size > FILE_SIZE) {
              // 					result = false;
              // 					break;
              // 				}
              // 			}
              // 		}
              // 		return result;
              // 	}
              // )
              // spokenLang: Yup.string().required(
              //   stepThree.spokenLang.errors.required[code]
              // ),
            })}
          />
          {/*  */}
          {/*  */}
          {/*  */}
          <ApplicationStepFour
            validationSchema={Yup.object().shape({
              transfer_money_way: Yup.string().required('Select one method'),
              bank_name: Yup.string().test(
                'bank_name',
                stepFour.bankName.errors.required[code],
                (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way == transfareMoneyWay?.bank
                  )
                    result = false;
                  return result;
                }
              ),
              bank_country: Yup.string().test(
                'bank_country',
                stepFour.bankCountry.errors.required[code],
                (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way == transfareMoneyWay?.bank
                  )
                    result = false;
                  return result;
                }
              ),
              bank_account_num: Yup.string().test(
                'bank_account_num',
                stepFour.accountNumber.errors.required[code],
                (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way == transfareMoneyWay?.bank
                  )
                    result = false;
                  return result;
                }
              ),
              iban_num: Yup.string().test(
                'bank_account_num',
                stepFour.ibanNumber.errors.required[code],
                (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way == transfareMoneyWay?.bank
                  )
                    result = false;
                  return result;
                }
              ),

              western_full_name: Yup.string().test(
                'western_full_name',
                'Please Enter westren full name',
                (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way ==
                      transfareMoneyWay?.western
                  )
                    result = false;
                  return result;
                }
              ),
              western_country: Yup.string().test(
                'western_country',
                'Please Enter westren country',
                (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way ==
                      transfareMoneyWay?.western
                  )
                    result = false;
                  return result;
                }
              ),
              paypal_mail: Yup.string()
                .notRequired()
                .email('Not a valid email')
                .test(
                  'paypal_mail',
                  'Please Enter westren email',
                  (v, context) => {
                    let result = true;
                    if (
                      !v &&
                      context.parent.transfer_money_way ==
                        transfareMoneyWay?.paypal
                    )
                      result = false;
                    return result;
                  }
                ),
              email: Yup.string()
                .notRequired()
                .email('Not a valid email')
                .test('email', 'Please Enter your email', (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way ==
                      transfareMoneyWay?.email
                  )
                    result = false;
                  return result;
                }),
              phone: Yup.string().test(
                'phone',
                'Please enter your phone',
                (v, context) => {
                  let result = true;
                  if (
                    !v &&
                    context.parent.transfer_money_way ==
                      transfareMoneyWay?.email
                  )
                    result = false;
                  return result;
                }
              )

              // .matches(
              // 	/^[0-9]+$/,
              // 	stepFour.accountNumber.errors.validNumber[code]
              // )
            })}
          />
          {/*  */}
          {/*  */}
          {/*  */}
          {/* <ApplicationStepFive
            validationSchema={Yup.object().shape({
              is_accept: Yup.boolean()
                .required('Please accept terms first')
                .oneOf([true], 'Please accept terms first')
            })}
          /> */}
        </FormikStepper>
      </div>

      {isSubmittingForm && <LoadingModal />}
      {/* sucess modal */}
      {/* <Modal
        className="success-modal"
        open={successModalOpened}
        onClose={() => setSuccessModalOpened(false)}
      >
        <div className="modal-body">
          <img src={successImg} alt="sucess" />
          <p>تم التسجيل بنجاح</p>
        </div>
      </Modal> */}
    </div>
  );
};

export default DesignerApplication;
