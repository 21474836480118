import uaeFlag from "../../assets/imgs/icons/flags/uae.svg";
import usaFlag from "../../assets/imgs/icons/flags/usa.svg";
const languages = [
  {
    id: 1,
    name: "العربية",
    code: "ar",
    dir: "rtl",
    flag: uaeFlag,
  },
  {
    id: 2,
    name: "English",
    code: "en",
    dir: "ltr",
    flag: usaFlag,
  },
];

export default languages;
