import React from "react";

const DocumentIcon = ({ color = "#000" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="164.397"
			height="165.004"
			viewBox="0 0 164.397 165.004"
		>
			<g id="report" transform="translate(-0.004 0)">
				<path
					id="Path_1391"
					data-name="Path 1391"
					d="M138.977,383h-76.4a2.87,2.87,0,1,0,0,5.739h76.4a2.87,2.87,0,1,0,0-5.739Zm0,0"
					transform="translate(-38.285 -245.618)"
					fill={color}
				/>
				<path
					id="Path_1392"
					data-name="Path 1392"
					d="M62.573,268.739h40.535a2.87,2.87,0,1,0,0-5.739H62.573a2.87,2.87,0,1,0,0,5.739Zm0,0"
					transform="translate(-38.285 -168.662)"
					fill={color}
				/>
				<path
					id="Path_1393"
					data-name="Path 1393"
					d="M148.341,42.338a2.972,2.972,0,0,0-4.139,0L125.191,61.223V32.085a2.8,2.8,0,0,0-.759-2.03L95.435.929A2.846,2.846,0,0,0,93.446,0H2.7A2.885,2.885,0,0,0,0,3.047V161.954A2.886,2.886,0,0,0,2.7,165H122.485a2.893,2.893,0,0,0,2.706-3.049V100.031l38.352-38.356a2.869,2.869,0,0,0,.039-4.055Zm-54.029,60.47,8.841,8.841-13.282,4.44Zm14.148,6.031L97.121,97.5l36.412-36.332L144.79,72.426ZM96.495,9.975l19.063,19.08H96.495Zm22.957,149.288H5.743V5.739H90.755V32.085a2.587,2.587,0,0,0,2.69,2.708h26.006V66.949l-28.5,28.517a2.569,2.569,0,0,0-.654,1.058l-4.045,12.162H24.288a2.87,2.87,0,0,0,0,5.739H84.334L82.6,119.663a2.816,2.816,0,0,0,.692,2.9c.2.2.432.467.674.467H24.288a2.87,2.87,0,0,0,0,5.739h76.4a2.87,2.87,0,1,0,0-5.739H86.812l22.559-7.478a2.509,2.509,0,0,0,1.04-.661l9.04-9.112Zm29.392-90.9L137.6,57.116l8.713-8.691,11.228,11.228Zm0,0"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default DocumentIcon;
