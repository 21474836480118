import axios from 'axios';

const countriesApi = async (user,langCode) => {
  try {
    const res = await axios.get(`/api/v1/countries`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        password: process.env.REACT_APP_AUTH_PASS,
        'X-Language': langCode,
        'X-Portal': user?.accountType,

      }
    });

    return res;
  } catch (e) {
    throw e;
  }
};

export default countriesApi;
