/* eslint-disable react-hooks/exhaustive-deps */
import "./ServicesTab.scss";

import React from "react";
import dashboard1Img from "../../../assets/imgs/dashboard/dashboard-1.png";
// import slugMe from "../../../utils/slugMe";
import DocTitleScrollTop from "../../../utils/DocTitleScrollTop";
import ServicesList from "../../../components/services-list/ServicesList";
const ServicesTab = () => {
	DocTitleScrollTop("Services");

	return (
		<div className="services-tab">
			<div className="services-list">
				<ServicesList />
			</div>
			<img src={dashboard1Img} alt="dashboard" />
		</div>
	);
};

export default ServicesTab;
