import "./DashBoardSideMenu.scss";

import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import slugify from "slugify";

const DashBoardSideMenu = ({ links }) => {
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    setActiveTab(slugify(pathname));
    if (
      slugify(pathname) ===
        slugify("/dashboard/user/my-orders/order-details") ||
      slugify(pathname) ===
        slugify("/dashboard/user/my-orders/order-details/chat-with-designer")
    ) {
      setActiveTab(slugify("/dashboard/user/my-orders"));
    }
  }, [pathname]);

  const renderSideMenu = () => {
    return links.map(({ id, name, icon, route, className }) => (
      <RouterLink
        key={id}
        to={route}
        className={`dashboard-tab ${className} ${
          activeTab === slugify(route) ? "active-tab" : ""
        }`}
      >
        {name}
        {icon}
      </RouterLink>
    ));
  };

  return <aside className="dashboard-side-menu">{renderSideMenu()}</aside>;
};

export default DashBoardSideMenu;
