/* eslint-disable react-hooks/exhaustive-deps */
import './OrderDetails.scss';

import React, { useContext, useEffect, useState } from 'react';
import MyOrdersCard from './MyOrdersCard';
import OrderStages from './OrderStages';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UesrContext from '../../../contexts/user-context/UserProvider';
import getSingleOrderApi from '../../../apis/user/getSingleOrderApi';
import { useParams } from 'react-router-dom';
import LangContext from '../../../contexts/lang-context/LangProvider';
import checkRes from '../../../utils/checkRes';
import ContentLoader from 'react-content-loader';
import { getUserOrdersByIdApi } from '../../../apis/user/getMyOrdersApi';
import OrderDetailsSteps from '../orderDetailsSteps/OrderDetailsSteps';
const OrderDetails = () => {
  const { user } = useContext(UesrContext);
  const params = useParams();
  const {
    language: { code, dir }
  } = useContext(LangContext);
  const [singleOrder, setSingleOrder] = useState(null);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const customApiRequest = useCustomApiRequest();
  // useEffect(() => {
  //   let isMounted = true;

  //   if (isMounted && user) {
  //     const fetchCart = () => {
  //       setIsLoadingOrder(true);
  //       customApiRequest(
  //         getSingleOrderApi({ orderId: params?.orderId }, user, code),
  //         (res) => {
  //           setIsLoadingOrder(false);
  //           if (checkRes(res) && res?.data?.data) {
  //             setSingleOrder(res.data.data);
  //           }
  //         },
  //         (error) => {
  //           setIsLoadingOrder(false);
  //         }
  //       );
  //     };
  //     fetchCart();
  //   }

  //   return () => (isMounted = false);
  // }, [code]);
  const [myOrder, setMyOrder] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [completedLevel, setCompletedLevel] = useState(null);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const { orderId } = useParams();

  const getOrderById = async () => {
    setIsLoadingOrders(true);
    const data = await getUserOrdersByIdApi(user, orderId, code);
    setMyOrder(data.data.data);
    const completedLevel = data.data.data.levels.findIndex(
      (item) => item.status === "complete"
    );
    data.data.data.levels.forEach((item) => {
      if(item.status === "complete") {
        setCompletedLevel(item.level_id);
        console.log(item.level_id)
      }
      if(item.status === "in_work") {
        setCurrentLevel(item.level_id)
        setShowConfirmButton(true)
      }
    })
    

    setIsLoadingOrders(false);
  };
  useEffect(() => {
    getOrderById();
  }, [code, orderId]);
  if (isLoadingOrder) {
    return (
      <>
        <div className="service-card-loader-box">
          <ContentLoader
            width="100%"
            height="100%"
            speed={1}
            rtl={dir === 'rtl' ? true : false}
          >
            <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
          </ContentLoader>
        </div>
      </>
    );
  } else if(myOrder){
    return (
      <div className="order-details-wrap">
        <MyOrdersCard withBtn={false}    
              key={myOrder.level_id}
              card={myOrder}
   />
  
        <OrderDetailsSteps myOrder={myOrder} showConfirmButton={false} confirmLevelById={() =>{}} />
  
      </div>
    );
  }
  return null
};

export default OrderDetails;
