import React, { createContext, useReducer } from 'react';
import {
  setUserToStateAction,
  setUserToSessionAction,
  setUser,
  removeUser
} from './user.actions';
import userReducer from './user.reducer';
import Cookies from 'js-cookie';
import { useState } from 'react';

const getUserFromSession = () => {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  } else if (Cookies.get('currentUser')) {
    return JSON.parse(Cookies.get('currentUser'));
  }
  return null;
};

const INITIAL_STATE = {
  fetchUserCount: 0,
  setFetchUserCount: (v) => {},
  loggedIn:
    Cookies.get('currentUser') || sessionStorage.getItem('currentUser')
      ? true
      : false,
  user: getUserFromSession()
};

const contextInitialState = {
  ...INITIAL_STATE,
  setUserToState: (user) => {},
  setUserToSession: (user) => {},
  setCurrentUser: (user) => {},
  removeCurrentUser: () => {}
};

const UesrContext = createContext(contextInitialState);

export const UserProvider = ({ children }) => {
  const [fetchUserCount, setFetchUserCount] = useState(
    INITIAL_STATE?.fetchUserCount
  );
  const [reducerState, dispatch] = useReducer(userReducer, INITIAL_STATE);
  const { user, loggedIn } = reducerState;
  const setUserToState = (stateUser) =>
    dispatch(setUserToStateAction(stateUser));
  const setUserToSession = (sessionUser) =>
    dispatch(setUserToSessionAction(sessionUser));
  const setCurrentUser = (cUser) => dispatch(setUser(cUser));
  const removeCurrentUser = () => dispatch(removeUser());

  return (
    <UesrContext.Provider
      value={{
        fetchUserCount,
        setFetchUserCount,
        loggedIn,
        user,
        setUserToState,
        setUserToSession,
        setCurrentUser,
        removeCurrentUser
      }}
    >
      {children}
    </UesrContext.Provider>
  );
};

export default UesrContext;
