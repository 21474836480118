const settingsLocalization = {
	mainTitle: {
		ar: "المعلومات الشخصية",
		en: "Personal information"
	},
	settingsForm: {
		personalImg: {
			label: {
				ar: "الصورة الشخصية",
				en: "Personal photo"
			},
			errors: {
				required: {
					ar: "ادخل الصورة الشخصية",
					en: "Enter your photo"
				}
			}
		},
		firstname: {
			label: {
				ar: "الاسم الاول",
				en: "Firstname"
			},
			errors: {
				required: {
					ar: "ادخل الاسم الاول",
					en: "Firstname is required"
				},
				minChar: min => ({
					ar: `الاسـم الاول لا يقل عن ${min} حرف`,
					en: `Firstname must be at least ${min} chars`
				}),
				maxChar: max => ({
					ar: `الاسـم الاول لا يزيد عن ${max} حرف`,
					en: `Firstname must be at most ${max} chars`
				}),
				noSpaces: {
					ar: "لا يسمح بوجود فراغات فى الاسم الاول",
					en: "No white spaces allowed in firstname"
				}
			}
		},
		lastname: {
			label: {
				ar: "اســم العائلة",
				en: "Lastname"
			},
			errors: {
				required: {
					ar: "ادخل اسـم العائلة",
					en: "Lastname is required"
				},
				minChar: min => ({
					ar: `اسـم العائلة لا يقل عن ${min} حرف`,
					en: `Lastname must be at least ${min} chars`
				}),
				maxChar: max => ({
					ar: `اسـم العائلة لا يزيد عن ${max} حرف`,
					en: `Lastname must be at most ${max} chars`
				}),
				noSpaces: {
					ar: "لا يسمح بوجود فراغات فى اسـم العائلة",
					en: "No white spaces allowed in lastname"
				}
			}
		},
		country: {
			label: {
				ar: "الدولة",
				en: "Country"
			},
			errors: {
				required: {
					ar: "برجاء اختيار الدولة",
					en: "Please choose the country"
				}
			}
		},
		language: {
			label: {
				ar: "اللغة",
				en: "Language"
			},
			errors: {
				required: {
					ar: "برجاء ادخال اللغة",
					en: "Enter the language"
				}
			}
		},
		gender: {
			label: {
				ar: "النوع",
				en: "Gender"
			},
			errors: {
				required: {
					ar: "برجاء اختيار النوع",
					en: "Please Select a the gender"
				}
			},
			fields: {
				male: {
					ar: "ذكر",
					en: "Male"
				},
				female: {
					ar: "أنثى",
					en: "Female"
				}
			}
		},
		dateOfBirth: {
			label: {
				ar: "تاريخ الميلاد",
				en: "Date of birth"
			},
			errors: {
				required: {
					ar: "برجاء ادخال تاريخ الميلاد",
					en: "Enter date of birth"
				}
			}
		},
		phoneNumber: {
			label: {
				ar: "رقـم الهاتف",
				en: "Phone number"
			},
			errors: {
				required: {
					ar: "برجاء ادخال رقم الهاتف",
					en: "Enter your phone number"
				},
				validNumber: {
					ar: "لا يسمح الا بادخال ارقام ",
					en: "Only numbers accepted"
				},
				minChar: min => ({
					ar: `اقـل حد ${min} أرقـام`,
					en: `At least ${min} digits required`
				}),
				maxChar: max => ({
					ar: `اقصى حد ${max} أرقـام`,
					en: `At Most ${max} digits required`
				})
			}
		},
		email: {
			label: {
				ar: "البريد الالكترونى",
				en: "Email address"
			},
			errors: {
				required: {
					ar: "برجاء ادخال البريد الالكترونى",
					en: "Enter your email address"
				},
				typeError: {
					ar: "برجاء ادخال بريد الكترونى صحيح",
					en: "Provide a vaild email address"
				}
			}
		},
		saveBtn: {
			label: {
				ar: "حفظ",
				en: "Save"
			},
			loading: {
				ar: "جارى الحفظ",
				en: "Saving"
			}
		}
	}
};

export default settingsLocalization;
