/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import ButtonWithLoading from "../button-with-loading/ButtonWithLoading";
import "./ActivationsCodeForm.scss";
import LangContext from '../../contexts/lang-context/LangProvider'
import indexLocalization from "../../localization";
const ActivationCodeForm = ({
  value,
  digits,
  err,
  setErr,
  submitCodeCount,
  onChange,
  isLoadingState,
  submitForm,
  resendCode,
  btnTypes,
  invalid
}) => {
  React.useEffect(() => {
    if (value?.trim().length === 4) {
      setErr({});
    }
  }, [value]);
  React.useEffect(() => {
    return () => {
      onChange("");
    };
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };
  const onResendCode = () => {
    resendCode();
  };
  const {
    language: { code },
  } = useContext(LangContext);

  const { sendCode } = indexLocalization
  return (
    <form onSubmit={onSubmit} className="digits-form">
      <div className={`digits-wrap ${invalid ? "invalid-otp" : ""}`}>
        <input inputMode="decimal" autoFocus {...digits[0]} />
        <input inputMode="decimal" {...digits[1]} />
        <input inputMode="decimal" {...digits[2]} />
        <input inputMode="decimal" {...digits[3]} />
      </div>
      {err && (
        <p style={{ color: "red", textAlign: "center", marginTop: 8 }}>
          {err.message}
        </p>
      )}

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 22,
        }}
      >
        <ButtonWithLoading
          isSubmitting={
            isLoadingState?.type === btnTypes.confirmCode &&
            isLoadingState?.isLoading
          }
          errors={err}
          btnText={sendCode[code]}
          loadingMsg="Please Wait.."
          className="submit-btn"
        // onClick={onsubmit}
        />

        {/* {submitCodeCount > 0 && (
          <ButtonWithLoading
            isSubmitting={
              isLoadingState?.type === btnTypes.resendCode &&
              isLoadingState?.isLoading
            }
            errors={err}
            btnText="إعادة الارسال"
            loadingMsg="Please Wait.."
            className="submit-btn"
            form="activattion-code-form"
            onClick={resendCode}
          />
        )} */}
      </div>
    </form>
  );
};

export default ActivationCodeForm;
