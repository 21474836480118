/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import './ServiceDetails.scss';

import React, { useContext, useEffect, useState } from 'react';
import successCartImg from '../../assets/imgs/cart-sucess.svg';
import LangContext from '../../contexts/lang-context/LangProvider';

import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import UserServicesContext from '../../contexts/services-context/UserServicesProvider';
import { useParams } from 'react-router';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import ContentLoader from 'react-content-loader';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { Link as RouterLink } from 'react-router-dom';
import routerLinks from '../app/routerLinks';
import CartIcon from '../../common/icons/CartIcon';
import Service1234Steps from './Service1234Steps';
import Service5Steps from './Service5Steps';
import useServices from '../../custom-hooks/useServices';
import useServicePrices from '../../custom-hooks/useServicePrices';
import useSubServices from '../../custom-hooks/useSubServices';
import Service6Steps from './Service6Steps';
import Service7Steps from './Service7Steps';
import Service8Steps from './Service8Steps';
const ServiceDetails = () => {
  DocTitleScrollTop('Make Order');
  const {
    language: { code, dir }
  } = useContext(LangContext);

  // handle invalid service ids
  const { serviceId } = useParams();
  const [foundService, setFoundService] = useState(null);
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);

  const [singleOrder, setSingleOrder] = useState(null);
  // get all services

  const { isLoadingServices, allServices } = useServices();
  const { setServicePrices, setSubServices } = useContext(UserServicesContext);
  const { servicePrices } = useServicePrices({
    service_id: serviceId
  });
  const { subServices } = useSubServices();

  useEffect(() => {
    setServicePrices(servicePrices);
  }, [servicePrices]);
  useEffect(() => {
    setSubServices(subServices);
  }, [subServices]);

  useEffect(() => {
    if (allServices?.length > 0) {
      const service = allServices.find((serv) => String(serv.id) == serviceId);
      if (service) {
        setFoundService(service);
      } else {
        setFoundService(null);
      }
    }
  }, [allServices, serviceId]);

  if (isLoadingServices) {
    return (
      <div className="service-card-loader-box">
        <ContentLoader
          width="100%"
          height="100%"
          speed={1}
          rtl={dir == 'rtl' ? true : false}
        >
          <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
        </ContentLoader>
      </div>
    );
  }
  if (!foundService && !isLoadingServices) {
    return <NotFoundPage />;
  }

  return (
    <>
      <div className="service-details-page">
        <div className="steps-wrapper">
          <h1 style={{ marginBottom: 32, textAlign: 'center' }}>
            {code == 'ar' && `مرحبا بك فى خدمة ${foundService.title}`}
            {code == 'en' && `Welcome to Service ${foundService.title}`}
          </h1>
          {(serviceId == '1' ||
            serviceId == '2' ||
            serviceId == '3' ||
            serviceId == '4') && (
            <Service1234Steps
              foundService={foundService}
              serviceId={serviceId}
              setIsSubmittingOrder={setIsSubmittingOrder}
              setSuccessOrder={setSuccessOrder}
              setSingleOrder={setSingleOrder}
            />
          )}
          {serviceId == '5' && (
            <Service5Steps
              foundService={foundService}
              serviceId={serviceId}
              setIsSubmittingOrder={setIsSubmittingOrder}
              setSuccessOrder={setSuccessOrder}
              setSingleOrder={setSingleOrder}
            />
          )}

          {serviceId == '6' && (
            <Service6Steps
              foundService={foundService}
              serviceId={serviceId}
              setIsSubmittingOrder={setIsSubmittingOrder}
              setSuccessOrder={setSuccessOrder}
              setSingleOrder={setSingleOrder}
            />
          )}

          {serviceId == '7' && (
            <Service7Steps
              foundService={foundService}
              serviceId={serviceId}
              setIsSubmittingOrder={setIsSubmittingOrder}
              setSuccessOrder={setSuccessOrder}
              setSingleOrder={setSingleOrder}
            />
          )}

          {serviceId == '8' && (
            <Service8Steps
              foundService={foundService}
              serviceId={serviceId}
              setIsSubmittingOrder={setIsSubmittingOrder}
              setSuccessOrder={setSuccessOrder}
              setSingleOrder={setSingleOrder}
            />
          )}
        </div>
      </div>

      {isSubmittingOrder && <LoadingModal />}

      {/* sucess modal */}
      {successOrder && (
        <LoadingModal clsName="success-order-modal">
          <img src={successCartImg} alt="sucess order" />

          {code == 'en' && <p>Your order added to cart successfully</p>}
          {code == 'ar' && <p>تمت اضافة طلبك للسلة بنجاح</p>}
          <RouterLink
            className="cart-link"
            // to={routerLinks.userCart}
            to={{
              pathname: routerLinks.userCart,
              // search: "?sort=name",
              // hash: "#the-hash",
              state: { orderId: singleOrder?.id }
            }}
          >
            {code == 'en' && <span>Cart</span>}
            {code == 'ar' && <span>سلة الشراء</span>}
            <CartIcon />
          </RouterLink>
        </LoadingModal>
      )}
    </>
  );
};

export default ServiceDetails;
