import axios from 'axios';

const citiesApi = async (filterValues, langCode,user) => {
  try {
    const res = await axios.get(
      `/api/v1/cities?country_id=${filterValues?.countryId || ''}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode,
          'X-Portal': user?.accountType

        }
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};

export default citiesApi;
