/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import getMyOrdersApi from '../../../apis/user/getMyOrdersApi';
import LangContext from '../../../contexts/lang-context/LangProvider';
import UesrContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import DocTitleScrollTop from '../../../utils/DocTitleScrollTop';
import './MyOrders.scss';
import MyOrdersCard from './MyOrdersCard';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { userOrderStatusArr } from '../../../orderStatus';
import { Link as RouterLink, useLocation } from "react-router-dom";
import userSideMenuLocalization from '../../../localization/userSideMenu.localization';

const MyOrders = () => {
  DocTitleScrollTop('My requests');
  const { user } = useContext(UesrContext);
  const {
    language: { code, dir }
  } = useContext(LangContext);

  const {status} =useParams()

  const {noOrder} = userSideMenuLocalization
 
  const [myOrders, setMyOrders] = useState(null);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) {
      const fetchCart = () => {
        setIsLoadingOrders(true);
        customApiRequest(
          getMyOrdersApi({ status: status }, user, code),
          (res) => {
            setIsLoadingOrders(false);
            if (checkRes(res) && res?.data?.data) {
              setMyOrders(res.data.data);
            }
          },
          (error) => {
            setIsLoadingOrders(false);
          }
        );
      };
      fetchCart();
    }

    return () => (isMounted = false);
  }, [code,status]);

  const renderContentFilter = () => {
    return (
      <div className="content-filter">
        {userOrderStatusArr()?.length > 0 &&
          userOrderStatusArr().map((obj) => {
            return (
              <RouterLink
                className={`filter-link ${
                 status == obj?.value ? "active" : ""
                }`}
                key={obj?.id}
                to={`/dashboard/user/my-orders/${obj.value}`}
              >
                {obj?.name[code] || ""}
              </RouterLink>
            );
          })}
      </div>
    );
  };


  if (isLoadingOrders) {
    return (
      <>
        <div className="service-card-loader-box">
          <ContentLoader
            width="100%"
            height="100%"
            speed={1}
            rtl={dir === 'rtl' ? true : false}
          >
            <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
          </ContentLoader>
        </div>
      </>
    );
  } else if (myOrders?.length > 0)
   { return (
      <div className="my-orders-tab">
        <div className=''>
        {renderContentFilter()}

        </div>
        {myOrders.map((order) => {
          return <MyOrdersCard hideCancel={status === "accepted" || status === "completed"} key={order?.id} card={order} withBtn={true} />;
        })}
      </div>
    );
  } else if (myOrders?.length === 0) {
    return (
      <div className="not-found-filter-wrapper">
        {renderContentFilter()}
        <div className="empty-wrapper">
          <h2>{noOrder[code]}</h2>
        </div>
      </div>
    );
  }

  return null;
};

export default MyOrders;
