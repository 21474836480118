import axios from "axios";

const newMessageApi = async (user, body) => {
    try {
        const func = await axios.post(
            `/api/v1/${user.accountType}/newMessage`,
            body,
            {
                headers: {
                    password: process.env.REACT_APP_AUTH_PASS,
                    "X-Portal": user?.accountType,

                }
            }
        );

        return func;
    } catch (e) {
        throw e;
    }
};

export const newMessageOldApi = async (user, body) => {
    try {
        const func = await axios.post(
            `/api/v1/${user.accountType}/new-message`,
            body,
            {
                headers: {
                    password: process.env.REACT_APP_AUTH_PASS,
                    "X-Portal": user?.accountType,

                }
            }
        );

        return func;
    } catch (e) {
        throw e;
    }
};
export default newMessageApi