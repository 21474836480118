import React, { useContext } from "react";
import UserContext from "../contexts/user-context/UserProvider";
import { useHistory } from "react-router-dom";
import routerLinks from "../components/app/routerLinks";
import useCustomApiRequest from "./useCustomApiRequest";
import signoutApi from "../apis/auth/signout";
import checkRes from "../utils/checkRes";
import LangContext from "../contexts/lang-context/LangProvider";
import FlashMessageContext from "../contexts/flash-messages-context/FlashMessageProvider";
import DeviceIdContext from "../contexts/device-id-context/DeviceIdContext";

const useSignout = () => {
  const history = useHistory();
  const { language } = useContext(LangContext);
  const { user, removeCurrentUser } = useContext(UserContext);
  const { deviceId } = useContext(DeviceIdContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignout, setIsLoadingSignout] = React.useState(false);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);

  const signMeOut = () => {
    setIsLoadingSignout(true);
    const formData = new FormData();
    formData.append("api_token", user?.api_token);
    formData.append("device_id", deviceId);
    customApiRequest(
      signoutApi(user?.accountType, formData, language?.code),
      (res) => {
        setIsLoadingSignout(false);
        if (checkRes(res)) {
          removeCurrentUser();
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || "تم تسجيل الخروج بنجاح");
          setFlashMsgDuration(4000);
          setNotificationSeverity("success");
          //
          history.push(routerLinks?.signinPage);
        } else {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || "من فضلك حاول فى وقت لاحق");
          setFlashMsgDuration(4000);
          setNotificationSeverity("error");
        }
      },

      (error) => {
        setIsLoadingSignout(false);
        setFlashMsgIsOpen(true);
        setFlashMsg(error?.response?.data?.message || "من فضلك حاول وقت لاحق");
        setFlashMsgDuration(4000);
        setNotificationSeverity("error");
      }
    );
  };

  return { signMeOut, isLoadingSignout, setIsLoadingSignout };
};

export default useSignout;
