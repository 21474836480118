import axios from 'axios';
import { signup } from './signup';

export const signinWithEmail = async (values, langCode) => {
  try {
    const response = await axios.post('/api/v1/login', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
        password: process.env.REACT_APP_AUTH_PASS,
        'X-Language': langCode
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};

// user signin with google
export const userSigninWithGoogle = async (
  googleResponse,
  langCode,
  correctUserType,
  incorrectUserType,
  successSignup,
  failureSignup,
  //
  setSocialModalOpened,
  userType
) => {
  try {
    if (googleResponse) {
      const isSignIn = window.location.pathname.includes('signin')
      if (isSignIn) {
        setSocialModalOpened(true);
        const values = {
          email: googleResponse.email,
          password: null,
          social: 1,
          socialKey: googleResponse.sub,
          type: userType
        };
        const formData = new FormData();
        formData.append('type', userType);
        formData.append('social_id', values.socialKey);
        const signinResponse = await signinWithEmail(formData, langCode);
        if (signinResponse?.data?.status === 1 && signinResponse?.data?.data) {
            setSocialModalOpened(false);
            correctUserType({
              ...signinResponse.data.data,
              accountType:userType,
              activated:true
            });
        } else {
          setSocialModalOpened(false);
          incorrectUserType(signinResponse);

        }
      } else {
        setSocialModalOpened(true)
        const values = {
          firstname: googleResponse.givenName,
          lastname: googleResponse.familyName,
          email: googleResponse.email,
          password: null,
          confirmPassword: null,
          social: 1,
          socialKey: googleResponse.sub,
          userType: 1, // here i set the userType to one as iam registering an account of type user,
          type:userType

        };
        const formData = new FormData();
        formData.append('social_id', values.socialKey);
        formData.append('social_type', 'google');
        formData.append('type', userType);
        formData.append('email', values.email);
        const signupResponse = await signup(formData, langCode);
        if (signupResponse?.data?.status === 1 ) {
          // user registered successfully
          setSocialModalOpened(false);
          successSignup(signupResponse);
        } else if (
          signupResponse?.data?.status === 0 ) {
          setSocialModalOpened(false);
          failureSignup(signupResponse);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};
///////////////////////////
///////////////////////////
///////////////////////////
// designer signin with google
export const designerSigninWithGoogle = async (
  googleResponse,
  langCode,
  correctUserType,
  incorrectUserType,
  successSignup,
  failureSignup,
  //
  setSocialModalOpened
) => {
  try {
    console.log('google result: ', googleResponse);
    if (googleResponse?.profileObj) {
      const values = {
        email: googleResponse.profileObj.email,
        password: null,
        social: 1,
        socialKey: googleResponse.profileObj.googleId
      };
      // open social modal
      setSocialModalOpened(true);
      // try to signin with email
      const signinResponse = await signinWithEmail(values, langCode);
      if (signinResponse?.data?.status === 1 && signinResponse?.data?.data) {
        if (signinResponse.data.data.userType === 2) {
          // userType = 1 <=== user account
          // userType = 2 <=== employee account
          setSocialModalOpened(false);
          correctUserType(signinResponse);
        } else {
          setSocialModalOpened(false);
          incorrectUserType(signinResponse);
        }
      } else if (
        signinResponse?.data?.status === 0 &&
        !signinResponse?.data.data
      ) {
        // if the user is not exist in the database
        // try to register the user
        // call the signup function
        const values = {
          firstname: googleResponse.profileObj.givenName,
          lastname: googleResponse.profileObj.familyName,
          email: googleResponse.profileObj.email,
          password: null,
          confirmPassword: null,
          social: 1,
          socialKey: googleResponse.profileObj.googleId,
          userType: 2 // here i set the userType to 2 as iam registering an account of type designer
        };
        const signupResponse = await signup(values, langCode);
        if (signupResponse?.status === 200 && signupResponse?.data?.data) {
          // user registered successfully
          setSocialModalOpened(false);
          successSignup(signupResponse);
        } else if (
          signupResponse?.data?.status === 0 &&
          !signupResponse?.data.data
        ) {
          setSocialModalOpened(false);
          failureSignup(signupResponse);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};
///////////////////////////
///////////////////////////
///////////////////////////
// user signin with facebook
export const userSigninWithFacebook = async (
  facebookResponse,
  langCode,
  correctUserType,
  incorrectUserType,
  successSignup,
  failureSignup,
  //
  setSocialModalOpened
) => {
  try {
    if (facebookResponse) {
      const values = {
        email: facebookResponse.email,
        password: null,
        social: 1,
        socialKey: facebookResponse.userID
      };
      // open social modal
      setSocialModalOpened(true);
      // try to signin with email
      const signinResponse = await signinWithEmail(values, langCode);
      if (signinResponse?.data?.status === 1 && signinResponse?.data?.data) {
        if (signinResponse.data.data.userType === 1) {
          // userType = 1 <=== user account
          // userType = 2 <=== employee account
          setSocialModalOpened(false);
          correctUserType(signinResponse);
        } else {
          setSocialModalOpened(false);
          incorrectUserType(signinResponse);
        }
      } else if (
        signinResponse?.data?.status === 0 &&
        !signinResponse?.data.data
      ) {
        // if the user is not exist in the database
        // try to register the user
        // call the signup function
        const values = {
          firstname: facebookResponse.first_name,
          lastname: facebookResponse.last_name,
          email: facebookResponse.email,
          password: null,
          confirmPassword: null,
          social: 1,
          socialKey: facebookResponse.userID,
          userType: 1 // here i set the userType to one as iam registering an account of type user
        };
        const signupResponse = await signup(values, langCode);
        if (signupResponse?.status === 200 && signupResponse?.data?.data) {
          // user registered successfully
          setSocialModalOpened(false);
          successSignup(signupResponse);
        } else if (
          signupResponse?.data?.status === 0 &&
          !signupResponse?.data.data
        ) {
          setSocialModalOpened(false);
          failureSignup(signupResponse);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};
///////////////////////////
///////////////////////////
///////////////////////////
// designer sign in with facebook
export const designerSigninWithFacebook = async (
  facebookResponse,
  langCode,
  correctUserType,
  incorrectUserType,
  successSignup,
  failureSignup,
  //
  setSocialModalOpened
) => {
  try {
    if (facebookResponse) {
      const values = {
        email: facebookResponse.email,
        password: null,
        social: 1,
        socialKey: facebookResponse.userID
      };
      // open social modal
      setSocialModalOpened(true);
      // try to signin with email
      const signinResponse = await signinWithEmail(values, langCode);
      if (signinResponse?.data?.status === 1 && signinResponse?.data?.data) {
        if (signinResponse.data.data.userType === 2) {
          // userType = 1 <=== user account
          // userType = 2 <=== employee account
          setSocialModalOpened(false);
          correctUserType(signinResponse);
        } else {
          setSocialModalOpened(false);
          incorrectUserType(signinResponse);
        }
      } else if (
        signinResponse?.data?.status === 0 &&
        !signinResponse?.data.data
      ) {
        // if the user is not exist in the database
        // try to register the user
        // call the signup function
        const values = {
          firstname: facebookResponse.firstname,
          lastname: facebookResponse.lastname,
          email: facebookResponse.email,
          password: null,
          confirmPassword: null,
          social: 1,
          socialKey: facebookResponse.userID,
          userType: 2 // here i set the userType to 2 as iam registering an account of type designer
        };
        const signupResponse = await signup(values, langCode);
        if (signupResponse?.status === 200 && signupResponse?.data?.data) {
          // user registered successfully
          setSocialModalOpened(false);
          successSignup(signupResponse);
        } else if (
          signupResponse?.data?.status === 0 &&
          !signupResponse?.data.data
        ) {
          setSocialModalOpened(false);
          failureSignup(signupResponse);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};
