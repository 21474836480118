import { useContext, useRef, useState } from "react"
import TextField from '@material-ui/core/TextField';
import { IconButton, Paper } from "@material-ui/core";
import BackupIcon from '@material-ui/icons/Backup';
import "./DesignerMessages.scss";
import SendIcon from '@material-ui/icons/Send';
import UesrContext from "../../../contexts/user-context/UserProvider";
import newMessageApi from "../../../apis/conversations/newMessage";
import LangContext from "../../../contexts/lang-context/LangProvider";
import indexLocalization from "../../../localization";
import loadingImage from '../../../assets/imgs/icons/loading1.gif'

const NewMessage = ({ conversationId, getMessage }) => {
    const { user } = useContext(UesrContext);
    const [value, setValue] = useState('')
    const [files, setFiles] = useState('')
    const [images, setImages] = useState('')
    const {
        language: { dir, code },
    } = useContext(LangContext);
    const { selectedFiles ,selectedMedia } = indexLocalization
    const [isLoading, setIsLoading] = useState(false)

    const uploadInput = useRef()
    const openUploadFiles = () => {
        uploadInput.current.click()
    }
    const handleUploadInput = (event) => {
        const files = event.target.files;
            console.log(files.length)
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith('image/')) {
                // setImages(prevImages => [...prevImages, file]);
                setImages(file)
            } else {
                // setFiles(prevValue => [...prevValue, file]);
                setFiles(file)

            }

        }
    }

    const sendNewMessage = async () => {
        if (!value && !images && !files) {
            return
        }
        setIsLoading(true)
        const formData = new FormData();
        formData.append("api_token", user.api_token);
        formData.append("conversation_id", conversationId);
        formData.append("message", value);
        // images.forEach((image, index) => {
        //     formData.append(`image${index}`, image);
        // });
        // files.forEach((file, index) => {
        //     formData.append(`file${index}`, file);
        // });

        formData.append("image", images);
        formData.append("file", files);
        const data = await newMessageApi(user, formData)
        setIsLoading(false)
        getMessage()
        setValue('')
        setFiles('')
        setImages('')
    }
    console.log(files)
    return (
        <div className="newMessage-container">
            <Paper elevation={3} className="p-4 flex-column" >
                <div className="flex-between ">
                    <input type="file" style={{ display: 'none' }} ref={uploadInput} onChange={handleUploadInput} multiple />
                    <TextField
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={4}
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        variant="outlined"
                        className='newMessage-input'
                    />
                    <IconButton aria-label="upload" className="iconButton" onClick={openUploadFiles}>
                        <BackupIcon />
                    </IconButton>
                    <IconButton aria-label="send" className="iconButton rotate-180" onClick={sendNewMessage}>
                    {!isLoading ? <SendIcon /> : <img src={loadingImage} width={25} height={25} alt="loading" />}
                    </IconButton>
                </div>
                {files?.name ? <span>{selectedFiles[code]} {files?.name}</span>:""}
               {images?.name ? <span>{selectedMedia[code]} {images?.name}</span>:""}

            </Paper>
        </div>
    )
}

export default NewMessage