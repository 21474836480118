import React from "react";

const FemaleIcon = ({ color = "#000000" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13.984"
			height="29.831"
			viewBox="0 0 13.984 29.831"
		>
			<path
				id="Icon_awesome-female"
				data-name="Icon awesome-female"
				d="M7.554,0A3.729,3.729,0,1,1,3.825,3.729,3.729,3.729,0,0,1,7.554,0M14.5,20.635l-2.8-11.186A1.4,1.4,0,0,0,10.351,8.39H9.689a5.12,5.12,0,0,1-4.269,0H4.757A1.4,1.4,0,0,0,3.4,9.449L.6,20.635a1.4,1.4,0,0,0,1.357,1.737H5.223v6.059a1.4,1.4,0,0,0,1.4,1.4H8.486a1.4,1.4,0,0,0,1.4-1.4V22.373h3.263A1.4,1.4,0,0,0,14.5,20.635Z"
				transform="translate(-0.562)"
				fill={color}
			/>
		</svg>
	);
};

export default FemaleIcon;
