import { Redirect, Route } from "react-router-dom";
import AppLayout from "../components/layout/Layout";
import routerLinks from "../components/app/routerLinks";
const protectMe = (
  path,
  component,
  key,
  redirectRoute,
  loggedIn,
  user,
  exact = true
) => {
  const renderComp = () => {
    // if (loggedIn && !user?.activated) {
    //   return <ActivateAccountPage />;
    // }

    if (loggedIn && user?.activated) {
      // return <AppLayout>{component}</AppLayout>;
      return component;
    } else if (!loggedIn) {
      return (
        <Redirect to={redirectRoute ? redirectRoute : routerLinks.signupPage} />
      );
    } else return "null";
  };

  return (
    <Route exact={exact} path={path} key={key}>
      {renderComp()}

      {/* {loggedIn ? (
        component
      ) : (
        <Redirect to={redirectRoute ? redirectRoute : routerLinks.signupPage} />
      )} */}
    </Route>
  );
};

export default protectMe;
