import React, { useCallback, useContext, useEffect, useState } from "react";
import NotificationListItem from "../../../common/notification-list-item/NotificationListItem";
import designerAvatar from "../../../assets/imgs/designer-avatar.png";
import UesrContext from "../../../contexts/user-context/UserProvider";
import LangContext from "../../../contexts/lang-context/LangProvider";
import ContentLoader from "react-content-loader";
import { getSuperNotifications } from "../../../apis/supervisor-apis/supervisorOrdersApi";
import { acceptEmployeeJob, getEmployeeNotifications, rejectEmployeeJob } from "../../../apis/employee/employeeApi";
import indexLocalization from "../../../localization";
import FlashMessageContext from "../../../contexts/flash-messages-context/FlashMessageProvider";

const DesignerNotifications = ({notify,setNotify}) => {
  const { user } = useContext(UesrContext);
  const {
    language: { code, dir },
  } = useContext(LangContext);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity
  } = useContext(FlashMessageContext);

  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const { noNotifications } = indexLocalization;

  const getNotification = useCallback(async () => {
    setIsLoadingOrders(true);
    let data;

    console.log(user.accountType)
    if (user.accountType === "employee") {
      
      data = await getEmployeeNotifications(user);
    } else {
      data = await getSuperNotifications(user);

    }
    setNotifications(data.data.data);
    setIsLoadingOrders(false);
  }, [user]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  useEffect(() => {

    if(notify) {
      getNotification();
      setNotify(false)
    }
  },[notify])
  const handleAcceptJob = useCallback(async (user,id,serviceId,code) => {
    setIsLoadingOrders(true);

    const data = await acceptEmployeeJob(user,id,serviceId,code)
    setFlashMsgIsOpen(true);
    setFlashMsg(data?.data?.message);
    setFlashMsgDuration(4000);
    setNotificationSeverity('success');
    getNotification()
    setIsLoadingOrders(false);

  },[])

  const handleRejectJob = useCallback(async (user,id,serviceId,code) => {
    setIsLoadingOrders(true);
    const data = await rejectEmployeeJob(user,id,serviceId,code)
    setFlashMsgIsOpen(true);
    setFlashMsg(data?.data?.message);
    setFlashMsgDuration(4000);
    setNotificationSeverity('success');
    getNotification()
    setIsLoadingOrders(false);

  },[])


  

  if (isLoadingOrders) {
    <div className="service-card-loader-box">
      <ContentLoader
        width="100%"
        height="100%"
        speed={1}
        rtl={dir === "rtl" ? true : false}
      >
        <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
      </ContentLoader>
    </div>;
  } else if (notifications.length) {
    return (
      <div className="designer-notifications">
        {notifications.map((item) => (
          <NotificationListItem
            avatar={item.image || designerAvatar}
            title={item.title}
            desc={item.content}
            date={item.created_at}
            serviceName={item.service_name}
            subService = {item.sub_service_name}
            key={item.id}
            actions ={(user.accountType ==="employee" && item.action ==="new_order") ? true : false}
            acceptJob={() => handleAcceptJob(user,item?.action_id,item?.sub_service_id,code)}
            rejectJob={() => handleRejectJob(user,item?.action_id,item?.sub_service_id,code)}

          />
        ))}
      
      </div>
    );
  } else {
   return (
    <div>
    {
      notifications.length === 0  && !isLoadingOrders &&<div class="empty-wrapper">
      <h2>{noNotifications[code]}</h2>
    </div>
    }
    </div>
    )
  }
  return null;
};

export default DesignerNotifications;
