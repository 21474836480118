/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "./App.styles.scss";
import React, { Suspense, useContext, useEffect } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { create } from "jss";
import { ThemeProvider, StylesProvider, jssPreset } from "@material-ui/core";
import rtl from "jss-rtl";
import theme from "../../theme";
// import { MainAppBarProvider } from "../../contexts/main-app-bar-context/MainAppBarProvider";
import Loading from "../../common/loading/Loading";
import LangContext from "../../contexts/lang-context/LangProvider";
import Routes from "./Routes";
import axios from "axios";
import NotificationBar from "../../common/NotificationBar";
import FlashMessageContext from "../../contexts/flash-messages-context/FlashMessageProvider";
import { CropperProvider } from "../../contexts/cropper-context/CropperProvider";
import { UserServicesProvider } from "../../contexts/services-context/UserServicesProvider";
import AppLayout from "../layout/Layout";
import { DeviceIdProvider } from "../../contexts/device-id-context/DeviceIdContext";
import UesrContext from "../../contexts/user-context/UserProvider";
import DeviceIdContext from "../../contexts/device-id-context/DeviceIdContext";
import useCustomApiRequest from "../../custom-hooks/useCustomApiRequest";
import registerTokenApi from "../../apis/auth/registerTokenApi";
import UserCartContext from "../../contexts/user-cart-context/UserCartContext";
import getUserCartApi from "../../apis/user/getUserCartAPi";
import checkRes from "../../utils/checkRes";
import userTypes from "../../constants/userTypes";
import profileApi from "../../apis/auth/profileApi";
import { useHistory } from "react-router-dom";
import routerLinks from "./routerLinks";
// import LoadingModal from "../../common/loading-modal/LoadingModal";
// import NotificationBar from "../../common/NotificationBar";
// import AddEditPrevWork from '../user-profile/AddEditPrevWork';
// import FirebaseContext from '../../contexts/firebase-context/FirebaseProvider';
// axios.defaults.baseURL = "http://video.videotubevideo.com/VideoPanel";
axios.defaults.baseURL = process.env.REACT_APP_BACK_END_URL;

function App() {
  const history = useHistory();
  // const { setFirebaseIdToken } = useContext(FirebaseContext);
  const {
    flashMsgIsOpen,
    flashMsg,
    flashMsgDuration,
    notificationSeverity,
    handleFlashMsgClose,
    //
    // setFlashMsgIsOpen,
    // setFlashMsg,
    // setFlashMsgDuration,
    // setNotificationSeverity
  } = useContext(FlashMessageContext);
  const {
    language,
    language: { dir, code },
  } = useContext(LangContext);

  const { setIsLoadingCart, setUserCart, fetchCartCount } =
    useContext(UserCartContext);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && user?.accountType == userTypes?.client) {
      const fetchCart = () => {
        setIsLoadingCart(true);
        customApiRequest(
          getUserCartApi(user, code),
          (res) => {
            setIsLoadingCart(false);
            if (checkRes(res) && res?.data?.data) {
              setUserCart(res.data.data);
            } else {
              setUserCart(null);
            }
          },
          (error) => {
            setIsLoadingCart(false);
          }
        );
      };
      fetchCart();
    }

    return () => (isMounted = false);
  }, [fetchCartCount, code]);

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const { fetchUserCount, user, removeCurrentUser, setCurrentUser } =
    useContext(UesrContext);
  const { deviceId } = useContext(DeviceIdContext);

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    if (user && isMounted) {
      customApiRequest(
        profileApi(user, code),
        (res) => {
          if (checkRes(res)) {
            setCurrentUser({
              ...res?.data?.data,
              api_token: user?.api_token,
              accountType: user.accountType,
              activated: user.activated,
            });
          } else {
            removeCurrentUser();
            history.push(routerLinks?.signinPage);
          }
        },
        (error) => {}
      );
    }
    return () => {
      isMounted = false;
    };
  }, [fetchUserCount]);

  // useEffect(() => {
  //   if (user && user?.activated) {
  //     const formData = new FormData();
  //     formData.append("api_token", user?.api_token);
  //     formData.append("device_id", deviceId);
  //     formData.append("token", "FCM TOKEN");
  //     formData.append("type", "android");
  //     customApiRequest(
  //       registerTokenApi(formData, user?.accountType, language?.code),
  //       (res) => {},
  //       (error) => {}
  //     );
  //   }
  // }, []);

  return (
    <div className={`app app-${language.dir}`}>
      {/* <ThemeProvider theme={theme}> */}
      {/* <ForgetPasswordProvider> */}
      <CropperProvider>
        <ThemeProvider theme={theme(dir)}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <StylesProvider jss={jss}>
              <Suspense fallback={<Loading />}>
                <UserServicesProvider>
                  {/* <MyAppBar /> */}
                  {/* <Switch> */}
                  <DeviceIdProvider>
                    <AppLayout>
                      <Routes />
                    </AppLayout>
                  </DeviceIdProvider>
                  {/* </Switch> */}
                  {/* <MainAppFooter /> */}
                </UserServicesProvider>
              </Suspense>

              {/* Flash messages */}
              <NotificationBar
                flashMsgIsOpen={flashMsgIsOpen}
                flashMsg={flashMsg}
                flashMsgDuration={flashMsgDuration}
                notificationSeverity={notificationSeverity}
                handleFlashMsgClose={handleFlashMsgClose}
              />
            </StylesProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </CropperProvider>
      {/* </ForgetPasswordProvider> */}
    </div>
  );
}

export default App;
