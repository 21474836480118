import React from "react";

const HomeIcon = ({ color = "#000" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="27.399"
			height="21.306"
			viewBox="0 0 27.399 21.306"
		>
			<path
				id="Icon_awesome-home"
				data-name="Icon awesome-home"
				d="M13.335,7.781,4.566,15v7.8a.761.761,0,0,0,.761.761l5.33-.014a.761.761,0,0,0,.757-.761V18.232a.761.761,0,0,1,.761-.761h3.044a.761.761,0,0,1,.761.761v4.549a.761.761,0,0,0,.761.763l5.328.015a.761.761,0,0,0,.761-.761V15L14.063,7.781A.58.58,0,0,0,13.335,7.781ZM27.187,12.69,23.21,9.412V2.824a.571.571,0,0,0-.571-.571H19.976a.571.571,0,0,0-.571.571V6.278l-4.258-3.5a2.283,2.283,0,0,0-2.9,0L.206,12.69a.571.571,0,0,0-.076.8l1.213,1.474a.571.571,0,0,0,.8.078L13.335,5.831a.58.58,0,0,1,.728,0l11.188,9.215a.571.571,0,0,0,.8-.076L27.268,13.5a.571.571,0,0,0-.081-.805Z"
				transform="translate(0.001 -2.254)"
				fill={color}
			/>
		</svg>
	);
};

export default HomeIcon;
