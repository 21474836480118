import "./HomeAppBar.scss";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import SharedBtn from "../../common/shared-btn/SharedBtn";
import logoImg from "../../assets/imgs/logo/logo.png";
import lightBulb from "../../assets/imgs/icons/light-bulb.svg";
import LangSelect from "../../common/lang-select/LangSelect";
import LangContext from "../../contexts/lang-context/LangProvider";
import homeAppBarTranslations from "../../localization/homeAppBar";
import CustomAppBar from "../../common/custom-app-bar/CustomAppBar";
import routerLinks from "../app/routerLinks";
import UesrContext from "../../contexts/user-context/UserProvider";
import MainAppBarLocalization from "../../localization/mainAppBarLocalization";

import arrowLeft from "../../assets/imgs/icons/arrows/arrow-left-white.svg";
import arrowRight from "../../assets/imgs/icons/arrows/arrow-right-white.svg";
import useSignout from "../../custom-hooks/useSignout";

const HomeAppBar = () => {
  const {
    language,
    language: { dir },
  } = useContext(LangContext);
  const { loggedIn } = useContext(UesrContext);
  const { signMeOut, isLoadingSignout } = useSignout();
  const handleSignout = async () => {
    signMeOut();
  };

  return (
    <CustomAppBar customClass="home-app-bar">
      <div className="mfa-container">
        <div className="home-app-bar-wrapper">
          <RouterLink className="logo-link" to="/">
            <img src={logoImg} alt="video tube logo" />
          </RouterLink>

          <div className="lang-signup-links">
            <LangSelect />

            {loggedIn ? (
              <SharedBtn
                buttonComp={true}
                btnClass="signout-link"
                onClick={handleSignout}
              >
                <span>{MainAppBarLocalization.signoutBtn[language.code]}</span>
                <img
                  src={dir === "rtl" ? arrowLeft : arrowRight}
                  alt="arrow icon"
                />
              </SharedBtn>
            ) : (
              <div className="signin-signup-links">
                <RouterLink className="signin-link" to="/signin/user">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // width="26.191"
                    // height="22.917"
                    width="20"
                    viewBox="0 0 26.191 22.917"
                  >
                    <path
                      id="Icon_open-account-login"
                      data-name="Icon open-account-login"
                      d="M9.822,0V3.274h13.1v16.37H9.822v3.274h16.37V0ZM13.1,6.548V9.822H0V13.1H13.1V16.37l6.548-4.911Z"
                      fill="#b2b2b2"
                    />
                  </svg>
                  {homeAppBarTranslations.signin[language.code]}
                </RouterLink>
                <span className="or-span">
                  {homeAppBarTranslations.or[language.code]}
                </span>
                <SharedBtn to={routerLinks.userSignup} btnClass="signup-link">
                  <span>{homeAppBarTranslations.signup[language.code]}</span>
                  <img src={lightBulb} alt="light bulb icon" />
                </SharedBtn>
              </div>
            )}
          </div>
        </div>
      </div>
    </CustomAppBar>
  );
};

export default HomeAppBar;
