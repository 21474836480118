import { useState } from "react";
import { createContext } from "react";

const INITIAL_VALUES = {
  isLoadingData: false,
  setIsLoadingData: (v) => {},
  fetchedDataCount: 0,
  setFetchedDataCount: (v) => {},
  fetchedData: null,
  setFetchedData: (v) => {},
  //
  selectedOrder: null,
  setSelectedOrder: (v) => {},
  //
  acceptOrderModalOpened: false,
  setAcceptOrderModalOpened: (v) => {},
  rejectOrderModalOpened: false,
  setRejectOrderModalOpened: (v) => {},
  isSubmitting: false,
  setIsSubmitting: (v) => {},
};

const EmployeeOrdersContext = createContext(INITIAL_VALUES);

export const EmployeesOrdersProvider = ({ children }) => {
  const [isLoadingData, setIsLoadingData] = useState(
    INITIAL_VALUES.isLoadingData
  );
  const [fetchedDataCount, setFetchOrdersCount] = useState(
    INITIAL_VALUES.fetchedDataCount
  );
  const [fetchedData, setFetchedData] = useState(INITIAL_VALUES.fetchedData);
  const [selectedOrder, setSelectedOrder] = useState(
    INITIAL_VALUES.selectedOrder
  );
  const [acceptOrderModalOpened, setAcceptOrderModalOpened] = useState(
    INITIAL_VALUES.acceptOrderModalOpened
  );
  const [rejectOrderModalOpened, setRejectOrderModalOpened] = useState(
    INITIAL_VALUES.rejectOrderModalOpened
  );
  const [isSubmitting, setIsSubmitting] = useState(INITIAL_VALUES.isSubmitting);

  return (
    <EmployeeOrdersContext.Provider
      value={{
        isLoadingData,
        setIsLoadingData,
        fetchedDataCount,
        setFetchOrdersCount,
        fetchedData,
        setFetchedData,
        selectedOrder,
        setSelectedOrder,
        acceptOrderModalOpened,
        setAcceptOrderModalOpened,
        rejectOrderModalOpened,
        setRejectOrderModalOpened,
        isSubmitting,
        setIsSubmitting,
      }}
    >
      {children}
    </EmployeeOrdersContext.Provider>
  );
};

export default EmployeeOrdersContext;
