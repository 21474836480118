/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import getServicePricesApi from "../apis/user/getServicePricesApi";
import LangContext from "../contexts/lang-context/LangProvider";
import UesrContext from "../contexts/user-context/UserProvider";
import checkRes from "../utils/checkRes";
import useCustomApiRequest from "./useCustomApiRequest";

const useServicePrices = (values) => {
  const { user } = useContext(UesrContext);
  const {
    language: { code },
  } = useContext(LangContext);

  const [isLoadingServicePrices, setIsLoadingServicePrieces] = useState(false);
  const [servicePrices, setServicePrices] = useState(null);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    const fetchPrices = () => {
      if (isMounted) {
        setIsLoadingServicePrieces(true);
        customApiRequest(
          getServicePricesApi(user, values, code),
          (res) => {
            setIsLoadingServicePrieces(false);
            if (checkRes(res) && res?.data?.data) {
              setServicePrices(res.data.data);
            }
          },
          (error) => {
            setIsLoadingServicePrieces(false);
          }
        );
      }
    };
    fetchPrices();

    return () => (isMounted = false);
  }, [code]);

  return {
    isLoadingServicePrices,
    servicePrices,
  };
};

export default useServicePrices;
