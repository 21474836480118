import "./Logo.scss";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import logoImg from "../../assets/imgs/logo/logo.png";
const Logo = () => {
	return (
		<RouterLink className="logo-link" to="/">
			<img src={logoImg} alt="Application logo" />
		</RouterLink>
	);
};

export default Logo;
