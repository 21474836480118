/* eslint-disable react-hooks/exhaustive-deps */
import "./ChatWithDesigner.scss";

import React, { useContext, useEffect, useRef, useState } from "react";
import MyOrdersCard from "./MyOrdersCard";
import chatWithDesignerLocalization from "../../../localization/chatWithDesignerLocalization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

import userAvatar from "../../../assets/imgs/profile/avatar.png";
import designerAvatar from "../../../assets/imgs/designer-avatar.png";
import { Avatar, Link } from "@material-ui/core";
import { SendRounded } from "@material-ui/icons";

const ChatWithDesigner = () => {
  const { mainTitle } = chatWithDesignerLocalization;
  const {
    language: { code },
  } = useContext(LangContext);

  const messages = [
    {
      docId: 1,
      message:
        "مرحبا عميل ,, لقد قمت بكتابة المحتوي والذي سيتم اضافتة للفيديو ,, ",
      sender: {
        image: designerAvatar,
        name: "Designer name",
      },
      timeStamp: new Date().toLocaleString(),
    },

    {
      docId: 2,
      message:
        "حسنا مصمم ,, ساقوم بمراجعته الان وساقوم بالرد عليك بمجرد الانتهاء منه لتقوم بمباشرة عملك وفققك الله",
      sender: {
        image: userAvatar,
        name: "user name",
      },
      timeStamp: new Date().toLocaleString(),
    },
  ];

  const renderChatMessages = () => {
    return (
      <>
        {messages.length > 0 &&
          messages.map((msg) => {
            const {
              docId,
              message,
              sender: { image, name },
              timeStamp,
            } = msg;
            if (name === "user name") {
              return (
                <div key={docId} className="chatSelf">
                  <Avatar src={image ? image : null} />
                  <div className="msg__date">
                    <div className="msg">{message}</div>
                    <RouterLink to={`/profile/${name}`}></RouterLink>
                    <span className="msg-date">
                      {timeStamp && moment().fromNow()}
                    </span>
                  </div>
                </div>
              );
            }
            return (
              <div key={docId} className="chatOther">
                <div className="msg__date">
                  <div className="msg">
                    <div className="othreUsername">
                      <strong>{name}</strong>
                    </div>{" "}
                    {message}
                  </div>
                  <span className="msg-date">
                    {timeStamp && moment().fromNow()}
                  </span>
                </div>
                <Link to={`/profile/${name}`} component={RouterLink}>
                  <Avatar src={image ? image : null} />
                </Link>
              </div>
            );
          })}
      </>
    );
  };

  const inputRef = useRef(null);
  const chatBodyRef = useRef(null);
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // scroll to bottom every time the chat messages array chagnes
  useEffect(() => {
    if (messages.length) {
      // console.log('Chat Messages', chatMessages);
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      if (messages.length > 0) {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  //////////////////////////////////////
  const handleFormSubmit = (e) => {
    // e.preventDefault();
    if (e.preventDefault) e.preventDefault();
    // send messages to chat server
    // message: fieldValue.trim(),
    // 		token: user.token
  };

  //////////////////////////////////////
  const pastePlainText = (e) => {
    e.preventDefault();

    const text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  // const disableNewlines = evt => {
  // 	const keyCode = evt.keyCode || evt.which;

  // 	if (keyCode === 13) {
  // 		evt.returnValue = false;
  // 		// if (evt.preventDefault) evt.preventDefault();
  // 	}
  // };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13 && !e.shiftKey && fieldValue) {
      handleFormSubmit(e);
    }
  };

  const handleFieldChange = (e) => {
    const fieldValue = e.target.innerText
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<");

    setFieldValue(fieldValue);
  };

  return (
    <div className="chat-with-designer">
      <MyOrdersCard withBtn={false} />

      <div className="main-title">{mainTitle[code]}</div>

      <div className="chat-wrapper">
        <div ref={chatBodyRef} className="chatBody">
          {renderChatMessages()}
        </div>

        <form className="chatForm" onSubmit={handleFormSubmit}>
          <div
            ref={inputRef}
            contentEditable={true}
            placeholder="اكتب رســالتك..."
            onInput={handleFieldChange}
            // onKeyPress={disableNewlines
            onKeyPress={handleKeyPress}
            onPaste={pastePlainText}
          />
          <button className="submit-btn" type="submit">
            <SendRounded />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWithDesigner;
