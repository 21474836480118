/* eslint-disable react-hooks/exhaustive-deps */
import "./ServiceStepTen.scss";
import React, { useContext, useEffect } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { Checkbox, RadioGroup } from "formik-material-ui";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import {
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Radio,
  TextField as MuiTextField,
} from "@material-ui/core";
import FacebookSocial from "../../../common/icons/social/FacebookSocial";
import TwitterSocial from "../../../common/icons/social/TwitterSocial";
import InstagramSocial from "../../../common/icons/social/InstagramSocial";
import YoutubeSocial from "../../../common/icons/social/YoutubeSocial";
import SnapChatSocial from "../../../common/icons/social/SnapChatSocial";
import { PublishRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import LInkedInSocial from "../../../common/icons/social/LInkedInSocial";
import TiktokSocial from "../../../common/icons/social/TiktokSocial";
import XSocial from '../../../common/icons/social/XSocial'
const ServiceStepTen = () => {
  const {
    language: { code },
  } = useContext(LangContext);
  const {
    values,
    errors,
    isSubmitting,
    setFieldValue,
    initialValues,
    setFieldTouched,
    touched,
  } = useFormikContext();

  const {
    stepTen: {
      isSocial,
      socialMedia,
      fontSelection,
      musicSelection,
      colorSelection,
    },
  } = serviceDetailsLocalization;

  const socialMediaArray = [
    {
      id: 1,
      label: "twitter",
      value: "twitter",
      icon: <XSocial width={28} height={28} />,
    },
    {
      id: 2,
      label: "facebook",
      value: "facebook",
      icon: <FacebookSocial width={28} height={28} />,
    },
    {
      id: 3,
      label: "instagram",
      value: "instagram",
      icon: <InstagramSocial width={28} height={28} />,
    },
    {
      id: 4,
      label: "youtube",
      value: "youtube",
      icon: <YoutubeSocial width={28} height={28} />,
    },
    {
      id: 5,
      label: "Snapchat",
      value: "snapchat",
      icon: <SnapChatSocial width={28} height={28} />,
    },
    {
      id: 6,
      label: "Linkedin",
      value: "linkedin",
      icon: <LInkedInSocial width={28} height={28} />,
    },
    {
      id: 7,
      label: "Tiktok",
      value: "tiktok",
      icon: <TiktokSocial width={28} height={28} />,
    },
  ];

  useEffect(() => {
    if (values.font === "no") {
      setFieldValue("uploadedFont", initialValues.uploadedFont);
    }
  }, [values.font]);
  useEffect(() => {
    if (values.color === "no") {
      setFieldValue("uploadedColor", initialValues.uploadedColor);
    }
  }, [values.color]);

  const handleFileUpload = async (name, event) => {
    const file = await event.target.files[0];
    setFieldValue(name, file);
    setFieldTouched(name, true);
  };
  return (
    <div className="formik-step service-step-ten">
      <div>
        <div className="font-group-wrapper">
          <h3 className="group-title">{isSocial.title[code]}</h3>
          <Field
            className="field-group-wrap"
            component={RadioGroup}
            name="isSocial"
          >
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" disabled={isSubmitting} />}
              label={isSocial.yes.label[code]}
              disabled={isSubmitting}
              onClick={(e) => {
                if (String(values.isSocial) === "yes")
                  setFieldValue("isSocial", "");
              }}
            />
  
            <FormControlLabel
              value="no"
              control={<Radio color="primary" disabled={isSubmitting} />}
              label={isSocial.no.label[code]}
              disabled={isSubmitting}
            />
            {errors?.isSocial && (
              <Alert severity="error">{errors?.isSocial}</Alert>
            )}
          </Field>
        </div>

        {values.isSocial === "yes" && (
          <>
            <div className="checkBoxs-title-wrap">
              <div className="checkboxs-title">{socialMedia.title[code]}</div>
              <FieldArray name="socialMediaAddresses">
                {() => (
                  <div className="checkboxs-wrap">
                    {socialMediaArray.map((item, index) => (
                      <div key={index} className="checkbox-input-wrap">
                        <Field
                          component={Checkbox}
                          type="checkbox"
                          name="socialMediaAddresses.socialCheckBoxs"
                          value={item.value}
                        />

                        <div className="text-field-errors">
                          <Field
                            as={MuiTextField}
                            type="text"
                            variant="outlined"
                            name={`socialMediaAddresses.inputs.${item.label}`}
                            disabled={
                              values.socialMediaAddresses.socialCheckBoxs.filter(
                                (i) => i === item.value
                              )[0]
                                ? false
                                : true
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment>
                                  <IconButton>{item.icon}</IconButton>
                                </InputAdornment>
                              ),
                            }}
                            error={
                              errors?.socialMediaAddresses?.inputs &&
                              errors.socialMediaAddresses.inputs[item.value]
                                ? true
                                : false
                            }
                            // helperText={
                            // 	errors?.socialMediaAddresses?.inputs &&
                            // 	errors.socialMediaAddresses.inputs[item.value] &&
                            // 	socialMedia.textField.errors[code]
                            // }
                          />
                          {errors?.socialMediaAddresses?.inputs && (
                            <FormHelperText error>
                              {errors.socialMediaAddresses.inputs[item.value]}
                            </FormHelperText>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>

              {errors?.socialMediaAddresses?.socialCheckBoxs && (
                <Alert severity="error" className="error-alert">
                  {errors?.socialMediaAddresses?.socialCheckBoxs}
                </Alert>
              )}
            </div>
          </>
        )}
      </div>
      {/*  */}
      {/*  */}
      {/* FONTS */}
      {/*  */}
      {/*  */}
      <div className="font-group-wrapper">
        <h3 className="group-title">{fontSelection.title[code]}</h3>
        <Field className="field-group-wrap" component={RadioGroup} name="font">
          <div className="yes-wrap">
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" disabled={isSubmitting} />}
              label={fontSelection.yes.label[code]}
              disabled={isSubmitting}
              onClick={(e) => {
                if (String(values.font) === "yes") setFieldValue("font", "");
              }}
            />

            {/* only show the upload btn if yes is selected */}
            {values.font === "yes" && (
              <>
                <label className="upload-file-label">
                  {/* <Field name="uploadedFont" type="file" /> */}
                  <input
                    id="file"
                    name="uploadedFont"
                    type="file"
                    onChange={(e) => handleFileUpload("uploadedFont", e)}
                  />
                  {values.uploadedFont ? (
                    values.uploadedFont.name.split(/(\\|\/)/g).pop()
                  ) : (
                    <>
                      <span className="icon-text">
                        <PublishRounded />
                        Upload
                      </span>
                    </>
                  )}
                </label>
                {errors?.uploadedFont && touched?.uploadedFont && (
                  <FormHelperText error>{errors?.uploadedFont}</FormHelperText>
                )}
              </>
            )}
          </div>

          <FormControlLabel
            value="no"
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={fontSelection.no.label[code]}
            disabled={isSubmitting}
          />
        </Field>

        {errors?.font && <Alert severity="error">{errors?.font}</Alert>}
      </div>
      {/*  */}
      {/*  */}
      {/* music */}
      {/*  */}
      {/*  */}
      <div className="font-group-wrapper">
        <h3 className="group-title">{musicSelection.title[code]}</h3>
        <Field className="field-group-wrap" component={RadioGroup} name="music">
          <div className="yes-wrap">
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" disabled={isSubmitting} />}
              label={musicSelection.yes.label[code]}
              disabled={isSubmitting}
              onClick={(e) => {
                if (String(values.music) === "yes") setFieldValue("music", "");
              }}
            />

            {/* only show the upload btn if yes is selected */}
            {values.music === "yes" && (
              <>
                <label className="upload-file-label">
                  {/* <Field name="uploadedMusic" type="file" /> */}
                  <input
                    id="file"
                    name="uploadedMusic"
                    type="file"
                    onChange={(e) => handleFileUpload("uploadedMusic", e)}
                  />
                  {values.uploadedMusic ? (
                    values.uploadedMusic.name.split(/(\\|\/)/g).pop()
                  ) : (
                    <>
                      <span className="icon-text">
                        <PublishRounded />
                        Upload
                      </span>
                    </>
                  )}
                </label>
                {errors?.uploadedMusic && touched?.uploadedMusic && (
                  <FormHelperText error>{errors?.uploadedMusic}</FormHelperText>
                )}
              </>
            )}
          </div>

          <FormControlLabel
            value="no"
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={musicSelection.no.label[code]}
            disabled={isSubmitting}
          />
        </Field>

        {errors?.font && <Alert severity="error">{errors?.font}</Alert>}
      </div>
      {/*  */}
      {/*  */}
      {/* COLORS */}
      {/*  */}
      {/*  */}
      <div className="color-group-wrapper">
        <h3 className="group-title">{colorSelection.title[code]}</h3>
        <Field className="field-group-wrap" component={RadioGroup} name="color">
          <div className="yes-wrap">
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" disabled={isSubmitting} />}
              label={colorSelection.yes.label[code]}
              disabled={isSubmitting}
              onClick={(e) => {
                if (String(values.color) === "yes") setFieldValue("color", "");
              }}
            />

            {/* only show the upload btn if yes is selected */}
            {values.color === "yes" && (
              <>
                <label className="upload-file-label">
                  {/* <Field name="uploadedColor" type="file" /> */}
                  <input
                    id="color"
                    name="uploadedColor"
                    type="file"
                    onChange={(e) => handleFileUpload("uploadedColor", e)}
                  />
                  {values.uploadedColor ? (
                    values.uploadedColor.name.split(/(\\|\/)/g).pop()
                  ) : (
                    <>
                      <span className="icon-text">
                        <PublishRounded />
                        Upload
                      </span>
                    </>
                  )}
                </label>
                {touched?.uploadedColor && errors?.uploadedColor && (
                  <FormHelperText error>{errors?.uploadedColor}</FormHelperText>
                )}
              </>
            )}
          </div>

          <div className="yes-wrap">
            <FormControlLabel
              value="no"
              control={<Radio color="primary" disabled={isSubmitting} />}
              label={fontSelection.no.label[code]}
              disabled={isSubmitting}
            />
          </div>
        </Field>

        {errors?.font && <Alert severity="error">{errors?.font}</Alert>}
      </div>
    </div>
  );
};

export default ServiceStepTen;
