import ServiceStepOne from "../service-step-one/ServiceStepOne";
import * as Yup from "yup";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import LangContext from "../../../contexts/lang-context/LangProvider";
import { useContext } from "react";

const ServiceFiveStepTwo = () => {
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection },
  } = serviceDetailsLocalization;
  const {
    language: { code },
  } = useContext(LangContext);
  return (
    <>
      <ServiceStepOne
        validationSchema={Yup.object().shape({
          script: Yup.string().when("scriptIsReady", {
            is: "ready",
            then: Yup.string().required(yes.textarea.errors.required[code]),
          }),
          //////////////////////////////
          numberOfWords: Yup.string().when("scriptIsReady", {
            is: "ready",
            then: Yup.string()
              .matches(/^[0-9]+$/, yes.wordsCount.errors.isNumber[code])
              .required(yes.wordsCount.errors.required[code]),
          }),
          acceptScript: Yup.string().when("scriptIsReady", {
            is: "ready",
            then: Yup.string().required(yes.acceptScript.errors.required[code]),
          }),
          reviewedVideoDuration: Yup.string()
            .test(
              "reviewedVideoDuration",
              code === "ar" ? "أدخل مدة الفيديو" : "Enter video duration",

              (v, context) => {
                let result = true;
                if (
                  (!v || !parseFloat(v) > 0) &&
                  (context?.parent?.acceptScript === "review" ||
                    context?.parent?.scriptIsReady === "not_ready")
                ) {
                  result = false;
                }
                return result;
              }
            )
            .test(
              "reviewedVideoDuration",
              "لا يسمح الا بكتابة الارقام",
              (v, context) => {
                let result = true;
                if (
                  v &&
                  !v.match(/^(\d+)?(\.\d+)?$/) &&
                  (context?.parent?.acceptScript === "review" ||
                    context?.parent?.scriptIsReady === "not_ready")
                ) {
                  result = false;
                }
                return result;
              }
            ),
          //////////////////////////////
          briefScript: Yup.string().when("scriptIsReady", {
            is: "not_ready",
            then: Yup.string().required(no.textarea.errors.required[code]),
          }),
        })}
      />
    </>
  );
};

export default ServiceFiveStepTwo;
