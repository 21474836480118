import React, { createContext, useEffect, useState } from "react";
import languages from "./languages";

const INITIAL_LANG = languages.find((lang) => lang.code === "ar");

const LangContext = createContext({
  language: { ...INITIAL_LANG },
  setLanguage: () => {},
});

export const LangProvider = ({ children }) => {
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("lang")
      ? JSON.parse(localStorage.getItem("lang"))
      : { ...INITIAL_LANG }
  );
  useEffect(() => {
    if (localStorage.getItem("lang")) {
      document.documentElement.lang = JSON.parse(
        localStorage.getItem("lang")
      ).code;
      document.documentElement.dir = JSON.parse(
        localStorage.getItem("lang")
      ).dir;
    } else {
      localStorage.setItem("lang", JSON.stringify(selectedLang));
      document.documentElement.lang = selectedLang.code;
      document.documentElement.dir = selectedLang.dir;
    }
  }, [selectedLang]);

  const handleSetLang = (lang) => {
    localStorage.setItem("lang", JSON.stringify(lang));
    setSelectedLang(lang);
  };

  return (
    <LangContext.Provider
      value={{
        language: selectedLang,
        setLanguage: (lang) => handleSetLang(lang),
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export default LangContext;
