/* eslint-disable eqeqeq */
import './MainAppBar.scss';

import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import CustomAppBar from '../../common/custom-app-bar/CustomAppBar';
import Logo from '../../common/logo/Logo';
import LangSelect from '../../common/lang-select/LangSelect';
import LangContext from '../../contexts/lang-context/LangProvider';
import MainAppBarLocalization from '../../localization/mainAppBarLocalization';
import arrowLeft from '../../assets/imgs/icons/arrows/arrow-left-white.svg';
import arrowRight from '../../assets/imgs/icons/arrows/arrow-right-white.svg';
import SharedBtn from '../../common/shared-btn/SharedBtn';
import ChatIcon from '../../common/icons/ChatIcon';
import BellIcon from '../../common/icons/BellIcon';
import CartIcon from '../../common/icons/CartIcon';
import MainAppBarContext from '../../contexts/main-app-bar-context/MainAppBarProvider';
import routerLinks from '../app/routerLinks';
import UesrContext from '../../contexts/user-context/UserProvider';
import loadingImg from '../../assets/imgs/icons/loading.gif';
import useSignout from '../../custom-hooks/useSignout';
import UserCartContext from '../../contexts/user-cart-context/UserCartContext';
import userTypes from '../../constants/userTypes';

const MainAppBar = () => {
  const {
    language,
    language: { dir }
  } = useContext(LangContext);
  const { userCart } = useContext(UserCartContext);
  const { activeLink } = useContext(MainAppBarContext);
  const { user } = useContext(UesrContext);

  const renderLinks = () => {
    return (
      <nav className="app-bar-navs">
        {user?.image && (
          <RouterLink
            className="profile-link"
            to={
              user?.accountType == userTypes.client
                ? routerLinks.userSettings
                : routerLinks.designerSettings
            }
          >
            <img src={`${process.env.REACT_APP_BACK_END_URL}${user.image}`} alt="profile" />
          </RouterLink>
        )}
        <RouterLink
          className="nav-link"
          to={
            user?.accountType == userTypes.client
              ? routerLinks.userMessages :
              user?.accountType == userTypes.supervisor
                ? routerLinks.supervisorMessages
                : routerLinks.designerMessages
          }
        >
          <ChatIcon />
        </RouterLink>
        <RouterLink
          className="nav-link"
          to={
            user?.accountType == userTypes.client
              ? routerLinks.userNotifications
              : routerLinks.designerNotifications
          }
        >
          <BellIcon />
        </RouterLink>

        {user?.accountType == userTypes.client && (
          <RouterLink
            className={`nav-link cart-link ${activeLink === 'cart' ? 'active-nav' : ''
              }`}
            to={routerLinks.userCart}
          >
            <CartIcon />
            {userCart && <span className="badge-span" />}
          </RouterLink>
        )}
      </nav>
    );
  };

  // const sleep = ms => new Promise(r => setTimeout(r, ms));
  const { signMeOut, isLoadingSignout } = useSignout();
  const handleSignout = async () => {
    signMeOut();
  };
  return (
    <>
      <CustomAppBar customClass="main-app-bar">
        <div className="mfa-container">
          <div className="main-app-bar-wrapper">
            <Logo />
            {user?.activated && renderLinks()}
            <div className="lang-signout-link">
              <LangSelect />
              {user && user?.activated && (
                <SharedBtn
                  buttonComp={true}
                  btnClass={`signout-link ${isLoadingSignout ? 'is-submitting' : ''
                    }`}
                  onClick={handleSignout}
                >
                  <span>
                    {MainAppBarLocalization.signoutBtn[language.code]}
                  </span>
                  {isLoadingSignout ? (
                    <img
                      className="loading-img"
                      src={loadingImg}
                      alt="loading"
                    />
                  ) : (
                    <img
                      src={dir === 'rtl' ? arrowLeft : arrowRight}
                      alt="arrow icon"
                    />
                  )}
                </SharedBtn>
              )}
            </div>
          </div>
        </div>
      </CustomAppBar>
      <div className="mobile-app-bar">
        <div className="mfa-container">{user?.activated && renderLinks()}</div>
      </div>
    </>
  );
};

export default MainAppBar;
