/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// import "./ServiceStepFive.scss";

import React, { useContext, useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import { DatePicker, TimePicker } from "formik-material-ui-pickers";
import UserServicesContext from "../../../contexts/services-context/UserServicesProvider";
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { RadioGroup, Select, TextField } from "formik-material-ui";
import LangContext from "../../../contexts/lang-context/LangProvider";
import { makeStyles } from "@material-ui/core/styles";
import ReactPhoneInput from "react-phone-input-material-ui";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import {
  PublishRounded,
  CalendarTodayRounded,
  AccessTimeOutlined,
  Create,
} from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import Map from "../../map/Map";
import "./ServiceFiveStepOne.scss";
import lightGraphicsTypes from "../../../lightGraphicsTypes";
import filmMaterialTypes from "../../../filmMaterialTypes";
import { getTommorow } from "../../../utils/getDates";
import UesrContext from "../../../contexts/user-context/UserProvider";
import citiesApi from "../../../apis/general/citiesApi";
import designerApplicationLocalization from "../../../localization/designerApplication.localization";
import useCountries from "../../../custom-hooks/useCountries";
import Loading from "../../../common/loading/Loading";

const filmPhotoAreaTypes = {
  inside: "inside",
  outside: "outside",
  both: "both",
};

const filmPhotoTypes = {
  fullCovarage: "full_coverage",
  pressCovarage: "press_coverage",
  filming: "filming",
  ads: "ads",
  meetings: "meetings",
  hdAir: "hd_air",
  fourKAir: "4k_air",
};

const filmPeopleCount = {
  onePerson: 1,
  twoPeople: 2,
  threeOrMore: 3,
};

const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1,
  },
  field: {
    margin: "10px 0",
  },
}));

const ServiceFiveStepOne = () => {
  const classes = useStyles();
  const {
    language: { code },
  } = useContext(LangContext);
  const { servicePrices } = useContext(UserServicesContext);
  const {
    service6StepTen: { motion, isFilm },
  } = serviceDetailsLocalization;
  const {
    isSubmitting,
    setTouched,
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const isMotionArr = [
    {
      id: 1,
      label: {
        ar: `نعم`,
        en: `Yes`,
        // ar: `نعم ${servicePrices?.graphPricePerSeconds}$ لكل ${servicePrices?.graphCountSeconds} ثانية - ملاحظة تتم البرمجة لحساب عدد الكلمات ${servicePrices?.countWordsEqualMinute} كلمة = دقيقة وتحسب كل كلمتين زيادة بثانية وقيمة الثانية ب ${servicePrices?.secondPrice}$`,
        // en: `Yes ${servicePrices?.graphPricePerSeconds}$ for every ${servicePrices?.graphCountSeconds} second - Note words count ${servicePrices?.countWordsEqualMinute} word = one minute and every additional 2 words equal one second and the second equals ${servicePrices?.secondPrice}$`,
      },
      value: lightGraphicsTypes?.yes,
    },
    {
      id: 2,
      label: {
        ar: "لا",
        en: "No",
      },
      value: lightGraphicsTypes?.no,
    },
  ];

  const isFilmArr = [
    {
      id: 1,
      label: {
        ar: "نعم",
        en: "Yes",
      },
      value: filmMaterialTypes?.uploadFile,
    },
    {
      id: 2,
      label: {
        ar: ` أختيار المادة الفلمية والصور حسب السيناريو من قبل فيديو تيوب `,
        en: `choose the film material and images according to the scenario by Video Tube`,
        // ar: `هل تريد أختيار المادة الفلمية والصور حسب السيناريو من قبل فيديو تيوب؟ ${servicePrices?.filmServicePrice}$`,
        // en: `Do you want to choose the film material and images according to the scenario by Video Tube? ${servicePrices?.filmServicePrice}$`,
      },
      value: filmMaterialTypes?.fromApp,
    },
    {
      id: 3,
      label: {
        ar: " تصوير المادة من قبل فيديو تيوب ؟",
        en: "Do you want to film a birth on a YouTube video?",
      },
      value: filmMaterialTypes?.outFilm,
    },
  ];

  const filmPhotoAreaArr = [
    {
      id: "inside",
      label: {
        ar: "داخلى",
        en: "Inside",
      },
      value: filmPhotoAreaTypes?.inside,
    },
    {
      id: "outside",
      label: {
        ar: "خارجى",
        en: "Outside",
      },
      value: filmPhotoAreaTypes?.outside,
    },
    {
      id: "both",
      label: {
        ar: "كليهما معا",
        en: "Both",
      },
      value: filmPhotoAreaTypes?.both,
    },
  ];

  const filmPhotoTypesArr = [
    {
      id: 1,

      label: {
        ar: `تغطية كاملة للمؤتمرات مع المونتاج`,
        en: `Full coverage of conferences with montage`,
        // ar: `تغطية كاملة للمؤتمرات مع المونتاج ${servicePrices?.FullCoveragePerDayPrice}$ لليوم الواحد`,
        // en: `Full coverage of conferences with montage ${servicePrices?.FullCoveragePerDayPrice}$ for one day`,
      },
      value: filmPhotoTypes?.fullCovarage,
    },
    {
      id: 2,
      label: {
        ar: `تغطية صحفية للمؤتمرات`,
        en: `Conference press coverage`,
        // ar: `تغطية صحفية للمؤتمرات -تصوير يوم واحد ${servicePrices?.PressCoveragePerDayPrice}$ لليوم الواحد`,
        // en: `Conference press coverage - one day shooting ${servicePrices?.PressCoveragePerDayPrice}$ for one day`,
      },
      value: filmPhotoTypes?.pressCovarage,
    },
    {
      id: 3,
      label: {
        ar: `تصوير فيلم وثائقي`,
        en: `Filming a Documentary Film`,
        // ar: `تصوير فيلم وثائقي- تصوير يوم واحد ${servicePrices?.FilmingPerDayPrice}`,
        // en: `Filming a Documentary Film - One Day Shooting ${servicePrices?.FilmingPerDayPrice}`,
      },
      value: filmPhotoTypes?.filming,
    },
    {
      id: 4,
      label: {
        ar: `تصوير محترف إعلانات- تصوير`,
        en: `Professional Photography Advertising - One Day Photography`,
        // ar: `تصوير محترف إعلانات- تصوير ${servicePrices?.AdsPerDayPrice}$ ليوم واحد ${servicePrices?.AdsCountHourPerDay} ساعات`,
        // en: `Professional Photography Advertising - One Day Photography ${servicePrices?.AdsPerDayPrice}$ for one day ${servicePrices?.AdsCountHourPerDay} hours`,
      },
      value: filmPhotoTypes?.ads,
    },
    {
      id: 5,
      label: {
        ar: "تصوير مقابلت- تصوير يوم واحد",
        en: "Interview photography - one day photography",
      },
      value: filmPhotoTypes?.meetings,
    },
    {
      id: 6,
      label: {
        ar: `تصوير جوى درون 1080HD - ملاحظة تصريح رسوم التصوير على الزبون حسب المنطقة`,
        en: `1080HD aerial photography - a note about permitting the photography fees on the customer according to the pronunciation`,
        // ar: `تصوير جوى درون 1080HD - ملاحظة تصريح رسوم التصوير على الزبون حسب المنطقة - ${servicePrices?.HdAirPerDayPrice}$ ليوم واحد ${servicePrices?.HdAirCountHourPerDay}ساعات`,
        // en: `1080HD aerial photography - a note about permitting the photography fees on the customer according to the pronunciation ${servicePrices?.HdAirPerDayPrice}$ for one day ${servicePrices?.HdAirCountHourPerDay}hours`,
      },
      value: filmPhotoTypes?.hdAir,
    },
    {
      id: 7,
      label: {
        ar: ` تصوير جوي درون بجودة 4K - ملحوظة تصريح رسوم التصوير على الزبون حسب المنطقة`,
        en: `Aerial photography by 4K quality - a note about permitting the photography fees on the customer according to the pronunciation`,
        // ar: ` تصوير جوي درون بجودة 4K - ملحوظة تصريح رسوم التصوير على الزبون حسب المنطقة ${servicePrices?.FourkAirPerDayPrice}$ ليوم ${servicePrices?.FourkAirCountHourPerDay}ساعات`,
        // en: `Aerial photography by 4K quality - a note about permitting the photography fees on the customer according to the pronunciation ${servicePrices?.FourkAirPerDayPrice}$ for one day ${servicePrices?.FourkAirCountHourPerDay}hours`,
      },
      value: filmPhotoTypes?.fourKAir,
    },
  ];

  const filmPeopleCountArr = [
    {
      id: 1,
      label: {
        ar: `للشخص الواحد فى اليوم الواحد كاميرا واحدة`,
        en: `One Person for one day - One Camera`,
        // ar: `للشخص الواحد فى اليوم الواحد كاميرا واحدة ${servicePrices?.MeetingsPerDayOnePersonPrice}$`,
        // en: `One Person for one day - One Camera ${servicePrices?.MeetingsPerDayOnePersonPrice}$`,
      },
      value: filmPeopleCount?.onePerson,
    },
    {
      id: 2,
      label: {
        ar: `شخصين فى اليوم الواحد فى اللقاء الحوارى كاميرتين`,
        en: `Two People in one day - Two Cameras`,
        // ar: `شخصين فى اليوم الواحد فى اللقاء الحوارى كاميرتين ${servicePrices?.MeetingsPerDayTwoPersonPrice}$`,
        // en: `Two People in one day - Two Cameras ${servicePrices?.MeetingsPerDayTwoPersonPrice}$`,
      },
      value: filmPeopleCount?.twoPeople,
    },
    {
      id: 3,
      label: {
        ar: `3 اشخاص أو أكثر فى اليوم الواحد 3 كاميرات`,
        en: `Three People or more in one day - 3 Cameras`,
        // ar: `3 اشخاص أو أكثر فى اليوم الواحد 3 كاميرات ${servicePrices?.MeetingsPerDayThreePersonPrice}$`,
        // en: `Three People or more in one day - 3 Cameras ${servicePrices?.MeetingsPerDayThreePersonPrice}$`,
      },
      value: filmPeopleCount?.threeOrMore,
    },
  ];

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.label[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === item.value)
                setFieldValue(fieldName, "");
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  useEffect(() => {
    setTouched({});
  }, []);

  const handleFileUpload = async (name, event) => {
    // await sleep(500);
    let file = await event.target.files[0];
    setFieldValue(name, file);
    setFieldTouched(name, true);
  };

  const renderFileLinkInputs = () => {
    return (
      <div className="file-link-inputs-wrap">
        <div className="upload-wrap">
          <div className="title-input-wrap">
            <div className="input-title">Upload Film Materials</div>
            <label className="upload-file-label">
              {/* <Field name="cv" type="file" /> */}
              <input
                id="file"
                name="film_material_file"
                type="file"
                onChange={(e) => handleFileUpload("film_material_file", e)}
              />
              {values?.film_material_file ? (
                values.film_material_file?.name &&
                values.film_material_file.name.split(/(\\|\/)/g).pop()
              ) : (
                <>
                  <span className="icon-text">
                    <PublishRounded />
                    Upload Film Materials
                  </span>
                </>
              )}
            </label>
          </div>

          {errors?.film_material_file && touched?.film_material_file && (
            <FormHelperText error>{errors.film_material_file}</FormHelperText>
          )}
        </div>

        <Field
          component={TextField}
          label="Film Materials Link"
          name="film_material_link"
          variant="outlined"
        />
      </div>
    );
  };
  //
  // handle out_film value
  const [selectedLocation, setSelecectedLocation] = useState({
    lat: "",
    lng: "",
  });
  const [selectedAddress, setSelectedAddress] = useState("");

  useEffect(() => {
    if (selectedLocation?.lat && selectedLocation?.lng) {
      setFieldValue("film_material_lat", selectedLocation.lat);
      setFieldValue("film_material_lng", selectedLocation.lng);
    }
  }, [selectedLocation.lat, selectedLocation.lng]);

  useEffect(() => {
    setFieldValue("film_material_address", selectedAddress);
  }, [selectedAddress])



  const [phoneValue, setPhoneValue] = React.useState("");
  const handlePhoneValue = (v, setFieldValue) => {
    setPhoneValue(v);
    setFieldValue("film_material_contact_num", v);
  };


  const { user } = useContext(UesrContext);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [allCities, setAllCities] = useState([])
  const [isCityDisabled, setIsCityDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fetchCities = async (id) => {
    setIsCityDisabled(true)
    setIsLoading(true)
    const data = await citiesApi({ countryId: id }, code, user)
    if (data?.data?.data) {
      setAllCities(data?.data?.data)
      setIsCityDisabled(false)
      setIsLoading(false)
    }
  }
  const { stepOne } = designerApplicationLocalization;
  const { allCountries } = useCountries();


  const renderOutFilmSection = () => {
    return (
      <>
        {
          isLoading ?
            <Loading centerLoader />

            : <div className="outfilm-section">
              <div className="form-to-wrap">

                <FormControl
                  className="field-wrapper"
                  variant="outlined"
                  error={touched?.country && errors?.country ? true : false}
                >
                  <InputLabel htmlFor="country-id" className="country-label">
                    {stepOne.country.label[code]}
                  </InputLabel>
                  <Field
                    value={selectedCountryId}
                    onChange={(e) => {
                      setFieldValue("country", e.target.value);
                      setSelectedCountryId(e.target.value);
                      fetchCities(e.target.value)
                    }}
                    component={Select}
                    name="country"
                    // variant="outlined"
                    inputProps={{
                      id: "country-id",
                    }}
                  >
                    {allCountries?.length > 0
                      ? allCountries.map((c) => (
                        <MenuItem key={c?.id} value={c?.id}>
                          {c?.ar_name || c?.en_name || c?.name}
                        </MenuItem>
                      ))
                      : null}
                  </Field>
                  {touched?.country && errors?.country && (
                    <FormHelperText error>{errors.country}</FormHelperText>
                  )}
                </FormControl>


                {selectedCountryId && <FormControl
                  className="field-wrapper"
                  variant="outlined"
                  error={touched?.city && errors?.city ? true : false}
                >
                  <InputLabel htmlFor="city-id" className="city-label country-label">
                    {stepOne.city.label[code]}
                  </InputLabel>
                  <Field
                    defaultValue=""
                    onChange={(e) => {
                      setFieldValue("film_material_city_id", e.target.value);
                    }}
                    component={Select}
                    name="film_material_city_id"
                    // variant="outlined"
                    inputProps={{
                      id: "city-id",
                    }}
                    disabled={isCityDisabled}
                  >
                    {allCities?.length > 0
                      ? allCities.map((c) => (
                        <MenuItem key={c?.id} value={c?.id} >
                          {c?.ar_name || c?.en_name || c?.name}
                        </MenuItem>
                      ))
                      : null}
                  </Field>
                  {touched?.city && errors?.city && (
                    <FormHelperText error>{errors.city}</FormHelperText>
                  )}
                </FormControl>}
              </div>



              <div className="map-wrapper">
                {code === "ar" && (
                  <h3 style={{ color: "#d72a33" }}>
                    عنوان التصوير على جوجل
                  </h3>
                )}
                {code === "en" && (
                  <h3 style={{ color: "#d72a33" }}>Film address</h3>
                )}

                <Map
                  width="100%"
                  height="400px"
                  selectedLocation={selectedLocation}
                  setSelecectedLocation={setSelecectedLocation}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                />
              </div>

              <div className="date-time-wrapper">
                <Field
                  className="field-wrapper"
                  component={DatePicker}
                  label="File Date"
                  name="film_material_date"
                  // disablePast
                  minDate={getTommorow()}
                  // format="MM/dd/yyyy"
                  inputVariant="outlined"
                  disabled={isSubmitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <CalendarTodayRounded fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="form-to-wrap">
                  <Field
                    className="field-wrapper"
                    component={TimePicker}
                    label="Time From : "
                    name="film_material_from_time"
                    inputVariant="outlined"
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AccessTimeOutlined fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    className="field-wrapper"
                    component={TimePicker}
                    label="To From : "
                    name="film_material_to_time"
                    inputVariant="outlined"
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AccessTimeOutlined fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="phone-number-wrap">
                <p>Contact Number </p>
                <ReactPhoneInput
                  country={"ae"}
                  value={phoneValue}
                  onChange={(v) => handlePhoneValue(v, setFieldValue)}
                  component={MuiTextField}
                  dropdownClass={classes.countryList}
                  specialLabel={false}
                  name="film_material_contact_num"
                />
                {touched?.film_material_contact_num &&
                  errors?.film_material_contact_num && (
                    <FormHelperText error>
                      {errors.film_material_contact_num}
                    </FormHelperText>
                  )}
              </div>

              <div className="radio-group-wrapper">
                {code === "ar" && <h3 className="group-title">منطقة التصوير</h3>}
                {code === "en" && <h3 className="group-title">File Area</h3>}

                <Field
                  className="field-group-wrap"
                  component={RadioGroup}
                  name="film_material_photo_area"
                >
                  {renderRadiosGroup(filmPhotoAreaArr, "film_material_photo_area")}
                </Field>
              </div>

              <div className="radio-group-wrapper film-photo-type">
                {code === "ar" && <h3 className="group-title">نوعية التصوير</h3>}
                {code === "en" && <h3 className="group-title">Filming Type</h3>}
                <Field
                  className="field-group-wrap"
                  component={RadioGroup}
                  name="film_material_photo_type"
                >
                  {renderRadiosGroup(filmPhotoTypesArr, "film_material_photo_type")}
                </Field>
              </div>

              {(values?.film_material_photo_type == filmPhotoTypes?.fullCovarage ||
                values?.film_material_photo_type ==
                filmPhotoTypes?.pressCovarage) && (
                  <Field
                    component={TextField}
                    name="film_material_count_day"
                    type="text"
                    label={(() => {
                      if (code === "ar") return "عدد أيام التصوير";
                      else if (code === "en") return "Film number of days";
                      return "";
                    })()}
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <Create />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              {values?.film_material_photo_type == filmPhotoTypes?.meetings && (
                <div className="radio-group-wrapper">
                  {code === "ar" && <h3 className="group-title">عدد الاشخاص</h3>}
                  {code === "en" && <h3 className="group-title">number of people</h3>}
                  <Field
                    className="field-group-wrap"
                    component={RadioGroup}
                    name="film_material_photo_count_people"
                  >
                    {renderRadiosGroup(
                      filmPeopleCountArr,
                      "film_material_photo_count_people"
                    )}
                  </Field>
                </div>
              )}
            </div>
        }
      </>
    );
  };

  return (
    <div className="formik-step service-five-step-one">
      <div className="radio-group-wrapper light-graphics-wrapper">
        <h3 className="group-title">{motion.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="light_graphics"
        >
          {renderRadiosGroup(isMotionArr, "light_graphics")}
        </Field>
      </div>

      {/* {values?.light_graphics == lightGraphicsTypes?.yes && ( */}
      <div className="radio-group-wrapper film-material-type">
        <h3 className="group-title">{isFilm.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="film_material_type"
        >
          {renderRadiosGroup(isFilmArr, "film_material_type")}
        </Field>
      </div>
      {/* )} */}

      {
        values?.film_material_type == filmMaterialTypes?.uploadFile &&
        renderFileLinkInputs()}

      {
        values?.film_material_type == filmMaterialTypes?.outFilm &&
        renderOutFilmSection()}
    </div>
  );
};

export default ServiceFiveStepOne;
