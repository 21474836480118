import { useContext } from "react";
import * as Yup from "yup";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import ServiceStepTwo from "../service-step-two/ServiceStepTwo";

const ServiceFiveStepThree = () => {
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection },
  } = serviceDetailsLocalization;
  const {
    language: { code },
  } = useContext(LangContext);

  return (
    <>
      <ServiceStepTwo
        validationSchema={Yup.object().shape({
          videoLang: Yup.string().required(
            stepTwo.videoLang.errors.required[code]
          ),
          // displayMethod: Yup.string().required(
          //   stepTwo.displayMethod.errors.required[code]
          // ),
          voiceOver: Yup.string().required(
            stepTwo.voiceOver.errors.required[code]
          ),
        })}
      />
    </>
  );
};

export default ServiceFiveStepThree;
