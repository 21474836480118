import { useContext } from "react";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import ServiceStepFive from "../service-step-five/ServiceStepFive";
import * as Yup from "yup";

const ServiceFiveStepSix = () => {
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection },
  } = serviceDetailsLocalization;
  const {
    language: { code },
  } = useContext(LangContext);

  return (
    <>
      <ServiceStepFive
        validationSchema={Yup.object().shape({
          companySpeciality: Yup.string().required(
            companySpeciality.errors.required[code]
          ),
          otherCompSpeciality: Yup.string().when("companySpeciality", {
            is: "other",
            then: Yup.string().required(
              companySpeciality.otherTextarea.errors.required[code]
            ),
          }),
          brandName: Yup.string().required(brandName.errors.required[code]),
        })}
      />
    </>
  );
};

export default ServiceFiveStepSix;
