/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext } from "react";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { IconButton, InputAdornment } from "@material-ui/core";
import { PersonRounded } from "@material-ui/icons";
import * as Yup from "yup";
import FlashMessageContext from "../../contexts/flash-messages-context/FlashMessageProvider";
import forgetPasswordLocalization from "../../localization/forgetPassword.localization";
import LangContext from "../../contexts/lang-context/LangProvider";
import { sendForgetPassCode } from "../../apis/auth/forgetPassword";
import ForgetPasswordContext from "../../contexts/forget-password-context/ForgetPasswordProvider";
import ButtonWithLoading from "../../common/button-with-loading/ButtonWithLoading";
import { useHistory, useLocation, useParams } from "react-router";
import routerLinks from "../app/routerLinks";
import useCustomApiRequest from "../../custom-hooks/useCustomApiRequest";
import checkRes from "../../utils/checkRes";

const ForgetPasswordEmail = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);
  const {
    language: { code },
  } = useContext(LangContext);
  const { setTempEmail } = useContext(ForgetPasswordContext);

  const { email, submitBtn, successMsg } = forgetPasswordLocalization;

  const FORGET_PASSWORD_SCHEMA = Yup.object().shape({
    email: Yup.string()
      .email(email.errors.typeError[code])
      // .typeError()
      .trim()
      .required(email.errors.required[code]),
  });

  const customApiRequest = useCustomApiRequest();
  return (
    <Formik
      initialValues={{
        email: location?.state?.email ? location.state.email : "",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("email", values?.email);
        formData.append("type", params?.accountType);
        customApiRequest(
          sendForgetPassCode(formData),
          (res) => {
            setSubmitting(false);
            if (checkRes(res) && res?.data?.data) {
              resetForm();
              setTempEmail(res.data.data?.email);
              setFlashMsgIsOpen(true);
              setFlashMsg(successMsg[code]);
              setFlashMsgDuration(4000);
              setNotificationSeverity("success");
              history.push(routerLinks.forgetPasswordCode(params?.accountType));
            } else if (res?.data?.status === 0) {
              if (res?.data?.message) {
                setFlashMsgIsOpen(true);
                setFlashMsg(res?.data?.message);
                setFlashMsgDuration(4000);
                setNotificationSeverity("error");
              }
            }
          },
          (error) => {
            setSubmitting(false);
            setFlashMsgIsOpen(true);
            setFlashMsg(error?.response?.data?.message || "حاول فى وقت لاحق");
            setFlashMsgDuration(4000);
            setNotificationSeverity("error");
          }
        );
      }}
      validationSchema={FORGET_PASSWORD_SCHEMA}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="forget-password-form"
            autoComplete="off"
            id="forget-password-form"
          >
            <Field
              component={TextField}
              name="email"
              type="text"
              label={email.label[code]}
              variant="outlined"
              color="primary"
              className="form-field"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <PersonRounded />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
									<pre>{JSON.stringify(errors, null, 2)}</pre>
									<pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </form>
          <div className="send-btn">
            <ButtonWithLoading
              isSubmitting={isSubmitting}
              errors={errors}
              btnText={submitBtn.label[code]}
              loadingMsg={submitBtn.sending[code]}
              className="submit-btn"
              form="forget-password-form"
            />
          </div>
        </>
      )}
    </Formik>
  );
};

export default ForgetPasswordEmail;
