import "./NotificationListItem.scss";

import React, { useContext } from "react";
import { Avatar, Button } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import UesrContext from "../../contexts/user-context/UserProvider";
import LangContext from "../../contexts/lang-context/LangProvider";
import indexLocalization from "../../localization";
const NotificationListItem = ({ avatar, title, desc, date, actions ,acceptJob,rejectJob,serviceName,subService}) => {
  const { user } = useContext(UesrContext);
  const {
    language: { code, dir },
  } = useContext(LangContext);


  return (
    <div className="notification-list-item">
      <Avatar src={avatar} />
      <div className="notif-data">
        <div className="notif-title">{title}</div>
        <div className="notf-desc">{desc}</div>
        <div className="notif-service">{serviceName} - {subService}</div>
        <div className="notif-date">
          <AccessTime />
          {date}
        </div>
        {actions && (
          <div className="actions-container">
            <Button variant="contained" color="primary" onClick={acceptJob}>
              {indexLocalization.yes[code]}
            </Button>
            <Button variant="contained" color="primary" onClick={rejectJob}>
              {indexLocalization.no[code]}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationListItem;
