/* eslint-disable react-hooks/exhaustive-deps */
import './SupervisorOrders.scss';
import React, { useContext, useEffect, useState } from 'react';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import LangContext from '../../../contexts/lang-context/LangProvider';
import UesrContext from '../../../contexts/user-context/UserProvider';
import DocTitleScrollTop from '../../../utils/DocTitleScrollTop';
import supervisorOrdersApi from '../../../apis/supervisor-apis/supervisorOrdersApi';
import checkRes from '../../../utils/checkRes';
import SupervisorOrderCard from './SupervisorOrderCard';
import ContentLoader from 'react-content-loader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import supervisorSideMenuLocalization from '../../../localization/supervisorSideMenu.localization';
const SupervisorOrders = () => {
  DocTitleScrollTop('My requests');
  const { user } = useContext(UesrContext);
  const {
    language: { code, dir }
  } = useContext(LangContext);

  const [myOrders, setMyOrders] = useState(null);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const { noOrder } = supervisorSideMenuLocalization
  useEffect(() => {

    const getAllOrders = async () => {

      const data = await supervisorOrdersApi('active', user, code)
      setMyOrders(data.data.data)
    }
    getAllOrders()

  }, [code]);

  if (isLoadingOrders) {
    return (
      <>
        <div className="service-card-loader-box">
          <ContentLoader
            width="100%"
            height="100%"
            speed={1}
            rtl={dir === 'rtl' ? true : false}
          >
            <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
          </ContentLoader>
        </div>
      </>
    );
  } else if (myOrders?.length > 0)
    return (
      <>

        <div className="my-orders-tab">
          {myOrders.map((order) => {
            return <SupervisorOrderCard key={order?.id} card={order} withBtn />;
          })}
        </div>

      </>

    );
  return (
    <div className="designer-projects-wrapper">
      <h2>
        {noOrder[code]}
      </h2>
    </div>
  )


};

export default SupervisorOrders;
