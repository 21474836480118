import React from "react";

const ApplePayIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48.042"
			height="37.366"
			viewBox="0 0 48.042 37.366"
		>
			<path
				id="Icon_awesome-cc-apple-pay"
				data-name="Icon awesome-cc-apple-pay"
				d="M25.206,17.81a2.131,2.131,0,0,1-2.419,2.26H20.76V15.55H22.8a2.125,2.125,0,0,1,2.41,2.26Zm3.962,5.221c0,.692.6,1.143,1.543,1.143a1.917,1.917,0,0,0,2.1-1.827v-.642l-1.96.125C29.743,21.905,29.167,22.314,29.167,23.031ZM48.042,6.183V35.543a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V6.183a4,4,0,0,1,4-4H44.039A4,4,0,0,1,48.042,6.183ZM10.659,16.042a2.311,2.311,0,0,0,1.843-.867,2.753,2.753,0,0,0,.642-1.977,2.743,2.743,0,0,0-1.827.943,2.617,2.617,0,0,0-.659,1.9Zm5.054,6.214a2.751,2.751,0,0,1-1.651-2.5A2.808,2.808,0,0,1,15.4,17.4a2.871,2.871,0,0,0-2.26-1.226c-1.018-.058-1.885.576-2.369.576s-1.226-.55-2.027-.534A2.991,2.991,0,0,0,6.2,17.768c-1.093,1.885-.284,4.671.776,6.205.517.759,1.143,1.593,1.96,1.56.776-.033,1.084-.5,2.018-.5s1.209.5,2.027.492c.851-.017,1.376-.759,1.9-1.518A7.06,7.06,0,0,0,15.714,22.256ZM27.007,17.8a3.571,3.571,0,0,0-3.745-3.737h-4.27V25.442H20.76V21.555H23.2a3.613,3.613,0,0,0,3.8-3.753Zm7.507,1.977c0-1.643-1.318-2.7-3.336-2.7-1.877,0-3.261,1.076-3.311,2.544h1.593a1.552,1.552,0,0,1,1.668-1.159c1.084,0,1.685.5,1.685,1.435v.626l-2.2.133c-2.052.125-3.161.968-3.161,2.427a2.5,2.5,0,0,0,2.786,2.452,2.912,2.912,0,0,0,2.6-1.451h.033V25.45h1.635V19.779Zm8.524-2.594H41.245l-2.077,6.723h-.033l-2.077-6.723H35.2l2.994,8.282-.158.5a1.409,1.409,0,0,1-1.493,1.184c-.142,0-.409-.017-.517-.025v1.368a4.321,4.321,0,0,0,.676.042c1.727,0,2.536-.659,3.245-2.652Z"
				transform="translate(0 -2.18)"
			/>
		</svg>
	);
};

export default ApplePayIcon;
