import axios from "axios";

const newOrderApi = async (values, user, langCode) => {
  try {
    const func = await axios.post(`/api/v1/client/new-order`, values, {
      headers: {
        "X-Portal": user?.accountType,
        "Content-Type": "multipart/form-data",
        password: process.env.REACT_APP_AUTH_PASS,
        lang: langCode,
      },
    });

    window.open(func.data.data.InvoiceURL, "_self");

    return func;
  } catch (e) {
    throw e;
  }
};

export const completePaymentApi = async (user,id) => {
  try {
    const {
      data: { data },
    } = await axios.post(`api/v1/client/complete-payment/${id}`, "", {
      headers: {
        password: process.env.REACT_APP_AUTH_PASS,
        "X-Portal": user?.accountType,

      },
    });

    window.open(data?.InvoiceURL, "_self");
  } catch (e) {
    throw e;
  }
};
export const cancelOrderApi = async (id, user) => {
  const formData = new FormData();
  formData.append("order_id", id);
  formData.append("api_token", user?.api_token);
  console.log(formData.get("order_id"));
  console.log(formData.get("api_token"));

  try {
    const res = await axios.post(`api/v1/client/cancel-order`, formData, {
      headers: {
        "X-Portal": user?.accountType,
        "Content-Type": "multipart/form-data",
        password: process.env.REACT_APP_AUTH_PASS,
      },
    });
    return res;
  } catch (e) {
    throw e;
  }
};

export default newOrderApi;
