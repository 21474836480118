import React, { createContext, useState } from "react";

const MainAppBarContext = createContext({
	activeLink: "",
	setActiveLink: r => {}
});

export const MainAppBarProvider = ({ children }) => {
	const [activeLink, setActiveLink] = useState("");

	return (
		<MainAppBarContext.Provider
			value={{
				activeLink,
				setActiveLink: route => setActiveLink(route)
			}}
		>
			{children}
		</MainAppBarContext.Provider>
	);
};

export default MainAppBarContext;
