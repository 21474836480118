const indexLocalization = {
	yes: {
		ar: "نعم",
		en: "Yes"
	},
	no: {
		ar: "لا",
		en: "No"
	},

	noMsg : {
		ar: "لا يوجد رسائل",
		en: "No Messages"

	},
	noNotifications: {
		ar: "لا يوجد اشعارات",
		en: "No Notifications"

	},
	newMessage:{
		ar:"لديك رسالة جديدة",
		en:"You have new Message"
	},
	newNotification:{
		ar:" لديك اشعار جديد",
		en:"You have new Notification"
	},
	downloadFile : {
		ar:"تحميل الملف",
		en:'Download file'
	},
	selectedFiles :{
		ar:"الملفات المحدده",
		en:"Selected files"
	},
	selectedMedia :{
		ar:"الوسائط المحدده",
		en:"Selected media"
	},
	sendCode :{
		ar:"ارسل الكود",
		en:"Send the code"
	}
};

export default indexLocalization;
