/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { getServices } from "../apis/services";
import userTypes from "../constants/userTypes";
import LangContext from "../contexts/lang-context/LangProvider";
import UesrContext from "../contexts/user-context/UserProvider";
import checkRes from "../utils/checkRes";
import useCustomApiRequest from "./useCustomApiRequest";

const useServices = () => {
  const { user } = useContext(UesrContext);
  const {
    language: { code },
  } = useContext(LangContext);

  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [allServices, setAllServices] = useState([]);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    if (isMounted && (user?.accountType == userTypes?.client || !user)) {
      const fetchServices = () => {
        setIsLoadingServices(true);
        customApiRequest(
          getServices(user, code),
          (res) => {
            setIsLoadingServices(false);
            if (checkRes(res) && res?.data?.data) {
              setAllServices(res.data.data);
            }
          },
          (error) => {
            setIsLoadingServices(false);
          }
        );
      };
      fetchServices();
    }

    return () => (isMounted = false);
  }, [code]);

  return {
    isLoadingServices,
    allServices,
  };
};

export default useServices;
