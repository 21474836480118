import './SupervisorSignin.scss';
import React, { useContext } from 'react';
import signinPage from '../../localization/signinPage';
import LangContext from '../../contexts/lang-context/LangProvider';
import employeeImg from '../../assets/imgs/signup-signin/teacher.png';
import SupervisorSigninForm from './SupervisorSigninForm';

const SuperVisorSignin = () => {
  const {
    supervisorSignin: { title: sectionTitle }
  } = signinPage;
  const {
    language: { code, dir }
  } = useContext(LangContext);
  return (
    <div className="developer-signin-section">
      <div className="title-form-section">
        <div className="section-title">{sectionTitle[code]}</div>
        <SupervisorSigninForm />
      </div>
      <div className={`section-img ${dir}`}>
        <img src={employeeImg} alt="employee" />
      </div>
    </div>
  );
};

export default SuperVisorSignin;
