/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "./UserCart.scss";

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import userCartLocalization from "../../localization/userCart.localization";
import LangContext from "../../contexts/lang-context/LangProvider";
import { Button } from "@material-ui/core";
import { RemoveShoppingCartTwoTone } from "@material-ui/icons";
import cartImg from "../../assets/imgs/icons/cart-black.png";
import visaImg from "../../assets/imgs/icons/visa-in-button.png";
import MainAppBarContext from "../../contexts/main-app-bar-context/MainAppBarProvider";
import UesrContext from "../../contexts/user-context/UserProvider";
import useCustomApiRequest from "../../custom-hooks/useCustomApiRequest";
import checkRes from "../../utils/checkRes";
import ContentLoader from "react-content-loader";
import { makeStyles } from "@material-ui/core";
import LoadingModal from "../../common/loading-modal/LoadingModal";
import newOrderApi from "../../apis/user/newOrderApi";
import FlashMessageContext from "../../contexts/flash-messages-context/FlashMessageProvider";
import routerLinks from "../app/routerLinks";
import UserCartContext from "../../contexts/user-cart-context/UserCartContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  "chekout-modal-content-wrap": {
    border: 0,
    outline: 0,
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "grid",
    placeContent: "center",
  },
}));

const UserCart = () => {
  const classes = useStyles();
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);
  const [submittingNewOrder, setSubmittingNewOrder] = useState(false);
  const { setActiveLink } = useContext(MainAppBarContext);

  const {
    cartPage: {
      mainTitle,
      subTitle,
      serviceType,
      serviceNumber,
      videoDuration,
      videoLang,
      langDisplay,
      voiceoverGender,
      arabicVoiceover,
      englishVoiceOver,
      voiceOver,
      socialLinks,
      videoScale,
      companySpeciality,
      nickName,
      videoPurpose,
      targetGroup,
      targetSector,
      targetAudience,
      offerPackages,
      distinctionPoint,
      total,
      chooseBtn,
      scalePrice,
      graphPrice,
      filmMaterPrice,
      superVisorPrice,
      taxPrice,
    },
  } = userCartLocalization;
  const {
    language: { code, dir },
  } = useContext(LangContext);

  const { user } = useContext(UesrContext);
  const history = useHistory();
  useEffect(() => {
    setActiveLink("cart");
    return () => {
      setActiveLink("");
    };
  }, []);

  // const [checkoutModalOpened, setCheckoutModalOpened] = useState(false);
  const { isLoadingCart, userCart, setFetchCartCount } =
    useContext(UserCartContext);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    setFetchCartCount((prev) => prev + 1);
  }, []);

  const submitNewOrder = () => {
    setSubmittingNewOrder(true);
    const formData = new FormData();
    formData.append("api_token", user?.api_token);

    customApiRequest(
      newOrderApi(formData, user, code),
      (res) => {
        setSubmittingNewOrder(false);
        if (checkRes(res)) {
          setFetchCartCount((prev) => prev + 1);
          history.push(routerLinks?.userServices);
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || "تم تسجيل الطلب بنجاح");
          setFlashMsgDuration(4000);
          setNotificationSeverity("success");
        }
      },
      (error) => {
        setSubmittingNewOrder(false);
        setFlashMsgIsOpen(true);
        setFlashMsg(
          error?.response?.data?.message || "حدث خطأ حاول فى وقت لاحق"
        );
        setFlashMsgDuration(4000);
        setNotificationSeverity("error");
      }
    );
  };

  const strObjToArr = (strObj) => {
    if (strObj && Object.keys(strObj)?.length > 0) {
      let obj = JSON.parse(strObj);
      let arr = [];
      for (let key in obj) {
        if (obj[key]) {
          arr.push({
            name: key,
            value: obj[key],
          });
        }
      }

      return arr;
    }
  };
  const renderSocialLinks = (strObj) => {
    let arr = strObjToArr(strObj);
    if (arr?.length > 0) {
      return (
        <tr>
          <th>{socialLinks[code]}</th>
          <td>
            <span className='cell-value social-value'>
              {arr.map((item, index) => (
                <div key={index} style={{ display: "flex", gap: 4 }}>
                  <span>{item?.name} : </span>
                  <span>{item?.value} </span>
                </div>
              ))}
            </span>
          </td>
          <td className='money-value'>
            {userCart?.cart_price?.scale_price && (
              <span>
                {userCart ? `${userCart.cart_price.scale_price}$` : ""}
              </span>
            )}
          </td>
        </tr>
      );
    }
    return null;
  };
  const renderVideoScale = (key) => {
    let scales = userCart[key] && JSON.parse(userCart[key])
    if (userCart && userCart[key])
    return (
        <tr>
          <th> {userCartLocalization[key][code]}</th>
          <td>
            {
              scales.map((item) =>
              <>
              <span className='cell-value'>{item?.value}</span>
              <br/>
              </>
              )
            }
          </td>
        </tr>
      );
  };

  if (isLoadingCart) {
    return (
      <ContentLoader
        width='100%'
        height='100%'
        speed={1}
        rtl={dir === "rtl" ? true : false}
      >
        <rect x='0' y='0' width='100%' height='100%' rx='12' ry='12' />
      </ContentLoader>
    );
  } else if (!isLoadingCart && !userCart) {
    return (
      <div
        style={{
          display: "grid",
          placeItems: "center",
          height: 132,
        }}
      >
        <RemoveShoppingCartTwoTone
          style={{
            fontSize: "62px",
          }}
        />
      </div>
    );
  } else if (userCart)
    return (
      <div className='user-cart'>
        <div className='main-title'>
          <img src={cartImg} alt='cart' />
          {mainTitle[code]}
        </div>
        <div className='sub-title'>{subTitle[code]}</div>

        <div className='options-wrapper'>
          <table className='options-table'>
            <tbody>
             {Boolean(parseFloat(userCart?.cart_price?.duration_price)) &&  <tr>
                <th>{serviceType[code]}</th>
                <td>
                  <span className='cell-value'>
                    {userCart?.service?.en_title ||
                      userCart?.service?.ar_title ||
                      ""}
                  </span>
                </td>
                {/* <td className='money-value'>
                    <span>
                      {userCart && Boolean(parseFloat(userCart?.cart_price?.duration_price)) 
                        ? `${userCart?.cart_price?.duration_price}$`
                        : ""}
                    </span>
                  </td> */}

              </tr>}
              <tr>
                <th>{serviceNumber[code]}</th>
                <td>
                  <span className='cell-value'>
                    {userCart?.service?.uni_code || ""}
                  </span>
                </td>
              </tr>
              {userCart?.duration && Boolean(parseFloat(userCart?.cart_price?.text_duration_price)) && (
                <tr>
                  <th>{videoDuration.title[code]}</th>
                  <td>
                    <span className='cell-value'>{userCart?.duration}</span>
                    <span className='helper-span'>
                      {videoDuration.min[code]}
                    </span>
                  </td>
                  {/* <td className='money-value'>
                    <span>
                      {userCart
                        ? `${userCart?.cart_price?.text_duration_price}$`
                        : ""}
                    </span>
                  </td> */}
                </tr>
              )}

              {userCart?.trans_lang && Boolean(parseFloat(userCart?.cart_price?.trans_price)) && (
                <tr>
                  <th>{videoLang[code]}</th>
                  <td>
                    <span className='cell-value'>
                      {userCart ? userCart.trans_lang : ""}
                    </span>
                  </td>
                  {/* <td className='money-value'>
                    <span>
                      {userCart ? `${userCart?.cart_price?.trans_price}$` : ""}
                    </span>
                  </td> */}
                </tr>
              )}
              {/* {userCart?.trans_lang_style && (
                <tr>
                  <th>{langDisplay[code]}</th>
                  <td>
                    <span className='cell-value'>
                      {userCart ? userCart.trans_lang_style : ""}
                    </span>
                  </td>
                </tr>
              )} */}

              {userCart?.cart_price?.comment_price &&  Boolean(parseFloat(userCart?.cart_price?.comment_price)) && (
                <tr>
                  <th>{voiceOver[code]}</th>
                  <td>
                    <div
                      style={{
                        display: "grid",
                        gap: 5,
                      }}
                    >
                    {userCart.ar_voice_comment &&  <span className='cell-value'>
                        {userCart.ar_voice_comment ? arabicVoiceover[code] : ""}
                      </span>}
                    {  userCart.en_voice_comment &&  <span className='cell-value'>
                        {userCart.en_voice_comment
                          ? englishVoiceOver[code]
                          : ""}
                      </span>}
                    </div>
                  </td>
                  {/* <td className='money-value'>
                    <span>{`${userCart?.cart_price?.comment_price}$`}</span>
                  </td> */}
                </tr>
              )}

            {/* {userCart?.comment_gender &&  <tr>
                <th>{voiceoverGender[code]}</th>
                <td>
                  <span className='cell-value'>
                    {userCart ? userCart?.comment_gender : ""}
                  </span>
                </td>
              </tr>} */}

              {/* { Boolean(parseFloat(userCart?.cart_price?.scale_price)) && renderVideoScale("facebook_scale")}
              { Boolean(parseFloat(userCart?.cart_price?.scale_price)) && renderVideoScale("twitter_scale")}
              { Boolean(parseFloat(userCart?.cart_price?.scale_price)) && renderVideoScale("insta_scale")}
              { Boolean(parseFloat(userCart?.cart_price?.scale_price)) && renderVideoScale("snap_scale")}
              { Boolean(parseFloat(userCart?.cart_price?.scale_price)) && renderVideoScale("youtube_scale")} */}
              {userCart?.cart_price?.scale_price && Boolean(parseFloat(userCart?.cart_price?.scale_price)) &&
                <tr>
                  <th>{scalePrice[code]}</th>
                  <td>
                  </td>

                  {/* <td className='money-value'>
                    <span>{`${userCart?.cart_price?.scale_price}$`}</span>
                  </td> */}


                </tr>
              }

              {userCart?.cart_price?.graph_price && Boolean(parseFloat(userCart?.cart_price?.graph_price)) &&
                <tr>
                  <th>{graphPrice[code]}</th>
                  <td>
                  </td>

                  {/* <td className='money-value'>
                    <span>{`${userCart?.cart_price?.graph_price}$`}</span>
                  </td> */}


                </tr>
              }
                 {userCart?.cart_price?.film_material_price &&Boolean(parseFloat(userCart?.cart_price?.film_material_price)) &&
                <tr>
                  <th>{filmMaterPrice[code]}</th>
                  <td>
                  </td>

                  {/* <td className='money-value'>
                    <span>{`${userCart?.cart_price?.film_material_price}$`}</span>
                  </td> */}


                </tr>
              }
                 {userCart?.cart_price?.supervisor_price && Boolean(parseFloat(userCart?.cart_price?.supervisor_price)) &&
                <tr>
                  <th>{superVisorPrice[code]}</th>
                  <td>
                  </td>

                  {/* <td className='money-value'>
                    <span>{`${userCart?.cart_price?.supervisor_price}$`}</span>
                  </td> */}


                </tr>
              }
                 {userCart?.cart_price?.tax_price && Boolean(parseFloat(userCart?.cart_price?.tax_price)) &&
                <tr>
                  <th>{taxPrice[code]}</th>
                  <td>
                  </td>

                  {/* <td className='money-value'>
                    <span>{`${userCart?.cart_price?.tax_price}$`}</span>
                  </td> */}


                </tr>
              }


              
              {/* {userCart?.company_specialty && (
                <tr>
                  <th>{companySpeciality[code]}</th>
                  <td>
                    <span className='cell-value'>
                      {userCart.company_specialty}
                    </span>
                  </td>
                </tr>
              )} */}

              {/* {userCart?.nickname_case && (
                <tr>
                  <th>{nickName[code]}</th>
                  <td>
                    <span className='cell-value'>
                      {userCart.nickname_case == 1 && "YES"}
                      {userCart.nickname_case == 0 && "NO"}
                    </span>
                  </td>
                </tr>
              )} */}
              {/* {userCart?.video_purpose && (
                <tr>
                  <th>{videoPurpose[code]}</th>
                  <td>
                    <span className='cell-value'>{userCart.video_purpose}</span>
                  </td>
                </tr>
              )} */}
              {/* {userCart?.target_group && (
                <tr>
                  <th>{targetGroup[code]}</th>
                  <td>
                    <span className='cell-value'>{userCart.target_group}</span>
                  </td>
                </tr>
              )} */}
              {/* {userCart?.target_sector && (
                <tr>
                  <th>{targetSector[code]}</th>
                  <td>
                    <span className='cell-value'>{userCart.target_sector}</span>
                  </td>
                </tr>
              )} */}
              {/* {userCart?.target_public?.length > 0 && (
                <tr>
                  <th>{targetAudience[code]}</th>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 5,
                      }}
                    >
                      {JSON.parse(userCart.target_public).map((item, index) => (
                        <span
                          key={index}
                          style={{
                            backgroundColor: "#ebebeb",
                            padding: "6px 16px",
                            borderRadius: "5px",
                            color: "#d72a33",
                          }}
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              )} */}

              {/* {userCart?.offer_packages && (
                <tr>
                  <th>{offerPackages[code]}</th>
                  <td>
                    <span className='cell-value'>
                      {userCart.offer_packages}
                    </span>
                  </td>
                </tr>
              )} */}
              {/* {userCart?.distinction_point && (
                <tr>
                  <th>{distinctionPoint[code]}</th>
                  <td>
                    <span className='cell-value'>
                      {userCart.distinction_point}
                    </span>
                  </td>
                </tr>
              )} */}

              {renderSocialLinks(userCart?.social_links)}
            </tbody>
          </table>

          <div className='total-price'>
            <div className='total-label'>{total[code]}</div>
            <div className='total-value'>
              {`${userCart?.cart_price?.total_price}$` || ""}
            </div>
          </div>

          <Button
            // to="/dashboard/user/choose-payment"
            // component={RouterLink}
            className='paymentBtn'
            variant='contained'
            color='primary'
            onClick={() => {
              // setCheckoutModalOpened(true);
              submitNewOrder();
            }}
          >
            <span>{chooseBtn[code]}</span>
            <img src={visaImg} alt='visa' />
          </Button>
        </div>

        {submittingNewOrder && <LoadingModal />}

        {/* <Modal
          open={checkoutModalOpened}
          onClose={() => {
            setCheckoutModalOpened(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={checkoutModalOpened}>
            <div className={classes["chekout-modal-content-wrap"]}>
              content wrap
            </div>
          </Fade>
        </Modal> */}
      </div>
    );
  return null;
};

export default UserCart;
