import React from "react";

const VisaIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="66.801"
			height="20.474"
			viewBox="0 0 66.801 20.474"
		>
			<path
				id="Icon_simple-visa"
				data-name="Icon simple-visa"
				d="M48.556,13.321a13.892,13.892,0,0,0-4.843-.838c-5.344,0-9.116,2.694-9.143,6.555-.033,2.839,2.683,4.437,4.735,5.389,2.107.974,2.811,1.587,2.806,2.463-.014,1.328-1.684,1.929-3.234,1.929a11.442,11.442,0,0,1-5.1-1.044l-.665-.309-.754,4.448a18.088,18.088,0,0,0,6.054,1.044c5.681,0,9.394-2.675,9.438-6.789.045-2.263-1.42-3.98-4.512-5.394-1.876-.919-3.045-1.534-3.045-2.466,0-.824,1-1.7,3.087-1.7a9.909,9.909,0,0,1,4.05.76l.5.225.754-4.3-.131.028Zm13.87-.473H58.25a2.7,2.7,0,0,0-2.842,1.645l-8.03,18.187h5.681L54.195,29.7l6.931.006c.17.7.668,2.973.668,2.973H66.8L62.425,12.848Zm-35.538-.167H32.3L28.914,32.523H23.5l3.385-19.848ZM13.14,23.616l.562,2.756,5.291-13.524h5.731l-8.52,19.8H10.488L5.812,15.879a1.108,1.108,0,0,0-.5-.66A22.855,22.855,0,0,0,0,13.251l.07-.418H8.784a2.264,2.264,0,0,1,2.458,1.681l1.9,9.11v-.008ZM55.748,25.64l2.157-5.55c-.028.056.445-1.147.718-1.893l.37,1.712,1.25,5.725h-4.5v.006Z"
				transform="translate(0 -12.483)"
			/>
		</svg>
	);
};

export default VisaIcon;
