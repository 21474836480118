import axios from "axios";

// 'pending','accepted','completed'
const getMyOrdersApi = async (filterValues, user, langCode) => {
  try {
    const res = await axios.get(
      `/api/v1/client/my-orders?api_token=${user?.api_token}&status=${
        filterValues?.status || ""
      }`,
      {
        headers: {
          "X-Portal": user?.accountType,
          "Content-Type": "multipart/form-data",
          password: process.env.REACT_APP_AUTH_PASS,
          "X-Language": langCode,
        },
      }
    );

    console.log("order response", res);
    return res;
  } catch (e) {
    throw e;
  }
};

export default getMyOrdersApi;


export const getUserOrdersByIdApi = async ( user,id, langCode) => {
  try {
    const res = await axios.get(
      `/api/v1/client/orderById?api_token=${user?.api_token}&order_id=${id}`,
      {
        headers: {
          'X-Portal': user?.accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        }
      }
    );

    return res;
  } catch (e) {
    throw e;
  }
};
