const supervisorSideMenuLocalization = {
  home: {
    ar: 'الرئيسية',
    en: 'Home'
  },
  orders: {
    ar: 'الطلبات',
    en: 'Orders'
  },
  notifications: {
    ar: 'الاشعارات',
    en: 'Notifications'
  },
  messages: {
    ar: 'الرسائل',
    en: 'Messages'
  },
  balance: {
    ar: 'رصيدى',
    en: 'Balance'
  },
  settings: {
    ar: 'الاعدادات',
    en: 'Settings'
  },
  noOrder:{
		ar:"لا يوجد طلبات متاحة",
		en:"No available orders"
	}
};

export default supervisorSideMenuLocalization;
