/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "./ServiceStepThree.scss";

import React, { useContext } from "react";
import { Field, useFormikContext } from "formik";
import { FormControlLabel, FormHelperText, Radio } from "@material-ui/core";
import { RadioGroup, TextField } from "formik-material-ui";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import UserServicesContext from "../../../contexts/services-context/UserServicesProvider";
import videoLangTypes from "../../../videoLangTypes";

const ServiceStepThree = () => {
  const {
    language: { code },
  } = useContext(LangContext);
  const { subServices } = useContext(UserServicesContext);
  const {
    stepThree: { count_voice_comment },
  } = serviceDetailsLocalization;

  const {
    values,
    setFieldValue,
    isSubmitting,
    errors,
    touched,
    // setTouched,
    // setErrors,
    // initialValues,
  } = useFormikContext();

  // useEffect(() => {
  //   setFieldValue("arabicVoiceOver", "");
  //   setFieldValue("englishVoiceOver", "");
  //   setTouched({});
  //   setErrors({});
  // }, []);

  // handle reset voiceover models after selecting the voiceover accent
  // useEffect(() => {
  //   if (values.arabicVoiceOver) {
  //     setFieldValue("arabicVoiceOverModel", initialValues.arabicVoiceOverModel);
  //   }
  // }, [values.arabicVoiceOver]);
  // useEffect(() => {
  //   if (values.englishVoiceOver) {
  //     setFieldValue(
  //       "englishVoiceOverModel",
  //       initialValues.englishVoiceOverModel
  //     );
  //   }
  // }, [values.englishVoiceOver]);
  // ///////////////////////////////

  const renderRadiosGroup = (arr, name) => {
    if (arr?.length > 0)
      return arr.map((item) => (
        <FormControlLabel
          key={item.id}
          value={String(item?.id)}
          control={
            <Radio
              color="primary"
              disabled={isSubmitting}
              onClick={(e) => {
                if (String(values[name]) === String(item?.id))
                  setFieldValue(name, "");
              }}
            />
          }
          label={item?.value || ""}
          disabled={isSubmitting || values?.voiceOver === "3"}
        />
      ));
    return [];
  };

  const renderModelsGroup = (arr, name) => {
    const foundObj = arr.find((m) => String(m.id) == String(values[name]));
    if (foundObj) {
      return (
        foundObj?.voice_notes?.length > 0 &&
        foundObj.voice_notes.map((item) => (
          <div key={item.id} className="models-group">
            <FormControlLabel
              key={item.id}
              value={String(item.id)}
              control={<Radio color="primary" disabled={isSubmitting} />}
              // label={item?.attribute_id}
              disabled={isSubmitting}
            />
            <audio
              controls
              src={process.env.REACT_APP_BACK_END_URL + item?.voice}
            >
              Your browser does not support the audio element.
            </audio>
          </div>
        ))
      );
    }
  };

  return (
    <div className="formik-step service-step-three">
      {subServices?.length > 0 && (
        <>
          {((values.videoLang === videoLangTypes?.bothArEnOneVideo && values?.displayMethod !== videoLangTypes?.arabicLang) ||values.videoLang !== videoLangTypes?.bothArEnOneVideo) && <>
            {((values.videoLang === videoLangTypes?.arabicLang &&
              subServices[0]?.uni_code === "ar_voice_comment") ||
              values.videoLang === videoLangTypes?.bothArEnOneVideo ||
              values.videoLang === videoLangTypes?.bothArEnSeperateVideos) && (
                <div className="voiceOver-radio-group-wrapper">
                  {/* <h3 className="group-title">{arabicVoiceOver.label[code]}</h3> */}
                  <h3 className="group-title">{subServices[0]?.title}</h3>
                  <div className="wrap-two">
                    <div className="main-fields">
                      <Field
                        className="field-group-wrap"
                        component={RadioGroup}
                        name="arabicVoiceOver"
                      >
                        {subServices[0]?.attributes &&
                          renderRadiosGroup(
                            subServices[0]?.attributes,
                            "arabicVoiceOver"
                          )}
                      </Field>
                      {touched?.arabicVoiceOver && errors?.arabicVoiceOver && (
                        <FormHelperText error>
                          {errors.arabicVoiceOver}
                        </FormHelperText>
                      )}
                    </div>
                    {/* arabic voiceOver models */}
                    {subServices[0]?.attributes?.length > 0 &&
                      values.arabicVoiceOver && (
                        <div className="models-wrapper">
                          <Field
                            className="field-group-wrap"
                            component={RadioGroup}
                            name="arabicVoiceOverModel"
                          >
                            {renderModelsGroup(
                              subServices[0].attributes,
                              "arabicVoiceOver"
                            )}
                          </Field>
                          {touched?.arabicVoiceOverModel &&
                            errors?.arabicVoiceOverModel && (
                              <FormHelperText error>
                                {errors.arabicVoiceOverModel}
                              </FormHelperText>
                            )}
                        </div>
                      )}
                  </div>
                </div>
              )}
          </>}
          {/*  */}
          {/*  */}
          {((values.videoLang === videoLangTypes?.bothArEnOneVideo && values?.displayMethod !== videoLangTypes?.englishLang)|| values.videoLang !== videoLangTypes?.bothArEnOneVideo) && <>
            {((values.videoLang === videoLangTypes?.englishLang &&
              subServices[1]?.uni_code === "en_voice_comment") ||
              values.videoLang === videoLangTypes?.bothArEnOneVideo ||
              values.videoLang === videoLangTypes?.bothArEnSeperateVideos) && (
                <div className="voiceOver-radio-group-wrapper">
                  {/* <h3 className="group-title">{englishVoiceOver.label[code]}</h3> */}
                  <h3 className="group-title">
                    {subServices?.length > 1 &&
                      subServices[1] &&
                      subServices[1]?.title}
                  </h3>
                  <div className="wrap-two">
                    <div className="main-fields">
                      <Field
                        className="field-group-wrap"
                        component={RadioGroup}
                        name="englishVoiceOver"
                      >
                        {subServices?.length > 1 &&
                          subServices[1]?.attributes &&
                          renderRadiosGroup(
                            subServices[1].attributes,
                            "englishVoiceOver"
                          )}
                      </Field>
                      {touched?.englishVoiceOver && errors?.englishVoiceOver && (
                        <FormHelperText error>
                          {errors.englishVoiceOver}
                        </FormHelperText>
                      )}
                    </div>
                    {/* english voiceOver models */}
                    {subServices?.length > 1 &&
                      subServices[1]?.attributes?.length > 0 &&
                      values.englishVoiceOver && (
                        <div className="models-wrapper">
                          <Field
                            className="field-group-wrap"
                            component={RadioGroup}
                            name="englishVoiceOverModel"
                          >
                            {renderModelsGroup(
                              subServices[1].attributes,
                              "englishVoiceOver"
                            )}
                          </Field>
                          {touched?.englishVoiceOverModel &&
                            errors?.englishVoiceOverModel && (
                              <FormHelperText error>
                                {errors.englishVoiceOverModel}
                              </FormHelperText>
                            )}
                        </div>
                      )}
                  </div>
                </div>
              )}
          </>}
          <div className="count-voice-comment-wrapper">
          {values?.voiceOver !=="3" &&  <Field
              component={TextField}
              type="number"
              label={count_voice_comment?.label[code]}
              name="count_voice_comment"
              variant="outlined"
              className="count-voice-comment"
              inputProps={{ min: 1 }}
            />}
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceStepThree;
