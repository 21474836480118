import "./UserMessages.scss";
import { Avatar } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import desinerImg from "../../../assets/imgs/designer-avatar.png";
import DocTitleScrollTop from "../../../utils/DocTitleScrollTop";
import fakeMsgs from "./fakeMsgs";
import MessageBox from "../../../common/message-box/MessageBox";
import UesrContext from "../../../contexts/user-context/UserProvider";
import getConversationsApi from "../../../apis/conversations/conversations";
import NewMessage from "./NewMessage";
import getMessagesApi from "../../../apis/conversations/messages";
import registerTokenNotificationsApi from "../../../apis/conversations/registerToken";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ContentLoader from "react-content-loader";
import LangContext from "../../../contexts/lang-context/LangProvider";
import indexLocalization from "../../../localization";

const UserMessages = ({notify ,setNotify}) => {
  DocTitleScrollTop("Messages");

  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState("");
  const [type, setType] = useState("");
  const [typeId, setTypeId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [openLoader, setOpenLoader] = useState(true);
  const [messages, setMessages] = useState([]);
  const { noMsg } = indexLocalization;

  const { user } = useContext(UesrContext);
  const {
    language: { dir, code },
  } = useContext(LangContext);

  const params = useParams();
  useEffect(() => {
    if (params?.orderId) {
      setOrderId(params?.orderId);

    }
  }, [params]);

  // const firebaseConfig = useMemo(() => {
  //   return  {
  //     apiKey: "AIzaSyAEyHtoZqYQUC25bGfkhvYTBPVMgDZkivM",
  //     authDomain: "videotube-f6a14.firebaseapp.com",
  //     projectId: "videotube-f6a14",
  //     storageBucket: "videotube-f6a14.appspot.com",
  //     messagingSenderId: "1065299110898",
  //     appId: "1:1065299110898:web:32dd5c4202995ed673996b",
  //   }
  // },[]) ;

  // const vapidKey =
  //   "BEdRyFebE2WqWtHWIoazamSjhdF8Oyld0mFkW7JV7t6t93Eh8m-XWYwbjEmj1je5uahJXQ4_V1Je6ZloSYbYVHg";


  const getMessage = useCallback(
    async (orderId, type, typeID) => {
      const data = await getMessagesApi(user, orderId, type, typeID);
      // setConversationId(conversationId)
      if (data?.data?.data) {
        setMessages(data.data.data.messages);
        setConversationId(data.data.data.conversation_id);
      }
    },
    [user]
  );




  useEffect(() => {
    async function getConversations() {
      const data = await getConversationsApi(user);
      if (data?.data?.data.length) {
        setConversations(data.data.data);
      } 
      if (orderId) {
        await getMessage(orderId, "", "");
      }
      setOpenLoader(false)
    }
    getConversations();


    if (notify) {
      if (orderId) {
        getMessage(orderId, type, typeId);
      } else {
        getConversations();
      }
      setNotify(false)
    }

  }, [user, getMessage, orderId, type, typeId,notify]);

  const messageRef = useRef(null)
  const scrollToEnd = () => {
    messageRef.current?.scrollTo({
      top:messageRef?.current.scrollHeight,
    })
  }

  const renderUsersList = () => {
    return (
      <>
        {conversations.map((item) => {
          const type = item?.employee ? "employee" : "supervisor";
          const typeId = item?.employee ? item?.employee?.id : item?.supervisor?.id;
          const imageSrc =item?.employee ? item?.employee?.image : item?.supervisor?.image;

          return (
            <div
              className="msg-list-item"
              key={item.id}
              onClick={() => {
                getMessage(item?.order?.id, type, typeId);
                setType(type);
                setTypeId(typeId);
                setOrderId(item?.order?.id);
                setConversationId(item.id);
              }}
            >
              <Avatar  src={`${process.env.REACT_APP_BACK_END_URL}${imageSrc}`} />
              <div className="msg-data">
                <div className="msg-title">
                  {item.supervisor
                    ? `${item?.supervisor?.f_name} ${item?.supervisor?.l_name}`
                    : `${item?.employee?.f_name} ${item?.employee?.l_name}`}
                </div>
                <div className="msg-desc">{item?.last_message?.message}</div>
                {item?.last_message?.created_at && (
                  <div className="msg-date">
                    <AccessTime />
                    {item?.last_message?.created_at}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };


  return (
    <>
      {openLoader && (
        <ContentLoader
          width="100%"
          height="100%"
          speed={1}
          rtl={dir === "rtl" ? true : false}
        >
          <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
        </ContentLoader>
      )}

      {!openLoader && (
        <div className="messages-page">
          <div className="messages-users-wrap">
            {conversations.length > 0  ? <div className="users-wrap">{renderUsersList()}</div> : <div/>}
            <div className="p-4 padding-b-0 messages-wrap-container">
              <div className="messages-warp " ref={messageRef}>
                {messages.length > 0 ? (
                  messages.map((item, i) => {
                    if (i === (messages.length - 1)) {
                      scrollToEnd()
                    }

                    return (
                      <MessageBox key={item.id} message={item} />
                    )
                  }
                  )
                ) : (
                  <div />
                )}
              </div>
              {conversationId && (
                <NewMessage conversationId={conversationId} getMessage={() => { getMessage(orderId, type, typeId); }} />
              )}

            </div>
          </div>
        </div>
      )}
	      {conversations.length === 0 &&  !orderId && !conversationId && !openLoader && (
        <div class="empty-wrapper">
          <h2>{noMsg[code]}</h2>
        </div>
      )}
    
    </>
  );
};

export default UserMessages;
