import { useContext } from 'react';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';
import ServiceStepNine from '../service-setp-nine/ServiceStepNine';
import * as Yup from 'yup';

const ServiceFiveStepTen = ({ setStep }) => {
  const {
    stepOne: {
      scriptIsReady: { yes, no }
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection }
  } = serviceDetailsLocalization;
  const {
    language: { code }
  } = useContext(LangContext);

  return (
    <>
      <ServiceStepNine
        setStep={setStep}
        validationSchema={Yup.object().shape({
          offerPackages: Yup.string().required(offerPackages.errors[code]),
          highlightPoint: Yup.string().required(highlightPoint.errors[code])
        })}
      />
    </>
  );
};

export default ServiceFiveStepTen;
