const signupPage = {
  signupLinks: {
    userLink: {
      ar: 'التسجيل كمستخدم',
      en: 'Register as a user'
    },
    developerLink: {
      ar: 'التسجيل كموظف',
      en: 'Register as an employee'
    },
    supervisorLink: {
      ar: 'التسجيل كمشرف',
      en: 'Register as a supervisor'
    }
  },
  userSignup: {
    title: {
      ar: 'التسجيل كمستخدم',
      en: 'Register a a user'
    }
  },
  developerSignup: {
    title: {
      ar: 'التسجيل كموظف',
      en: 'Register as an employee'
    }
  },
  supervisorSignup: {
    title: {
      ar: 'التسجيل كمشرف',
      en: 'Register as a supervisor'
    }
  }
};

export default signupPage;
