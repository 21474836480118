import React from "react";

const VideoIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30.859"
			height="20.573"
			viewBox="0 0 30.859 20.573"
		>
			<path
				id="Icon_awesome-video"
				data-name="Icon awesome-video"
				d="M12.847,4.5H28.3a2.561,2.561,0,0,1,2.561,2.561V22.512A2.561,2.561,0,0,1,28.3,25.073H12.847a2.561,2.561,0,0,1-2.561-2.561V7.061A2.561,2.561,0,0,1,12.847,4.5ZM2.7,6.52l5.872,4.05V19L2.7,23.048A1.718,1.718,0,0,1,0,21.666V7.9A1.719,1.719,0,0,1,2.7,6.52Z"
				transform="translate(0 -4.5)"
			/>
		</svg>
	);
};

export default VideoIcon;
