import "./DesignerBalance.scss";

import React, { useCallback, useContext, useEffect, useState } from "react";
import LangContext from "../../../contexts/lang-context/LangProvider";
import desingerBalanceLocalization from "../../../localization/designerBalance.localization";
import designerAvatar from "../../../assets/imgs/designer-avatar.png";
import { AccessTime } from "@material-ui/icons";
import {
  getEmployeeBalance,
  getEmployeeWalletHistory,
  getEmployeeWithdraw,
  postEmployeeWithdraw,
} from "../../../apis/employee/employeeApi";
import UesrContext from "../../../contexts/user-context/UserProvider";
import { Button } from "@material-ui/core";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import FlashMessageContext from "../../../contexts/flash-messages-context/FlashMessageProvider";
import { Pagination } from "@material-ui/lab";
const DesignerBalance = () => {
  const {
    language: { dir, code },
  } = useContext(LangContext);
  const {
    mainTitle,
    totalBalanceTitle,
    suspendedBalance,
    withdrawOperation,
    balanceInput,
    withdrawMethod,
    withdraw,
    more,
    pending,
    completed,
    progress,
  } = desingerBalanceLocalization;

  const { user } = useContext(UesrContext);
  const [availableBalance, setAvailableBalance] = useState("");
  const [outStandingBalance, setoutStandingBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [walletHistory, setWalletHistory] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);

  const [withdrawMsg, setWithdrawMsg] = useState("");
const [walletHistoryCount, setWalletHistoryCount] = useState(1)
const [withdrawHistoryCount, setWithdrawHistoryCount] = useState(1)

  const [openLoader, setOpenLoader] = useState(true);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);



  const getBalance = useCallback(async () => {
    setOpenLoader(true);
    const data = await getEmployeeBalance(user);
    if (data?.data) {
      setAvailableBalance(data?.data?.balance?.Available);
      setWalletHistory(data?.data?.balance_histories);
      setoutStandingBalance(data?.data?.balance?.Outstanding);
    }
    setOpenLoader(false);
  },[user]) 


  const getWalletHistory = useCallback( async (page) => {
    const data = await getEmployeeWalletHistory(user,page);
    if (data?.data?.balance_histories) {
      setWalletHistory(data.data.balance_histories);
    }
    if(data?.data?.count) {
      const  total = +data?.data?.count
      const totalPages = Math.ceil(total / 10); // total pages 
      setWalletHistoryCount(totalPages)
    }
    
    setOpenLoader(false);
  },[user])

  const getWithdrawHistory =useCallback( async (page) => {
    const data = await getEmployeeWithdraw(user,page);
    console.log(data)
    if (data?.data?.data) {
      setWithdrawHistory(data.data?.data);
    }
    if(data?.data?.count) {
      const  total = +data?.data?.count
      const totalPages = Math.ceil(total / 10); // total pages 
      setWithdrawHistoryCount(totalPages)
    }
    setOpenLoader(false);
  },[user]);


  useEffect(() => {
    getBalance();
    getWalletHistory(1);
    getWithdrawHistory(1)
  }, [getBalance,getWithdrawHistory,getWalletHistory]);

  const employeeWithdraw = useCallback(async () => {
    if (!amount) {
      return;
    }
    setOpenLoader(true);
    const data = await postEmployeeWithdraw(user, amount);
    setWithdrawMsg(data.data.message);
    if(data?.data?.status === 0) {
      setFlashMsgIsOpen(true);
      setFlashMsg(data?.data?.message);
      setFlashMsgDuration(4000);
      setNotificationSeverity("error");
    } else {
      setFlashMsgIsOpen(true);
      setFlashMsg(data?.data?.message);
      setFlashMsgDuration(4000);
      setNotificationSeverity("success");
      getBalance()

    }
    setOpenLoader(false);
  }, [amount, user]);

  return (
    <>
      {openLoader && (
        <ContentLoader
          width="100%"
          height="100%"
          speed={1}
          rtl={dir === "rtl" ? true : false}
        >
          <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
        </ContentLoader>
      )}
      {!openLoader && (
        <div className="desinger-balance">
          <h1 className="main-title">{mainTitle[code]}</h1>
          <div className="main-content">
            <div className="total-susp-wrap">
              <div className={`total-wrap ${dir}`}>
                <div className="total-title">{totalBalanceTitle[code]}</div>
                <div className="total-value"> {availableBalance}$</div>
              </div>
              <div className="susp-wrap">
                <div className="susp-title">{suspendedBalance[code]}</div>
                <div className="susp-value">{outStandingBalance}$</div>
              </div>
            </div>

            <div className="msgs-operation-wrap">
              <div>
                {walletHistory.map((item) => (
                  <div key={item.id} className="msgs-wrap">
                    <div className={`msg-list-item ${dir}`}>
                      <img
                        className="msg-avatar"
                        src={item.image || designerAvatar}
                        alt=""
                      />
                      <div className="msg-data">
                        <div className="desc">
                          {item?.service_name}
                          {/* <span className="msg-sender"> احمد محمد علي</span> */}
                        </div>
                        <div className="desc">
                          {item?.amount} $
                        </div>
                        <div className="desc">
                          {item.description}
                        </div>

                        <div className="msg-date">
                          <AccessTime />
                          <div> {item.created_at}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <Pagination
                  count={walletHistoryCount}
                  color="primary"
                  onChange={(e, page) => {
                    getWalletHistory(page);
                  }}
                />
              </div>
              <div>
                <div className="operation-wrap">
                  <div className="top-wrap">
                    <div className="wrap-title">{withdrawOperation[code]}</div>
                    <input
                      type="number"
                      name="withdraw_input"
                      placeholder={balanceInput[code]}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="bottom-wrap">
                    {/* <div className="wrap-title">{withdrawMethod[code]}</div> */}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={employeeWithdraw}
                    >
                      {withdraw[code]}
                    </Button>
                  </div>
                </div>

                {withdrawHistory.map((item) => (
                  <div className="withdraw-history-item">
                    <p>
                      {withdraw[code]} {item.amount}
                    </p>
                    <span>
                      {
                        item?.status === "1" ? pending[code]
                        :item?.status === "2" ? progress[code]
                        : item?.status === "3" ? completed[code]
                        :""
                      }
                    </span>
                    <div className="flex-center">
                      <AccessTime />
                      <div> {item.created_at}</div>
                    </div>
                  </div>
                ))}
                <Pagination
                  count={withdrawHistoryCount}
                  color="primary"
                  onChange={(e, page) => {
                    getWithdrawHistory(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DesignerBalance;
