const incorrectUserTypeLocalization = {
  incorrectTypeOfUser: {
    ar: 'غير مسجل كمستخدم',
    en: 'Not registered as a user'
  },
  incorrectTypeOfDesigner: {
    ar: 'غير مسجل كوظف',
    en: 'Not registered as an emplyee'
  },
  incorrectTypeOfSupervisor: {
    ar: 'غير مسجل كمشرف',
    en: 'Not registered as an supervisor'
  }
};

export default incorrectUserTypeLocalization;
