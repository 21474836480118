import React, { useContext, useEffect, useState } from "react";
import { getEmployeeWalletHistory } from "../../../apis/employee/employeeApi";
import UesrContext from "../../../contexts/user-context/UserProvider";
import ContentLoader from "react-content-loader";
import LangContext from "../../../contexts/lang-context/LangProvider";
import { AccessTime } from "@material-ui/icons";
import designerAvatar from "../../../assets/imgs/designer-avatar.png";
import "./DesignerBalance.scss";
const DesignerTransactions = () => {
  const { user } = useContext(UesrContext);
  const [walletHistory, setWalletHistory] = useState([]);
  const [openLoader, setOpenLoader] = useState(true);
  const {
    language: { dir, code },
  } = useContext(LangContext);

  useEffect(() => {
    const getWalletHistory = async () => {
      const data = await getEmployeeWalletHistory(user);
      if (data?.data?.balance_histories) {
        setWalletHistory(data.data.balance_histories);
      }
      setOpenLoader(false);
    };

    getWalletHistory();
  }, [user]);

  return (
    <>
      {openLoader && (
        <ContentLoader
          width="100%"
          height="100%"
          speed={1}
          rtl={dir === "rtl" ? true : false}
        >
          <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
        </ContentLoader>
      )}

      {!openLoader && (
        <div className="desinger-balance transactions">
          <div className="main-content">
        <div className="msgs-operation-wrap">
          <div>
            {walletHistory.map((item) => (
              <div key={item.id} className="msgs-wrap">
                <div className={`msg-list-item ${dir}`}>
                  <img
                    className="msg-avatar"
                    src={item.image || designerAvatar}
                    alt="avatar"
                  />
                  <div className="msg-data">
                    <div className="desc">
                      {item.description}
                      {/* <span className="msg-sender"> احمد محمد علي</span> */}
                    </div>
                    <div className="msg-date">
                      <AccessTime />
                      <div> {item.created_at}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
        </div>
      )}
    </>
  );
};

export default DesignerTransactions;
