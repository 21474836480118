import React, { useContext, useState } from 'react';
import serviceDetailsLocalization from '../../localization/serviceDetailsLocalization';
import * as Yup from 'yup';
import LangContext from '../../contexts/lang-context/LangProvider';
import FormikStepper from '../../common/formik-stepper/FormikStepper';
import FlashMessageContext from '../../contexts/flash-messages-context/FlashMessageProvider';
import { service7InitialValues } from './serviceDetailsInitialValues';
import makeOrder from '../../apis/user/makeOrder';
import UesrContext from '../../contexts/user-context/UserProvider';
import ServiceSevenStepOne from './service-seven-steps/ServiceSevenStepOne';
import ServiceSevenStepTwo from './service-seven-steps/ServiceSevenStepTwo';
import checkRes from '../../utils/checkRes';
import makeOrder7Api from '../../apis/user/makeOrder7Api';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import routerLinks from '../app/routerLinks';
import { useHistory } from 'react-router-dom';
import ServiceStepZero from './service-step-zero/ServiceStepZero';

const Service7Steps = ({
  serviceId,
  foundService,
  setIsSubmittingOrder,
  setSuccessOrder,
  setSingleOrder
}) => {
  const history = useHistory();

  const SUPPORTED_FORMATS = {
    pdf: ['application/pdf'],
    images: ['image/jpg', 'image/jpeg', 'image/png']
  };

  const FILE_SIZE = 1024 * 1024; // 1024 * 1024 bytes = 1024 kbytes = 1 megabytes
  // const FILE_SIZE = 100 * 1024; // 100 kbytes
  const { user } = useContext(UesrContext);
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity
  } = useContext(FlashMessageContext);
  const {
    stepOne: {
      scriptIsReady: { no }
    }
  } = serviceDetailsLocalization;
  const customApiRequest = useCustomApiRequest();
  const [step, setStep] = useState(0);

  return (
    <FormikStepper
      step={step}
      setStep={setStep}
      stepperType="user"
      className="formik-stepper"
      initialValues={service7InitialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        setIsSubmittingOrder(true);
        customApiRequest(
          makeOrder7Api(user, foundService, values),
          (res) => {
            setIsSubmittingOrder(false);
            setSubmitting(false);
            if (checkRes(res)) {
              setFlashMsgIsOpen(true);
              setFlashMsg(res?.data?.message);
              setFlashMsgDuration(4000);
              setNotificationSeverity('success');
              history.push(routerLinks?.userCart);
            } else {
              setFlashMsgIsOpen(true);
              setFlashMsg(res?.data?.message || 'Something went wrong');
              setFlashMsgDuration(4000);
              setNotificationSeverity('error');
            }
          },
          (error) => {
            setIsSubmittingOrder(false);
            setSubmitting(false);
            setFlashMsgIsOpen(true);
            setFlashMsg(
              error?.response?.data?.message || 'Something went wrong'
            );
            setFlashMsgDuration(4000);
            setNotificationSeverity('error');
          }
        );
      }}
    >
      <ServiceStepZero foundService={foundService} />
      <ServiceSevenStepOne />
      <ServiceSevenStepTwo />
      {/*  */}
    </FormikStepper>
  );
};

export default Service7Steps;
