import axios from "axios";

const getConversationsApi = async (user) => {
    try {
        const func = await axios.get(
            `/api/v1/${user.accountType}/conversations?api_token=${user?.api_token}`,
            {
                headers: {
                    password: process.env.REACT_APP_AUTH_PASS,
                    "X-Portal": user?.accountType,

                }
            }
        );

        return func;
    } catch (e) {
        throw e;
    }
};
export default getConversationsApi