/* eslint-disable react-hooks/exhaustive-deps */
import "./ServiceStepFour.scss";

import React, { useContext, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { FormControlLabel, FormHelperText, Radio, Checkbox } from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import scalesImgs from "./mocks";
import { useState } from "react";
import "./ServiceStepFour.scss";

const ServiceStepFour = () => {
  const {
    language: { code },
  } = useContext(LangContext);
  const {
    stepFour: { mainTitle, twitter, instagram, facebook, snapChat, youtube, tiktok, LinkedIn },
  } = serviceDetailsLocalization;
  const { isSubmitting, touched, errors, values, setTouched, setFieldValue } = useFormikContext();

  const twitterArray = [
    {
      id: 1,
      label: {
        en: "1500px × 500px",
        ar: "1500بكسل × 500بكسل",
      },
      value: {
        en: "1500px × 500px",
        ar: "1500بكسل × 500بكسل",
      },
      img: scalesImgs.twitter5001500,
    },
    {
      id: 2,
      label: {
        en: "1024px × 512px",
        ar: "1024بكسل × 512بكسل",
      },
      value: {
        en: "1024px × 512px",
        ar: "1024بكسل × 512بكسل",
      },
      img: scalesImgs.twitter5121024,
    },
    {
      id: 3,
      label: {
        en: "1080px × 1080px",
        ar: "1080بكسل × 1080بكسل",
      },
      value: {
        en: "1080px × 1080px",
        ar: "1080بكسل × 1080بكسل",
      },
      img: scalesImgs.twitter10801080,
    },
  ];
  const instagramArray = [
    {
      id: 1,
      label: {
        en: "1080px × 1350px",
        ar: "1080بكسل × 1350بكسل",
      },
      value: {
        en: "1080px × 1350px",
        ar: "1080بكسل × 1350بكسل",
      },
      img: scalesImgs.insta13501080,
    },
    {
      id: 2,
      label: {
        en: "1080px × 2340px",
        ar: "1080بكسل × 2340بكسل",
      },
      value: {
        en: "1080px × 2340px",
        ar: "1080بكسل × 2340بكسل",
      },
      img: scalesImgs.insta23401080,
    },
    {
      id: 3,
      label: {
        en: "1080px × 1080px",
        ar: "1080بكسل × 1080بكسل",
      },
      value: {
        en: "1080px × 1080px",
        ar: "1080بكسل × 1080بكسل",
      },
      img: scalesImgs.insta10801080,
    },
    {
      id: 4,
      label: {
        en: "1080px × 522px",
        ar: "1080بكسل × 522بكسل",
      },
      value: {
        en: "1080px × 522px",
        ar: "1080بكسل × 522بكسل",
      },
      img: scalesImgs.insta5221080,
    },
  ];
  const facebookArray = [
    {
      id: 1,
      label: {
        en: "1200px × 675px",
        ar: "1200بكسل × 675بكسل",
      },
      value: {
        en: "1200px × 675px",
        ar: "1200بكسل × 675بكسل",
      },
      img: scalesImgs.fb6751200,
    },
    {
      id: 2,
      label: {
        en: "1200px × 628px",
        ar: "1200بكسل × 628بكسل",
      },
      value: {
        en: "1200px × 628px",
        ar: "1200بكسل × 628بكسل",
      },
      img: scalesImgs.fb6281200,
    },
    {
      id: 3,
      label: {
        en: "1080px × 1080px",
        ar: "1080بكسل × 1080بكسل",
      },
      value: {
        en: "1080px × 1080px",
        ar: "1080بكسل × 1080بكسل",
      },
      img: scalesImgs.fb10801080,
    },
  ];
  const snapChatArray = [
    {
      id: 1,
      label: {
        en: "1080px × 1920px",
        ar: "1080بكسل × 1920بكسل",
      },
      value: {
        en: "1080px × 1920px",
        ar: "1080بكسل × 1920بكسل",
      },
      img: scalesImgs.snap19201080,
    },
  ];
  const youtubeArray = [
    {
      id: 1,
      label: {
        en: "1280px × 720px",
        ar: "1200بكسل × 720بكسل",
      },
      value: {
        en: "1280px × 720px",
        ar: "1200بكسل × 720بكسل",
      },
      img: scalesImgs.youtube7201200,
    },
    {
      id: 2,
      label: {
        en: "1080px × 1920px",
        ar: "1080بكسل × 1920بكسل",
      },
      value: {
        en: "1080px × 1920px",
        ar: "1080بكسل × 1920بكسل",
      },
      img: scalesImgs.youtube19201080,
    },
    {
      id: 3,
      label: {
        en: "1920px × 1080px",
        ar: "1920بكسل × 1080بكسل",
      },
      value: {
        en: "1920px × 1080px",
        ar: "1920بكسل × 1080بكسل",
      },
      img: scalesImgs.youtube10801920,
    },
  ];

  const tiktokArray = [
    {
      id: 1,
      label: {
        en: "1080px × 1920px",
        ar: "1080بكسل × 1920بكسل",
      },
      value: {
        en: "1080px × 1920px",
        ar: "1080بكسل × 1920بكسل",
      },
      img: scalesImgs.tiktok19201080,
    },
  ];
  const linkedInArray = [
    {
      id: 1,
      label: {
        en: "1548px × 396px",
        ar: "1548بكسل × 396بكسل",
      },
      value: {
        en: "1548px × 396px",
        ar: "1548بكسل × 396بكسل",
      },
      img: scalesImgs.linkedIn3961584,
    },
    {
      id: 2,
      label: {
        en: "1200px × 628px",
        ar: "1200بكسل × 628بكسل",
      },
      value: {
        en: "1200px × 628px",
        ar: "1200بكسل × 628بكسل",
      },
      img: scalesImgs.linkedIn6281200,
    },
  ];
  useEffect(() => {
    setTouched({});
  }, []);

  const [selectedTwitter, setSelectedTwitter] = useState([]);
  const [selectedFb, setSelectedFb] = useState([]);
  const [selectedInsta, setSelectedInsta] = useState([]);
  const [selectedSnapChat, setSelectedSnapChat] = useState([]);
  const [selectedYoutube, setSelectedYoutube] = useState([]);
  const [selectedTikTok, setSelectedTikTok] = useState([]);
  const [selectedLinkedin, setSelectedLinkedin] = useState([]);

  useEffect(() => {
    if (values.twitterScale) {
      setSelectedTwitter(values.twitterScale);
    } else setSelectedTwitter([]);
  }, [values.twitterScale]);

  useEffect(() => {
    if (values.facebookScale) {
      setSelectedFb(values.facebookScale);
    } else setSelectedFb([]);
  }, [values.facebookScale]);

  useEffect(() => {
    if (values.instagramScale) {
      setSelectedInsta(values.instagramScale);
    } else setSelectedInsta([]);
  }, [values.instagramScale]);

  useEffect(() => {
    if (values.snapChatScale) {
      setSelectedSnapChat(values.snapChatScale);
    } else setSelectedSnapChat([]);
  }, [values.snapChatScale]);

  useEffect(() => {
    if (values.youtubeScale) {
      setSelectedYoutube(values.youtubeScale);
    } else setSelectedYoutube([]);
  }, [values.youtubeScale]);

  useEffect(() => {
    if (values.tiktokScale) {
      setSelectedTikTok(values.tiktokScale);
    } else setSelectedTikTok([]);
  }, [values.tiktokScale]);

  useEffect(() => {
    if (values.linkedin_scale) {
      setSelectedLinkedin(values.linkedin_scale);
    } else setSelectedLinkedin([]);
  }, [values.linkedin_scale]);

  const handleSelected = (isChecked, value, id, selectedArr, setSelectedArr, fieldValue) => {
    let selectedArrClone = selectedArr.map((item) => item);

    if (isChecked) {
      selectedArrClone.push({
        id,
        value,
      });
    } else {
      selectedArrClone = selectedTwitter.filter((item) => item.id !== id);
    }
    setSelectedArr(selectedArrClone);
    setFieldValue(fieldValue, selectedArrClone);
  };

  const renderRadiosGroup = (arr, fieldName) => {
    const handleChange = (e, id) => {
      switch (e.target.name) {
        case "twitterScale":
          handleSelected(
            e.target.checked,
            e.target.value,
            id,
            selectedTwitter,
            setSelectedTwitter,
            "twitterScale"
          );
          break;
        case "instagramScale":
          handleSelected(
            e.target.checked,
            e.target.value,
            id,
            selectedInsta,
            setSelectedInsta,
            "instagramScale"
          );
          break;
        case "facebookScale":
          handleSelected(
            e.target.checked,
            e.target.value,
            id,
            selectedFb,
            setSelectedFb,
            "facebookScale"
          );
          break;
        case "snapChatScale":
          handleSelected(
            e.target.checked,
            e.target.value,
            id,
            selectedSnapChat,
            setSelectedSnapChat,
            "snapChatScale"
          );
          break;
        case "youtubeScale":
          handleSelected(
            e.target.checked,
            e.target.value,
            id,
            selectedYoutube,
            setSelectedYoutube,
            "youtubeScale"
          );
          break;
        case "tiktokScale":
          handleSelected(
            e.target.checked,
            e.target.value,
            id,
            selectedTikTok,
            setSelectedTikTok,
            "tiktokScale"
          );
          break;
        case "linkedin_scale":
          handleSelected(
            e.target.checked,
            e.target.value,
            id,
            selectedLinkedin,
            setSelectedLinkedin,
            "linkedin_scale"
          );
          break;
        default:
          break;
      }
    };

    const isScaleChecked = (fieldName, id) => {
      let itemId;
      switch (fieldName) {
        case "twitterScale":
          itemId = selectedTwitter.find((item) => item.id === id);
          return itemId === undefined ? false : true;
        case "instagramScale":
          itemId = selectedInsta.find((item) => item.id === id);
          return itemId === undefined ? false : true;

        case "facebookScale":
          itemId = selectedFb.find((item) => item.id === id);
          return itemId === undefined ? false : true;

        case "snapChatScale":
          itemId = selectedSnapChat.find((item) => item.id === id);
          return itemId === undefined ? false : true;

        case "youtubeScale":
          itemId = selectedYoutube.find((item) => item.id === id);
          return itemId === undefined ? false : true;

        case "tiktokScale":
          itemId = selectedTikTok.find((item) => item.id === id);
          return itemId === undefined ? false : true;

        case "linkedin_scale":
          itemId = selectedLinkedin.find((item) => item.id === id);
          return itemId === undefined ? false : true;

        default:
          return false;
      }
    };

    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.value[code]}
            control={
              <Checkbox
                color='primary'
                disabled={isSubmitting}
                checked={isScaleChecked(fieldName, item.id)}
              />
            }
            label={item.label[code]}
            disabled={isSubmitting}
            name={fieldName}
            onChange={(e) => handleChange(e, item.id)}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  return (
    <div className='formik-step service-step-four'>
      <h2>{mainTitle[code]}</h2>
      <div className='radios-img-wrapper'>
        <div className='radio-group-wrapper'>
          <h3 className='group-title'>{twitter.label[code]}</h3>
          <Field className='field-group-wrap' component={RadioGroup} name='twitterScale'>
            {renderRadiosGroup(twitterArray, "twitterScale")}
          </Field>
        </div>
        {selectedTwitter.length > 0 &&
          selectedTwitter.map((item) => {
            const id = item.id - 1;
            return <img src={twitterArray[id].img} alt='scale' key={"twitter" + id} />;
          })}
      </div>

      <div className='radios-img-wrapper'>
        <div className='radio-group-wrapper'>
          <h3 className='group-title'>{instagram.label[code]}</h3>
          <Field className='field-group-wrap' component={RadioGroup} name='instagramScale'>
            {renderRadiosGroup(instagramArray, "instagramScale")}
          </Field>
        </div>
        {selectedInsta.length > 0 &&
          selectedInsta.map((item) => {
            const id = item.id - 1;
            return <img src={instagramArray[id].img} alt='scale' key={"insta" + id} />;
          })}
      </div>

      <div className='radios-img-wrapper'>
        <div className='radio-group-wrapper'>
          <h3 className='group-title'>{facebook.label[code]}</h3>
          <Field className='field-group-wrap' component={RadioGroup} name='facebookScale'>
            {renderRadiosGroup(facebookArray, "facebookScale")}
          </Field>
        </div>
        {selectedFb.length > 0 &&
          selectedFb.map((item) => {
            const id = item.id - 1;
            return <img src={facebookArray[id].img} alt='scale' key={"facebook" + id} />;
          })}{" "}
      </div>

      <div className='radios-img-wrapper'>
        <div className='radio-group-wrapper'>
          <h3 className='group-title'>{snapChat.label[code]}</h3>
          <Field className='field-group-wrap' component={RadioGroup} name='snapChatScale'>
            {renderRadiosGroup(snapChatArray, "snapChatScale")}
          </Field>
        </div>
        {selectedSnapChat.length > 0 &&
          selectedSnapChat.map((item) => {
            const id = item.id - 1;
            return <img src={snapChatArray[id].img} alt='scale' key={"snapchat" + id} />;
          })}{" "}
      </div>
      <div className='radios-img-wrapper'>
        <div className='radio-group-wrapper'>
          <h3 className='group-title'>{youtube.label[code]}</h3>
          <Field className='field-group-wrap' component={RadioGroup} name='youtubeScale'>
            {renderRadiosGroup(youtubeArray, "youtubeScale")}
          </Field>
        </div>
        {selectedYoutube.length > 0 &&
          selectedYoutube.map((item) => {
            const id = item.id - 1;
            return <img src={youtubeArray[id].img} alt='scale' key={"youtube" + id} />;
          })}{" "}
      </div>

      <div className='radios-img-wrapper'>
        <div className='radio-group-wrapper'>
          <h3 className='group-title'>{tiktok.label[code]}</h3>
          <Field className='field-group-wrap' component={RadioGroup} name='tiktokScale'>
            {renderRadiosGroup(tiktokArray, "tiktokScale")}
          </Field>
        </div>
        {selectedTikTok.length > 0 &&
          selectedTikTok.map((item) => {
            const id = item.id - 1;
            return <img src={tiktokArray[id].img} alt='scale' key={"tiktok" + id} />;
          })}{" "}
      </div>
      <div className='radios-img-wrapper'>
        <div className='radio-group-wrapper'>
          <h3 className='group-title'>{LinkedIn.label[code]}</h3>
          <Field className='field-group-wrap' component={RadioGroup} name='linkedin_scale'>
            {renderRadiosGroup(linkedInArray, "linkedin_scale")}
          </Field>
        </div>
        {selectedLinkedin.length > 0 &&
          selectedLinkedin.map((item) => {
            const id = item.id - 1;
            return <img src={linkedInArray[id].img} alt='scale' key={"linkedin" + id} />;
          })}{" "}
      </div>
    </div>
  );
};

export default ServiceStepFour;
