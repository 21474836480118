const forgetPasswordLocalization = {
  email: {
    label: {
      ar: "البريد الالكترونى",
      en: "Email address",
    },
    errors: {
      required: {
        ar: "برجاء ادخال البريد الالكترونى",
        en: "Enter your email address",
      },
      typeError: {
        ar: "برجاء ادخال بريد الكترونى صحيح",
        en: "Provide a vaild email address",
      },
    },
  },
  activationCode: {
    label: {
      ar: "ادخل كود التفعيل",
      en: "Enter activation code",
    },
    errors: {
      required: {
        ar: "برجاء ادخال كود التفعيل",
        en: "Please enter activation code",
      },
    },
  },
  submitBtn: {
    label: {
      ar: "ارسل الان",
      en: "Send now",
    },
    sending: {
      ar: "جارى الارسال",
      en: "Sending",
    },
  },
  successMsg: {
    ar: "تم ارسال كود التفعيل على الايميل الخاص بك",
    en: "Activation code sent to your email",
  },
  resendBtn: {
    ar: "اعادة الارســال",
    en: "Resend code",
  },
  resetPassMsg: {
    ar: "تم تغير كلمة المرور بنجاح",
    en: "Your password changed successfully",
  },

  //
  password: {
    label: {
      ar: "كلمة المرور",
      en: "Password",
    },
    errors: {
      required: {
        ar: "برجاء ادخال كلمة المرور",
        en: "Please enter your password",
      },
      minChar: (min) => ({
        ar: `كلمة المرور بحد ادنى ${min} حرف`,
        en: `Password must be at least ${min} chars`,
      }),
      maxChar: (max) => ({
        ar: `كلمة المرور بحد اقصى ${max} حرف`,
        en: `Password must be at Most ${max} chars`,
      }),
      trim: {
        ar: "لا يسمح بالفراغات فى بداية او نهاية كلمة المرور",
        en: "No leading or trailing spaces",
      },
    },
  },
  confirmPassword: {
    label: {
      ar: "تأكيد كلمة المرور",
      en: "Confirm Password",
    },
    errors: {
      required: {
        ar: "برجاء ادخال تأكيد كلمة المرور",
        en: "Enter your password confirmation",
      },
      validate: {
        ar: "كلمة المرور غير مطابقة",
        en: "The password did't match",
      },
    },
  },
  successPassChanged: {
    ar: "تم تعديل الرقم السرى بنجاح",
    en: "Password changed successfully",
  },
};

export default forgetPasswordLocalization;
