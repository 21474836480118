/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import ServicesCard from '../../common/services-card/ServicesCard';
import LangContext from '../../contexts/lang-context/LangProvider';
import useServices from '../../custom-hooks/useServices';
import routerLinks from '../app/routerLinks';

const ServicesList = () => {
  const {
    language: { dir }
  } = useContext(LangContext);

  // const { user } = useContext(UesrContext);

  const { isLoadingServices, allServices } = useServices();

  const renderServices = () => {
    if (isLoadingServices) {
      return (
        <>
          <div className="service-card-loader-box">
            <ContentLoader
              width="100%"
              height="100%"
              speed={1}
              rtl={dir === 'rtl' ? true : false}
            >
              <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
            </ContentLoader>
          </div>

          <div className="service-card-loader-box">
            <ContentLoader
              width="100%"
              height="100%"
              speed={1}
              rtl={dir === 'rtl' ? true : false}
            >
              <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
            </ContentLoader>
          </div>
          <div className="service-card-loader-box">
            <ContentLoader
              width="100%"
              height="100%"
              speed={1}
              rtl={dir === 'rtl' ? true : false}
            >
              <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
            </ContentLoader>
          </div>
          <div className="service-card-loader-box">
            <ContentLoader
              width="100%"
              height="100%"
              speed={1}
              rtl={dir === 'rtl' ? true : false}
            >
              <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
            </ContentLoader>
          </div>
        </>
      );
    }
    if (!allServices && !isLoadingServices) {
      return <p style={{ textAlign: 'center' }}>No services available</p>;
    }
    // return services.map(({ id, name }, index) => (
    return allServices?.map((serv, index) => {
      return (
        <ServicesCard
          index={index}
          // to={`/services/${slugMe(title)}`}
          to={routerLinks.makeOrder(serv?.id)}
          key={serv?.id}
          title={serv?.title}
          video={serv?.video}
          image={serv?.image}
          id={serv?.id}
        />
      );
    });
  };

  return renderServices();
};

export default ServicesList;
