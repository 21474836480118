/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "./DesignerProjects.scss";
import React from "react";
import useCustomApiRequest from "../../../custom-hooks/useCustomApiRequest";
import getEmployeeOrdersApi from "../../../apis/employee/getEmployeeOrdersApi";
import { useContext } from "react";
import UesrContext from "../../../contexts/user-context/UserProvider";
import { useEffect } from "react";
import LangContext from "../../../contexts/lang-context/LangProvider";
import checkRes from "../../../utils/checkRes";
import ContentLoader from "react-content-loader";
import EmployeeOrdersCard from "./EmployeeOrdersCard";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { OrderStatusArr, orderStatusValues } from "../../../orderStatus";
import routerLinks from "../../../components/app/routerLinks";
import queryString from "query-string";
import EmployeeAcceptModal from "./EmployeeAcceptModal";
import EmployeeRejectModal from "./EmployeeRejectModal";
import EmployeeOrdersContext from "../../../contexts/employee-orders-context/EmployeeOrdersContext";
import LoadingModal from "../../../common/loading-modal/LoadingModal";
import designerHomeLocalization from "../../../localization/designerHome.localization";

const DesignerProjects = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);

  const { user } = useContext(UesrContext);
  const {noOrder} = designerHomeLocalization
  const {
    language: { code, dir },
  } = useContext(LangContext);
  const {
    isLoadingData,
    setIsLoadingData,
    fetchedData,
    setFetchedData,
    fetchedDataCount,
    isSubmitting,
  } = useContext(EmployeeOrdersContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user) {
      const fetchCart = () => {
        setIsLoadingData(true);
        customApiRequest(
          getEmployeeOrdersApi(
            { status: values?.status || orderStatusValues?.pending },
            user,
            code
          ),
          (res) => {
            setIsLoadingData(false);
            if (checkRes(res) && res?.data?.data?.length >= 0) {
              setFetchedData(
                res.data.data.map((item) => ({
                  ...item,
                  orderStatus: values?.status,
                }))
              );
            } else {
              setFetchedData([]);
            }
          },
          (error) => {
            setIsLoadingData(false);
          }
        );
      };
      fetchCart();
    }

    return () => (isMounted = false);
  }, [code, search, fetchedDataCount]);

  const renderContentFilter = () => {
    return (
      <div className="content-filter">
        {OrderStatusArr()?.length > 0 &&
          OrderStatusArr().map((obj) => {
            return (
              <RouterLink
                className={`filter-link ${
                  values?.status == obj?.value ? "active" : ""
                }`}
                key={obj?.id}
                to={`${routerLinks?.designerProjects}?status=${
                  obj?.value || ""
                }`}
              >
                {obj?.name || ""}
              </RouterLink>
            );
          })}
      </div>
    );
  };

  const renderContent = () => {
    if (isLoadingData) {
      return (
        <>
          <div className="service-card-loader-box">
            <ContentLoader
              width="100%"
              height="100%"
              speed={1}
              rtl={dir === "rtl" ? true : false}
            >
              <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
            </ContentLoader>
          </div>
        </>
      );
    } else if (fetchedData?.length > 0) {
      return (
        <div className="filter-orders-wrapper">
          {renderContentFilter()}
          <div className="designer-projects">
            {fetchedData.map((order) => {
              return (
                <EmployeeOrdersCard
                  key={order?.id}
                  withBtn={true}
                  card={order}
                />
              );
            })}
          </div>
        </div>
      );
    } else if (fetchedData?.length === 0) {
      return (
        <div className="not-found-filter-wrapper">
          {renderContentFilter()}
          <div className="empty-wrapper">
            <h2>   {noOrder[code]}</h2>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="designer-projects-wrapper">
      {renderContent()}
      <EmployeeAcceptModal />
      <EmployeeRejectModal />
      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default DesignerProjects;
