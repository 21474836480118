import { createContext } from "react";
import { DeviceUUID } from "device-uuid";

const INITIAL_VALUES = {
  deviceId: new DeviceUUID().get(),
};
const DeviceIdContext = createContext(INITIAL_VALUES);
export const DeviceIdProvider = ({ children }) => {
  return (
    <DeviceIdContext.Provider
      value={{
        deviceId: INITIAL_VALUES.deviceId,
      }}
    >
      {children}
    </DeviceIdContext.Provider>
  );
};

export default DeviceIdContext;
