import './ApplicationStepFive.scss';

import React, { useContext } from 'react';
import { Field, useFormikContext } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import designerApplicationLocalization from '../../../localization/designerApplication.localization';
import LangContext from '../../../contexts/lang-context/LangProvider';
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  FormHelperText
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1
  },
  field: {
    margin: '10px 0'
  }
}));

const ApplicationStepFive = () => {
  const {
    language: { code }
  } = useContext(LangContext);
  const { stepFive } = designerApplicationLocalization;

  const { touched, errors } = useFormikContext();

  return (
    <div className="formik-step application-step-five">
      <div className="step-title">{stepFive.mainTitle[code]}</div>

      <div className="fields-wrap">
        <div className="terms-conditions-wrap">
          <Field
            component={CheckboxWithLabel}
            name="is_accept"
            type="checkbox"
            Label={{
              // label: agreeTerms.label[code],
              className: 'terms-input'
            }}
            // label={agreeTerms.label[code]}
            color="primary"
          />

          <div
            className={`terms-btn ${
              touched?.is_accept && errors?.is_accept ? 'error' : ''
            }`}
          >
            يرجى الاطلاع والموافقة على شروط العمل
          </div>
        </div>
      </div>

      <h3>أنا على إطلاع تام وموافقة وإلتزام</h3>
      <ul>
        <li>
          - الموافقة على اسعار الشركة حسب التخصص ( رابط قائمة السعار ) حسب التخصص
          الذي تم أختياره من قبل الموظف
        </li>
        <li>- العمل كموظف متعاون</li>
        <li>- الالتزام بوقت الانتاج المحدد للشركة حسب التخصص.</li>
        <li>
          - التقييم بأقل من ٣ نجوم يؤثر سلبا في الستمرارية في تقديم الخدمة وذلك
          بعد مراجعة الادارة للخدمة المقدمة.
        </li>
        <li>
          - في حالة الزبون رفض العمل لعدم رضاه يحق للشركة عدم صرف البلغ المتفق
          عليه.
        </li>
        <li>
          - في حالة التأخير عن المدة المحددة لانتاج العمل ( بسبب الوظف ) يحق لشركة
          عدم الاستمرارية لعمال قادمة مستقبليا.
        </li>
        <li>- الاتزام بخصوصية العمل وعدم نشر العمل في أي موقع مطلقا.</li>
        <li>
          - كل المواد المستخدمة في الانتاج من صوت أو صورة أو فكرة أونص تكون محفوظة
          الحقوق.
        </li>
      </ul>
    </div>
  );
};

export default ApplicationStepFive;
