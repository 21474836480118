/* eslint-disable react-hooks/exhaustive-deps */
import './LangSelect.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import langIcon from '../../assets/imgs/icons/languages.svg';
import languages from '../../contexts/lang-context/languages';
import LangContext from '../../contexts/lang-context/LangProvider';
import changeLanguage from '../../apis/change-language';
import UesrContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';

const LangSelect = () => {
  const { user } = useContext(UesrContext);
  const { language, setLanguage } = useContext(LangContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickLang = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLangMenu = (lang) => {
    setAnchorEl(null);
    if (lang?.code) {
      setLanguage(lang);
    }
  };

  const renderLangs = () => {
    return languages.map((lang) => (
      <MenuItem key={lang.id} onClick={() => handleCloseLangMenu(lang)}>
        <img src={lang.flag} alt={`${lang.name} flag`} />
        {lang.name}
      </MenuItem>
    ));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    if (user?.activated && user?.api_token) {
      customApiRequest(
        changeLanguage(language?.code, user),
        (res) => {},
        (error) => {}
      );
    }
  }, [language, user]);

  return (
    <>
      <div className="lang-btn" onClick={handleClickLang}>
        <img src={language ? language.flag : langIcon} alt="change lang icon" />
        {/* <span>{language.name}</span> */}
      </div>

      <Menu
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseLangMenu}
        className="lang-menu"
      >
        {renderLangs()}
      </Menu>
    </>
  );
};

export default LangSelect;
