import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import DesignerBalance from '../../pages/dashboard/designer-balance/DesignerBalance';
import DesignerHome from '../../pages/dashboard/designer-home/DesignerHome';
import DesignerMessages from '../../pages/dashboard/designer-messages/DesignerMessages';
import DesignerNotifications from '../../pages/dashboard/designer-notifications/DesignerNotifications';
import DesignerProjects from '../../pages/dashboard/designer-projects/DesignerProjects';
import DesignerSettings from '../../pages/dashboard/designer-settings/DesignerSettings';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import routerLinks from './routerLinks';
import UserContext from '../../contexts/user-context/UserProvider';
import SupervisorOrders from '../../pages/dashboard/supervisor-orders/SupervisorOrders';
import SupervisorOrdersDetails from '../../pages/dashboard/supervisor-orders/SupervisorOrdersDetails';
import DesignerApplication from '../designer-application/DesignerApplication';
import indexLocalization from '../../localization';
import LangContext from '../../contexts/lang-context/LangProvider';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import registerTokenNotificationsApi from '../../apis/conversations/registerToken';
import AllowNotificationsModal from '../AllowNotificationsModal/AllowNotificationsModal';

const SupervisorRoutes = () => {
  const { user } = useContext(UserContext);
  const [NewMessageNotify, setNewMessageNotify] = useState(false)
  const [NewNotificationNotify, setNewNotificationNotify] = useState(false)

  const {newMessage,newNotification} = indexLocalization
  const {
    language: { dir, code },
  } = useContext(LangContext);
  const [openAllowNoti, setOpenAllowNoti] = useState(false)

  const firebaseConfig = useMemo(() =>{
    return {
      apiKey: "AIzaSyAEyHtoZqYQUC25bGfkhvYTBPVMgDZkivM",
      authDomain: "videotube-f6a14.firebaseapp.com",
      projectId: "videotube-f6a14",
      storageBucket: "videotube-f6a14.appspot.com",
      messagingSenderId: "1065299110898",
      appId: "1:1065299110898:web:32dd5c4202995ed673996b",
    }
  },[])

  const vapidKey =
    "BEdRyFebE2WqWtHWIoazamSjhdF8Oyld0mFkW7JV7t6t93Eh8m-XWYwbjEmj1je5uahJXQ4_V1Je6ZloSYbYVHg";

  useEffect(() => {
    if( window.isSecureContext || window.location.protocol === 'https:') {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      const isNotificationSupported = 'Notification' in window;
      if(!isNotificationSupported) {
        return 
      }

      Notification?.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            // get the FCM token
            let deviceID = "";
            getToken(messaging, { vapidKey: vapidKey })
              .then((token) => {
                if (user.accountType === "employee") {
                  deviceID = `EM_${user.id}`;
                } else if (user.accountType === "supervisor") {
                  deviceID = `SU_${user.id}`;
                }

                registerTokenNotificationsApi(user, token, deviceID);
                onMessage(messaging, (payload) => {
                  console.log("new message",{payload});


                  if(payload?.data?.conversation_id) {
                    setNewMessageNotify(true)
                    const notification = new Notification(newMessage[code], { body: payload?.data?.content});
                  } else {
                    const notification = new Notification(newNotification[code], { body: payload?.data?.content});
                    setNewNotificationNotify(true)

                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log("Permission denied");
            setOpenAllowNoti(true)
          }
        })
        .catch((error) => {
          console.log(error);
        });

    }
  },[firebaseConfig,user,code])
  return (
    <>
      <AllowNotificationsModal  open={openAllowNoti} onClose={() => setOpenAllowNoti(false)} />

      <Switch>
        {/* home and designer stepper form */}

        {/* tatus == 1 ===> accpeted status */}
        {/* {user?.accountType === 'employee' && user?.employee && user.employee.status !== 1 && ( */}
        {/* {user?.accountType === userTypes?.employee && (
        <Route exact path={routerLinks.designerHome}>
          <DesignerHome />
        </Route>
      )} */}
        <Route exact path={routerLinks.supervisorHome}>
          <DesignerHome />
        </Route>

        <Route exact path={routerLinks.supervisorOrders}>
          <SupervisorOrders />
        </Route>
        <Route exact path={routerLinks.supervisorOrdersDetails}>
          <SupervisorOrdersDetails />
        </Route>
        <Route exact path={routerLinks.supervisorNotifications}>
          <DesignerNotifications notify={NewNotificationNotify} setNotify={setNewNotificationNotify} />
        </Route>
        <Route exact path={routerLinks.supervisorMessages}>
          <DesignerMessages notify={NewMessageNotify} setNotify={setNewMessageNotify} />
        </Route>
        <Route exact path={routerLinks.supervisorDirectMessages}>
          <DesignerMessages />
        </Route>
        <Route exact path={routerLinks.supervisorApplication}>
          <DesignerApplication />
        </Route>

        <Route exact path={routerLinks.supervisorBalance}>
          <DesignerBalance />
        </Route>
        <Route exact path={routerLinks.supervisorSettings}>
          <DesignerSettings />
        </Route>

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default SupervisorRoutes;
