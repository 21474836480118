const stepperBtnsLocalization = {
  nextBtn: {
    ar: 'التالى',
    en: 'Next'
  },
  prevBtn: {
    ar: 'السابق',
    en: 'Previous'
  },
  submitServiceBtn: {
    ar: 'اضف الى العربة',
    en: 'Add to cart'
  },
  apply: {
    ar: 'تقدم للطلب',
    en: 'Make order'
  },
  submitDesignerBtn: {
    ar: 'تقديم طلب الوظيفة',
    en: 'Apply for job'
  }
};

export default stepperBtnsLocalization;
