import React, { useContext, useEffect, useMemo, useState } from "react";
import ApplePay from "../../common/apple-pay/ApplePay";
import Paypal from "../../common/paypal/Paypal";
import Visa from "../../common/visa/Visa";
import ChatWithDesigner from "../../pages/dashboard/my-orders/ChatWithDesigner";
import MyOrders from "../../pages/dashboard/my-orders/MyOrders";
import OrderDetails from "../../pages/dashboard/my-orders/OrderDetails";
// import PaymentMethods from "../../pages/dashboard/payment-methods/PaymentMethods";
import ServicesTab from "../../pages/dashboard/services-tab/ServicesTab";
import UserMessages from "../../pages/dashboard/user-messages/UserMessages";
import UserNotifications from "../../pages/dashboard/user-notifications/UserNotifications";
import UserSettings from "../../pages/dashboard/user-settings/UserSettings";
import protectMe from "../../utils/protectMe";
import ServiceDetails from "../service-details/ServiceDetails";
import ChoosePayment from "../user-cart/ChoosePayment";
import UserCart from "../user-cart/UserCart";
import routerLinks from "./routerLinks";
import UserContext from "../../contexts/user-context/UserProvider";
import { Route } from "react-router-dom";
import PaymentError from "../../pages/payment-pages/PaymentError";
import PaymentSuccess from "../../pages/payment-pages/PaymentSuccess";
import FooterPage from "../../pages/footer-page/FooterPage";
import LangContext from "../../contexts/lang-context/LangProvider";
import indexLocalization from "../../localization";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import registerTokenNotificationsApi from '../../apis/conversations/registerToken';
import AllowNotificationsModal from "../AllowNotificationsModal/AllowNotificationsModal";

const UserRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const [NewMessageNotify, setNewMessageNotify] = useState(false)
  const [NewNotificationNotify, setNewNotificationNotify] = useState(false)
  const {newMessage,newNotification} = indexLocalization
  const [openAllowNoti, setOpenAllowNoti] = useState(false)

  const {
    language: { dir, code },
  } = useContext(LangContext);

  const firebaseConfig = useMemo(() =>{
    return {
      apiKey: "AIzaSyAEyHtoZqYQUC25bGfkhvYTBPVMgDZkivM",
      authDomain: "videotube-f6a14.firebaseapp.com",
      projectId: "videotube-f6a14",
      storageBucket: "videotube-f6a14.appspot.com",
      messagingSenderId: "1065299110898",
      appId: "1:1065299110898:web:32dd5c4202995ed673996b",
    }
  },[]) 

  const vapidKey =
    "BEdRyFebE2WqWtHWIoazamSjhdF8Oyld0mFkW7JV7t6t93Eh8m-XWYwbjEmj1je5uahJXQ4_V1Je6ZloSYbYVHg";

  useEffect(() => {
    if( window.isSecureContext || window.location.protocol === 'https:') {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);    
      const isNotificationSupported = 'Notification' in window;
      if(!isNotificationSupported) {
        return 
      }
      Notification?.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          // get the FCM token
          let deviceID = "";
          getToken(messaging, { vapidKey: vapidKey })
            .then((token) => {
              if (user.accountType === "employee") {
                deviceID = `EM_${user.id}`;
              } else if (user.accountType === "supervisor") {
                deviceID = `SU_${user.id}`;
              }

              registerTokenNotificationsApi(user, token, deviceID);
              onMessage(messaging, (payload) => {
                console.log("new message",{payload});


                if(payload?.data?.conversation_id) {
                  setNewMessageNotify(true)
                  const notification = new Notification(newMessage[code], { body: payload?.data?.content});
                }else {
                  const notification = new Notification(newNotification[code], { body: payload?.data?.content});
                  setNewNotificationNotify(true)
                }



                
                // if(orderId) {
                //   getMessage(orderId, type, typeId);

                // } else {
                //   getConversations()
                // }
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("Permission denied");
          setOpenAllowNoti(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });

    }
  },[firebaseConfig,user,code])

  return [
    <AllowNotificationsModal client open={openAllowNoti} onClose={() => setOpenAllowNoti(false)}/>,
    protectMe(
      routerLinks.userServices,
      <ServicesTab />,
      65129071,
      routerLinks.signinPage,
      loggedIn,
      user,
      true
    ),
    <Route key='service_route_key' exact path={routerLinks.makeOrder()}>
      <ServiceDetails />
    </Route>,
    // protectMe(
    //   routerLinks.makeOrder(),
    //   <ServiceDetails />,
    //   65129072,
    //   routerLinks.signinPage,
    //   loggedIn,
    //   user
    // ),
    //
    protectMe(
      routerLinks.userOrders,
      <MyOrders />,
      65129073,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    <Route key='service_route_key_error' exact path={routerLinks.paymentError}>
      <PaymentError />
    </Route>,
    <Route
      key='service_route_key_success'
      exact
      path={routerLinks.paymentSuccess}
    >
      <PaymentSuccess />
    </Route>,
    <Route key='service_route_key_footer' exact path={routerLinks.footerPage}>
      <FooterPage />
    </Route>,
    protectMe(
      routerLinks.orderDetails(),
      <OrderDetails />,
      "order_details_key",
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.chatWithDesigner,
      <ChatWithDesigner />,
      65129075,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.userNotifications,
      <UserNotifications notify={NewNotificationNotify} setNotify={setNewNotificationNotify} />,
      65129076,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.userMessages,
      <UserMessages notify={NewMessageNotify} setNotify={setNewMessageNotify} />,
      65129077,
      routerLinks.signinPage,
      loggedIn,
      user
    ),

    protectMe(
      routerLinks.userDirectMessages,
      <UserMessages />,
      65129077,
      routerLinks.signinPage,
      loggedIn,
      user
    ),


    
    protectMe(
      routerLinks.userSettings,
      <UserSettings />,
      65129078,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.userCart,
      <UserCart />,
      "user_cart_route",
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.userChoosePayment,
      <ChoosePayment />,
      651290710,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.userVisa,
      <Visa />,
      651290711,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.userPaypal,
      <Paypal />,
      651290712,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
    protectMe(
      routerLinks.userApplepay,
      <ApplePay />,
      651290713,
      routerLinks.signinPage,
      loggedIn,
      user
    ),
  ];
};

export default UserRoutes;
