import "./ChoosePayment.scss";

import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import userCartLocalization from "../../localization/userCart.localization";
import LangContext from "../../contexts/lang-context/LangProvider";
import VisaIcon from "../../common/icons/payment/VisaIcon";
import PaypalIcon from "../../common/icons/payment/PaypalIcon";
import ApplePayIcon from "../../common/icons/payment/ApplePayIcon";

const ChoosePayment = () => {
	const {
		language: { code }
	} = useContext(LangContext);
	const {
		choosePaymentPage: { mainTitle, subTitle }
	} = userCartLocalization;

	return (
		<div className="choose-payment">
			<div className="main-title">{mainTitle[code]}</div>
			<div className="sub-title">{subTitle[code]}</div>

			<div className="links-wrap">
				<RouterLink className="visa-link" to="/dashboard/user/visa">
					<VisaIcon />
				</RouterLink>
				<RouterLink className="paypal-link" to="/dashboard/user/paypal">
					<PaypalIcon />
				</RouterLink>
				<RouterLink className="apple-pay-link" to="/dashboard/user/applepay">
					<ApplePayIcon />
				</RouterLink>
			</div>
		</div>
	);
};

export default ChoosePayment;
