const routerLinks = {
  ////////////////////////////
  homePage: "/",
  ////////////////////////////
  aboutPage: "/about",
  ////////////////////////////
  signupPage: "/signup",
  userSignup: "/signup/user",
  designerSignup: "/signup/designer",
  supervisorSignup: "/signup/supervisor",
  activateAccountRoute: "/activate-account",
  ////////////////////////////
  signinPage: "/signin",
  userSignin: "/signin/user",
  designerSignin: "/signin/designer",
  supervisorSignin: "/signin/supervisor",
  ////////////////////////////
  dashboardPage: "/dashboard",
  // user dashbaord
  userServices: "/dashboard/user/services",
  makeOrder: (serviceId) => {
    if (serviceId) return `/dashboard/user/services/make-order/${serviceId}`;
    return "/dashboard/user/services/make-order/:serviceId";
  },
  userOrders: "/dashboard/user/my-orders/:status",
  orderDetails: (orderId) =>
    orderId
      ? `/dashboard/user/my-orders/order-details/${orderId}`
      : "/dashboard/user/my-orders/order-details/:orderId",
  paymentError: "/dashboard/user/my-orders/myfatoorah/error",
  paymentSuccess: "/dashboard/user/my-orders/myfatoorah/result",
  footerPage: "/dashboard/custom/:id",

  chatWithDesigner:
    "/dashboard/user/my-orders/order-details/chat-with-designer",
  userNotifications: "/dashboard/user/notifications",
  userMessages: "/dashboard/user/messages",
  userDirectMessages: "/dashboard/user/messages/:orderId",

  userSettings: "/dashboard/user/settings",
  userCart: "/dashboard/user/cart",
  userChoosePayment: "/dashboard/user/choose-payment",
  userVisa: "/dashboard/user/visa",
  userPaypal: "/dashboard/user/paypal",
  userApplepay: "/dashboard/user/applepay",
  // designer dashboard
  designerHome: "/dashboard/designer/home",
  designerProjects: "/dashboard/designer/my-projects",
  designerSingleProject: (id) =>
    id
      ? `/dashboard/designer/my-projects/project/${id}`
      : "/dashboard/designer/my-projects/project/:id",
  designerNotifications: "/dashboard/designer/notifications",
  designerMessages: "/dashboard/designer/messages",
  designerDirectMessages: "/dashboard/designer/messages/:orderId",

  designerBalance: "/dashboard/designer/balance",
  designerTransactions:"/dashboard/designer/transactions",
  designerSettings: "/dashboard/designer/settings",
  designerApplication: "/dashboard/designer/apply",
  ////////////////////////////
  supervisorHome: '/dashboard/supervisor/home',
  supervisorOrders: "/dashboard/supervisor/orders",
  supervisorOrdersDetails: "/dashboard/supervisor/orders/:id",
  supervisorNotifications: "/dashboard/supervisor/notifications",
  supervisorMessages: "/dashboard/supervisor/messages",
  supervisorDirectMessages: "/dashboard/supervisor/messages/:orderId/:type/:typeId",

  supervisorBalance: "/dashboard/supervisor/balance",
  supervisorSettings: "/dashboard/supervisor/settings",
  supervisorApplication: "/dashboard/supervisor/apply",

  ////////////////////////////
  // shared links
  forgetPassword: (accountType) =>
    accountType
      ? `/forget-password/${accountType}`
      : "/forget-password/:accountType",
  forgetPasswordEmail: (accountType) =>
    accountType
      ? `/forget-password/email/${accountType}`
      : "/forget-password/email/:accountType",
  forgetPasswordCode: (accountType) =>
    accountType
      ? `/forget-password/code/${accountType}`
      : "/forget-password/code/:accountType",
  forgetPasswordNewPass: (accountType) =>
    accountType
      ? `/forget-password/new-password/${accountType}`
      : "/forget-password/new-password/:accountType",
  ////////////////////////////////
  notFound: "/not-found",
};

export default routerLinks;
