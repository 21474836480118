import "./Loading.styles.scss";
import React from "react";

import loadingImg from "../../assets/imgs/icons/loading1.gif";

const Loading = ({centerLoader}) => {
	return (
		<div className="loadingPage">
			<img src={loadingImg} alt="Loading"  className={centerLoader ? "centerLoader" :""} />
		</div>
	);
};

export default Loading;
