import axios from "axios";

const footerPageApi = async (key, langCode,user) => {
  try {
    const res = await axios.get(`/api/v1/settings?key=${key}`, {
      headers: {
        "X-Portal": user?.accountType,
        password: process.env.REACT_APP_AUTH_PASS,
        "X-Language": langCode,
      },
    });

    return res;
  } catch (e) {
    throw e;
  }
};

export default footerPageApi;
