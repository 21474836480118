import axios from 'axios';

const getUserCartApi = async (user, langCode) => {
  try {
    const func = await axios.get(
      `/api/v1/client/my-card?api_token=${user?.api_token}`,
      {
        headers: {
          'X-Portal': user?.accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          lang: langCode
        }
      }
    );

    return func;
  } catch (e) {
    throw e;
  }
};

export default getUserCartApi;
