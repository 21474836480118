import "./CustomAppBar.scss";

import React, { useEffect, useState } from "react";

const CustomAppBar = ({ children, customClass }) => {
	const [scrollY, setScrollY] = useState(
		window.scrollY || document.documentElement.scrollTop
	);
	const [scrollDir, setScrollDir] = useState(null);
	useEffect(() => {
		const handleScroll = () => {
			if ((window.scrollY || document.documentElement.scrollTop) > scrollY) {
				setScrollDir("scroll-down");
			} else {
				setScrollDir("scroll-up");
			}
			setScrollY(window.scrollY);
		};
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollY]);

	return (
		<div
			className={`custom-app-bar ${scrollDir ? scrollDir : ""} ${
				scrollY > 0 ? "exceeds0" : ""
			} ${customClass}`}
		>
			{children}
		</div>
	);
};

export default CustomAppBar;
