import axios from 'axios';

const updateProfile = async (values, user) => {
  console.log(values)
  const { image, f_name, l_name, email, gender, mobile,zip_code } =
    values;
  try {
    const formData = new FormData();
    if (image) {
      formData.append('image', image);
    }
    formData.append('f_name', f_name);
    formData.append('l_name', l_name);
    formData.append('email', email);
    if (gender) {
      formData.append('gender', gender);
    }
    formData.append('mobile', mobile);
    formData.append('api_token', user?.api_token);
    formData.append('zip_code', zip_code);



    const updateProfileRes = await axios.post(
      `/api/v1/${user?.accountType}/update-profile`,
      formData,
      {
        headers: {
          'X-Portal': user?.accountType,
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user?.token}`,
          password: process.env.REACT_APP_AUTH_PASS,
        }
      }
    );
    return updateProfileRes;
    // return updateProfileRes;
  } catch (e) {
    console.log(e);
  }
};

export default updateProfile;
