import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
import { Router } from "react-router-dom";
import customHistory from "./history";
import { LangProvider } from "./contexts/lang-context/LangProvider";
import { UserProvider } from "./contexts/user-context/UserProvider";
import { FlashMessageProvider } from "./contexts/flash-messages-context/FlashMessageProvider";
import { MainAppBarProvider } from "./contexts/main-app-bar-context/MainAppBarProvider";
import { TemporaryUserProvider } from "./contexts/user-context/TempUserProvider";
// react-phone-input-mui/lib/style.css
// import "react-phone-input-material-ui/lib/style.css";
import "react-phone-input-material-ui/lib/material.css";
import "./scss/index.scss";
import { UserCartProvider } from "./contexts/user-cart-context/UserCartContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SocialProvider from "./contexts/social-context/SocialProvider";
const rootEl = document.getElementById("root");



let render = () => {
  ReactDOM.render(
    // react router dom

    <Router history={customHistory}>
      {/* temp user provider */}
      <SocialProvider>
        <TemporaryUserProvider>
          {/* user provider */}
          <UserProvider>
            <MainAppBarProvider>
              {/* firebase provider */}
              {/* lang and dir provider */}
              <LangProvider>
                <GoogleOAuthProvider clientId="861399125841-bo0vd5673gigpr1d25iv6d019m1a20t0.apps.googleusercontent.com">
                  {/* flash message provider */}
                  <FlashMessageProvider>
                    {/* <React.StrictMode> */}
                    <UserCartProvider>
                      <App />
                    </UserCartProvider>
                    {/* </React.StrictMode> */}
                  </FlashMessageProvider>
                </GoogleOAuthProvider>
              </LangProvider>
            </MainAppBarProvider>
          </UserProvider>
        </TemporaryUserProvider>
      </SocialProvider>
    </Router>,
    rootEl
  );
};

// if (module.hot) {
// 	// module.hot.accept();
// 	module.hot.accept('./components/app/App', () => {
// 		setTimeout(render);
// 	});
// }

render();
