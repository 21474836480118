/* eslint-disable react-hooks/exhaustive-deps */
import './ServiceStepSeven.scss';

import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import { RadioGroup, TextField } from 'formik-material-ui';
import LangContext from '../../../contexts/lang-context/LangProvider';
import serviceDetailsLocalization from '../../../localization/serviceDetailsLocalization';

const allTargetSectors = [
  {
    id: 1,
    name: {
      ar: 'التعليم',
      en: 'Education'
    },
    value: {
      ar: 'التعليم',
      en: 'Education'
    }
  },
  {
    id: 2,
    name: {
      ar: 'الصحة',
      en: 'Health'
    },
    value: {
      ar: 'الصحة',
      en: 'Health'
    }
  },
  {
    id: 3,
    name: {
      ar: 'التكنولوجيا',
      en: 'Technology'
    },
    value: {
      ar: 'التكنولوجيا',
      en: 'Technology'
    }
  },
  {
    id: 4,
    name: {
      ar: 'الصناعة',
      en: 'Industry'
    },
    value: {
      ar: 'الصناعة',
      en: 'Industry'
    }
  },
  {
    id: 5,
    name: {
      ar: 'الطاقة',
      en: 'Energy'
    },
    value: {
      ar: 'الطاقة',
      en: 'Energy'
    }
  },
  {
    id: 6,
    name: {
      ar: 'الأمن',
      en: 'Safty'
    },
    value: {
      ar: 'الأمن',
      en: 'Safty'
    }
  },
  {
    id: 7,
    name: {
      ar: 'الإعلام',
      en: 'Media'
    },
    value: {
      ar: 'الإعلام',
      en: 'Media'
    }
  },
  {
    id: 8,
    name: {
      ar: 'الإتصالات',
      en: 'Communication'
    },
    value: {
      ar: 'الإتصالات',
      en: 'Communication'
    }
  }
];

const ServiceStepSeven = ({ setStep }) => {
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    stepSeven: { targetSector }
  } = serviceDetailsLocalization;
  const { isSubmitting, values, setFieldValue, errors, setTouched, touched } =
    useFormikContext();

  const renderRadiosGroup = (arr, fieldName) => {
    return (
      <>
        {arr.map((item) => (
          <FormControlLabel
            key={item.id}
            value={String(item.value[code]) || String(item.value)}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={item.name[code]}
            disabled={isSubmitting}
            onClick={(e) => {
              if (String(values[fieldName]) === String(item.value))
                setFieldValue(fieldName, "");
            }}
          />
        ))}
        <FormHelperText error>
          {touched[fieldName] && errors[fieldName] && errors[fieldName]}
        </FormHelperText>
      </>
    );
  };

  useEffect(() => {
    setTouched({});
  }, []);

  useEffect(() => {
    if (values.videoPurpose !== 'other' && values.otherTargetSector) {
      setFieldValue('otherTargetSector', '');
    }
  }, [values.videoPurpose]);
  useEffect(() => {
    if (
      values?.scriptIsReady === 'ready' &&
      values?.acceptScript === 'accept'
    ) {
      setStep((prev) => prev + 1);
    }
  }, [values]);
  return (
    <div className="formik-step service-step-seven">
      {((values?.scriptIsReady === 'ready' &&
        values?.acceptScript === 'review') ||
        values?.scriptIsReady === 'not_ready') && (
        <div className="radio-group-wrapper">
          <h3 className="group-title">{targetSector.label[code]}</h3>
          <Field
            className="field-group-wrap"
            component={RadioGroup}
            name="targetSector"
          >
            {allTargetSectors?.length > 0 && (
              <>
                {renderRadiosGroup(allTargetSectors)}

                <FormControlLabel
                  value="other"
                  control={<Radio color="primary" disabled={isSubmitting} />}
                  label={
                    code === 'ar'
                      ? 'أخرى يجب ذكرها'
                      : 'Other you should mention'
                  }
                  disabled={isSubmitting}
                />
              </>
            )}
            {values.targetSector === 'other' && (
              <Field
                component={TextField}
                label={targetSector.otherTextarea.label[code]}
                name="otherTargetSector"
                variant="outlined"
                multiline
                rows="2"
                className="other-field"
              />
            )}
          </Field>
        </div>
      )}
    </div>
  );
};

export default ServiceStepSeven;
