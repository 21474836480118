import React, { createContext, useState } from "react";

const FlashMessageContext = createContext({
	flashMsgIsOpen: false,
	flashMsg: "",
	flashMsgDuration: 5000,
	notificationSeverity: "info",
	setFlashMsgIsOpen: () => {},
	setFlashMsg: () => {},
	setFlashMsgDuration: () => {},
	setNotificationSeverity: () => {},
	handleFlashMsgClose: () => {}
});

export const FlashMessageProvider = ({ children }) => {
	const [flashMsgIsOpen, setFlashMsgIsOpen] = useState(false);
	const [flashMsg, setFlashMsg] = useState("");
	const [flashMsgDuration, setFlashMsgDuration] = useState(5000);
	const [notificationSeverity, setNotificationSeverity] = useState("info");

	return (
		<FlashMessageContext.Provider
			value={{
				flashMsgIsOpen,
				flashMsg,
				flashMsgDuration,
				notificationSeverity,
				setFlashMsgIsOpen,
				setFlashMsg,
				setFlashMsgDuration,
				setNotificationSeverity,
				handleFlashMsgClose: () => setFlashMsgIsOpen(false)
			}}
		>
			{children}
		</FlashMessageContext.Provider>
	);
};

export default FlashMessageContext;
