import React, { createContext, useState } from "react";

const INTIAL_VALUE = {
	croppedPersonalImgFile: null,
	setCroppedPersonalImgFile: file => {}
};
const CropperContext = createContext(INTIAL_VALUE);

export const CropperProvider = ({ children }) => {
	const [croppedPersonalImgFile, setCroppedPersonalImgFile] = useState(
		INTIAL_VALUE.croppedPersonalImgFile
	);

	return (
		<CropperContext.Provider
			value={{
				croppedPersonalImgFile,
				setCroppedPersonalImgFile: file => setCroppedPersonalImgFile(file)
			}}
		>
			{children}
		</CropperContext.Provider>
	);
};

export default CropperContext;
