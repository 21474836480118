/* eslint-disable eqeqeq */
import axios from 'axios';
import userTypes from '../constants/userTypes';

export const getServices = async (user, langIsoCode) => {
  // const url = `/api/v1/${
  //   user?.accountType ? user.accountType : 'client'
  // }/all-services?api_token=${user?.api_token}`
  // const url = `/api/v1/${user?.accountType || 'client'}/all-services`;
  let url = '';
  if (user?.accountType == userTypes?.client || !user) {
    url = `/api/v1/client/all-services`;
  } else url = `/api/v1/employee/services?api_token=${user?.api_token}`;
  try {
    const response = await axios.get(url, {
      headers: {
        'X-Portal': user?.accountType,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Language': langIsoCode,
        password: process.env.REACT_APP_AUTH_PASS
        // Authorization: "Bearer " + token
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};
