import axios from 'axios';

const getServicePricesApi = async (user, values, langCode) => {
  try {
    const func = await axios.get(
      `/api/v1/client/prices?api_token=${user?.api_token}&service_id=${values?.service_id}`,
      {
        headers: {
          'X-Portal': user?.accountType,
          'Content-Type': 'multipart/form-data',
          password: process.env.REACT_APP_AUTH_PASS,
          'X-Language': langCode
        }
      }
    );

    return func;
  } catch (e) {
    throw e;
  }
};

export default getServicePricesApi;
