import { Modal, Button } from '@material-ui/core';

import React, { useContext } from 'react'
import LangContext from '../../contexts/lang-context/LangProvider';
import homePageTranslations from '../../localization/homepage';
const AllowNotificationsModal = ({ open, onClose ,client}) => {
  const {
    language: { code }
  } = useContext(LangContext);
  const { common } = homePageTranslations
  return (
    <Modal
      className="employee-accept-reject-modal accept-modal"
      open={open}
      onClose={() => onClose()}
    >
      <div className="modal-content">
        <h2 className="modal-h">{common.warning[code]}</h2>
        <div className="modal-data">
          <p>{client ? common.allowNotificationsClient[code] : common.allowNotifications[code]}</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
            style={{margin:"10px",marginTop:"30px",padding:"16px 50px"}}
          >
            {common.done[code]}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            style={{margin :"10px",marginTop:"30px",padding:"16px 50px"}}
          >
            {common.close[code]}
          </Button>


        </div>

      </div>
    </Modal>
  )
}

export default AllowNotificationsModal