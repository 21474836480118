const userSigninLocalization = {
	fields: {
		email: {
			label: {
				ar: "اسـم المستخدم او الايميل",
				en: "Username or email"
			},
			errors: {
				required: {
					ar: "ادخل اسم المستخدم او الايميل",
					en: "Username or email is required"
				},

				noSpaces: {
					ar: "لا يسمح بوجود فراغات",
					en: "No white spaces allowed"
				}
			}
		},
		password: {
			label: {
				ar: "كلمة المرور",
				en: "Password"
			},
			errors: {
				required: {
					ar: "برجاء ادخال كلمة المرور",
					en: "Please enter your password"
				}
			}
		}
	},
	signinBtn: {
		ar: "تسجيل",
		en: "Signin"
	},
	forgetPass: {
		ar: "هل نسيت كلمة المرور؟",
		en: "Forgot your password?"
	},
	moreSigninOptions: {
		ar: "أو قم سجل الدخول من خلال",
		en: "Or you can signin via"
	},
	notSignedup: {
		p: {
			ar: "ليس لديك حساب مسجل",
			en: "Dont' You have account"
		},
		link: {
			ar: "قم بالتسجيل الان",
			en: "Signup now"
		}
	}
};

export default userSigninLocalization;
