import { useContext } from "react";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import ServiceStepFour from "../service-step-four/ServiceStepFour";
import * as Yup from "yup";

const ServiceSixStepFive = () => {
  const {
    stepOne: {
      scriptIsReady: { yes, no },
    },
    stepTwo,
    stepThree,
    stepFive: { companySpeciality, brandName },
    stepSix: { videoPurpose, targetCategory },
    stepSeven: { targetSector },
    stepEight: { targetAudience },
    stepNine: { offerPackages, highlightPoint },
    stepTen: { socialMedia, fontSelection, colorSelection },
  } = serviceDetailsLocalization;
  const {
    language: { code },
  } = useContext(LangContext);
  return (
    <>
      <ServiceStepFour
      // validationSchema={Yup.object().shape({
      // 	twitterScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	instagramScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	facebookScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	snapChatScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	),
      // 	youtubeScale: Yup.string().required(
      // 		stepFour.errors.required[code]
      // 	)
      // })}
      />
    </>
  );
};

export default ServiceSixStepFive;
