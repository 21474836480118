import { Modal } from "@material-ui/core";
import { useContext } from "react";
import { employeeRejectOrderApi } from "../../../apis/employee/employeeRejectOrderApi";
import EmployeeOrdersContext from "../../../contexts/employee-orders-context/EmployeeOrdersContext";
import FlashMessageContext from "../../../contexts/flash-messages-context/FlashMessageProvider";
import LangContext from "../../../contexts/lang-context/LangProvider";
import UesrContext from "../../../contexts/user-context/UserProvider";
import useCustomApiRequest from "../../../custom-hooks/useCustomApiRequest";
import checkRes from "../../../utils/checkRes";

const EmployeeAcceptModal = () => {
  const { user } = useContext(UesrContext);
  const {
    selectedOrder,
    rejectOrderModalOpened,
    setSelectedOrder,
    setRejectOrderModalOpened,
    setIsSubmitting,
    setFetchedDataCount,
  } = useContext(EmployeeOrdersContext);
  const {
    language: { code },
  } = useContext(LangContext);
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity,
  } = useContext(FlashMessageContext);
  const customApiRequest = useCustomApiRequest();

  const handleRejectOrder = () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("api_token", user?.api_token);
    formData.append("order_id", selectedOrder?.id || "");

    customApiRequest(
      employeeRejectOrderApi(user, formData, code),
      (res) => {
        console.log("lkasdjlkf ", res);
        setIsSubmitting(false);
        if (checkRes(res)) {
          setRejectOrderModalOpened(false);
          setFetchedDataCount((prev) => prev + 1);
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || "تمت الموافقة على الطب بنجاح");
          setFlashMsgDuration(4000);
          setNotificationSeverity("success");
        } else {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || "حاول فى وقت لاحق");
          setFlashMsgDuration(4000);
          setNotificationSeverity("error");
        }
      },
      (error) => {
        console.log("eeeee : ", error);
        setIsSubmitting(false);
        setFlashMsgIsOpen(true);
        setFlashMsg(error?.response?.data?.message || "حاول فى وقت لاحق");
        setFlashMsgDuration(4000);
        setNotificationSeverity("error");
      }
    );
  };
  return (
    <Modal
      className="employee-accept-reject-modal reject-modal"
      open={rejectOrderModalOpened}
      onClose={() => setRejectOrderModalOpened(false)}
    >
      <div className="modal-content">
        <h2 className="modal-h">رفض الطلب</h2>
        <div className="modal-data">
          <p>{`انت على وشك رفض طلب ${selectedOrder?.service?.title}`}</p>
        </div>

        <div className="modal-btns-wrapper">
          <button
            onClick={() => {
              handleRejectOrder();
            }}
            className="submit-btn"
          >
            نعم
          </button>
          <button
            onClick={() => {
              setSelectedOrder(null);
              setRejectOrderModalOpened(false);
            }}
            className="cancel-btn"
          >
            إغلاق
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeAcceptModal;
