import BellIcon from '../../common/icons/BellIcon';
import ChatIcon from '../../common/icons/ChatIcon';
import SettingsIcon from '../../common/icons/SettingsIcon';
import VideoIcon from '../../common/icons/VideoIcon';
import HomeIcon from '../../common/icons/HomeIcon';
import DollarIcon from '../../common/icons/DollarIcon';
import routerLinks from '../../components/app/routerLinks';
import supervisorSideMenuLocalization from '../../localization/supervisorSideMenu.localization';

const {home, orders, notifications, messages, balance, settings } =
  supervisorSideMenuLocalization;
const {
  supervisorHome,
  supervisorOrders,
  supervisorNotifications,
  supervisorMessages,
  supervisorBalance,
  supervisorSettings
} = routerLinks;

const supervisroLinks = ({ code }) => [
  {
    id: 1,
    name: home[code],
    route: supervisorHome,
    icon: <HomeIcon />,
    className: 'home-link'
  },
  {
    id: 2,
    name: orders[code],
    route: supervisorOrders,
    icon: <VideoIcon />,
    className: 'orders-link'
  },
  {
    id: 3,
    name: notifications[code],
    route: supervisorNotifications,
    icon: <BellIcon />,
    className: 'notifications-link'
  },
  {
    id: 4,
    name: messages[code],
    route: supervisorMessages,
    icon: <ChatIcon />,
    className: 'messages-link'
  },
  {
    id: 5,
    name: balance[code],
    route: supervisorBalance,
    icon: <DollarIcon />,
    className: 'messages-link'
  },
  {
    id: 6,
    name: settings[code],
    route: supervisorSettings,
    icon: <SettingsIcon />,
    className: 'settings-link'
  }
];

export default supervisroLinks;
