const paymentProcessLocalization = {
  fields: {
    failed: {
      label: {
        ar: "حدث خطأ ما برجاء المحاولة مرة أخري",
        en: "Something went wrong please try again",
      },
      tryAgain : {
        ar:"حاول مره أخري",
        en:"Try again"
      }
    },
    done: {
      label: {
        ar: "تمت العملية بنجاح",
        en: "Process done successfully",
      },
    },
  },
};

export default paymentProcessLocalization;
