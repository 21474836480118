/* eslint-disable react-hooks/exhaustive-deps */

import { FormHelperText, IconButton, InputAdornment,makeStyles } from '@material-ui/core';
import {
  EmailRounded,
  PersonRounded,
  PhoneEnabledRounded
} from '@material-ui/icons';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
// import { Select } from "formik-material-ui";
// import { DatePicker } from "formik-material-ui-pickers";
import React, { useContext } from 'react';
import * as Yup from 'yup';
import ButtonWithLoading from '../../../common/button-with-loading/ButtonWithLoading';
import LangContext from '../../../contexts/lang-context/LangProvider';
import AvatarUpload from '../../../common/avatar-upload/AvatarUpload';
import settingsLocalization from '../../../localization/settingsLocalization';
import FemaleIcon from '../../../common/icons/FemaleIcon';
import MaleIcon from '../../../common/icons/MaleIcon';
import DocTitleScrollTop from '../../../utils/DocTitleScrollTop';
import UesrContext from '../../../contexts/user-context/UserProvider';
import updateProfile from '../../../apis/auth/updateProfile';
import FlashMessageContext from '../../../contexts/flash-messages-context/FlashMessageProvider';
import './DesignerSettings.scss';
import ReactPhoneInput from 'react-phone-input-material-ui';
import {TextField as MuiTextField}  from '@material-ui/core';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const DesignerSettings = () => {
  DocTitleScrollTop('Settings');
  const { user, setCurrentUser } = useContext(UesrContext);
  const {
    language: { code }
  } = useContext(LangContext);
  const {
    setFlashMsg,
    setFlashMsgIsOpen,
    setFlashMsgDuration,
    setNotificationSeverity
  } = useContext(FlashMessageContext);
  const {
    mainTitle,
    settingsForm: { firstname, lastname, gender, phoneNumber, email, saveBtn }
  } = settingsLocalization;
  const DESIGNER_SETTINGS_SCHEMA = Yup.object().shape({
    image: Yup.mixed(),
    f_name: Yup.string()
      // .trim('No leading and trailing spaces')
      // .strict(true)
      .required(firstname.errors.required[code])
      .min(2, firstname.errors.minChar(2)[code])
      .max(12, firstname.errors.maxChar(12)[code])
      .trim()
      .test('f_name', firstname.errors.noSpaces[code], (value) => {
        return value && !value.includes(' ');
      }),
    l_name: Yup.string()
      // .trim('No leading and trailing spaces')
      // .strict(true)
      .required(lastname.errors.required[code])
      .min(2, lastname.errors.minChar(2)[code])
      .max(12, lastname.errors.maxChar(12)[code])
      .trim()
      .test('l_name', lastname.errors.noSpaces[code], (value) => {
        return value && !value.includes(' ');
      }),
    // country: Yup.string().required(country.errors.required[code]),
    // language: Yup.string().required(language.errors.required[code]),
    gender: Yup.number().required(gender.errors.required[code]),
    // dateOfBirth: Yup.date()
    // .required(dateOfBirth.errors.required[code])
    // .nullable(),
    mobile: Yup.string()
      .required(phoneNumber.errors.required[code])
      .matches(/^[0-9]+$/, phoneNumber.errors.validNumber[code])
      .trim()
      .min(10, phoneNumber.errors.minChar(11)[code])
      .max(15, phoneNumber.errors.maxChar(15)[code]),
    email: Yup.string()
      .email(email.errors.typeError[code])
      // .typeError()
      .trim()
      .required(email.errors.required[code])
  });

  const handlePhoneValue = (v, setFieldValue,zipCode) => {
    console.log(v,zipCode)
    setFieldValue('mobile', v?.replace(zipCode,''));
    setFieldValue('zip_code', zipCode);
  };
  const useStyles = makeStyles((theme) => ({
    countryList: {
      ...theme.typography.body1
    },
    field: {
      margin: '10px 0'
    }
  }));
  
  const classes = useStyles();

  return (
    <div className="designer-settings-wrap">
      <div className="main-title">{mainTitle[code]}</div>

      <Formik
        initialValues={{
          // image: user?.image ? user.image : "",
          image: null,
          f_name: user?.f_name ? user.f_name : '',
          l_name: user?.l_name ? user.l_name : '',
          // gender: user?.gender ? String(user.gender) : '',
          gender:  user?.gender === "male" ? "1" : user?.gender === "female" ? "2" : "",
          mobile: user?.mobile ? user.mobile : '',
          email: user?.email ? user.email : '',
          zip_code:user?.zip_code ? user.zip_code : ''
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log('values',values)
          await sleep(500);
          try {
            setSubmitting(true);
            const castedValues = {
              ...values,
              f_name: values.f_name.trim(),
              l_name: values.l_name.trim(),
              mobile:values?.mobile?.replace(values.zip_code,'')

            };
            // console.log("casted values", castedValues);
            const updatedProfileRes = await updateProfile(castedValues, user);
            setSubmitting(false);
            if (updatedProfileRes?.data?.data) {
              setCurrentUser({
                ...user,
                image: updatedProfileRes.data.data.image,
                f_name: updatedProfileRes.data.data.f_name,
                l_name: updatedProfileRes.data.data.l_name,
                email: updatedProfileRes.data.data.email,
                mobile: updatedProfileRes.data.data.mobile,
                gender: updatedProfileRes.data.data.gender,
                zip_code:updatedProfileRes.data.data.zip_code

              });
              setFlashMsgIsOpen(true);
              setFlashMsg('updated successfully!');
              setFlashMsgDuration(4000);
              setNotificationSeverity('success');
            }
          } catch (e) {
            setSubmitting(false);
            console.log(e);
          }
        }}
        validationSchema={DESIGNER_SETTINGS_SCHEMA}
      >
        {({ values, isSubmitting, handleSubmit, errors, touched ,setFieldValue,setFieldTouched}) => (
          <>
            <form
              onSubmit={handleSubmit}
              className="settings-form"
              autoComplete="off"
              id="designer-settings-form"
            >
              <div className="avatar-wrap">
                <AvatarUpload
                  initialPhoto={`${process.env.REACT_APP_BACK_END_URL}${user.image}`} 
                  inputName="image"
                  formName="user-settings-form"
                />
              </div>

              <Field
                component={TextField}
                name="f_name"
                type="text"
                label={firstname.label[code]}
                variant="outlined"
                color="standard"
                className="form-field"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <PersonRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Field
                component={TextField}
                name="l_name"
                type="text"
                label={lastname.label[code]}
                variant="outlined"
                color="standard"
                className="form-field"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <PersonRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {/* <FormControl variant="outlined">
								<InputLabel htmlFor="country-id">
									{country.label[code]}
								</InputLabel>
								<Field
									component={Select}
									// variant="outlined"
									name="country"
									inputProps={{
										id: "country-id"
									}}
								>
									<MenuItem value="">المملكة العربية السعودية</MenuItem>
									<MenuItem value="0">المملكة العربية السعودية</MenuItem>
									<MenuItem value="1">مصر</MenuItem>
									<MenuItem value="2">الامارات</MenuItem>
								</Field>
								{errors?.country && (
									<FormHelperText error>{errors.country}</FormHelperText>
								)}
							</FormControl> */}
              {/* <Field
								component={TextField}
								name="language"
								type="text"
								label={language.label[code]}
								variant="outlined"
								color="primary"
								className="form-field"
								InputProps={{
									endAdornment: (
										<InputAdornment>
											<IconButton>
												<Language />
											</IconButton>
										</InputAdornment>
									)
								}}
							/> */}

              {/* <Field
								component={DatePicker}
								label={dateOfBirth.label[code]}
								name="dateOfBirth"
								format="YYYY-MM-DD"
								variant="dialog"
								inputVariant="outlined"
								disableFuture
								InputProps={{
									endAdornment: (
										<InputAdornment>
											<IconButton>
												<DateRange />
											</IconButton>
										</InputAdornment>
									)
								}}
							/> */}
              {/* <Field
                component={TextField}
                name="mobile"
                type="text"
                label={phoneNumber.label[code]}
                variant="outlined"
                color="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <PhoneEnabledRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              /> */}
              <div className="phone-number-wrap">

                <ReactPhoneInput
                  onChange={(v, result) => { handlePhoneValue(v, setFieldValue, result?.dialCode) }}
                  value={`${values.zip_code}${values.mobile.trim()}`}
                  component={MuiTextField}
                  dropdownClass={classes.countryList}
                  specialLabel={false}
                  disableCountryCode
                  name="mobile"
                  variant="standard"
                  color="primary"
                  onBlur={() => {
                    setFieldTouched('mobile', true);
                  }}
                  label={phoneNumber.label[code]}
                />
                {touched?.mobile && errors?.mobile && (
                  <FormHelperText className='p-absolute' error>{errors.mobile}</FormHelperText>
                )}
              </div>

              <Field
                component={TextField}
                name="email"
                type="email"
                label={email.label[code]}
                variant="outlined"
                color="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <EmailRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <div className="gender-wrap">
                {/* <div className="wrap-title">{gender.label[code]}</div> */}
                <div className="gender-labels">
                  <label
                    className={`gender-label ${
                      values.gender === '1' ? 'checked' : ''
                    }`}
                  >
                    <Field type="radio" name="gender" value="1" checked={values.gender === "1"}  />
                    {gender.fields.male[code]}
                    <MaleIcon color="#ababab" />
                  </label>
                  <label
                    className={`gender-label ${
                      values.gender === '2' ? 'checked' : ''
                    }`}
                  >
                    <Field type="radio" name="gender" value="2" checked={values.gender === "2"}  />
                    {gender.fields.female[code]}
                    <FemaleIcon color="#ababab" />
                  </label>
                </div>

                {errors?.gender && (
                  <FormHelperText error>{errors.gender}</FormHelperText>
                )}
              </div>

              <div className="btn-more-options">
                <ButtonWithLoading
                  isSubmitting={isSubmitting}
                  errors={errors}
                  btnText={saveBtn.label[code]}
                  loadingMsg={saveBtn.loading[code]}
                  className="submit-btn"
                />
              </div>
              {/* <div style={{ direction: "ltr", overflowX: "auto" }}>
								<h3>Values</h3>
								<pre>{JSON.stringify(values, null, 2)}</pre>
								<hr />
								<pre>{JSON.stringify(errors, null, 2)}</pre>
							</div> */}
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default DesignerSettings;
