import "./ServiceStepTwo.scss";

import React, { useContext } from "react";
import { Field, useFormikContext } from "formik";
import { FormControlLabel, Radio } from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";
import LangContext from "../../../contexts/lang-context/LangProvider";
import serviceDetailsLocalization from "../../../localization/serviceDetailsLocalization";
import videoLangTypes from "../../../videoLangTypes";

const ServiceStepTwo = () => {
  const {
    language: { code },
  } = useContext(LangContext);
  const {
    stepTwo: { videoLang, displayMethod, voiceOver },
  } = serviceDetailsLocalization;
  const { isSubmitting, values, setFieldValue } = useFormikContext();

  const videoLangArray = [
    {
      id: 1,
      label: {
        ar: "اللغة العربية",
        en: "Arabic Language",
      },
      value: videoLangTypes?.arabicLang,
    },
    {
      id: 2,
      label: {
        ar: "اللغة الانجليزية",
        en: "English Language",
      },
      value: videoLangTypes?.englishLang,
    },
    {
      id: 3,
      label: {
        ar: "كليهما فى فيديو واحد",
        en: "Both in one video",
      },
      value: videoLangTypes?.bothArEnOneVideo,
    },
    {
      id: 4,
      label: {
        ar: "كليهما منفصلين بنفس المحتوى",
        en: "Seperate videos with same content",
      },
      value: videoLangTypes?.bothArEnSeperateVideos,
    },
  ];
  const displayMethodArray = [
    {
      id: 5,
      label: {
        ar: "ترجمة مكتوبة عربى",
        en: "Written arabic script",
      },
      value: "ar",
    },
    {
      id: 6,
      label: {
        ar: "ترجمة مكتوبة انجليزى",
        en: "Written english script",
      },
      value: "en",
    },
  ];
  const voiceOverArray = [
    {
      id: 7,
      label: {
        ar: "ذكر",
        en: "Male",
      },
      value: "1",
    },
    {
      id: 8,
      label: {
        ar: "أنثى",
        en: "Female",
      },
      value: "2",
    },
    {
      id: 9,
      label: {
        ar: "لا أريد تعليق",
        en: "i don't want voice over",
      },
      value: "3",
    },
  ];

  const renderRadiosGroup = (arr, name) => {
    return arr.map((item) => (
      <FormControlLabel
        key={item.id}
        value={String(item.value)}
        control={<Radio color="primary" disabled={isSubmitting} />}
        onClick={(e) => {
          if (String(values[name]) === String(item.value))
            setFieldValue(name, "");
        }}
        label={item.label[code]}
        disabled={isSubmitting}
      />
    ));
  };

  return (
    <div className="formik-step service-step-two">
      <div className="radio-group-wrapper">
        <h3 className="group-title">{videoLang.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="videoLang"
        >
          {renderRadiosGroup(videoLangArray, "videoLang")}
        </Field>
      </div>
      {values?.videoLang === "both_one_video" && (
        <div className="radio-group-wrapper">
          <h3 className="group-title">{displayMethod.label[code]}</h3>
          <Field
            className="field-group-wrap"
            component={RadioGroup}
            name="displayMethod"
          >
            {renderRadiosGroup(displayMethodArray, "displayMethod")}
          </Field>
        </div>
      )}
      <div className="radio-group-wrapper">
        <h3 className="group-title">{voiceOver.label[code]}</h3>
        <Field
          className="field-group-wrap"
          component={RadioGroup}
          name="voiceOver"
        >
          {renderRadiosGroup(voiceOverArray, "voiceOver")}
        </Field>
      </div>
    </div>
  );
};

export default ServiceStepTwo;
