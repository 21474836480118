import axios from "axios";
import userTypes from "../../constants/userTypes";

const registerTokenNotificationsApi = async (user, token,deviceId) => {

    var formData = new FormData();
    formData.append("api_token", user.api_token);
    formData.append("device_id", deviceId);
    formData.append("token", token);
    formData.append("type", "web");
    try {
        const func = await axios.post(
            `/api/v1/${user.accountType == userTypes?.supervisor ? userTypes?.employee : user.accountType
            }/register-token`,
            formData,
            {
                headers: {
                    password: process.env.REACT_APP_AUTH_PASS,
                    "X-Portal": user?.accountType,

                }
            }
        );

        return func;
    } catch (e) {
        throw e;
    }
};
export default registerTokenNotificationsApi
