import axios from 'axios';

const makeOrder1234Api = async (user, foundService, values) => {
  const {
    //
    scriptIsReady,
    script,
    numberOfWords,
    calculatedVideoDuration,
    reviewedVideoDuration,
    acceptScript,
    briefScript,
    videoLang,
    displayMethod,
    voiceOver,
    arabicVoiceOver,
    englishVoiceOver,
    arabicVoiceOverModel,
    englishVoiceOverModel,
    count_voice_comment,
    twitterScale,
    instagramScale,
    facebookScale,
    snapChatScale,
    youtubeScale,
    companySpeciality,
    otherCompSpeciality,
    brandName,
    videoPurpose,
    otherVideoPurpose,
    targetCategory,
    targetSector,
    otherTargetSector,
    targetAudience,
    targetAudience_other_checkbox,
    otherTargetAudience,
    offerPackages,
    highlightPoint,
    socialMediaAddresses: { inputs: socialCheckBoxsInputs },
    uploadedFont,
    color,
    uploadedColor
  } = values;

  try {
    const formData = new FormData();
    formData.append('api_token', user?.api_token);
    foundService && formData.append('service_id', foundService.id);
    if (acceptScript === 'accept' && scriptIsReady === 'ready') {
      formData.append('text_status', 'confirm');
      formData.append('duration', `${calculatedVideoDuration}:00`);
    } else if (acceptScript === 'review' && scriptIsReady === 'ready') {
      formData.append('text_status', 'review');
      formData.append('duration', `${reviewedVideoDuration}:00`);
    }
    //
    //
    //
    //
    // handle scriptIsReady
    // haveText: '1' => yes, '0' => no
    // scriptIsReady: 'ready' => yes,  'not_ready' => don't have text
    if (scriptIsReady === 'ready') {
      formData.append('text_ready', 1);
      formData.append('video_text', script);
      formData.append('count_words', numberOfWords);
    }
    if (scriptIsReady === 'not_ready') {
      formData.append('text_ready', 0);
      formData.append('video_text', briefScript);
      // formData.append("count_words", numberOfWords);
      //
      formData.append('duration', `${reviewedVideoDuration}:00`);
      //
    }

    //
    //
    videoLang && formData.append('trans_lang', videoLang);
    displayMethod && formData.append('trans_lang_style', displayMethod);
    voiceOver && (voiceOver !=="3") && formData.append('comment_gender', voiceOver);
    //
    //
    arabicVoiceOverModel &&
      formData.append(
        'ar_voice_comment',
        JSON.stringify({
          attr: arabicVoiceOver,
          voice: arabicVoiceOverModel
        })
      );
    englishVoiceOverModel &&
      formData.append(
        'en_voice_comment',
        JSON.stringify({
          attr: englishVoiceOver,
          voice: englishVoiceOverModel
        })
      );
    formData.append('count_voice_comment', count_voice_comment);
    //
    //
    twitterScale?.length && formData.append('twitter_scale',    JSON.stringify(twitterScale));
    instagramScale?.length && formData.append('insta_scale',    JSON.stringify(instagramScale));
    facebookScale?.length && formData.append('facebook_scale',    JSON.stringify(facebookScale));
    snapChatScale?.length && formData.append('snap_scale',    JSON.stringify(snapChatScale));
    youtubeScale?.length && formData.append('youtube_scale',    JSON.stringify(youtubeScale));
    let socialScaleArr = [
      twitterScale,
      instagramScale,
      facebookScale,
      snapChatScale,
      youtubeScale
    ];
    socialScaleArr = socialScaleArr.filter((i) => i?.length );
    if (socialScaleArr?.length > 0)
      formData.append('count_scale', socialScaleArr.length);
    //
    //
    /////
    if (companySpeciality && companySpeciality !== 'other') {
      formData.append('company_specialty', companySpeciality);
    } else if (companySpeciality === 'other' && otherCompSpeciality) {
      formData.append('company_specialty', otherCompSpeciality);
    }
    /////
    brandName && formData.append('nickname_case', brandName);
    //
    //
    ////
    if (videoPurpose && videoPurpose !== 'other') {
      formData.append('video_purpose', videoPurpose);
    } else if (videoPurpose === 'other' && otherVideoPurpose) {
      formData.append('video_purpose', otherVideoPurpose);
    }
    ////
    targetCategory && formData.append('target_group', targetCategory);
    //
    //
    if (targetSector && targetSector !== 'other') {
      formData.append('target_sector', targetSector);
    } else if (targetSector === 'other' && otherTargetSector) {
      formData.append('target_sector', otherTargetSector);
    }
    //
    //
    if (targetAudience?.length > 0 && !targetAudience_other_checkbox) {
      formData.append('target_public', JSON.stringify(targetAudience));
    } else if (
      targetAudience?.length >= 0 &&
      targetAudience_other_checkbox &&
      otherTargetAudience
    ) {
      formData.append(
        'target_public',
        JSON.stringify([...targetAudience, otherTargetAudience])
      );
    }
    //
    //
    offerPackages && formData.append('offer_packages', offerPackages);
    highlightPoint && formData.append('distinction_point', highlightPoint);
    //
    //
    // socialCheckBoxsInputs.twitter &&
    //   formData.append("twitter", socialCheckBoxsInputs.twitter);
    // socialCheckBoxsInputs.facebook &&
    //   formData.append("facebook", socialCheckBoxsInputs.facebook);
    // socialCheckBoxsInputs.instagram &&
    //   formData.append("instagram", socialCheckBoxsInputs.instagram);
    // socialCheckBoxsInputs.snapchat &&
    //   formData.append("snapchat", socialCheckBoxsInputs.snapchat);
    // socialCheckBoxsInputs.youtube &&
    //   formData.append("youtube", socialCheckBoxsInputs.youtube);

    formData.append(
      'social_links',
      JSON.stringify({
        twitter: socialCheckBoxsInputs.twitter,
        facebook: socialCheckBoxsInputs.facebook,
        instagram: socialCheckBoxsInputs.instagram,
        sanpchat: socialCheckBoxsInputs.snapchat,
        youtube: socialCheckBoxsInputs.youtube
      })
    );
    //
    //
    //
    //
    if (color === 'yes') formData.append('company_color_status', 1);
    if (color === 'no') formData.append('company_color_status', 0);
    uploadedColor && formData.append('company_color', uploadedColor);
    uploadedFont && formData.append('writing_line', uploadedFont);
    //

    //

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    const response = await axios.post('/api/v1/client/add-to-card', formData, {
      headers: {
        'X-Portal': user?.accountType,
        password: process.env.REACT_APP_AUTH_PASS,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export default makeOrder1234Api;
