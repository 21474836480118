import React,{useCallback, useContext,useEffect,useState} from "react";
import NotificationListItem from "../../../common/notification-list-item/NotificationListItem";
import designerAvatar from "../../../assets/imgs/designer-avatar.png";
import DocTitleScrollTop from "../../../utils/DocTitleScrollTop";
import UesrContext from "../../../contexts/user-context/UserProvider";
import LangContext from "../../../contexts/lang-context/LangProvider";
import { getUserNotifications } from "../../../apis/user/userNotifications";
import ContentLoader from "react-content-loader";
import indexLocalization from "../../../localization";



const UserNotifications = ({notify,setNotify}) => {
	DocTitleScrollTop("Notifications");
	const { user } = useContext(UesrContext);
	const {
		language: { code, dir },
	  } = useContext(LangContext);
	  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [notifications, setNotifications] = useState([]);


  const getNotification = useCallback(async () => {
    setIsLoadingOrders(true);
      const data = await getUserNotifications(user);
    setNotifications(data.data.data);
    setIsLoadingOrders(false);
  }, [user]);

  useEffect(() => {
	getNotification()
  },[getNotification])

  useEffect(() => {

    if(notify) {
      getNotification();
      setNotify(false)
    }
  },[notify])

  const { noNotifications } = indexLocalization;


  if(isLoadingOrders) {
	return <div className="service-card-loader-box">
      <ContentLoader
        width="100%"
        height="100%"
        speed={1}
        rtl={dir === "rtl" ? true : false}
      >
        <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
      </ContentLoader>
    </div>;
  } else if(notifications.length > 0) {
	return (
		<div className="notifications-page">
			 {notifications.map((item) => (
          <NotificationListItem
            avatar={item.image || designerAvatar}
            title={item.title}
            desc={item.content}
            date={item.created_at}
            key={item.id}

          />
        ))}
		</div>
	);
  } else if(notifications.length === 0 && !isLoadingOrders ) {

    return <div>
    {
      notifications.length === 0  && !isLoadingOrders &&<div class="empty-wrapper">
      <h2>{noNotifications[code]}</h2>
    </div>
    }
    </div>

  } 
  return null

};

export default UserNotifications;
