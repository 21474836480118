/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import getSubServicesApi from "../apis/user/getSubServicesApi";
import LangContext from "../contexts/lang-context/LangProvider";
import UesrContext from "../contexts/user-context/UserProvider";
import checkRes from "../utils/checkRes";
import useCustomApiRequest from "./useCustomApiRequest";

const useSubServices = () => {
  const { user } = useContext(UesrContext);
  const {
    language: { code },
  } = useContext(LangContext);

  const [isLoadingSubServices, setIsLoadingSubServices] = useState(false);
  const [subServices, setSubServices] = useState([]);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      if (isMounted) {
        setIsLoadingSubServices(true);
        customApiRequest(
          getSubServicesApi(user, code),
          (res) => {
            setIsLoadingSubServices(false);
            if (checkRes(res) && res?.data?.data) {
              setSubServices(res.data.data);
            }
          },
          (error) => {
            setIsLoadingSubServices(false);
          }
        );
      }
    };
    fetchData();

    return () => (isMounted = false);
  }, [code]);

  return {
    isLoadingSubServices,
    subServices,
  };
};

export default useSubServices;
