const serviceDetailsLocalization = {
  title: {
    ar: "قم بملئ الاستبيان التالي لنقوم بتحديد السعر لك بناءا علي مدخلاتك",
    en: "Fill out the following questionnaire and we will determine the price for you based on your inputs",
  },
  stepOne: {
    videoDuration: {
      label: {
        ar: "مدة الفيديو",
        en: "Video duration",
      },
      helperText: {
        ar: "دقيقة",
        en: "Minute",
      },
    },
    scriptIsReady: {
      mainTitle: {
        ar: "هل يوجد نص جاهز؟",
        en: "Is there a script ready?",
      },
      yes: {
        checkBox: {
          label: {
            ar: "نعم",
            en: "Yes",
          },
          helperText: {
            ar: "يرجي نسخ النص ولصقة في المستطيل",
            en: "Please copy the text and paste it into the rectangle",
          },
        },
        textarea: {
          label: {
            ar: "اكتب هنا ...",
            en: "Type here ...",
          },
          errors: {
            required: {
              ar: "ادخل نص الفيديو",
              en: "Enter the video script",
            },
          },
        },
        wordsCount: {
          label: {
            ar: "عدد الكلمات",
            en: "Number of words",
          },
          errors: {
            required: {
              ar: "ادخل عدد كلمات الفيديو",
              en: "Enter the number of words",
            },
            isNumber: {
              ar: "لا يسمح الا بالارقام",
              en: "You must enter a number",
            },
          },
        },
        videoDuration: {
          label: {
            ar: "مدة الفيديو",
            en: "Video duration",
          },
          helperText: {
            ar: "دقيقة",
            en: "Minute",
          },
        },
        acceptScript: {
          errors: {
            required: {
              ar: "اختيار واحد من التالى",
              en: "Select one option",
            },
          },
        },
        acceptBtn: {
          label: {
            ar: "موافق",
            en: "Accept",
          },
        },
        reviewBtn: {
          label: {
            ar: "مراجعة",
            en: "Review",
          },
          helperText: (num) => {
            return {
              ar: `يرجي مراجعة عدد الكلمات علما بان ${num} كلمة / للدقيقة او توفير الخدمة من خلال فيديو تيوب كتابة النص للدقيقة = ${num} كلمة`,
              en: `Please review the number of words, noting that ${num} words / minute or the provision of the service Through YouTube video, writing the text per minute = ${num} words`,
            };
          },
        },
      },
      no: {
        checkBox: {
          label: {
            ar: "لا",
            en: "No",
          },
          helperText: (num) => {
            return {
              ar: `توفير الخدمة من خلال فيديو تيوب كتابة النص للدقيقة = ${num} كلمة`,
              en: `Providing the service through YouTube video. Typing the text for one minute = ${num} words`,
            };
          },
        },
        briefTitle: {
          ar: "يرجي كتابة فكرة مختصرة عن العمل المطلوب",
          en: "Please write a brief idea of the required work",
        },
        textarea: {
          label: {
            ar: "اكتب هنا ...",
            en: "Type here ...",
          },
          errors: {
            required: {
              ar: "ادخل فكرة مختصرة عن الفيديو",
              en: "Enter a brief idea about the video",
            },
          },
        },
      },
    },
  },

  //
  //
  //

  stepTwo: {
    videoLang: {
      label: {
        ar: "لغة الفيديو المطلوبة",
        en: "Requested video Language",
      },
      errors: {
        required: {
          en: "Please select the required language",
          ar: "برجاء اختيار اللغة المطلوبة",
        },
      },
    },
    displayMethod: {
      label: {
        ar: "طريقة عرض اللغة",
        en: "Language display method",
      },
      errors: {
        required: {
          ar: "برجاء اختيار طريقة عرض اللغة",
          en: "Please select the language display method",
        },
      },
    },
    voiceOver: {
      label: {
        ar: "جنس المعلق الصوتى",
        en: "Voiceover gender",
      },
      errors: {
        required: {
          ar: "برجاء اختيار جنس المعلق الصوتى",
          en: "Please select the voiceover gender",
        },
      },
    },
  },
  //
  //
  //
  stepThree: {
    arabicVoiceOver: {
      label: {
        ar: "تعليق صوتى عربى",
        en: "Arabic voiceover",
      },
    },
    englishVoiceOver: {
      label: {
        ar: "تعليق صوتى انجليزى",
        en: "English voiceover",
      },
    },
    voiceOver: {
      label: {
        ar: "جنس المعلق الصوتى",
        en: "Voiceover gender",
      },
    },
    count_voice_comment: {
      label: {
        ar: "عدد المحاورين",
        en: "Number of interlocutors",
      },
    },
    errors: {
      requiredVoiceOver: {
        ar: "اختار لهجة التعليق الصوتى",
        en: "Choose the voiceover",
      },
      requiredModel: {
        ar: "اختار نموذج التعليق الصوتى",
        en: "Choose the voiceover model",
      },
    },
  },
  //
  //
  //
  stepFour: {
    mainTitle: {
      ar: "مقياس الفيديو المطلوب",
      en: "Required video scale",
    },
    twitter: {
      label: {
        ar: "منصة X",
        en: "X",
      },
    },
    instagram: {
      label: {
        ar: "انستقرام",
        en: "Instagram",
      },
    },
    facebook: {
      label: {
        ar: "فيسبوك",
        en: "Facebook",
      },
    },
    snapChat: {
      label: {
        ar: "سناب شات",
        en: "Snap chat",
      },
    },
    youtube: {
      label: {
        ar: "يوتيوب",
        en: "Youtube",
      },
    },
    tiktok: {
      label: {
        ar: "تك توك",
        en: "Tiktok",
      },
    },
    LinkedIn: {
      label: {
        ar: "لينكد ان",
        en: "LinkedIn",
      },
    },
    errors: {
      required: {
        ar: "من فضلك اختار المقاس اولا",
        en: "Please select scale first",
      },
    },
  },
  //
  //
  //
  stepFive: {
    companySpeciality: {
      label: {
        ar: "ما هو تخصص شركتك؟",
        en: "What is the specialty of your company?",
      },
      errors: {
        required: {
          ar: "من فضلك اختار تخصص شركتك",
          en: "Please Select your company speciality",
        },
      },
      otherTextarea: {
        label: {
          ar: "أخرى يجب ذكرها",
          en: "Other, you should type",
        },
        placeholder: {
          ar: "اكتب هنا...",
          en: "Type here...",
        },
        errors: {
          required: {
            ar: "ادخل تخصص شركتك",
            en: "Enter your company speciality",
          },
        },
      },
    },
    brandName: {
      label: {
        ar: "هل مطلوب اسم دعائي للفيديو؟",
        en: "Is a brand name required for the video?",
      },
      errors: {
        required: {
          ar: "من فضلك اختار نعم أو لا",
          en: "Please choose Yes, or No",
        },
      },
    },
  },
  //
  //
  //
  stepSix: {
    videoPurpose: {
      label: {
        ar: "الهدف من الفيديو",
        en: "Video purpose",
      },
      errors: {
        required: {
          ar: "من فضلك اختار الهدف من الفيديو",
          en: "Please select video purpose",
        },
      },

      otherTextarea: {
        label: {
          ar: "اكتب هنا...",
          en: "Type here...",
        },
        errors: {
          required: {
            ar: "ادخل الهدف من الفيديو",
            en: "Enter video puropse",
          },
        },
      },
    },
    targetCategory: {
      label: {
        ar: "الفئة المستهدفة",
        en: "Target Category",
      },
      errors: {
        required: {
          ar: "من فضلك اختار الفئة المستهدفة",
          en: "Please select target category",
        },
      },
    },
  },
  //
  //
  //
  stepSeven: {
    targetSector: {
      label: {
        ar: "القطاع المستهدف",
        en: "Target Sector",
      },
      errors: {
        required: {
          ar: "من فضلك اختار القطاع المسهتدف",
          en: "Please select the target sector",
        },
      },

      otherTextarea: {
        label: {
          ar: "اكتب هنا...",
          en: "Type here...",
        },
        errors: {
          required: {
            ar: "ادخل القطاع المستهدف من الفيديو",
            en: "Enter video target sector",
          },
        },
      },
    },
  },
  //
  //
  //
  stepEight: {
    targetAudience: {
      label: {
        ar: "الجمهور المستهدف",
        en: "Target Audience",
      },
      errors: {
        required: {
          ar: "من فضلك اختار الجمهور المستهدف",
          en: "Please select the target audience",
        },
      },
      otherTextarea: {
        label: {
          ar: "اكتب هنا...",
          en: "Type here...",
        },
        errors: {
          required: {
            ar: "ادخل فئة الجمهور المستهدف من الفيديو",
            en: "Enter video target sector",
          },
        },
      },
    },
  },
  service7StepEight: {
    map: {
      title: {
        ar: "من فضلك اختار مكان التصوير",
        en: "Please select the video location",
      },
    },
  },
  //
  //
  //
  stepNine: {
    offerPackages: {
      title: {
        ar: "باقات العرض",
        en: "Offer packages",
      },
      label: {
        ar: "يرجي ذكرها",
        en: "Please mention...",
      },
      errors: {
        ar: "ادخل باقات العرض",
        en: "Enter offer packages",
      },
    },
    highlightPoint: {
      title: {
        ar: "ما هي نقطة التميز التي تريد ان تسلط الضوء عليها؟",
        en: "What is the point of distinction that you want to highlight?",
      },
      label: {
        ar: "يرجي ذكرها",
        en: "Please mention...",
      },
      errors: {
        ar: "ادخل نقطة التميز",
        en: "Enter the point of distinction",
      },
    },
  },
  service7StepNine: {
    date: {
      label: {
        ar: "تاريخ التصوير",
        en: "video date",
      },
      errors: {
        required: {
          ar: "من فضلك اختار تاريخ التصوير",
          en: "Please pick the video date!!",
        },
      },
    },
    time: {
      label: {
        ar: "وقت التصوير",
        en: "Video time",
      },
      errors: {
        required: {
          ar: "اختار وقت التصوير",
          en: "Please choose the video time",
        },
      },
    },
    contactNumber: {
      label: {
        ar: "رقم الاتصال",
        en: "Contact Number",
      },
      errors: {
        required: {
          ar: "من فضلك اكتب رقم الاتصال",
          en: "Please type the contact number",
        },
      },
    },
  },
  //
  //
  //
  stepTen: {
    isSocial: {
      title: {
        ar: "هل تريد اضافة عناوين التواصل الاجتماعى فى الفيديو؟",
        en: "Do you want social media links in your video?",
      },
      yes: {
        label: {
          ar: "نعم",
          en: "Yes",
        },
      },
      no: {
        label: {
          ar: "لا",
          en: "No",
        },
      },
      errors: {
        required: {
          ar: "اختيار واحد على الاقل",
          en: "At least one choice",
        },
      },
    },
    socialMedia: {
      title: {
        ar: "عناوين التواصل الاجتماعي التي تريد ظهورها في الفيديو",
        en: "The social media addresses that you want to appear in the video",
      },
      errors: {
        required: {
          ar: "اختيار واحد على الاقل",
          en: "At least one choice",
        },
      },
      textField: {
        errors: {
          ar: "ادخل العنوان",
          en: "Enter the address",
        },
      },
    },

    fontSelection: {
      title: {
        ar: "هل يوجد لديك خط معين معتمد في الكتابة؟",
        en: "Do you have a specific font approved in writing?",
      },
      errors: {
        required: {
          ar: "اختيار واحد على الاقل",
          en: "At least one choice",
        },
      },
      yes: {
        label: {
          ar: "نعم",
          en: "Yes",
        },
        errors: {
          required: {
            ar: "يرجى رفع الخط",
            en: "Please upload the font",
          },
          unSuppFormat: {
            ar: "صيغة الملف غير مقبولة",
            en: "Not supported file format",
          },
          tooLarge: {
            ar: "حجم الملف كبير",
            en: "The file is too large                                                                                    ",
          },
        },
      },
      no: {
        label: {
          ar: "لا",
          en: "No",
        },
      },
    },
    musicSelection: {
      title: {
        ar: "هل يوجد لديك ملف موسيقى معين؟",
        en: "Do you have a specific music file?",
      },
      errors: {
        required: {
          ar: "اختيار واحد على الاقل",
          en: "At least one choice",
        },
      },
      yes: {
        label: {
          ar: "نعم",
          en: "Yes",
        },
        errors: {
          required: {
            ar: "يرجى رفع الملف",
            en: "Please upload the file",
          },
          unSuppFormat: {
            ar: "صيغة الملف غير مقبولة",
            en: "Not supported file format",
          },
          tooLarge: {
            ar: "حجم الملف كبير",
            en: "The file is too large                                                                                    ",
          },
        },
      },
      no: {
        label: {
          ar: "لا",
          en: "No",
        },
      },
    },
    colorSelection: {
      title: {
        ar: "هل يوجد لديك لون خاص بالشركة؟",
        en: "Do you have a specific color for the company?",
      },
      errors: {
        required: {
          ar: "اختيار واحد على الاقل",
          en: "At least one choice",
        },
      },
      yes: {
        label: {
          ar: "نعم",
          en: "Yes",
        },
        errors: {
          required: {
            ar: "يرجى رفع ملف الالوان",
            en: "Please upload the color file",
          },
          unSuppFormat: {
            ar: "صيغة الملف غير مقبولة",
            en: "Not supported file format",
          },
          tooLarge: {
            ar: "حجم الملف كبير",
            en: "The file is too large                                                                                    ",
          },
        },
      },
      no: {
        label: {
          ar: "لا",
          en: "No",
        },
      },
    },
  },
  service6StepTen: {
    point:{
      ar:'ما هي نقطة التميز التي تريد ان تسلط الضوء عليها',
      en:"What is the point of excellence that you want to highlight?"
    },
    motion: {
      label: {
        ar: "هل تريد اضافة موشن خفيف بنسبة ٣٠%",
        en: "Do you want to add a 30% light motion?",
      },
      errors: {
        required: {
          ar: "من فضلك اختار الموشن",
          en: "Please select video motion",
        },
      },
    },
    isFilm: {
      label: {
        ar: "هل المادة الفلمية والصور من عند الزبون؟",
        en: "Does film materials and photos form the customer?",
      },
      errors: {
        required: {
          ar: "من فضلك اختار من الاعلى",
          en: "Please select from above",
        },
      },
    },
  },
  service7StepTen: {
    daysNum: {
      label: {
        ar: "عدد ايام التصوير",
        en: "Number of Filming days",
      },
      errors: {
        required: {
          ar: "من فضلك ادخل عدد الايام",
          en: "Please enter the number of days",
        },
        validNumber: {
          ar: "لا يسمح الا بالارقام",
          en: "Only numbers allowed",
        },
      },
    },
    personNum: {
      label: {
        ar: "عدد الاشخاص",
        en: "Number of people",
      },
      errors: {
        required: {
          ar: "من فضلك ادخل عدد الاشخاص",
          en: "Please enter the number of people",
        },
        validNumber: {
          ar: "لا يسمح الا بالارقام",
          en: "Only numbers allowed",
        },
      },
    },
    sessionAreaType: {
      label: {
        ar: "نوع منطقة التصوير",
        en: "Session Area Type",
      },
      errors: {
        required: {
          ar: "من فضلك اختار نوع منطقة التصوير",
          en: "Please select the session area type",
        },
      },
    },
    photoType: {
      label: {
        ar: "نوع التصوير",
        en: "Film Type",
      },
      errors: {
        required: {
          ar: "من فضلك اختار نوع التصوير",
          en: "Please select the film type",
        },
      },
    },
  },
};

export default serviceDetailsLocalization;
