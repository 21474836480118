import React, { useContext } from 'react';
import UserContext from '../contexts/user-context/UserProvider';
import { useHistory } from 'react-router-dom';
import routerLinks from '../components/app/routerLinks';
import useCustomApiRequest from './useCustomApiRequest';
import { signup } from '../apis/auth/signup';
import LangContext from '../contexts/lang-context/LangProvider';
import FlashMessageContext from '../contexts/flash-messages-context/FlashMessageProvider';

const useSignupEmailPassword = () => {
  const { language } = useContext(LangContext);
  const history = useHistory();
  const { setUserToState } = useContext(UserContext);
  const {
    setFlashMsgIsOpen,
    setFlashMsg,
    setFlashMsgDuration,
    setNotificationSeverity
  } = useContext(FlashMessageContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignup, setIsLoadingSignup] = React.useState(false);
  const signMeUpWithEmailPassword = (accountType, data, resetForm) => {
    setIsLoadingSignup(true);
    const formData = new FormData();
    data?.type && formData.append('type', data.type);
    data?.f_name && formData.append('f_name', data.f_name);
    data?.l_name && formData.append('l_name', data.l_name);
    data?.email && formData.append('email', data.email);
    data?.mobile && formData.append('mobile', data.mobile);
    data?.password && formData.append('password', data.password);
    data?.password_confirmation &&
      formData.append('password_confirmation', data.password_confirmation);
    data?.accept_term &&
      formData.append('accept_term', data.accept_term ? '1' : '0');
    data?.social_id && formData.append('social_id', data.social_id);
    data?.social_type && formData.append('social_type', data.social_type);
    data?.zip_code && formData.append('zip_code', data.zip_code);

    customApiRequest(
      signup(formData, language?.code),
      (res) => {
        setIsLoadingSignup(false);
        if (res?.data?.status === 0) {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'البيانات المدخلة غير صحيحة');
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        } else if (
          res?.data?.status === 1 &&
          res?.data?.data?.mail_fails?.length > 0
        ) {
          setFlashMsgIsOpen(true);
          setFlashMsg(
            res?.data?.message || 'فشل الارسال الى البريد الالكترونى'
          );
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        } else if (res?.data?.status === 1 && !res?.data?.data) {
          // user not activated yet
          setUserToState({
            accountType,
            email: data?.email,
            activated: false
          });
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'Check your email for code');
          setFlashMsgDuration(4000);
          setNotificationSeverity('success');
          history.push(routerLinks?.activateAccountRoute);
        } else {
          setFlashMsgIsOpen(true);
          setFlashMsg(res?.data?.message || 'Something went wrong');
          setFlashMsgDuration(4000);
          setNotificationSeverity('error');
        }
      },

      (error) => {
        setIsLoadingSignup(false);
        setFlashMsgIsOpen(true);
        setFlashMsg(error?.response?.data?.message || 'Try again later');
        setFlashMsgDuration(4000);
        setNotificationSeverity('error');
      }
    );
  };

  return { signMeUpWithEmailPassword, isLoadingSignup, setIsLoadingSignup };
};

export default useSignupEmailPassword;
