/* eslint-disable eqeqeq */
import "./DesignerHome.scss";
import React, { useContext } from "react";
import DocumentIcon from "../../../common/icons/DocumentIcon";
import LangContext from "../../../contexts/lang-context/LangProvider";
import designerHomeLocalization from "../../../localization/designerHome.localization";
import DocTitleScrollTop from "../../../utils/DocTitleScrollTop";
import SharedBtn from "../../../common/shared-btn/SharedBtn";
import arrowLeft from "../../../assets/imgs/icons/arrows/arrow-left-white.svg";
import arrowRight from "../../../assets/imgs/icons/arrows/arrow-right-white.svg";
import routerLinks from "../../../components/app/routerLinks";
import UesrContext from "../../../contexts/user-context/UserProvider";
import userTypes from "../../../constants/userTypes";
import employeeJobApplication from "../../../constants/employeeJobApplication";

const DesignerHome = () => {
  DocTitleScrollTop("Welcome");
  const { welcome, applyText, fillBtn ,underReview} = designerHomeLocalization;
  const {
    language: { dir, code },
  } = useContext(LangContext);
  const { user } = useContext(UesrContext);

  return (
    <div className="designer-home">
      <h1>
        {welcome[code]} <span className="username">{user?.f_name}</span>
      </h1>
      <div className="main-content">
        <DocumentIcon className="doc-icon" color="#D50606" />

        {/* status == 0 ===> didint apply */}
        {/* {user?.accountType === userTypes?.employee &&
            user?.job_application == employeeJobApplication?.none && (
              <>
                <p className="apply-text">{applyText[code]}</p>
                <SharedBtn
                  buttonComp={false}
                  to={routerLinks.designerApplication}
                  btnClass="signout-link"
                >
                  <span>{fillBtn[code]}</span>
                  <img
                    src={dir === "rtl" ? arrowLeft : arrowRight}
                    alt="arrow icon"
                  />
                </SharedBtn>
              </>
            )} */}
        {
          user?.job_application == employeeJobApplication?.none && (
            <>
              <p className="apply-text">{applyText[code]}</p>
              <SharedBtn
                buttonComp={false}
                to={user?.accountType === "employee" ? routerLinks.designerApplication : routerLinks.supervisorApplication}
                btnClass="signout-link"
              >
                <span>{fillBtn[code]}</span>
                <img
                  src={dir === "rtl" ? arrowLeft : arrowRight}
                  alt="arrow icon"
                />
              </SharedBtn>
            </>
          )}

        {/* staus == pending ====> Under review status */}
        {/* {user?.accountType === userTypes?.employee &&
            user?.job_application == employeeJobApplication?.pending && (
              <h2 className="apply-text">Your application is under review</h2>
            )} */}
        {
          user?.job_application == employeeJobApplication?.pending && (
            <h2 className="apply-text">{underReview[code]}</h2>
          )}
        {/* status == 3 ====>rejected */}
        {/* {user?.accountType === 2 &&
          user?.employee &&
          user.employee.status === 3 && (
            <h2 className="apply-text">Your application is Rejected</h2>
          )} */}
      </div>
    </div>
  );
};

export default DesignerHome;
