/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { getServices } from '../apis/services';
import LangContext from '../contexts/lang-context/LangProvider';
import UesrContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useDesignerServices = () => {
  const {
    language: { code }
  } = useContext(LangContext);
  const { user } = useContext(UesrContext);

  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [allServices, setAllServices] = useState([]);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const fetchServices = () => {
        setIsLoadingServices(true);
        customApiRequest(
          getServices(user, code),
          (res) => {
            setIsLoadingServices(false);
            if (checkRes(res) && res?.data?.data) {
              setAllServices(res.data.data);
            }
          },
          (error) => {
            setIsLoadingServices(false);
          }
        );
      };
      fetchServices();
    }

    return () => (isMounted = false);
  }, [code]);

  return {
    isLoadingServices,
    setIsLoadingServices,
    allServices,
    setAllServices
  };
};

export default useDesignerServices;
