import React, { useReducer } from 'react'
import { SocialContext, SocialDispatchContext } from './socialContext'
import { socialReducer } from './socialReducer'

const SocialProvider = ({ children }) => {
    const initialState = {
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        linkedin: "",
        tiktok: "",
        whatsapp: "",
        snap: "",
        email:"",
        address: "",
        mobile: "",
        isLoading:true

    }

    const [social, dispatch] = useReducer(socialReducer, initialState)

    return (
        <SocialContext.Provider value={social}>
            {/* @ts-ignore */}
            <SocialDispatchContext.Provider value={dispatch}>

                {children}
            </SocialDispatchContext.Provider>

        </SocialContext.Provider>
    )
}

export default SocialProvider